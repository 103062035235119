import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Divider } from '@mui/material';
import Button from 'shared/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import theme from 'theme';
// @ts-ignore
import CCApplicationStatus from '@carclarity/cc-application-status';
import SlideOutPane from 'shared/SlideOutPane';
import { Referral } from 'pages/referrals/Referrals';
import NotesIcon from '../../icons/notes.svg';

interface ReferralCardProps {
  id: string;
  name: string;
  loanAmount: number | string;
  updatedAt: string;
  status?: string;
  reason?: string;
  details?: Referral;
  isKanbanView?: boolean;
}

const ReferralCard: React.FC<ReferralCardProps> = ({ id, name, loanAmount, updatedAt, status, reason, details, isKanbanView = false }) => {
  const [isPaneOpen, setIsPaneOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);

  const handlePaneOpen = (activeTab: number) => {
    setActiveTab(activeTab);
    setIsPaneOpen(true);
  };

  const handlePaneClose = () => {
    setIsPaneOpen(false);
  };

  return (
    <Card sx={{ boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05) !important', borderRadius: '4px', border: '0.5px solid #e8e8e8 !important' }}>
      <CardContent sx={{ px: '12px', paddingBottom: '10px !important' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize="0.75rem" color="#303030">
            {id}
          </Typography>
          {isKanbanView ? '' : <CCApplicationStatus status={status} reason={reason} />}
        </Box>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="14px" fontWeight="800">
            {name}
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="space-between">
            <Typography fontSize="12px" color="textSecondary">
              Loan amount:
            </Typography>
            <Typography fontSize="14px" color={loanAmount === '--' ? 'textSecondary' : '#00b849'} fontWeight={loanAmount === '--' ? '500' : '800'}>
              {loanAmount}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mt: 2 }} />
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={0.5}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
            <Typography fontSize="12px" color="textSecondary">
              Last updated at:
            </Typography>
            <Typography fontSize="12px" color="#303030" fontWeight="500">
              {updatedAt}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="row">
            <Button variantType="full" sx={{ minWidth: '10px', width: '20px', height: '20px', backgroundColor: '#fff0c1', alignItems: 'center', marginRight: '15px' }} isImage onPress={() => handlePaneOpen(3)}>
              <img src={NotesIcon} alt="Notes" style={{ width: '16px', height: '15px' }} />
            </Button>
            <Button variantType="full" sx={{ minWidth: '10px', width: '20px', height: '20px', backgroundColor: '#f6eefd', alignItems: 'center' }} isImage onPress={() => handlePaneOpen(0)}>
              <VisibilityIcon color="inherit" fontSize="inherit" sx={{ fontSize: '16px', color: theme.palette.primary.main }} />
            </Button>
          </Box>
        </Box>
      </CardContent>
      <SlideOutPane open={isPaneOpen} onClose={handlePaneClose} details={details} activeTab={activeTab} />
    </Card>
  );
};

export default ReferralCard;
