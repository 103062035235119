import React, { useState, useEffect } from 'react';
import DataTable from 'shared/DataTable';
import { Box, CircularProgress } from '@mui/material';
import Button from 'shared/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import theme from 'theme';
import { Settlement } from 'pages/referrals/Settlements';
import SlideOutPane from 'shared/SlideOutPane';
import NotesIcon from '../../icons/notes.svg';

interface Column {
  id: string;
  label: string;
  sortable?: boolean;
  minWidth?: string;
  maxWidth?: string;
  render?: (value: any, row: any) => React.ReactNode;
}

interface SettlementsTableProps {
  data: Settlement[];
  form: any;
  setForm: React.Dispatch<React.SetStateAction<any>>;
  fetchSettlements: (reset: boolean) => void;
  totalSettlements: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const SettlementsTableView: React.FC<SettlementsTableProps> = ({ data, setForm, fetchSettlements, totalSettlements, page, setPage }) => {
  const columns: Column[] = [
    { id: 'id', label: 'ID', sortable: false, minWidth: '65px' },
    { id: 'User', label: 'Customer', sortable: true, minWidth: '140px' },
    { id: 'naf', label: 'NAF', sortable: false, minWidth: '90px' },
    { id: 'commission', label: 'Commission', sortable: false, minWidth: '90px' },
    { id: 'agent', label: 'Agent', sortable: false, minWidth: '96px' },
    { id: 'subaccount', label: 'Subaccount', sortable: true, minWidth: '100px' },
    { id: 'referrer', label: 'Referrer', sortable: true, minWidth: '100px' },
    { id: 'createdAt', label: 'Created at', sortable: true, minWidth: '100px' },
    { id: 'updatedAt', label: 'Last update', sortable: true, minWidth: '130px' },
    {
      id: 'action',
      label: 'Action',
      sortable: false,
      minWidth: '155px',
      render: (value, row) => (
        <Box display="flex" flexDirection="row">
          <Button variantType="full" sx={{ minWidth: '10px', width: '20px', height: '20px', backgroundColor: '#f6eefd', alignItems: 'center', marginRight: '15px' }} isImage onPress={() => handlePaneOpen(0, row)}>
            <VisibilityIcon color="inherit" fontSize="inherit" sx={{ fontSize: '16px', color: theme.palette.primary.main }} />
          </Button>
          <Button variantType="full" sx={{ minWidth: '10px', width: '20px', height: '20px', backgroundColor: '#fff0c1', alignItems: 'center', marginRight: '15px' }} isImage onPress={() => handlePaneOpen(3, row)}>
            <img src={NotesIcon} alt="Notes" style={{ width: '16px', height: '15px' }} />
          </Button>
        </Box>
      ),
    },
  ];

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('-createdAt');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPaneOpen, setIsPaneOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<Settlement | null>(null);

  const handlePaneOpen = (activeTab: number, row: Settlement) => {
    setActiveTab(activeTab);
    setSelectedRow(row);
    setIsPaneOpen(true);
  };

  const handlePaneClose = () => {
    setIsPaneOpen(false);
    setSelectedRow(null);
  };

  useEffect(() => {}, [order]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    setForm((prevForm: any) => ({ ...prevForm, sort: (newOrder === 'desc' ? '-' : '') + property }));
    setIsLoading(true);
    fetchSettlements(true);
  };

  useEffect(() => {
    if (!isLoading) return;
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, [data]);

  return isLoading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <DataTable columns={columns} data={data} count={totalSettlements} order={order} orderBy={orderBy} page={page} rowsPerPage={20} onRequestSort={handleRequestSort} setPage={setPage} loading={isLoading} setLoading={setIsLoading} />
      <SlideOutPane open={isPaneOpen} onClose={handlePaneClose} details={selectedRow} activeTab={activeTab} />
    </Box>
  );
};

export default SettlementsTableView;
