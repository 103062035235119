import React, { useState, useEffect, useCallback } from 'react';
import { Box, CircularProgress, IconButton, useMediaQuery } from '@mui/material';
import debounce from 'lodash/debounce';
import PageHeader from 'components/PageHeader';
import ReferralsFilterPanel from 'components/ReferralsFilterPanel';
import ReferralsContentArea from 'components/ReferralsContentArea';
import Sidebar from '../../../shared/SideBar';
import theme from '../../../theme';
import MenuIcon from '../../../icons/menu.svg';
import { LABEL_FOR_GRID, LABEL_FOR_KANBAN } from '../../../constants/applications';
import { fetchReferrals, handleStatusChange, handleSortChange, handleViewChange, handleDeleteChip, fetchMoreReferralsForColumn } from '../../../helpers/utils';

export interface Referral {
  id: string;
  User: string;
  status: string;
  updatedAt: string;
  loanAmount: number | string;
  agent?: string;
  subaccount?: string;
  referrer?: string;
  createdAt?: string;
  details?: string;
}

export interface ReferralStatus {
  sort: string;
  status: string[];
  search?: string;
}

const Referrals: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpen, setIsOpen] = useState<boolean>(!isMobile);
  const [activeGrid, setActiveGrid] = useState<string>(LABEL_FOR_GRID);
  const [form, setForm] = useState<ReferralStatus>({ sort: '-createdAt', status: ['All'] });
  const [isViewLoading, setIsViewLoading] = useState<boolean>(false);
  const [referralList, setReferralList] = useState<Referral[]>([]);
  const [totalReferrals, setTotalReferrals] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [kanbanData, setKanbanData] = useState<Record<string, Referral[]>>({});
  const [page, setPage] = useState<number>(1);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [columnPages, setColumnPages] = useState<Record<string, number>>({});
  const [totalDocs, setTotalDocs] = useState<Record<string, number>>({});

  const toggleDrawer = () => setIsOpen(!isOpen);
  const handleMenu = (event: { currentTarget: any }) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setForm((prev) => ({ ...prev, search: value }));
      fetchReferralsWrapper(true);
    }, 600),
    []
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const fetchReferralsWrapper = (reset = false) => {
    if (reset) {
      setPage(1);
      setReferralList([]);
      setIsFetching(false);
    } else if (totalPages !== page) {
      setPage((prev) => prev + 1);
    }
  };

  const loadMoreReferralsForColumn = (status: string, setColumnLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    const nextPage = (columnPages[status] || 1) + 1;
    setColumnPages((prev) => ({ ...prev, [status]: nextPage }));
    fetchMoreReferralsForColumn(status, nextPage, setColumnLoading, setKanbanData, setTotalDocs);
  };

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      fetchReferrals(activeGrid, form, setIsViewLoading, setKanbanData, setTotalReferrals, setReferralList, setTotalDocs, searchTerm, page, page === 1)
        .then((response) => {
          setTotalPages(response?.totalPages || 0);
        })
        .finally(() => setIsFetching(false));
    }
  }, [form, activeGrid, page, searchTerm]);

  useEffect(() => {
    if (page > 1 && !isFetching) {
      setIsFetching(true);
      fetchReferrals(activeGrid, form, setIsViewLoading, setKanbanData, setTotalReferrals, setReferralList, setTotalDocs, searchTerm, page, false)
        .then((response) => {
          setTotalPages(response?.totalPages || 0);
        })
        .finally(() => setIsFetching(false));
    }
  }, [page]);

  const handleViewChangeWithReset = (view: string) => {
    setSearchTerm('');
    setForm((prev) => ({ ...prev, search: '' }));
    handleViewChange(view, setActiveGrid, setPage, fetchReferrals, form, setIsViewLoading, setKanbanData, setTotalReferrals, setReferralList, setTotalDocs);
  };

  const groupedReferrals =
    activeGrid === LABEL_FOR_KANBAN
      ? kanbanData
      : referralList.reduce(
          (acc, referral) => {
            const { status } = referral;
            if (!acc[status]) {
              acc[status] = [];
            }
            acc[status].push(referral);
            return acc;
          },
          {} as Record<string, Referral[]>
        );

  return (
    <Box height="100vh" display="flex">
      {isOpen && (
        <IconButton sx={{ marginLeft: '10px', marginTop: '5px', position: 'absolute', zIndex: 10000 }} onClick={toggleDrawer} edge="start" color="inherit" aria-label="menu">
          <img src={MenuIcon} alt="menu" height="24px" />
        </IconButton>
      )}
      <Sidebar
        open={isOpen}
        menuItems={[
          { text: 'Referrals', path: '/referrals' },
          { text: 'Settlements', path: '/referrals/settlements' },
        ]}
        color={theme.palette.primary.main}
        drawerStyle={{
          position: 'relative',
          borderRight: '1px solid #e8e8e8',
          backgroundColor: '#fcf8fd',
          width: isOpen ? '220px' : '0px',
          height: '100%',
          paddingTop: '40px',
          paddingLeft: '5px',
          flexShrink: 0,
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100vh', overflow: 'hidden' }}>
        <PageHeader headerName="Referrals" open={isOpen} toggleDrawer={toggleDrawer} handleMenu={handleMenu} handleClose={handleClose} anchorEl={anchorEl} />
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', backgroundColor: '#fafafa', paddingLeft: '10px' }}>
          <ReferralsFilterPanel
            activeGrid={activeGrid}
            form={form}
            handleStatusChange={(e) => handleStatusChange(e, form, setForm, setPage, setReferralList)}
            handleSortChange={(e) => handleSortChange(e, setForm)}
            handleSearchChange={handleSearchChange}
            searchTerm={searchTerm}
            totalReferrals={totalReferrals}
            handleViewChange={handleViewChangeWithReset}
            handleDeleteChip={(chipToDelete) => handleDeleteChip(chipToDelete, form, setForm, setPage, setReferralList, fetchReferralsWrapper)}
          />

          <Box sx={{ flex: 1, overflow: 'auto' }}>
            {isViewLoading && page === 1 ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <ReferralsContentArea
                setForm={setForm}
                isViewLoading={isViewLoading}
                activeGrid={activeGrid}
                referralList={referralList}
                groupedReferrals={groupedReferrals}
                fetchReferrals={fetchReferralsWrapper}
                kanbanColumns={[
                  { title: 'New' },
                  { title: 'In Progress' },
                  { title: 'Callback Requested' },
                  { title: 'Unqualified' },
                  { title: 'Exhausted' },
                  { title: 'Lost' },
                  { title: 'Application in Progress' },
                  { title: 'Submitted to Lender' },
                  { title: 'Application Withdrawn' },
                  { title: 'Approved' },
                  { title: 'Declined' },
                  { title: 'Pending Settlement' },
                  { title: 'Won' },
                ]}
                form={form}
                loadMoreReferrals={fetchReferralsWrapper}
                fetchMoreReferralsForColumn={loadMoreReferralsForColumn}
                setKanbanData={setKanbanData}
                totalDocs={totalDocs}
                setTotalDocs={setTotalDocs}
                page={page}
                setPage={setPage}
                setReferralList={setReferralList}
                totalReferrals={totalReferrals}
                searchTerm={searchTerm}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Referrals;
