import { SelectChangeEvent } from "@mui/material";
import axiosInstance from "axiosInstance";
import { datadogLogs } from "@datadog/browser-logs";
import { toCurrency } from "helpers/currency";
import { LABEL_FOR_KANBAN, LABEL_FOR_TABLE } from "constants/applications";
import { Settlement, SettlementStatus } from "pages/referrals/Settlements";
import { Referral, ReferralStatus } from "pages/referrals/Referrals";
import { SetStateAction } from "react";
/* eslint-disable */
export const fetchReferrals = async (
  activeGrid: string,
  form: ReferralStatus,
  setIsViewLoading: {
    (value: SetStateAction<boolean>): void;
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setKanbanData: {
    (value: SetStateAction<Record<string, Referral[]>>): void;
    (value: SetStateAction<Record<string, Referral[]>>): void;
    (arg0: Record<string, Referral[]>): void;
  },
  setTotalReferrals: {
    (value: SetStateAction<number>): void;
    (value: SetStateAction<number>): void;
    (arg0: any): void;
  },
  setReferralList: {
    (value: SetStateAction<Referral[]>): void;
    (value: SetStateAction<Referral[]>): void;
    (
      arg0: {
        id: any;
        status: any;
        updatedAt: string;
        User: any;
        agent: any;
        subAccount: any;
        details: any;
        loanAmount: string;
        referrer: any;
        createdAt: string;
        term: any;
        loanType: any;
        asset: any;
        tradeIn: any;
        firstName: any;
        lastName: any;
        mobile: any;
        email: any;
        state: any;
        preferredComm: any;
        customerConsent: string;
        type: any;
        businessName: any;
        abn: any;
      }[],
    ): void;
  },
  setTotalDocs: {
    (value: SetStateAction<Record<string, number>>): void;
    (value: SetStateAction<Record<string, number>>): void;
    (arg0: Record<string, number>): void;
  },
  searchTerm: string | any[],
  page: number,
  reset: boolean,
) => {
  setIsViewLoading(true);
  try {
    const statusString = form.status.join(",");
    const sortString = form.sort;
    const isKanbanView = activeGrid === LABEL_FOR_KANBAN;
    const isTableView = activeGrid === LABEL_FOR_TABLE;
    const isShortSearchTerm = !searchTerm || searchTerm.length < 2;

    const baseUri = `${process.env.REACT_APP_API_URL}/vehicle-request-groups?&status=${encodeURIComponent(statusString)}&sort=${encodeURIComponent(sortString)}&page=${page}&isAPICalledForKanBanView=${isKanbanView}`;
    const uri = isShortSearchTerm ? baseUri : `${baseUri}&search=${searchTerm}`;

    const response = await axiosInstance.get(uri);

    if (response?.data) {
      if (isKanbanView) {
        const kanbanData = response.data as Record<string, { docs: any[] }>;
        const mappedData = Object.entries(kanbanData).reduce(
          (acc, [status, { docs }]) => {
            acc[status] = docs.map((item) => ({
              id: item?.application?.shortId,
              status: item?.sFOpportunity?.stage || item?.sFLead?.status,
              updatedAt:
                new Date(item?.updatedAt)
                  .toLocaleDateString("en-GB")
                  .replace(/\//g, "-") +
                " " +
                new Date(item?.updatedAt).toLocaleTimeString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                }),
              User: item?.primaryUser?.fullName,
              agent: item?.owner?.name || "--",
              subAccount: item?.referrerSubaccount?.businessName || "--",
              details: item?.application?.withdrawnReasonType,
              loanAmount:
                item?.finance?.application?.loanAmount === 0
                  ? "--"
                  : toCurrency(item?.finance?.application?.loanAmount),
              referrer: item?.referrerUser?.fullName,
              createdAt: new Date(item?.createdAt)
                .toLocaleDateString("en-GB")
                .replace(/\//g, "-"),
              term: item?.finance?.application?.loanTermYears,
              loanType: item?.finance?.application?.loanType,
              asset:
                item?.finance?.application?.vehicle?.customerNote &&
                item?.finance?.application?.vehicle?.customerNote.trim() !== ""
                  ? item?.finance?.application?.vehicle?.customerNote
                  : item?.finance?.application?.vehicle?.year ||
                      item?.finance?.application?.vehicle?.makeDescription ||
                      item?.finance?.application?.vehicle?.familyDescription ||
                      item?.finance?.application?.vehicle?.codeDescription
                    ? `${item?.finance?.application?.vehicle?.year || ""} ${item?.finance?.application?.vehicle?.makeDescription || ""} ${item?.finance?.application?.vehicle?.familyDescription || ""} ${item?.finance?.application?.vehicle?.codeDescription || ""}`.trim()
                    : "--",
              tradeIn:
                item?.finance?.application?.tradeIn?.vehicle?.customerNote ||
                "--",
              firstName: item?.primaryUser?.firstName,
              lastName: item?.primaryUser?.lastName,
              mobile: item?.primaryUser?.mobile,
              email: item?.primaryUser?.email,
              state:
                item?.application?.primaryApplicantData?.addresses[0]?.state ||
                "--",
              preferredComm:
                item?.primaryUser?.preferredCommunicationMethods || "--",
              customerConsent:
                item?.finance?.application?.primaryApplicantData
                  ?.agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt ==
                null
                  ? "--"
                  : new Date(
                      item?.finance?.application?.primaryApplicantData?.agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt,
                    )
                      .toLocaleDateString("en-GB")
                      .replace(/\//g, "-") +
                    " " +
                    new Date(
                      item?.finance?.application?.primaryApplicantData?.agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt,
                    ).toLocaleTimeString("en-GB", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    }),
              type: item?.finance?.application?.applicationType,
              businessName:
                item?.finance?.application?.businessData?.businessName,
              abn: item?.finance?.application?.businessData?.aBN,
            }));
            return acc;
          },
          {} as Record<string, Referral[]>,
        );

        const totalDocs = Object.keys(kanbanData).reduce(
          (acc, status) => {
            acc[status] = response.data[status].totalDocs;
            return acc;
          },
          {} as Record<string, number>,
        );

        setKanbanData(mappedData);
        setTotalDocs(totalDocs);
      } else {
        const docs = response.data.docs as any[];
        const mappedData = docs.map((item) => ({
          id: item?.application?.shortId,
          status: item?.sFOpportunity?.stage || item?.sFLead?.status,
          updatedAt:
            new Date(item?.updatedAt)
              .toLocaleDateString("en-GB")
              .replace(/\//g, "-") +
            " " +
            new Date(item?.updatedAt).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
          User: item?.primaryUser?.fullName,
          agent: item?.owner?.name || "--",
          subAccount: item?.referrerSubaccount?.businessName || "--",
          details: item?.application?.withdrawnReasonType,
          loanAmount:
            item?.finance?.application?.loanAmount || isTableView
              ? toCurrency(item?.finance?.application?.loanAmount)
              : "--",
          referrer: item?.referrerUser?.fullName,
          createdAt: new Date(item?.createdAt)
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-"),
          term: item?.finance?.application?.loanTermYears,
          loanType: item?.finance?.application?.loanType,
          asset:
            item?.finance?.application?.vehicle?.customerNote &&
            item?.finance?.application?.vehicle?.customerNote.trim() !== ""
              ? item?.finance?.application?.vehicle?.customerNote
              : item?.finance?.application?.vehicle?.year ||
                  item?.finance?.application?.vehicle?.makeDescription ||
                  item?.finance?.application?.vehicle?.familyDescription ||
                  item?.finance?.application?.vehicle?.codeDescription
                ? `${item?.finance?.application?.vehicle?.year || ""} ${item?.finance?.application?.vehicle?.makeDescription || ""} ${item?.finance?.application?.vehicle?.familyDescription || ""} ${item?.finance?.application?.vehicle?.codeDescription || ""}`.trim()
                : "--",
          tradeIn:
            item?.finance?.application?.tradeIn?.vehicle?.customerNote || "--",
          firstName: item?.primaryUser?.firstName,
          lastName: item?.primaryUser?.lastName,
          mobile: item?.primaryUser?.mobile,
          email: item?.primaryUser?.email,
          state:
            item?.application?.primaryApplicantData?.addresses[0]?.state ||
            "--",
          preferredComm:
            item?.primaryUser?.preferredCommunicationMethods || "--",
          customerConsent:
            item?.finance?.application?.primaryApplicantData
              ?.agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt ==
            null
              ? "--"
              : new Date(
                  item?.finance?.application?.primaryApplicantData?.agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt,
                )
                  .toLocaleDateString("en-GB")
                  .replace(/\//g, "-") +
                " " +
                new Date(
                  item?.finance?.application?.primaryApplicantData?.agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt,
                ).toLocaleTimeString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                }),
          type: item?.finance?.application?.applicationType,
          businessName: item?.finance?.application?.businessData?.businessName,
          abn: item?.finance?.application?.businessData?.aBN,
        }));

        if (reset || isTableView) {
          setReferralList(mappedData);
        } else {
          setReferralList((prev) => [...prev, ...mappedData]);
        }
        setTotalReferrals(response?.data?.totalDocs);
      }
    }

    return response?.data;
  } catch (err) {
    console.error(err);
  } finally {
    setIsViewLoading(false);
  }
};

export const fetchMoreReferralsForColumn = async (
  status: string,
  page: number,
  setColumnLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setKanbanData: React.Dispatch<
    React.SetStateAction<Record<string, Referral[]>>
  >,
  setTotalDocs: React.Dispatch<React.SetStateAction<Record<string, number>>>,
) => {
  setColumnLoading(true);
  try {
    const baseUri = `${process.env.REACT_APP_API_URL}/vehicle-request-groups?&status=${encodeURIComponent(status)}&sort=-createdAt&page=${page}&isAPICalledForKanBanView=true`;

    const response = await axiosInstance.get(baseUri);
    if (response?.data) {
      const newDocs = response.data[status]?.docs || [];
      const totalDocs = response.data[status]?.totalDocs || 0;
      const mappedData = newDocs.map(
        (item: {
          application: {
            shortId: any;
            withdrawnReasonType: any;
            primaryApplicantData: { addresses: { state: any }[] };
          };
          sFOpportunity: { stage: any };
          sFLead: { status: any };
          updatedAt: string | number | Date;
          primaryUser: {
            fullName: any;
            firstName: any;
            lastName: any;
            mobile: any;
            email: any;
            preferredCommunicationMethods: any;
          };
          owner: { name: any };
          referrerSubaccount: { businessName: any };
          finance: {
            application: {
              loanAmount: number | undefined;
              loanTermYears: any;
              loanType: any;
              vehicle: {
                customerNote: string;
                year: any;
                makeDescription: any;
                familyDescription: any;
                codeDescription: any;
              };
              tradeIn: { vehicle: { customerNote: any } };
              primaryApplicantData: {
                agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt:
                  | string
                  | number
                  | Date
                  | null;
              };
              applicationType: any;
              businessData: { businessName: any; aBN: any };
            };
          };
          referrerUser: { fullName: any };
          createdAt: string | number | Date;
        }) => ({
          id: item?.application?.shortId,
          status: item?.sFOpportunity?.stage || item?.sFLead?.status,
          updatedAt:
            new Date(item?.updatedAt)
              .toLocaleDateString("en-GB")
              .replace(/\//g, "-") +
            " " +
            new Date(item?.updatedAt).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }),
          User: item?.primaryUser?.fullName,
          agent: item?.owner?.name || "--",
          subAccount: item?.referrerSubaccount?.businessName || "--",
          details: item?.application?.withdrawnReasonType,
          loanAmount:
            item?.finance?.application?.loanAmount === 0
              ? "--"
              : toCurrency(item?.finance?.application?.loanAmount),
          referrer: item?.referrerUser?.fullName,
          createdAt: new Date(item?.createdAt)
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-"),
          term: item?.finance?.application?.loanTermYears,
          loanType: item?.finance?.application?.loanType,
          asset:
            item?.finance?.application?.vehicle?.customerNote &&
            item?.finance?.application?.vehicle?.customerNote.trim() !== ""
              ? item?.finance?.application?.vehicle?.customerNote
              : item?.finance?.application?.vehicle?.year ||
                  item?.finance?.application?.vehicle?.makeDescription ||
                  item?.finance?.application?.vehicle?.familyDescription ||
                  item?.finance?.application?.vehicle?.codeDescription
                ? `${item?.finance?.application?.vehicle?.year || ""} ${item?.finance?.application?.vehicle?.makeDescription || ""} ${item?.finance?.application?.vehicle?.familyDescription || ""} ${item?.finance?.application?.vehicle?.codeDescription || ""}`.trim()
                : "--",
          tradeIn:
            item?.finance?.application?.tradeIn?.vehicle?.customerNote || "--",
          firstName: item?.primaryUser?.firstName,
          lastName: item?.primaryUser?.lastName,
          mobile: item?.primaryUser?.mobile,
          email: item?.primaryUser?.email,
          state:
            item?.application?.primaryApplicantData?.addresses[0]?.state ||
            "--",
          preferredComm:
            item?.primaryUser?.preferredCommunicationMethods || "--",
          customerConsent:
            item?.finance?.application?.primaryApplicantData
              ?.agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt ==
            null
              ? "--"
              : new Date(
                  item?.finance?.application?.primaryApplicantData?.agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt,
                )
                  .toLocaleDateString("en-GB")
                  .replace(/\//g, "-") +
                " " +
                new Date(
                  item?.finance?.application?.primaryApplicantData?.agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt,
                ).toLocaleTimeString("en-GB", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                }),
          type: item?.finance?.application?.applicationType,
          businessName: item?.finance?.application?.businessData?.businessName,
          abn: item?.finance?.application?.businessData?.aBN,
        }),
      );

      setKanbanData((prevData) => ({
        ...prevData,
        [status]: [...(prevData[status] || []), ...mappedData],
      }));
      setTotalDocs((prevTotalDocs) => ({
        ...prevTotalDocs,
        [status]: totalDocs,
      }));
    }
  } catch (err: any) {
    datadogLogs.logger.error(err);
  } finally {
    setColumnLoading(false);
  }
};

export const fetchSettlements = async (
  form: any[] | SettlementStatus,
  setIsViewLoading: {
    (value: SetStateAction<boolean>): void;
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
  setTotalSettlements: {
    (value: SetStateAction<number>): void;
    (value: SetStateAction<number>): void;
    (arg0: any): void;
  },
  setSettlementList: {
    (value: SetStateAction<Settlement[]>): void;
    (value: SetStateAction<Settlement[]>): void;
    (
      arg0: {
        id: any;
        status: any;
        updatedAt: string;
        User: any;
        agent: any;
        subAccount: any;
        details: any;
        commission: string;
        naf: string;
        referrer: any;
        createdAt: string;
        term: any;
        loanType: any;
        asset: any;
        tradeIn: any;
        firstName: any;
        lastName: any;
        mobile: any;
        email: any;
        state: any;
        preferredComm: any;
        customerConsent: string;
        type: any;
        businessName: any;
        abn: any;
      }[],
    ): void;
  },
  setTotalPages: React.Dispatch<React.SetStateAction<number>>,
  searchTerm: string | any[],
  page: number,
  reset: boolean,
) => {
  setIsViewLoading(true);
  try {
    const sortString = typeof form.sort === "string" ? form.sort : "";
    const isShortSearchTerm = !searchTerm || searchTerm.length < 2;

    const baseUri = `${process.env.REACT_APP_API_URL}/vehicle-request-groups?&status=Won&sort=${encodeURIComponent(sortString)}&page=${page}&isAvgNAFAndTotalCommissionRequired=true`;
    const uri = isShortSearchTerm ? baseUri : `${baseUri}&search=${searchTerm}`;

    const response = await axiosInstance.get(uri);

    if (response?.data) {
      const docs = response.data.docs as any[];
      const mappedData = docs.map((item) => ({
        id: item?.application?.shortId,
        status: item?.sFOpportunity?.stage || item?.sFLead?.status,
        updatedAt:
          new Date(item?.updatedAt)
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-") +
          " " +
          new Date(item?.updatedAt).toLocaleTimeString("en-GB", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
        User: item?.primaryUser?.fullName,
        agent: item?.owner?.name,
        subAccount: item?.referrerSubaccount?.businessName || "--",
        details: item?.application?.withdrawnReasonType,
        commission: toCurrency(
          item?.finance?.application?.loanDetails?.settlement
            ?.partnerFinanceReferralFee,
        ),
        naf: toCurrency(
          item?.finance?.application?.loanDetails?.settlement
            ?.netAmountFinanced,
        ),
        referrer: item?.referrerUser?.fullName,
        createdAt: new Date(item?.createdAt)
          .toLocaleDateString("en-GB")
          .replace(/\//g, "-"),
        term: item?.finance?.application?.loanTermYears,
        loanType: item?.finance?.application?.loanType,
        asset:
          item?.finance?.application?.vehicle?.customerNote ||
          item?.finance?.application?.vehicle?.year +
            " " +
            item?.finance?.application?.vehicle?.makeDescription +
            " " +
            item?.finance?.application?.vehicle?.familyDescription +
            " " +
            item?.finance?.application?.vehicle?.codeDescription ||
          "--",
        tradeIn:
          item?.finance?.application?.tradeIn?.vehicle?.customerNote || "--",
        firstName: item?.primaryUser?.firstName,
        lastName: item?.primaryUser?.lastName,
        mobile: item?.primaryUser?.mobile,
        email: item?.primaryUser?.email,
        state:
          item?.application?.primaryApplicantData?.addresses[0]?.state || "--",
        preferredComm: item?.primaryUser?.preferredCommunicationMethods || "--",
        customerConsent:
          new Date(
            item?.finance?.application?.primaryApplicantData?.agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt,
          )
            .toLocaleDateString("en-GB")
            .replace(/\//g, "-") +
            " " +
            new Date(
              item?.finance?.application?.primaryApplicantData?.agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt,
            ).toLocaleTimeString("en-GB", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            }) || "--",
        type: item?.finance?.application?.applicationType,
        businessName: item?.finance?.application?.businessData?.businessName,
        abn: item?.finance?.application?.businessData?.aBN,
      }));

      if (reset) {
        setSettlementList(mappedData);
      } else {
        setSettlementList((prev) => [...prev, ...mappedData]);
      }

      setTotalSettlements(response?.data?.totalDocs);
      setTotalPages(response?.data?.totalPages || 0);
    }
    return response?.data;
  } catch (err) {
    console.error(err);
  } finally {
    setIsViewLoading(false);
  }
};

export const handleStatusChange = (
  event: SelectChangeEvent<string[]>,
  form: ReferralStatus,
  setForm: React.Dispatch<React.SetStateAction<ReferralStatus>>,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  setReferralList: React.Dispatch<React.SetStateAction<Referral[]>>,
) => {
  const value = event.target.value as string[];
  if (value.includes("All") && !form.status.includes("All")) {
    setForm({ ...form, status: ["All"] });
  } else if (form.status.includes("All") && value.length > 1) {
    const newStatusData = value.filter((status) => status !== "All");
    setForm({ ...form, status: newStatusData });
  } else {
    setForm({ ...form, status: value });
  }
  setPage(1);
  setReferralList([]);
};

export const handleSortChange = (
  event: SelectChangeEvent<string>,
  setForm: React.Dispatch<React.SetStateAction<ReferralStatus>>,
) => {
  setForm((prev) => ({ ...prev, sort: event.target.value as string }));
};

export const handleViewChange = (
  view: string,
  setActiveGrid: React.Dispatch<React.SetStateAction<string>>,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  fetchReferrals: (
    activeGrid: string,
    form: ReferralStatus,
    setIsViewLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setKanbanData: React.Dispatch<
      React.SetStateAction<Record<string, Referral[]>>
    >,
    setTotalReferrals: React.Dispatch<React.SetStateAction<number>>,
    setReferralList: React.Dispatch<React.SetStateAction<Referral[]>>,
    setTotalDocs: React.Dispatch<React.SetStateAction<Record<string, number>>>,
    searchTerm: string,
    page: number,
    reset: boolean,
  ) => Promise<void>,
  form: ReferralStatus,
  setIsViewLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setKanbanData: React.Dispatch<
    React.SetStateAction<Record<string, Referral[]>>
  >,
  setTotalReferrals: React.Dispatch<React.SetStateAction<number>>,
  setReferralList: React.Dispatch<React.SetStateAction<Referral[]>>,
  setTotalDocs: React.Dispatch<React.SetStateAction<Record<string, number>>>,
) => {
  setIsViewLoading(true);
  setActiveGrid(view);
  setPage(1);
  fetchReferrals(
    view,
    form,
    setIsViewLoading,
    setKanbanData,
    setTotalReferrals,
    setReferralList,
    setTotalDocs,
    "",
    1,
    true,
  );
};

export const handleSettlementsViewChange = (
  view: string,
  setActiveGrid: React.Dispatch<React.SetStateAction<string>>,
  fetchSettlements: (
    form: SettlementStatus,
    setIsViewLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setTotalSettlements: React.Dispatch<React.SetStateAction<number>>,
    setSettlementList: React.Dispatch<React.SetStateAction<Settlement[]>>,
    setTotalPages: React.Dispatch<React.SetStateAction<number>>,
    searchTerm: string,
    page: number,
    reset: boolean,
  ) => Promise<void>,
  form: ReferralStatus,
  setTotalPages: React.Dispatch<React.SetStateAction<number>>,
  setIsViewLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setTotalSettlements: React.Dispatch<React.SetStateAction<number>>,
  setSettlementList: React.Dispatch<React.SetStateAction<Settlement[]>>,
) => {
  setActiveGrid(view);
  fetchSettlements(
    form,
    setIsViewLoading,
    setTotalSettlements,
    setSettlementList,
    setTotalPages,
    "",
    1,
    true,
  );
};

export const handleDeleteChip = (
  chipToDelete: string,
  form: ReferralStatus,
  setForm: React.Dispatch<React.SetStateAction<ReferralStatus>>,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  setReferralList: React.Dispatch<React.SetStateAction<Referral[]>>,
  fetchReferrals: (reset: boolean) => void,
) => {
  setForm((prev) => ({
    ...prev,
    status: prev.status.filter((chip) => chip !== chipToDelete),
  }));
  setPage(1);
  setReferralList([]);
  fetchReferrals(true);
};
