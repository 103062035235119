import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TablePagination, TableSortLabel, Typography, Box, Divider, CircularProgress } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import theme from 'theme';
import NoDataIcon from '../../icons/no-data.svg';

interface Column {
  id: string;
  label: string;
  sortable?: boolean;
  minWidth?: string;
  maxWidth?: string;
  render?: (value: any, row: any) => React.ReactNode;
}

interface DataTableProps {
  columns: Column[];
  data: any[];
  count: number;
  order: 'asc' | 'desc';
  orderBy: string;
  page: number;
  rowsPerPage: number;
  onRequestSort: (property: string) => void;

  setPage: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;

  setPage: React.Dispatch<React.SetStateAction<number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({ count, page, rowsPerPage, setPage, setLoading }) => {
  const handleBackButtonClick = () => {
    setPage(page - 1);
    setLoading(true);
  };

  const handleNextButtonClick = () => {
    setPage(page + 1);
    setLoading(true);
  };

  const pageCount = Math.ceil(count / rowsPerPage);
  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= pageCount; i += 1) {
      if (i === 1 || i === pageCount || (i >= page - 2 && i <= page + 2)) {
        pageNumbers.push(
          <Typography
            key={i}
            fontSize="13px"
            color={i === page ? theme.palette.primary.main : '#828282'}
            fontWeight={i === page ? '800' : 'normal'}
            sx={{ cursor: 'pointer', marginX: '20px' }}
            onClick={() => {
              setPage(i);
              setLoading(true);
            }}
          >
            {i}
          </Typography>
        );
      } else if ((i === page - 3 && page > 3) || (i === page + 3 && page < pageCount - 4)) {
        pageNumbers.push(
          <Typography key={i} fontSize="13px" color="#828282" fontWeight="normal" sx={{ marginX: '5px' }}>
            ...
          </Typography>
        );
      }
    }
    return pageNumbers;
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page" sx={{ marginRight: '10px' }}>
        <KeyboardArrowLeft sx={{ fontSize: '18px' }} />
      </IconButton>
      {renderPageNumbers()}
      <IconButton onClick={handleNextButtonClick} disabled={page >= pageCount - 1} aria-label="next page" sx={{ marginLeft: '10px' }}>
        <KeyboardArrowRight sx={{ fontSize: '18px' }} />
      </IconButton>
    </Box>
  );
};

const DataTable: React.FC<DataTableProps> = ({ columns, data, count, order, orderBy, page, rowsPerPage, onRequestSort, setPage, loading, setLoading }) => {
  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [data, setLoading]);

  return (
    <Paper sx={{ boxShadow: 'none' }}>
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table aria-label="data table" sx={{ minWidth: 800 }}>
          <TableHead
            sx={{
              '& th': {
                backgroundColor: '#F5F4Fc',
                padding: '0px 0px 0px 10px',
                whiteSpace: 'nowrap',
              },
              '& .MuiTableCell-head': { lineHeight: 1, paddingY: '8px' },
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sx={{
                    minWidth: column.minWidth,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {column.sortable ? (
                    <TableSortLabel active={orderBy === column.id} direction={orderBy === column.id ? order : 'asc'} onClick={() => onRequestSort(column.id)}>
                      <Typography fontSize="12px" fontWeight="800">
                        {column.label}
                      </Typography>
                    </TableSortLabel>
                  ) : (
                    <Typography fontSize="12px" fontWeight="800">
                      {column.label}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <TableRow
                sx={{
                  '& .MuiTableCell-body': {
                    padding: '150px',
                    lineHeight: 100,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
              >
                <TableCell colSpan={columns.length + 1} align="center" sx={{ padding: '20px' }}>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" color="#828282" height="100%">
                    {loading ? (
                      <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <img src={NoDataIcon} alt="No Data" style={{ width: 50, height: 50 }} />
                        <Typography fontSize="12px" color="#909399">
                          No Data
                        </Typography>
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              data.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    '& .MuiTableCell-body': {
                      padding: '0px 0px 0px 10px',
                      lineHeight: 4,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                >
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: '#303030',
                        fontSize: '12px',
                        fontWeight: '500',
                      }}
                    >
                      {(() => {
                        if (!row[column.id] && column.id !== 'action') {
                          return '--';
                        }
                        if (column.render) {
                          return column.render(row[column.id], row);
                        }
                        return row[column.id];
                      })()}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ mt: 2 }} />
      <TablePagination
        sx={{
          '& .MuiBox-root': {
            order: 2,
          },
          '& .MuiTablePagination-spacer': {
            order: 1,
          },
          '& .MuiTablePagination-selectLabel': {
            order: 0,
          },
          '.MuiTablePagination-toolbar': {
            marginTop: '8px',
            height: '32px',
            color: '#828282',
            minHeight: '0px',
            justifyContent: 'flex-end',
            paddingRight: '16px',
            paddingLeft: '15px',
          },
          '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
            margin: 0,
            fontSize: '12px',
            lineHeight: '32px',
          },
        }}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={() => {}}
        rowsPerPageOptions={[]}
        labelDisplayedRows={({ count }) => `${(page - 1) * rowsPerPage + 1}-${Math.min(page * rowsPerPage, count)} / ${count}`}
        ActionsComponent={(props) => <TablePaginationActions {...props} setPage={setPage} setLoading={setLoading} />}
      />
    </Paper>
  );
};

export default DataTable;
