import React, { useState, createContext, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { ThemeProvider } from '@emotion/react';
import MobileLogin from './pages/auth/Login';
import ResetPassword from './pages/forgotPassword';
import Referrals from './pages/referrals/Referrals';
import Settlements from './pages/referrals/Settlements';
import ReferralForm from './pages/referrals/Consumer';
import EmailLogin from './pages/auth/EmailLogin';
import Copyright from './shared/FooterCopyRight';
import CCLogo from './icons/cc-logo.svg';
import Header from './components/Header';
import theme from './theme';
import NavBar from './components/NavBar';
import { USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN } from './constants';
import './App.css';

interface AuthContextType {
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  redirectPath: string;
  setRedirectPath: React.Dispatch<React.SetStateAction<string>>;
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  isUserAdmin: boolean;
  isUserSuperAdmin: boolean;
  handleLogout: () => void;
}

const defaultAuthContext: AuthContextType = {
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  redirectPath: '/',
  setRedirectPath: () => {},
  user: null,
  setUser: () => {},
  isUserAdmin: false,
  isUserSuperAdmin: false,
  handleLogout: () => {},
};

export const AuthContext = createContext<AuthContextType>(defaultAuthContext);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    const storedLoggedIn = localStorage.getItem('isLoggedIn');
    return storedLoggedIn ? JSON.parse(storedLoggedIn) : false;
  });
  const [user, setUser] = useState<any>(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [redirectPath, setRedirectPath] = useState<string>('/');
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState<boolean>(false);

  const userObj = JSON.parse(localStorage.getItem('user')!);

  const userType = userObj?.type;

  const handleLogout = () => {
    datadogRum.stopSession();
    if (document.cookie) {
      document.cookie = 'cctok=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('user');
    setIsLoggedIn(false);
    setUser(null);
  };

  useEffect(() => {
    setIsUserAdmin(userType === USER_TYPE_ADMIN);
    setIsUserSuperAdmin(userType === USER_TYPE_SUPER_ADMIN);
  }, [userObj]);

  useEffect(() => {
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        redirectPath,
        setRedirectPath,
        user,
        setUser,
        isUserAdmin,
        isUserSuperAdmin,
        handleLogout,
      }}
    >
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <MainContent />
          </div>
        </Router>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};

const MainContent = () => {
  const location = useLocation();
  const isAuthRoute = ['/referrals', '/referrals/settlements', '/referrals/consumer', '/referrals/commercial'].includes(location.pathname);

  return (
    <div className="main-content">
      {!isAuthRoute && <Header logoSrc={CCLogo} />}
      <Switch>
        <Route exact path="/">
          <Redirect to="/auth/email-login" />
        </Route>
        <Route path="/auth/email-login" component={EmailLogin} />
        <Route path="/auth/login" component={MobileLogin} />
        <Route path="/email-password-reset-accelerate" component={ResetPassword} />
        <PrivateRoute path="/referrals/settlements" component={Settlements} />
        <PrivateRoute path="/referrals/commercial" component={ReferralForm} />
        <PrivateRoute path="/referrals/consumer" component={ReferralForm} />
        <PrivateRoute path="/referrals" component={Referrals} />
      </Switch>
      {!isAuthRoute && <Copyright />}
    </div>
  );
};

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  [key: string]: any;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const { isLoggedIn, handleLogout } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <>
            <NavBar onLogout={handleLogout} />
            <Component {...props} />
          </>
        ) : (
          <Redirect to={{ pathname: '/auth/email-login', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default App;
