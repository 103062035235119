import React, { useState, useEffect } from 'react';
import DataTable from 'shared/DataTable';
// @ts-ignore
import CCApplicationStatus from '@carclarity/cc-application-status';
import { Box, CircularProgress } from '@mui/material';
import Button from 'shared/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import theme from 'theme';
import { Referral } from 'pages/referrals/Referrals';
import SlideOutPane from 'shared/SlideOutPane';
import NotesIcon from '../../icons/notes.svg';

interface Column {
  id: string;
  label: string;
  sortable?: boolean;
  minWidth?: string;
  maxWidth?: string;
  render?: (value: any, row: any) => React.ReactNode;
}

interface ReferralsTableViewProps {
  data: Referral[];
  setForm: React.Dispatch<React.SetStateAction<any>>;
  fetchReferrals: (reset: boolean, page?: number) => void;
  totalReferrals: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const ReferralsTableView: React.FC<ReferralsTableViewProps> = ({ data, setForm, fetchReferrals, totalReferrals, page, setPage }) => {
  const columns: Column[] = [
    { id: 'id', label: 'ID', sortable: false, minWidth: '65px' },
    { id: 'User', label: 'Customer', sortable: true, minWidth: '140px' },
    { id: 'loanAmount', label: 'Loan amount', sortable: false, minWidth: '90px' },
    { id: 'agent', label: 'Agent', sortable: false, minWidth: '96px' },
    { id: 'subaccount', label: 'Subaccount', sortable: true, minWidth: '100px' },
    { id: 'referrer', label: 'Referrer', sortable: true, minWidth: '100px' },
    { id: 'createdAt', label: 'Created at', sortable: true, minWidth: '100px' },
    { id: 'updatedAt', label: 'Last update', sortable: true, minWidth: '130px' },
    {
      id: 'status',
      label: 'Status',
      sortable: false,
      minWidth: '150px',
      render: (value) => <CCApplicationStatus status={value || 'Unknown'} />,
    },
    { id: 'details', label: 'Details', sortable: false, minWidth: '155px' },
    {
      id: 'action',
      label: 'Action',
      sortable: false,
      minWidth: '155px',
      render: (value, row) => (
        <Box display="flex" flexDirection="row">
          <Button variantType="full" sx={{ minWidth: '10px', width: '20px', height: '20px', backgroundColor: '#f6eefd', alignItems: 'center', marginRight: '15px' }} isImage onPress={() => handlePaneOpen(0, row)}>
            <VisibilityIcon color="inherit" fontSize="inherit" sx={{ fontSize: '16px', color: theme.palette.primary.main }} />
          </Button>
          <Button variantType="full" sx={{ minWidth: '10px', width: '20px', height: '20px', backgroundColor: '#fff0c1', alignItems: 'center', marginRight: '15px' }} isImage onPress={() => handlePaneOpen(3, row)}>
            <img src={NotesIcon} alt="Notes" style={{ width: '16px', height: '15px' }} />
          </Button>
        </Box>
      ),
    },
  ];

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('-createdAt');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPaneOpen, setIsPaneOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<Referral | null>(null);

  const handlePaneOpen = (activeTab: number, row: Referral) => {
    setActiveTab(activeTab);
    setSelectedRow(row);
    setIsPaneOpen(true);
  };

  const handlePaneClose = () => {
    setIsPaneOpen(false);
    setSelectedRow(null);
  };

  useEffect(() => {}, [order]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    setForm((prevForm: any) => ({ ...prevForm, sort: (newOrder === 'desc' ? '-' : '') + property }));
    setIsLoading(true);
    fetchReferrals(true, page);
  };

  useEffect(() => {
    if (!isLoading) return;
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, [data]);

  return isLoading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <DataTable columns={columns} data={data} count={totalReferrals} order={order} orderBy={orderBy} page={page} rowsPerPage={20} onRequestSort={handleRequestSort} setPage={setPage} loading={isLoading} setLoading={setIsLoading} />

      <SlideOutPane open={isPaneOpen} onClose={handlePaneClose} details={selectedRow} activeTab={activeTab} />
    </Box>
  );
};

export default ReferralsTableView;
