import { useContext, useRef, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Container, Divider, Link, Typography } from '@mui/material';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { AuthContext } from 'App';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { ERROR_MESSAGE_FOR_PHONE, ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED, LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE, USER_SESSION_DATA_KEY, USER_TYPE_ADMIN, USER_TYPE_REFERRER, USER_TYPE_SUPER_ADMIN } from '../../../constants';
import { axiosErrorMessageExtractor } from '../../../utils/error';
import { isMobile } from '../../../helpers/validators';
import { mobileWithCountryCode } from '../../../helpers/mobile';
import Button from '../../../shared/Button';
import DynamicForm, { FormField } from '../../../shared/Form';
import OTPInput from '../../../shared/OTPInput';
import theme from '../../../theme';
import SnackbarAlert, { SnackBarProps } from '../../../shared/SnackbarAlert';

const MobileLogin: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const methods = useForm();
  const { setUser, setIsLoggedIn, setRedirectPath } = useContext(AuthContext);

  const otpFieldRef = useRef<HTMLInputElement[]>([]);
  const [isRequestCodeLoading, setIsRequestCodeLoading] = useState<boolean>(false);
  const [isSubmitLoginLoading, setIsSubmitLoginLoading] = useState<boolean>(false);
  const [isCodeRequested, setIsCodeRequested] = useState<boolean>(false);
  const [snackbarAlert, setSnackbarAlert] = useState<SnackBarProps>({
    open: false,
    message: '',
    severity: 'error',
    onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
  });
  const [otp, setOtp] = useState<string[]>(Array(4).fill(''));

  const otpLength = 4;

  useEffect(() => {
    if (location.pathname === '/auth/login') {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('user');
      setIsLoggedIn(false);
      setUser(null);
    }
  }, [location, setIsLoggedIn]);

  const focusInput = (index: number) => {
    otpFieldRef.current[index]?.focus();
  };

  const validatePhone = (phoneNumber: string): string | undefined => {
    if (!phoneNumber) {
      return ERROR_MESSAGE_FOR_PHONE;
    }
    return isMobile(phoneNumber) ? isMobile(phoneNumber) : '';
  };

  const formFields: FormField[] = [
    {
      label: 'Mobile Number',
      name: 'mobile',
      type: 'phoneNumber',
      placeHolder: '04XX-XXX-XXX',
      validateField: validatePhone,
      isNumber: true,
    },
  ];

  const handleEmailLogin = () => {
    history.push('/auth/email-login');
  };

  const authenticateNumber = async (mobile: any) => {
    try {
      setIsRequestCodeLoading(true);
      await axios.post(process.env.REACT_APP_API_URL + '/user/authenticate-number', {
        mobile: mobileWithCountryCode(mobile),
      });
      setIsCodeRequested(true);
      setSnackbarAlert({
        open: true,
        message: 'Successfully sent SMS code',
        severity: 'success',
        onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
      });
      focusInput(0);
    } catch (err: any) {
      const errorMessage = axiosErrorMessageExtractor(err);
      setSnackbarAlert({
        open: true,
        message: errorMessage,
        severity: 'error',
        onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
      });
    } finally {
      setIsRequestCodeLoading(false);
    }
  };

  const handleOTPChange = (value: string, index: number) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    if (value?.length === 1 && index < otpLength - 1) {
      focusInput(index + 1);
    } else if (value.length === 0 && index > 0) {
      focusInput(index - 1);
    }
  };

  const handleOTPComplete = async (mobile: string) => {
    setIsSubmitLoginLoading(true);

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/user/sign-in', {
        mobile: mobileWithCountryCode(mobile),
        oneTimePassword: otp.join(''),
      });

      if (response.data.tokens) {
        localStorage.setItem('token', response.data.tokens.token);
        localStorage.setItem('refreshToken', response.data.tokens.refreshToken);
      }

      const { user } = response.data;

      localStorage.setItem(USER_SESSION_DATA_KEY, JSON.stringify(user));

      if (![USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN, USER_TYPE_REFERRER].includes(user.type)) {
        setSnackbarAlert({
          open: true,
          message: ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED,
          severity: 'error',
          onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
        });
        datadogLogs.logger.error(`User unable to login - ${ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED}`);
        localStorage.setItem('accelerate-login-email', 'true');
        localStorage.removeItem(USER_SESSION_DATA_KEY);

        return;
      }

      if (response?.data) {
        datadogLogs.logger.info('Auth0 is authenticated - Login using mobile number successfully');
        datadogRum.startSessionReplayRecording();
        setIsLoggedIn(true);
        setRedirectPath('/referrals');
        history.push('/referrals');
      }
    } catch (err: any) {
      const errorMessage = axiosErrorMessageExtractor(err);
      setSnackbarAlert({
        open: true,
        message: errorMessage,
        severity: 'error',
        onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
      });
    } finally {
      setIsSubmitLoginLoading(false);
    }
  };

  const onSubmit = async (formData: Record<string, any>) => {
    const validationErrors = formFields.reduce(
      (errors, field) => {
        if (field.validateField) {
          const errorMessage = field.validateField(formData[field.name]);
          if (errorMessage) {
            errors[field.name] = errorMessage;
          }
        }
        return errors;
      },
      {} as Record<string, string>
    );

    if (Object.keys(validationErrors).length > 0) {
      Object.entries(validationErrors).forEach(([name, error]) => {
        methods.setError(name, { type: 'manual', message: error });
      });
      return;
    }

    setIsRequestCodeLoading(true);
    try {
      await authenticateNumber(formData.mobile);
    } finally {
      setIsRequestCodeLoading(false);
    }
  };

  useEffect(() => {
    if (otp.every((digit) => digit !== '')) {
      handleOTPComplete(methods.getValues('mobile'));
    }
  }, [otp]);

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 7rem)',
      }}
      maxWidth="sm"
    >
      <Box sx={{ width: '90%', maxWidth: { xs: '100%', sm: '90%', md: '600px' } }}>
        <Box sx={{ display: 'flex', marginBottom: '25px', justifyContent: 'space-between' }}>
          <Typography component="div" style={{ fontWeight: '600', fontSize: '24px' }}>
            Enter login details
          </Typography>
          <Button onPress={handleEmailLogin} disableRipple variantType="filled" color={theme.palette.primary.main} sx={{ paddingRight: '0px' }}>
            <Typography component="span" fontWeight="800" fontSize="14px">
              Login with Email
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            backgroundColor: '#fafafa',
            border: '1px solid #e8e8e8',
            padding: '24px',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box width="100%">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <DynamicForm fields={formFields} />
                <Button type="submit" variantType="full" color={theme.palette.primary.main} sx={{ width: '100%' }}>
                  <Typography component="span" fontSize="14px" fontWeight="800">
                    {isRequestCodeLoading ? 'Loading...' : 'Send verification SMS'}
                  </Typography>
                </Button>
              </form>
            </FormProvider>
          </Box>
          <Divider sx={{ width: '100%', height: '10px', color: 'black', my: '20px' }} />
          <Box width="100%" gap="10" flexDirection="column">
            <Typography component="div" sx={{ fontSize: '12px', color: 'black', marginBottom: '10px' }}>
              SMS verification code
            </Typography>
            <OTPInput
              length={otpLength}
              onChange={handleOTPChange}
              onComplete={() => handleOTPComplete(methods.getValues('mobile'))}
              textFieldStyle={{
                height: '40px',
              }}
              borderColor={!isCodeRequested ? '#f1f1f1' : theme.palette.primary.main}
              textFieldProps={{
                sx: {
                  mb: '20px',
                  width: '100%',
                  '& .MuiInputBase-root': {
                    height: '40px',
                  },
                },
                placeholder: '0',
              }}
              sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}
              disabledInputField={!isCodeRequested}
            />
          </Box>
          <Button
            type="submit"
            variantType="full"
            color={theme.palette.primary.main}
            sx={{
              width: '100%',
              backgroundColor: !isCodeRequested ? '#e8e8e8' : theme.palette.primary.main,
            }}
            textProps={{ color: 'white' }}
            disabled={!isCodeRequested}
          >
            <Typography component="span" sx={{ fontSize: '14px', fontWeight: '800' }}>
              {isSubmitLoginLoading ? 'Loading...' : 'Submit code and login'}
            </Typography>
          </Button>
        </Box>
        <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
          <Typography component="div" fontSize="0.875rem" color="#303030" marginBottom="0.5rem">
            For any technical assistance
          </Typography>
          <Link href={`mailto:tech-support@carclarity.com.au?subject=${LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE}`} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
            <Typography component="span" fontSize="14px" fontWeight="800" color={theme.palette.primary.main}>
              Contact Support
            </Typography>
          </Link>
        </Box>
      </Box>
      {snackbarAlert.message && <SnackbarAlert autoHideDuration={3000} message={snackbarAlert.message} severity={snackbarAlert.severity} open={snackbarAlert.open} onClose={snackbarAlert.onClose} />}
    </Container>
  );
};

export default MobileLogin;
