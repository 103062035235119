import React, { useState } from 'react';
import { Box, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import Button from '../../shared/Button';
import QuickReferral from '../../icons/quick-referral.svg';
import MenuIcon from '../../icons/menu.svg';
import theme from '../../theme';
import ReferrerDialogBox from '../ReferrerDialogBox';

interface PageHeaderProps {
  headerName: string;
  open: boolean;
  toggleDrawer: () => void;
  handleMenu?: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose?: () => void;
  anchorEl?: any;
}

const PageHeader: React.FC<PageHeaderProps> = ({ headerName, open, toggleDrawer, handleMenu, handleClose, anchorEl }) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isFullReferral, setIsFullReferral] = useState<boolean>(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsFullReferral(false);
    setIsDialogOpen(false);
  };

  return (
    <Box sx={{ backgroundColor: 'white', width: '100%', height: '48px', display: 'flex', alignItems: 'center', borderBottom: '1px solid #e8e8e8' }}>
      {!open && (
        <IconButton sx={{ marginLeft: '4px', marginTop: '2px', zIndex: 10000 }} onClick={toggleDrawer} edge="start" color="inherit" aria-label="menu">
          <img src={MenuIcon} alt="menu" height="24px" />
        </IconButton>
      )}
      <Box paddingLeft={open ? '20px' : 0} display="flex" alignItems="center" justifyContent="space-between" width="100%" paddingRight="20px">
        <Typography fontSize="1rem" color="#303030" fontWeight="800" component="div">
          {headerName}
        </Typography>
        {handleMenu && (
          <Button sx={{ height: '31px', width: '130px', paddingX: '0px' }} variantType="full" color={theme.palette.primary.main} onClick={handleMenu}>
            <Box display="flex" alignItems="center">
              <Typography fontWeight="800" fontSize="14px" marginRight="10px" component="div">
                +
              </Typography>
              <Typography fontWeight="800" fontSize="14px" component="div">
                New referral
              </Typography>
            </Box>
          </Button>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            boxShadow: 'none',
            overflow: 'visible',
            filter: 'none',
            mt: 1.5,
            borderRadius: 0,
            width: '200px',
          },
        }}
        BackdropProps={{ sx: { backgroundColor: 'rgba(48, 48, 48, 0.5)' } }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          <MenuItem sx={{ width: '200px', justifyContent: 'center', alignItems: 'center' }} onClick={handleDialogOpen}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" paddingX="12px" paddingY="5px">
              <img src={QuickReferral} alt="Quick Referral" height="73px" />
              <Typography fontSize="12px" fontWeight="800" color={theme.palette.primary.main} marginTop="0.844rem" marginBottom="0.375rem" component="div">
                Quick referral
              </Typography>
              <Typography fontSize="0.75rem" color="#606266" textAlign="center" sx={{ whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }} component="div">
                Only customer and asset details are required
              </Typography>
            </Box>
          </MenuItem>
        </Box>
      </Menu>
      <ReferrerDialogBox open={isDialogOpen} handleClose={handleDialogClose} isFullReferral={isFullReferral} />
    </Box>
  );
};

export default PageHeader;
