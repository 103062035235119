import _ from 'lodash';
import { yearOption, generateOptionsFromTypesWhereKeyIsSameAsValue, generateOptionsFromTypesWhereTheKeyIsSameAsValue, generateOptionsWithSameValueAndLabel } from '../utils/form';

export const LABEL_DOCUMENT_LOANAMOUNT = 'Loan amount';
export const MAINTAB_MATCHES_LABEL = 'Matches';
export const MAINTAB_MATCHES_KEY = 'matches';

export const MAINTAB_REQUIRE_LABEL = 'Requirements';
export const MAINTAB_REQUIRE_KEY = 'requirements';

export const MAINTAB_APPLICANT_LABEL = 'Applicant';
export const MAINTAB_APPLICANT_KEY = 'applicant';

export const BUSINESS_DETAILS_TAB_LABEL = 'Business details';
export const BUSINESS_DETAILS_TAB_KEY = 'businessDetails';

export const MAINTAB_INCOME_LABEL = 'Income';
export const MAINTAB_INCOME_KEY = 'income';

export const MAINTAB_EXPENSES_LABEL = 'Expenses';
export const MAINTAB_EXPENSES_KEY = 'expenses';

export const MAINTAB_ASSETS_LABEL = 'Assets & Liabilities';
export const MAINTAB_ASSETS_KEY = 'assetsLiabilities';

export const MAINTAB_DOCS_LABEL = 'Documents';
export const MAINTAB_DOCS_KEY = 'docs';

export const MAINTAB_COMPLIANCE_LABEL = 'Compliance';
export const MAINTAB_COMPLIANCE_KEY = 'compliance';

export const MAINTAB_CREDIT_LABEL = 'Credit Report';
export const MAINTAB_CREDIT_KEY = 'creditReport';

export const MAINTAB_ASSET_DETAILS_LABEL = 'Asset details';
export const MAINTAB_ASSET_DETAILS_KEY = 'assetDetails';

export const MAINTAB_LOANDETAILS_LABEL = 'Loan details';
export const MAINTAB_LOANDETAILS_KEY = 'loanDetails';

export const MAINTAB_SETTLEMENT_LABEL = 'Settlement';
export const MAINTAB_SETTLEMENT_KEY = 'settlement';

export const MAINTAB_NOTES_LABEL = 'Notes';
export const MAINTAB_NOTES_KEY = 'notes';

export const MAINTAB_LOANDETAILS_SUBMISSION_LABEL = 'Submission';
export const MAINTAB_LOANDETAILS_SUBMISSION_KEY = 'submission';

export const MAINTAB_LOANDETAILS_DECISION_LABEL = 'Decision';
export const MAINTAB_LOANDETAILS_DECISION_KEY = 'decision';

export const MAINTAB_LOANDETAILS_SETTLEMENT_LABEL = 'Settlement';
export const MAINTAB_LOANDETAILS_SETTLEMENT_KEY = 'settlement';

export const MAINTAB_LOANDETAILS_PRE_SETTLEMENT_LABEL = 'Pre-settlement';

export const APPLICATIONS_MAINTAB = [
  {
    label: BUSINESS_DETAILS_TAB_LABEL,
    keyId: BUSINESS_DETAILS_TAB_KEY,
  },
  {
    label: MAINTAB_MATCHES_LABEL,
    keyId: MAINTAB_MATCHES_KEY,
  },
  {
    label: MAINTAB_REQUIRE_LABEL,
    keyId: MAINTAB_REQUIRE_KEY,
  },
  {
    label: MAINTAB_APPLICANT_LABEL,
    keyId: MAINTAB_APPLICANT_KEY,
  },
  {
    label: MAINTAB_INCOME_LABEL,
    keyId: MAINTAB_INCOME_KEY,
  },
  {
    label: MAINTAB_EXPENSES_LABEL,
    keyId: MAINTAB_EXPENSES_KEY,
  },
  {
    label: MAINTAB_ASSETS_LABEL,
    keyId: MAINTAB_ASSETS_KEY,
  },
  {
    label: MAINTAB_DOCS_LABEL,
    keyId: MAINTAB_DOCS_KEY,
  },
  {
    label: MAINTAB_COMPLIANCE_LABEL,
    keyId: MAINTAB_COMPLIANCE_KEY,
  },
  {
    label: MAINTAB_CREDIT_LABEL,
    keyId: MAINTAB_CREDIT_KEY,
  },
  {
    label: MAINTAB_ASSET_DETAILS_LABEL,
    keyId: MAINTAB_ASSET_DETAILS_KEY,
  },
  {
    label: MAINTAB_LOANDETAILS_LABEL,
    keyId: MAINTAB_LOANDETAILS_KEY,
  },
  {
    label: MAINTAB_SETTLEMENT_LABEL,
    keyId: MAINTAB_SETTLEMENT_KEY,
  },
];

export const SUBTAB_PRIMARY_PERSONAL_LABEL = 'Personal';
export const SUBTAB_PRIMARY_PERSONAL_KEY = 'personal';

export const SUBTAB_PRIMARY_ADDRESS_LABEL = 'Address';
export const SUBTAB_PRIMARY_ADDRESS_KEY = 'address';

export const SUBTAB_PRIMARY_EMPLOYMENT_LABEL = 'Employment';
export const SUBTAB_PRIMARY_EMPLOYMENT_KEY = 'employment';

export const SUBTAB_SECONDAY_PERSONAL_LABEL = 'Personal';
export const SUBTAB_SECONDAY_PERSONAL_KEY = 'personalsecondary';

export const SUBTAB_SECONDAY_ADDRESS_LABEL = 'Address';
export const SUBTAB_SECONDAY_ADDRESS_KEY = 'address';

export const SUBTAB_SECONDAY_EMPLOYMENT_LABEL = 'Employment';
export const SUBTAB_SECONDARY_EMPLOYMENT_KEY = 'employment';

export const SUBTAB_ASSETS_AND_LIABILITIES_LABEL = 'Assets & Liabilities';
export const SUBTAB_ASSETS_AND_LIABILITIES_KEY = 'assetsAndLiabilities';

export const APPLICATIONS_PRIMARY_SUBTAB = [
  {
    label: SUBTAB_PRIMARY_PERSONAL_LABEL,
    keyId: SUBTAB_PRIMARY_PERSONAL_KEY,
  },
  {
    label: SUBTAB_PRIMARY_ADDRESS_LABEL,
    keyId: SUBTAB_PRIMARY_ADDRESS_KEY,
  },
  {
    label: SUBTAB_PRIMARY_EMPLOYMENT_LABEL,
    keyId: SUBTAB_PRIMARY_EMPLOYMENT_KEY,
  },
];

export const APPLICATIONS_SECONDARY_SUBTAB = [
  {
    label: SUBTAB_SECONDAY_PERSONAL_LABEL,
    keyId: SUBTAB_SECONDAY_PERSONAL_KEY,
  },
  {
    label: SUBTAB_SECONDAY_ADDRESS_LABEL,
    keyId: SUBTAB_SECONDAY_ADDRESS_KEY,
  },
  {
    label: SUBTAB_SECONDAY_EMPLOYMENT_LABEL,
    keyId: SUBTAB_SECONDARY_EMPLOYMENT_KEY,
  },
];

export const SUBTAB_BUSINESS_DETAILS_KEY = 'details';
export const SUBTAB_BUSINESS_DETAILS_LABEL = 'Details';

export const SUBTAB_BUSINESS_ADDRESS_KEY = 'address';
export const SUBTAB_BUSINESS_ADDRESS_LABEL = 'Address';

export const SUBTAB_BUSINESS_SHAREHOLDER_KEY = 'shareholder';
export const SUBTAB_BUSINESS_SHAREHOLDER_LABEL = 'Shareholder';

export const SUBTAB_BUSINESS_ACCOUNTANT_KEY = 'accountant';
export const SUBTAB_BUSINESS_ACCOUNTANT_LABEL = 'Accountant';

export const SUBTAB_BUSINESS_BENEFICIARIES_KEY = 'beneficiaries';
export const SUBTAB_BUSINESS_BENEFICIARIES_LABEL = 'Beneficiaries';

export const APPLICATIONS_COMMERCIAL_SUBTAB = [
  {
    label: SUBTAB_PRIMARY_PERSONAL_LABEL,
    keyId: SUBTAB_PRIMARY_PERSONAL_KEY,
  },
  {
    label: SUBTAB_PRIMARY_ADDRESS_LABEL,
    keyId: SUBTAB_PRIMARY_ADDRESS_KEY,
  },
  {
    label: SUBTAB_ASSETS_AND_LIABILITIES_LABEL,
    keyId: SUBTAB_ASSETS_AND_LIABILITIES_KEY,
  },
  {
    label: SUBTAB_SECONDAY_EMPLOYMENT_LABEL,
    keyId: SUBTAB_SECONDARY_EMPLOYMENT_KEY,
  },
];
export const SETTLEMENT_MENU_DOCUMENT_KEY = 'documents';
export const SETTLEMENT_MENU_DOCUMENT_LABEL = 'Documents';

export const SETTLEMENT_MENU_SELLER_DETAILS_KEY = 'sellerDetails';
export const SETTLEMENT_MENU_SELLER_DETAILS_LABEL = 'Seller details';

export const SETTLEMENT_MENU_LOAN_DETAILS_KEY = 'loanDetails';
export const SETTLEMENT_MENU_LOAN_DETAILS_LABEL = 'Loan details';

export const SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_KEY = 'warrantyAndInsuarnce';
export const SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_LABEL = 'Warranty & Insurance';

export const APPLICATIONS_SETTLEMENT_MENU = [
  {
    label: SETTLEMENT_MENU_DOCUMENT_LABEL,
    keyId: SETTLEMENT_MENU_DOCUMENT_KEY,
  },
  {
    label: SETTLEMENT_MENU_SELLER_DETAILS_LABEL,
    keyId: SETTLEMENT_MENU_SELLER_DETAILS_KEY,
  },
  {
    label: SETTLEMENT_MENU_LOAN_DETAILS_LABEL,
    keyId: SETTLEMENT_MENU_LOAN_DETAILS_KEY,
  },
  {
    label: SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_LABEL,
    keyId: SETTLEMENT_MENU_LOAN_WARRANTY_AND_INSURANCE_KEY,
  },
];

export const SETTLEMENT_DOCUMENT_LABEL = 'Settlement documents';

export const APPLICANT_PREFIX_MR_LABEL = 'Mr.';
export const APPLICANT_PREFIX_MR_VALUE = 'Mr';

export const APPLICANT_PREFIX_MS_LABEL = 'Ms.';
export const APPLICANT_PREFIX_MS_VALUE = 'Ms';

export const APPLICANT_PREFIX_MRS_LABEL = 'Mrs.';
export const APPLICANT_PREFIX_MRS_VALUE = 'Mrs';

export const APPLICANT_PREFIX_DR_LABEL = 'Dr.';
export const APPLICANT_PREFIX_DR_VALUE = 'Dr';

export const APPLICANT_PREFIX_PROF_LABEL = 'Prof.';
export const APPLICANT_PREFIX_PROF_VALUE = 'Prof';

export const APPLICANT_PREFIX_OTHER_LABEL = 'Other.';
export const APPLICANT_PREFIX_OTHER_VALUE = 'Other';

export const APPLICANT_PREFIXES = [
  {
    label: APPLICANT_PREFIX_MR_LABEL,
    value: APPLICANT_PREFIX_MR_VALUE,
  },
  {
    label: APPLICANT_PREFIX_MS_LABEL,
    value: APPLICANT_PREFIX_MS_VALUE,
  },
  {
    label: APPLICANT_PREFIX_MRS_LABEL,
    value: APPLICANT_PREFIX_MRS_VALUE,
  },
  {
    label: APPLICANT_PREFIX_DR_LABEL,
    value: APPLICANT_PREFIX_DR_VALUE,
  },
  {
    label: APPLICANT_PREFIX_PROF_LABEL,
    value: APPLICANT_PREFIX_PROF_VALUE,
  },
  {
    label: APPLICANT_PREFIX_OTHER_LABEL,
    value: APPLICANT_PREFIX_OTHER_VALUE,
  },
];

export const APPLICANT_PREFIXES_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(APPLICANT_PREFIXES);

export const LOAN_TYPE_ALL = 'All';

export const LOAN_TYPE = {
  CONSUMER: 'Consumer Car Loan',
  COMMERCIAL: 'Commercial Car Loan',
  PERSONAL: 'Personal Loan',
};

export const REQUEST_LOAN_TYPES = generateOptionsWithSameValueAndLabel(Object.values(LOAN_TYPE));

export const REQUIREMENT_LOAN_DETAILS = generateOptionsFromTypesWhereKeyIsSameAsValue(REQUEST_LOAN_TYPES);

export const APPLICANT_STATE_NSW_VALUE = 'NSW';
export const APPLICANT_STATE_VIC_VALUE = 'VIC';
export const APPLICANT_STATE_QLD_VALUE = 'QLD';
export const APPLICANT_STATE_WA_VALUE = 'WA';
export const APPLICANT_STATE_SA_VALUE = 'SA';
export const APPLICANT_STATE_ACT_VALUE = 'ACT';
export const APPLICANT_STATE_TAS_VALUE = 'TAS';
export const APPLICANT_STATE_NT_VALUE = 'NT';

export const APPLICANT_STATES = [
  {
    label: APPLICANT_STATE_NSW_VALUE,
    value: APPLICANT_STATE_NSW_VALUE,
  },
  {
    label: APPLICANT_STATE_VIC_VALUE,
    value: APPLICANT_STATE_VIC_VALUE,
  },
  {
    label: APPLICANT_STATE_QLD_VALUE,
    value: APPLICANT_STATE_QLD_VALUE,
  },
  {
    label: APPLICANT_STATE_WA_VALUE,
    value: APPLICANT_STATE_WA_VALUE,
  },
  {
    label: APPLICANT_STATE_SA_VALUE,
    value: APPLICANT_STATE_SA_VALUE,
  },
  {
    label: APPLICANT_STATE_ACT_VALUE,
    value: APPLICANT_STATE_ACT_VALUE,
  },
  {
    label: APPLICANT_STATE_TAS_VALUE,
    value: APPLICANT_STATE_TAS_VALUE,
  },
  {
    label: APPLICANT_STATE_NT_VALUE,
    value: APPLICANT_STATE_NT_VALUE,
  },
];

export const COMMUNICATION_METHOD_PHONE = 'Phone';
export const COMMUNICATION_METHOD_EMAIL = 'Email';
export const COMMUNICATION_METHOD_SMS = 'SMS';
export const COMMUNICATION_METHOD_ANY = 'Any';

export const PREFERRED_COMMUNICATION_METHODS = [
  {
    label: COMMUNICATION_METHOD_PHONE,
    value: COMMUNICATION_METHOD_PHONE,
  },
  {
    label: COMMUNICATION_METHOD_EMAIL,
    value: COMMUNICATION_METHOD_EMAIL,
  },
  {
    label: COMMUNICATION_METHOD_SMS,
    value: COMMUNICATION_METHOD_SMS,
  },
  {
    label: COMMUNICATION_METHOD_ANY,
    value: COMMUNICATION_METHOD_ANY,
  },
];

export const APPLICANT_STATE_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(APPLICANT_STATES);

export const PREFERRED_COMMUNICATION_METHODS_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(PREFERRED_COMMUNICATION_METHODS);

export const RESIDENCY_STATUS_AUSTRALIA_CITIZEN_VALUE = 'Australian Citizen';
export const RESIDENCY_STATUS_PERMANENT_RESIDENT_VALUE = 'Permanent Resident';
export const RESIDENCY_STATUS_VISA_457_VALUE = 'Visa 457';
export const RESIDENCY_STATUS_VISA_OTHER_VALUE = 'Visa Other';
export const RESIDENCY_STATUS_VISA_UNSPECIFIED_VALUE = 'Visa Unspecified';
export const RESIDENCY_STATUS_NON_RESIDENT_VALUE = 'Non Resident';
export const LABEL_VISA_SUBCLASS = 'Visa Subclass';
export const RESIDENCY_STATUS_TYPES = [
  {
    value: RESIDENCY_STATUS_AUSTRALIA_CITIZEN_VALUE,
    label: RESIDENCY_STATUS_AUSTRALIA_CITIZEN_VALUE,
  },
  {
    value: RESIDENCY_STATUS_PERMANENT_RESIDENT_VALUE,
    label: RESIDENCY_STATUS_PERMANENT_RESIDENT_VALUE,
  },
  {
    value: RESIDENCY_STATUS_VISA_OTHER_VALUE,
    label: RESIDENCY_STATUS_VISA_OTHER_VALUE,
  },
];

export const RESIDENCY_STATUS_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(RESIDENCY_STATUS_TYPES);

export const NON_EMPLOYMENT_INCOME_TYPE_RENTAL = 'Rental';
export const NON_EMPLOYMENT_INCOME_TYPE_INVESTMENT = 'Investment Income';
export const NON_EMPLOYMENT_INCOME_TYPE_SALARY_SACRIFICE = 'Salary Sacrifice';
export const NON_EMPLOYMENT_INCOME_TYPE_CENTRELINK = 'Centrelink';
export const NON_EMPLOYMENT_INCOME_TYPE_FOREIGN = 'Foreign';
export const NON_EMPLOYMENT_INCOME_TYPE_CHILD_MAINTENANCE = 'Child Maintenance';
export const NON_EMPLOYMENT_INCOME_TYPE_FAMILY_TAX_BENEFIT = 'Family Tax Benefit';

export const NON_EMPLOYMENT_INCOME_TYPES = [
  NON_EMPLOYMENT_INCOME_TYPE_RENTAL,
  NON_EMPLOYMENT_INCOME_TYPE_INVESTMENT,
  NON_EMPLOYMENT_INCOME_TYPE_SALARY_SACRIFICE,
  NON_EMPLOYMENT_INCOME_TYPE_CENTRELINK,
  NON_EMPLOYMENT_INCOME_TYPE_FOREIGN,
  NON_EMPLOYMENT_INCOME_TYPE_CHILD_MAINTENANCE,
  NON_EMPLOYMENT_INCOME_TYPE_FAMILY_TAX_BENEFIT,
];

export const NON_EMPLOYMENT_INCOME_OPTIONS = generateOptionsFromTypesWhereTheKeyIsSameAsValue(NON_EMPLOYMENT_INCOME_TYPES);

export const APPLICANT_RELATIONSHIP_STATUS_SINGLE = 'Single';
export const APPLICANT_RELATIONSHIP_MARRIED_STATUS = 'Married';
export const APPLICANT_RELATIONSHIP_DEFACTO_STATUS_LABEL = 'Defacto';
export const APPLICANT_RELATIONSHIP_DEFACTO_STATUS_VALUE = 'De Facto';

export const APPLICANT_RELATIONSHIP_TYPES = [
  {
    label: APPLICANT_RELATIONSHIP_STATUS_SINGLE,
    value: APPLICANT_RELATIONSHIP_STATUS_SINGLE,
  },
  {
    label: APPLICANT_RELATIONSHIP_MARRIED_STATUS,
    value: APPLICANT_RELATIONSHIP_MARRIED_STATUS,
  },
  {
    label: APPLICANT_RELATIONSHIP_DEFACTO_STATUS_LABEL,
    value: APPLICANT_RELATIONSHIP_DEFACTO_STATUS_VALUE,
  },
];

export const APPLICANT_RELATIONSHIP_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(APPLICANT_RELATIONSHIP_TYPES);

export const APPLICANT_DRIVER_LICENCE_C_LABEL = 'Car (C)';
export const APPLICANT_DRIVER_LICENCE_C_VALUE = 'C';

export const APPLICANT_DRIVER_LICENCE_R_LABEL = 'Rider (R)';
export const APPLICANT_DRIVER_LICENCE_R_VALUE = 'R';

export const APPLICANT_DRIVER_LICENCE_LR_LABEL = 'Light Rigid (LR)';
export const APPLICANT_DRIVER_LICENCE_LR_VALUE = 'LR';

export const APPLICANT_DRIVER_LICENCE_MR_LABEL = 'Medium Rigid (MR)';
export const APPLICANT_DRIVER_LICENCE_MR_VALUE = 'MR';

export const APPLICANT_DRIVER_LICENCE_HR_LABEL = 'Heavy Rigid (HR)';
export const APPLICANT_DRIVER_LICENCE_HR_VALUE = 'HR';

export const APPLICANT_DRIVER_LICENCE_HC_LABEL = 'Heavy Combination (HC)';
export const APPLICANT_DRIVER_LICENCE_HC_VALUE = 'HC';

export const APPLICANT_DRIVER_LICENCE_MC_LABEL = 'Multi Combination (MC)';
export const APPLICANT_DRIVER_LICENCE_MC_VALUE = 'MC';

export const APPLICANT_DRIVER_LICENCE_TYPES = [
  {
    label: APPLICANT_DRIVER_LICENCE_C_LABEL,
    value: APPLICANT_DRIVER_LICENCE_C_VALUE,
  },
  {
    label: APPLICANT_DRIVER_LICENCE_R_LABEL,
    value: APPLICANT_DRIVER_LICENCE_R_VALUE,
  },
  {
    label: APPLICANT_DRIVER_LICENCE_LR_LABEL,
    value: APPLICANT_DRIVER_LICENCE_LR_VALUE,
  },
  {
    label: APPLICANT_DRIVER_LICENCE_MR_LABEL,
    value: APPLICANT_DRIVER_LICENCE_MR_VALUE,
  },
  {
    label: APPLICANT_DRIVER_LICENCE_HR_LABEL,
    value: APPLICANT_DRIVER_LICENCE_HR_VALUE,
  },
  {
    label: APPLICANT_DRIVER_LICENCE_HC_LABEL,
    value: APPLICANT_DRIVER_LICENCE_HC_VALUE,
  },
  {
    label: APPLICANT_DRIVER_LICENCE_MC_LABEL,
    value: APPLICANT_DRIVER_LICENCE_MC_VALUE,
  },
];

export const APPLICANT_DRIVER_LICENCE_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(APPLICANT_DRIVER_LICENCE_TYPES);

export const TOTAL_LEAD_APPLICATION_STEPS = 18;
export const TOTAL_LEAD_APPLICATION_STEPS_EXTENDED = 12;
export const TOTAL_MAIN_APPLICATION_STEPS_CONSUMER_EXTENDED = 18;
export const TOTAL_MAIN_APPLICATION_STEPS_COMMERCIAL_EXTENDED = 13;
export const TOTAL_MAIN_APPLICATION_STEPS = 19;
export const MAIN_APPLICATION_STEP_START = 22;
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT = 700;
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT_MESSAGE = 'Very good';
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE = 600;
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE_MESSAGE = 'Good';
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR = 500;
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR_MESSAGE = 'Average';
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR = 399;
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR_MESSAGE = 'Below average';
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_UNSURE = 299;
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_UNSURE_MESSAGE = 'Not Sure';

export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_OPTIONS = [
  {
    key: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT,
    value: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT_MESSAGE,
  },
  {
    key: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE,
    value: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE_MESSAGE,
  },
  {
    key: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR,
    value: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR_MESSAGE,
  },
  {
    key: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR,
    value: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR_MESSAGE,
  },
  {
    key: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_UNSURE,
    value: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_UNSURE_MESSAGE,
  },
];

export const INCOME_EXPENSE_FREQUENCY_WEEKLY = 'Weekly';
export const INCOME_EXPENSE_FREQUENCY_FORTNIGHTLY = 'Fortnightly';
export const INCOME_EXPENSE_FREQUENCY_MONTHLY = 'Monthly';
export const INCOME_EXPENSE_FREQUENCY_YEARLY = 'Yearly';

export const INCOME_FREQUENCY_TYPES = [
  {
    key: INCOME_EXPENSE_FREQUENCY_WEEKLY,
    value: INCOME_EXPENSE_FREQUENCY_WEEKLY,
  },
  {
    key: INCOME_EXPENSE_FREQUENCY_FORTNIGHTLY,
    value: INCOME_EXPENSE_FREQUENCY_FORTNIGHTLY,
  },
  {
    key: INCOME_EXPENSE_FREQUENCY_MONTHLY,
    value: INCOME_EXPENSE_FREQUENCY_MONTHLY,
  },
  {
    key: INCOME_EXPENSE_FREQUENCY_YEARLY,
    value: INCOME_EXPENSE_FREQUENCY_YEARLY,
  },
];

export const INCOME_FREQUENCY_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(INCOME_FREQUENCY_TYPES);

export const APPLICATION_ASSETS_HOME_PROPERTY = 'Property';
export const APPLICATION_ASSETS_INVISTMENT_PROPERTY = 'Investment property';
export const APPLICATION_ASSETS_CASH_IN_BANK = 'Cash in bank';
export const APPLICATION_ASSETS_SHARES = 'Shares';
export const APPLICATION_ASSETS_VEHICLE = 'Vehicle';
export const APPLICATION_ASSETS_TOOLS_EQUIPMENT = 'Tools & Equipment';
export const APPLICATION_ASSETS_SUPERANNUATION = 'Superannuation';
export const APPLICATION_ASSETS_RECREATIONAL = 'Recreational assets';
export const APPLICATION_ASSETS_HOME_CONTENTS = 'Home Contents';
export const APPLICATION_ASSETS_OTHER = 'Other';
export const ASSET_TYPE_CHEQUE = 'Cheque';
export const ASSET_TYPE_SAVINGS = 'Savings';
export const ASSET_TYPE_TERM_DEPOSIT = 'Term Deposit';
export const ASSET_TYPE_MANAGED_FUND = 'Managed Fund';
export const ASSET_TYPE_INVESTMENT_SAVINGS = 'Investment Savings';
export const ASSET_TYPE_HOME_PROPERTY = 'Home property';

export const APPLICATION_ASSETS_TYPES = [
  {
    label: ASSET_TYPE_HOME_PROPERTY,
    value: APPLICATION_ASSETS_HOME_PROPERTY,
  },
  {
    label: APPLICATION_ASSETS_INVISTMENT_PROPERTY,
    value: APPLICATION_ASSETS_INVISTMENT_PROPERTY,
  },
  {
    label: APPLICATION_ASSETS_CASH_IN_BANK,
    value: APPLICATION_ASSETS_CASH_IN_BANK,
  },
  {
    label: APPLICATION_ASSETS_SHARES,
    value: APPLICATION_ASSETS_SHARES,
  },
  {
    label: APPLICATION_ASSETS_VEHICLE,
    value: APPLICATION_ASSETS_VEHICLE,
  },
  {
    label: APPLICATION_ASSETS_TOOLS_EQUIPMENT,
    value: APPLICATION_ASSETS_TOOLS_EQUIPMENT,
  },
  {
    label: APPLICATION_ASSETS_SUPERANNUATION,
    value: APPLICATION_ASSETS_SUPERANNUATION,
  },
  {
    label: APPLICATION_ASSETS_RECREATIONAL,
    value: APPLICATION_ASSETS_RECREATIONAL,
  },
  {
    label: APPLICATION_ASSETS_HOME_CONTENTS,
    value: APPLICATION_ASSETS_HOME_CONTENTS,
  },
  {
    label: APPLICATION_ASSETS_OTHER,
    value: APPLICATION_ASSETS_OTHER,
  },
  {
    label: ASSET_TYPE_CHEQUE,
    value: ASSET_TYPE_CHEQUE,
  },
  {
    label: ASSET_TYPE_SAVINGS,
    value: ASSET_TYPE_SAVINGS,
  },
  {
    label: ASSET_TYPE_TERM_DEPOSIT,
    value: ASSET_TYPE_TERM_DEPOSIT,
  },
  {
    label: ASSET_TYPE_MANAGED_FUND,
    value: ASSET_TYPE_MANAGED_FUND,
  },
  {
    label: ASSET_TYPE_INVESTMENT_SAVINGS,
    value: ASSET_TYPE_INVESTMENT_SAVINGS,
  },
];

export const APPLICATION_ASSETS_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(APPLICATION_ASSETS_TYPES);

export const LIABILITY_TYPE_STORE_CARD = 'Store Card';
export const LIABILITY_TYPE_CREDIT_CARD = 'Credit Card';
export const LIABILITY_TYPE_PERSONAL_LOAN = 'Personal Loan';
export const LIABILITY_TYPE_HOME_LOAN = 'Home loan';
export const LIABILITY_TYPE_INVESTMENT_HOME_LOAN = 'Investment home loan';
export const LIABILITY_TYPE_VEHICLE_LOAN = 'Vehicle Loan';
export const LIABILITY_TYPE_SHORT_TERM_FINANCE = 'Short Term Finance';
export const LIABILITY_TYPE_LEASE_OR_HIRE_PURCHASE = 'Lease Or Hire Purchase';
export const LIABILITY_TYPE_TAX_DEBT = 'Tax Debt';
export const LIABILITY_TYPE_LINE_OF_CREDIT = 'Line Of Credit';
export const LIABILITY_TYPE_OVERDRAFT = 'Overdraft';
export const LIABILITY_TYPE_HELP_HECS = 'Help Hecs';
export const LIABILITY_TYPE_MORTGAGE = 'Mortgage';
export const LIABILITY_TYPE_RECREATION_ASSET_LOAN = 'Recreational asset loan';
export const LIABILITY_TYPE_EQUIPMENT_LOAN = 'Equipment loan';
export const LIABILITY_TYPE_BUY_NOW_PAY_LATER = 'Buy now pay later';
export const LIABILITY_TYPE_DEBT_AGREEMENT = 'Debt agreement';
export const LIABILITY_TYPE_OTHER = 'Other';
export const LABEL_PDF = 'pdf';
export const LABEL_FOR_ONE_SCORE = 'One Score';
export const LABEL_ZIP_FILE_NAME = 'carclarity.zip';
export const LABEL_ZIP_FOLDER_NAME = 'carclarity';

export const APPLICATION_LIABILITY_TYPES = [
  {
    label: LIABILITY_TYPE_STORE_CARD,
    value: LIABILITY_TYPE_STORE_CARD,
  },
  {
    label: LIABILITY_TYPE_CREDIT_CARD,
    value: LIABILITY_TYPE_CREDIT_CARD,
  },
  {
    label: LIABILITY_TYPE_PERSONAL_LOAN,
    value: LIABILITY_TYPE_PERSONAL_LOAN,
  },
  {
    label: LIABILITY_TYPE_HOME_LOAN,
    value: LIABILITY_TYPE_HOME_LOAN,
  },
  {
    label: LIABILITY_TYPE_INVESTMENT_HOME_LOAN,
    value: LIABILITY_TYPE_INVESTMENT_HOME_LOAN,
  },
  {
    label: LIABILITY_TYPE_VEHICLE_LOAN,
    value: LIABILITY_TYPE_VEHICLE_LOAN,
  },
  {
    label: LIABILITY_TYPE_SHORT_TERM_FINANCE,
    value: LIABILITY_TYPE_SHORT_TERM_FINANCE,
  },
  {
    label: LIABILITY_TYPE_LEASE_OR_HIRE_PURCHASE,
    value: LIABILITY_TYPE_LEASE_OR_HIRE_PURCHASE,
  },
  {
    label: LIABILITY_TYPE_TAX_DEBT,
    value: LIABILITY_TYPE_TAX_DEBT,
  },
  {
    label: LIABILITY_TYPE_LINE_OF_CREDIT,
    value: LIABILITY_TYPE_LINE_OF_CREDIT,
  },
  {
    label: LIABILITY_TYPE_OVERDRAFT,
    value: LIABILITY_TYPE_OVERDRAFT,
  },
  {
    label: LIABILITY_TYPE_HELP_HECS,
    value: LIABILITY_TYPE_HELP_HECS,
  },

  {
    label: LIABILITY_TYPE_RECREATION_ASSET_LOAN,
    value: LIABILITY_TYPE_RECREATION_ASSET_LOAN,
  },
  {
    label: LIABILITY_TYPE_EQUIPMENT_LOAN,
    value: LIABILITY_TYPE_EQUIPMENT_LOAN,
  },
  {
    label: LIABILITY_TYPE_BUY_NOW_PAY_LATER,
    value: LIABILITY_TYPE_BUY_NOW_PAY_LATER,
  },
  {
    label: LIABILITY_TYPE_DEBT_AGREEMENT,
    value: LIABILITY_TYPE_DEBT_AGREEMENT,
  },
  {
    label: LIABILITY_TYPE_OTHER,
    value: LIABILITY_TYPE_OTHER,
  },
];

export const LIABILITES_TO_ASSET_MAPPING = {
  [LIABILITY_TYPE_HOME_LOAN]: APPLICATION_ASSETS_HOME_PROPERTY,
  [LIABILITY_TYPE_INVESTMENT_HOME_LOAN]: APPLICATION_ASSETS_INVISTMENT_PROPERTY,
  [LIABILITY_TYPE_VEHICLE_LOAN]: APPLICATION_ASSETS_VEHICLE,
  [LIABILITY_TYPE_EQUIPMENT_LOAN]: APPLICATION_ASSETS_TOOLS_EQUIPMENT,
  [LIABILITY_TYPE_RECREATION_ASSET_LOAN]: APPLICATION_ASSETS_RECREATIONAL,
};

export const ASSETS_TO_LIABILITY_MAPPING = {
  [APPLICATION_ASSETS_HOME_PROPERTY]: LIABILITY_TYPE_HOME_LOAN,
  [APPLICATION_ASSETS_INVISTMENT_PROPERTY]: LIABILITY_TYPE_INVESTMENT_HOME_LOAN,
  [APPLICATION_ASSETS_VEHICLE]: LIABILITY_TYPE_VEHICLE_LOAN,
  [APPLICATION_ASSETS_TOOLS_EQUIPMENT]: LIABILITY_TYPE_EQUIPMENT_LOAN,
  [APPLICATION_ASSETS_RECREATIONAL]: LIABILITY_TYPE_RECREATION_ASSET_LOAN,
};

export const APPLICATION_LIABILITY_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(APPLICATION_LIABILITY_TYPES);

export const APPLICATION_ASSETS_OWNER_PRIMARY = 'Primary Applicant';
export const APPLICATION_ASSETS_OWNER_SECONDARY = 'Secondary Applicant';
export const APPLICATION_ASSETS_OWNER_JOINT = 'Joint';
export const APPLICATION_ASSETS_OWNER_SOLE = 'Sole';

export const APPLICATION_ASSETS_OWNER_TYPES = [
  {
    label: APPLICATION_ASSETS_OWNER_PRIMARY,
    value: APPLICATION_ASSETS_OWNER_PRIMARY,
  },
  {
    label: APPLICATION_ASSETS_OWNER_SECONDARY,
    value: APPLICATION_ASSETS_OWNER_SECONDARY,
  },
  {
    label: APPLICATION_ASSETS_OWNER_SOLE,
    value: APPLICATION_ASSETS_OWNER_SOLE,
  },
  {
    label: APPLICATION_ASSETS_OWNER_JOINT,
    value: APPLICATION_ASSETS_OWNER_JOINT,
  },
];

export const LABEL_SORTED_BY = 'Sorted by';
export const LABEL_SHOW_REPAYMENT = 'Show repayment';
export const LABEL_REPAYMENT_HIGH_TO_LOW = 'Repayment Amount (High to Low)';
export const LABEL_REPAYMENT_LOW_TO_HIGH = 'Repayment Amount (Low to High)';
export const LABEL_CUSTOMER_RATE_HIGH_TO_LOW = 'Customer Rate (Low to High)';
export const LABEL_TOTAL_INCOME_LOW_TO_HIGH = 'Total Income (High to Low)';
export const LENDER_MATCHES_SORTED_BY = [
  {
    label: LABEL_REPAYMENT_HIGH_TO_LOW,
    value: LABEL_REPAYMENT_HIGH_TO_LOW,
  },
  {
    label: LABEL_REPAYMENT_LOW_TO_HIGH,
    value: LABEL_REPAYMENT_LOW_TO_HIGH,
  },

  {
    label: LABEL_CUSTOMER_RATE_HIGH_TO_LOW,
    value: LABEL_CUSTOMER_RATE_HIGH_TO_LOW,
  },
  {
    label: LABEL_TOTAL_INCOME_LOW_TO_HIGH,
    value: LABEL_TOTAL_INCOME_LOW_TO_HIGH,
  },
];
export const LENDER_MATCHES_SHOW_REPAYMENT = [
  {
    key: INCOME_EXPENSE_FREQUENCY_WEEKLY,
    value: INCOME_EXPENSE_FREQUENCY_WEEKLY,
  },
  {
    key: INCOME_EXPENSE_FREQUENCY_FORTNIGHTLY,
    value: INCOME_EXPENSE_FREQUENCY_FORTNIGHTLY,
  },
  {
    key: INCOME_EXPENSE_FREQUENCY_MONTHLY,
    value: INCOME_EXPENSE_FREQUENCY_MONTHLY,
  },
];

export const APPLICATION_ASSETS_OWNER_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(APPLICATION_ASSETS_OWNER_TYPES.filter((r) => r.value !== APPLICATION_ASSETS_OWNER_SOLE));

export const APPLICATION_CONTACT_ASSETS_OWNER_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(APPLICATION_ASSETS_OWNER_TYPES.filter((r) => r.value !== APPLICATION_ASSETS_OWNER_SECONDARY && r.value !== APPLICATION_ASSETS_OWNER_PRIMARY));

export const VEHICLE_CONDITION_NEW = 'New';
export const VEHICLE_CONDITION_DEMO = 'Demo';
export const VEHICLE_CONDITION_USED = 'Used';

export const VEHICLE_CONDITION_TYPES = [VEHICLE_CONDITION_NEW, VEHICLE_CONDITION_USED, VEHICLE_CONDITION_DEMO];
export const VEHICLE_ASSET_DETAILS_CONDITION_OPTIONS = generateOptionsFromTypesWhereTheKeyIsSameAsValue(VEHICLE_CONDITION_TYPES);

export const SALE_TYPE_PRIVATE = 'Private';
export const SALE_TYPE_DEALER = 'Dealer';
export const SALE_TYPE_AUCTION = 'Auction';
export const SALE_TYPE_PRIVATE_VALUE = 'Private seller';
export const SALE_TYPE_DEALER_VALUE = 'Dealership';
export const SALE_TYPE_AUCTION_VALUE = 'Auction';
export const SALE_TYPE_STILL_DECIDING = 'Still deciding';
export const SALE_BUY_BACK = 'Sale & Buy back';
export const SALE_BALLOON_REFINANCE = 'Balloon Refinance';
export const SALE_MIDSTREAM_REFINANCE = 'Midstream Refinance';

export const SALE_TYPES = [
  {
    label: SALE_TYPE_PRIVATE_VALUE,
    value: SALE_TYPE_PRIVATE,
  },
  {
    label: SALE_TYPE_DEALER_VALUE,
    value: SALE_TYPE_DEALER,
  },
  {
    label: SALE_TYPE_AUCTION_VALUE,
    value: SALE_TYPE_AUCTION,
  },
];

export const SALE_TYPES_V4 = [
  {
    label: SALE_TYPE_PRIVATE_VALUE,
    value: SALE_TYPE_PRIVATE,
  },
  {
    label: SALE_TYPE_DEALER_VALUE,
    value: SALE_TYPE_DEALER,
  },
  {
    label: SALE_TYPE_AUCTION_VALUE,
    value: SALE_TYPE_AUCTION,
  },
  {
    label: SALE_TYPE_STILL_DECIDING,
    value: SALE_TYPE_STILL_DECIDING,
  },
];

export const SALE_TYPE_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(SALE_TYPES);

export const SALE_TYPE_OPTIONS_V4 = generateOptionsFromTypesWhereKeyIsSameAsValue(SALE_TYPES_V4);

export const VEHICLE_CONDITION_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(APPLICATION_ASSETS_OWNER_TYPES);

/* eslint-disable */
export const NUMBER_OF_DIRECTORES_OPTIONS = _.range(1, 9).map((item) => ({
  value: item,
  label: `${item}`,
}));
export const NUMBER_OF_PARTNERS_OPTIONS = _.range(1, 3).map((item) => ({
  value: item,
  label: `${item}`,
}));

export const LOAN_TERM_YEAR_OPTIONS = _.range(1, 8).map(yearOption);

export const YEAR_OPTIONS = _.range(0, 31).map(yearOption);

export const MONTH_OPTIONS = _.range(0, 12).map((item) => ({
  value: item,
  label: `${item} ${item === 1 ? 'month' : 'months'}`,
}));
/* eslint-enable */

// Form Success Message

export const ADDRESS_UPDATED_SUCCESS = 'Address information saved';

// ADDRESS DETAILS
export const ADDRESS_INFO = {
  country: 'Australia',
  isCurrent: false,
  address: '',
  atYears: null,
  atAdditionalMonths: null,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  postcode: '',
  livingSituation: '',
  areYouRentingThroughAgent: true,
};

export const LIABILITES_INFO = {
  type: '',
  ownership: '',
  lenderName: '',
  outstandingAmount: 0,
  installmentAmount: 0,
  installmentFrequency: INCOME_EXPENSE_FREQUENCY_MONTHLY,
  creditLimit: 0,
  payout: null,
};

export const ASSETS_INFO = {
  type: '',
  ownership: '',
  amount: 0,
  hasLiability: null,
};

export const WARRANTY_INFO = {
  provider: '',
  product: '',
  wholesale: 0,
  retail: 0,
  Commission: 0,
};

export const OTHER_INCOME = [
  { type: 'Rental', frequency: 'Monthly', amountPretax: null, netAmount: 0 },
  {
    type: 'Investment Income',
    frequency: 'Monthly',
    amountPretax: null,
    netAmount: 0,
  },
  {
    type: 'Salary Sacrifice',
    frequency: 'Monthly',
    amountPretax: null,
    netAmount: 0,
  },
  {
    type: 'Centrelink',
    frequency: 'Monthly',
    amountPretax: null,
    netAmount: 0,
  },
  { type: 'Foreign', frequency: 'Monthly', amountPretax: null, netAmount: 0 },
  {
    type: 'Child Maintenance',
    frequency: 'Monthly',
    amountPretax: null,
    netAmount: 0,
  },
  {
    type: 'Family Tax Benefit',
    frequency: 'Monthly',
    amountPretax: null,
    netAmount: 0,
  },
  { type: 'Other', frequency: 'Monthly', amountPretax: null, netAmount: 0 },
];

// Dependants
export const DEPENDANTS_LIST = [
  {
    age: 0,
  },
  {
    age: 0,
  },
  {
    age: 0,
  },
  {
    age: 0,
  },
  {
    age: 0,
  },
  {
    age: 0,
  },
  {
    age: 0,
  },
  {
    age: 0,
  },
  {
    age: 0,
  },
  {
    age: 0,
  },
];
// EMPLOYMENT DETAILS
export const EMPLOYMENT_INFO = {
  isCurrent: false,
  isAdditionalJob: false,
  position: '',
  status: '',
  startDate: '',
  contactName: '',
  aBN: '',
  address: {
    country: '',
    isCurrent: false,
    address: '',
    atYears: null,
    atAdditionalMonths: null,
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postcode: '',
  },
};

// EMPLOYMENT COMPONENTS LABELS
export const MESSAGE_MINIMUM_THREE_YEARS_REQUIRED = 'Minimum 3 years of employment is required.';
export const MESSAGE_IS_APPLICANT_CUURENTLY_EMPLOYED = 'Is the applicant currently employed?';
export const EMPLOYER_NAME = 'Employer name';
export const EMPLOYER_OCCUPATION = 'Occupation';
export const EMPLOYER_STATUS = 'Status';
export const EMPLOYER_TIME_AT_EMPLOYMENT = 'Time at employment';
export const EMPLOYER_TIME_AT_EMPLOYER_YEARS = 'Time at employer (Years)';
export const EMPLOYER_TIME_AT_EMPLOYER_MONTHS = 'Time at employer (Months)';
export const EMPLOYER_START_DATE = 'Start date';
export const EMPLOYER_CURRENT_EMPLOYMENT = 'Current employment';
export const EMPLOYER_FULL_ADDRESS = 'Enter full address';
export const EMPLOYER_NO_PRIOR_EMPLOYMENT = 'No prior employment';
export const EMPLOYER_APPLICANT_NO_PRIOR_EMPLOYMENT = 'Applicant does not have prior employment';
export const EMPLOYER_FULL_ADDRESS_PLACE_HOLDER = 'Start typing';
export const EMPLOYER_UNIT_STREET = 'Unit / Street';
export const EMPLOYER_SUBURB = 'Suburb';
export const EMPLOYER_STATE = 'State';
export const EMPLOYER_POSTCODE = 'Postcode';
export const EMPLOYER_COUNTRY = 'Country';
export const EMPLOYER_CONTACT_NAME = 'Employer contact name';
export const EMPLOYER_PHONE = 'Employer phone';
export const EMPLOYER_ABN = 'Employer ABN';
export const EMPLOYER_ADD_EMPLOYMENT = 'Add Employment';
export const EMPLOYER_BACK_BUTTON = 'Back';
export const EMPLOYER_SAVE_BUTTON = 'Save';
export const EMPLOYER_SAVE_AND_NEXT_BUTTON = 'Save & Next';
export const TITLE_FOR_EMPLOYMENT_DETAILS = 'Employment details';

export const REMOVE_EMPLOYMENT_TEXT = 'Marking the applicant as unemployed will clear any added employment information. Do you want to continue?';

// LIVING_SITUATION - NEW
export const LIVING_SITUATION_OWN_HOME_WITH_MORTGAGE = 'I Own The Home I Live In (With Mortgage)';
export const LIVING_SITUATION_OWN_HOME_WITHOUT_MORTGAGE = 'I Own The Home I Live In (Without Mortgage)';
export const LIVING_SITUATION_TENANT_OWN_HOME_WITH_MORTGAGE = "I'm A Tenant (But I Have A Mortgage On Another Property)";
export const LIVING_SITUATION_TENANT_NO_MORTGAGE = "I'm A Tenant (Renting)";
export const LIVING_SITUATION_BOARDER = "I'm A Boarder";
export const LIVING_SITUATION_LIVING_WITH_PARENTS = "I'm Living With Parents";

export const LIVING_SITUATIONS_MAY_PAY_RENT_OR_BOARD = [
  { value: LIVING_SITUATION_OWN_HOME_WITH_MORTGAGE },
  { value: LIVING_SITUATION_OWN_HOME_WITHOUT_MORTGAGE },
  { value: LIVING_SITUATION_TENANT_OWN_HOME_WITH_MORTGAGE },
  { value: LIVING_SITUATION_TENANT_NO_MORTGAGE },
  { value: LIVING_SITUATION_BOARDER },
  { value: LIVING_SITUATION_LIVING_WITH_PARENTS },
];

export const RELATION_MARRIED = 'Married';
export const RELATION_DE_FACTO = 'De Facto';
export const RELATION_PARENT = 'Parent';
export const RELATION_CHILD = 'Child';

export const APPLICANT_RELATIONSHIP_WITH_PRIMARY_APPLICANT_TYPES = [
  {
    label: RELATION_MARRIED,
    value: RELATION_MARRIED,
  },
  {
    label: RELATION_DE_FACTO,
    value: RELATION_DE_FACTO,
  },
  {
    label: RELATION_PARENT,
    value: RELATION_PARENT,
  },
  {
    label: RELATION_CHILD,
    value: RELATION_CHILD,
  },
];

export const APPLICANT_RELATIONSHIP_WITH_PRIMARY_APPLICANT_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(APPLICANT_RELATIONSHIP_WITH_PRIMARY_APPLICANT_TYPES);
// EXPENSE COMPONENTS LABELS
export const LABEL_EXPENSES_BACK_BUTTON = 'Back';
export const LABEL_EXPENSES_SAVE_BUTTON = 'Save';
export const LABEL_EXPENSES_SAVE_AND_NEXT_BUTTON = 'Save & Next';
export const LABEL_TOTAL_EXPENSES_TOP = 'Total Expenses (Monthly)';
export const DESCRIPTION_FOR_DELETE_SECONDARY_APPLICANT = 'Removing secondary applicant will cause the current application to be withdrawn and create a clone of a current application with only primary applicant details. Do you want to continue?';
export const LABEL_PRIMARY_APPLICANT = 'Primary applicant';
export const LABEL_SECONDARY_APPLICANT = 'Secondary applicant';
export const LABEL_SETTLEMENT_PRIMARY_APPLICANT = 'Primary applicant';
export const LABEL_SETTLEMENT_SECONDARY_APPLICANT = 'Secondary applicant';
export const LABEL_FOR_REMOVED_SECONDARY_APPLICANT = 'Do you want to remove secondary applicant';
export const LABEL_FOR_REMOVE_CONTACT = 'Do you want to remove contact';
export const LABEL_TOTAL_EXPENSES = 'Total';
export const LABEL_ALL_EXPENSES = 'All Expenses (Total for household)';
export const LABEL_FREQUENCY = 'Frequency';
export const LABEL_DOCUMENT_FOLDER_PRIMARY = 'primaryApplicant';
export const LABEL_DOCUMENT_FOLDER_SECONDARY = 'otherApplicant';
export const LABEL_DOCUMENT_FOLDER_SHARED = 'shared';
export const DEFAULT_EXPENSE_CONSTANT = [
  {
    type: 'Rent Or Board',
    amount: 0,
    frequency: 'Monthly',
    helpText: '',
  },
  {
    type: 'Food or Grocery',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'Household food and groceries, and pet care',
  },
  {
    type: 'Internet And Telephone',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'Mobile phone, home phone, wifi, Foxtel, and any other subscriptions',
  },
  {
    type: 'Utilities',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'Council rates, strata, gas, electricity, and water bills',
  },
  {
    type: 'Insurances',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'Car insurance, health insurance, life Insurance, home and contents, pet insurance, and any other insurances',
  },
  {
    type: 'Travel',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'Public transport, parking, tolls, and ride sharing services',
  },
  {
    type: 'Entertainment',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'Take away, social outings, holidays, gym, and any other entertainment',
  },
  {
    type: 'Education',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'Schooling fees, private school costs, tutoring, and adult education',
  },
  {
    type: 'Childcare',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'Child care and child support',
  },

  {
    type: 'Motor Vehicle Running Costs',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'Petrol, rego, servicing and maintenance costs for owned vehicles',
  },
  {
    type: 'Clothing and personal care',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'Clothing, beauty (e.g., nails), hair, and cosmetics',
  },
  {
    type: 'Medical and health care',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'Medical scripts and ongoing appointments (e.g., physiotherapy) ',
  },
  {
    type: 'Other',
    amount: 0,
    frequency: 'Monthly',
    helpText: 'All expenses not specified above',
  },
];

export const LABEL_EXPENSES_INCOME = 'Income';
export const LABEL_EXPENSES_MONTH = 'month';
export const LABEL_EXPENSES_PRIMARY = 'Primary';
export const LABEL_EXPENSES_SECONDARY = 'Secondary';
export const LABEL_EXPENSES_JOINT = 'Joint';
export const LABEL_EXPENSES_OTHER = 'Other';
export const LABEL_EXPENSES_SPOUSAL = 'Spousal';
export const LABEL_EXPENSES_TOTAL = 'Total';
export const LABEL_EXPENSES_NET_SURPLUS = 'Net Surplus';
export const LABEL_EXPENSES_LIABILITIES = 'Liabilities';
export const LABEL_EXPENSES = 'Expenses';
export const LABEL_RENT = 'Rent';
export const LABEL_RENT_OR_BOARD = 'Rent Or Board';
export const LABEL_YES_PROCEED = 'Yes. Proceed';
export const LABEL_DECLARED = 'Declared';
export const LABEL_HEM = 'HEM';
export const LABEL_DIFFERENCE = 'Difference';
export const LABEL_ADD_DOCUMENT = 'Add document';
export const LABEL_REQUEST_PENDING_DOCUMENTS = 'Request pending documents';
export const LABEL_REQUEST_PENDING_DOCUMENTS_MANUALLY = 'Request pending documents manually';
export const LABEL_ALTERNATIVELY = 'Alternatively';
export const LABEL_REQUEST_MANUALLY = 'Request manually';
export const LABEL_RESEND_EMAIL_FOR_PENDING_DOCUMENTS = 'Resend email for pending documents';
export const LABEL_AVAILABLE = 'Available';
export const LABEL_ADD_OTHER_DOCUMENT = 'Add other document';
export const LABEL_SELECTED = 'Selected';
export const LABEL_CANCEL = 'Cancel';
export const LABEL_SWITCH_TO_THAT_USER = 'Switch to that user';
export const LABEL_TITLE_IMPERSONATE_ACCELERATE = 'Type a phone number of impersonate user';
export const LABEL_EMAIL_AND_ADD = 'Email & Add';
export const LABEL_ADD_ONLY = 'Add Only';
export const LABEL_REQUEST_BANK_STATEMENT = 'Request Bank statement';
export const LABEL_NEXT = 'Next';
export const LABEL_OTHER_DOCUMENT_NAME = 'Other Document name';
export const LABEL_BACK_BUTTON = 'Back';
export const LABEL_DOCUMENT_DROP = 'Drop file here or';
export const LABEL_DOCUMENT_UPLOAD = 'click here to upload';
export const LABEL_ADD_MORE_DOCUMENT_FIRST = 'Use the option';
export const LABEL_ADD_MORE_DOCUMENT_SECOND = '+ Add Documents';
export const LABEL_ADD_MORE_DOCUMENT_THIRD = 'to add one or more documents';
export const LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_FIRST = 'Select the option';
export const LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_SECOND = 'Email & Add';
export const LABEL_SELECT_THE_OPTION_EMAIL_AND_ADD_THIRD = 'to email the applicant to request for the selected documents as well';
export const LABEL_SELECT_ONE_OR_MORE_DOCUMENT_FIRST = 'Select one or more documents from the available list. A custom document name can be added using the option';
export const LABEL_SELECT_ONE_OR_MORE_DOCUMENT_SECOND = '+ Add Other document';
export const LABEL_ADD_NEW_BUSINESS = '+ Add new business';
export const LABEL_NEW_BUSINESS_INFORMATION = 'New business information';
export const LABEL_SHARED = 'Shared';
export const LABEL_INDIVIDUAL = 'Individual';
export const LABEL_DOCUMENT_PRIMARY = 'Primary';
export const LABEL_DOCUMENT_SECONDARY = 'Secondary';
export const LABEL_BANK_STATEMENT_REQUEST_PERIOD = 'Bank statement request period ';
export const LABEL_DOCUMENT_SETTLEMENT = 'Settlement';
export const LABEL_SETTLEMENTS = 'Settlements';
export const LABEL_REFERRALS = 'Referrals';
export const LABEL_DOCUMENT_SETTLEMENT_DOCUMENT = 'Settlement documents';
export const LABEL_CREDIT_REPORT_GENERATED = 'Credit report generated';
export const LABEL_CREDIT_REPORT_ERROR = 'Credit report error';
export const LABEL_CREDIT_HISTORY = 'Credit history';
export const LABEL_CREDIT_AGENT = 'Agent';
export const LABEL_CREDIT_ADMIN = 'Admin';

export const LABEL_AGENT_NOTES_DISCRIPTION = 'Please use this section for any personal notes related to the application';
export const LABEL_FOR_NO_NOTES_AVAILABLE_REFERRER = 'No notes are available from a referrer.';
export const LABEL_FOR_NO_NOTES_AVAILABLE_FOR_AGENT = 'No notes are available from an agent.';
export const LABEL_REFERRER_NOTES_DISCRIPTION = 'Please use this section to share any notes with the referrer';

export const LABEL_NAVIGATION_DUPLICATED = 'NavigationDuplicated';

export const CREDIT_FETCH_ERROR_DISCRIPTION = 'There was an issue retrieving the pdf for its associated credit score inquiry. Please use the option Re-fetch in the listing to retrieve the pdf again. If you receive the same error again, raise this issue with the support team';
export const CREDIT_FETCH_ERROR_DISCRIPTION_SECOND = 'Please note that using the option Re-fetch won’t get counted towards your quota of credit score inquiries';
export const ERROR_MESSAGES_FOR_BROKERLOADING = 'Please enter an amount between 0 - ';
export const ERROR_MESSAGES_FOR_ORIGINATIONFEE_EQUAL_OR_GREATER_TO_ZERO = 'Entered amount should be equal or greater than $0';
export const ERROR_MESSAGES_FOR_ORIGINATIONFEE = 'Maximum allowed amount is $';

export const SWITCH_APPLICATION_TYPE_DISCRIPTION = 'Please select one from the list of business  records found associated with the primary applicant or alternatively  create a new one';

export const SWITCH_APPLICATION_TYPE_DISCRIPTION_SECOND = 'No business record is found associated with the primary applicant. Please provide details to create a new one';

export const LABEL_FOR_DO_YOU_WANT_TO_KEEP_THE_DATA_OF_SECONDARY = 'Do you want to keep the data of secondary applicant';
export const LABEL_FOR_ARE_YOU_WANT_TO_CHANGE_THE_CURRENT_APPLICATION_TYPE_TO_CONSUMER = 'Are you sure you want to change the current application type to <b> Consumer </b> ?';

export const ERROR_MESSAGES_FOR_SWITCH_APPLICATION_TYPE_BUSINESS_STRUCTURE_SOLE_TRADER = '“Sole trader” business structure can only have one  contact. Please remove the secondary applicant and try again';

export const LABEL_FOR_SWITCH_APPLICATION_TYPE_TO_CONSUMER_DISCRIPTION = 'Please select the secondary applicant that you want to keep in the application. Select the option <b> None </b> if you dont want to add a secondary applicant.';

export const lABEL_FOR_SWITCH_APPLICATION_TYPE_TO = 'Switch application type to ';

export const LABEL_UNABLE_FETCH_PDF = 'Unable to fetch pdf';
export const LABEL_ELECTRONIC_BACK_STATEMENT_LAST_3_MONTHS = 'Electronic bank statement last 3 months';
export const LABEL_ELECTRONIC_BACK_STATEMENT_LAST_6_MONTHS = 'Electronic bank statement last 6 months';
export const LABEL_ELECTRONIC_BACK_STATEMENT_LAST_3_MONTHS_PURPOSE = 'Electronic bank statements last 3 months';
export const LABEL_ELECTRONIC_BACK_STATEMENT_LAST_6_MONTHS_PURPOSE = 'Electronic bank statements last 6 months';
export const LABEL_EMAIL_AND_ADD_IS_THE_ONLY_AVAIALABLE_ELECTRONIC_BANK_STATEMENT = '<b>Email & Add</b> is the only available option when the electronic bank statement is selected';

export const DEFAULT_DOCUMENT_TYPES = [
  {
    key: 'Identification (Licence front)',
    value: 'Driver Licence Front',
    selected: false,
  },
  {
    key: 'Identification (Licence back)',
    value: 'Driver Licence Back',
    selected: false,
  },
  {
    key: LABEL_ELECTRONIC_BACK_STATEMENT_LAST_3_MONTHS,
    value: 'Electronic bank statements last 3 months',
    selected: false,
  },
  {
    key: LABEL_ELECTRONIC_BACK_STATEMENT_LAST_6_MONTHS,
    value: 'Electronic bank statements last 6 months',
    selected: false,
  },
  {
    key: 'Medicare Card',
    value: 'Medicare',
    selected: false,
  },
  {
    key: 'Payslip 1',
    value: 'Payslip 1',
    selected: false,
  },
  {
    key: 'Payslip 2',
    value: 'Payslip 2',
    selected: false,
  },
  {
    key: 'Bank Statements',
    value: 'Bank Statement',
    selected: false,
  },
  {
    key: 'Identification (Passport)',
    value: 'Passport',
    selected: false,
  },
  {
    key: 'Residency / Visa Documents',
    value: 'Residency / Visa Document',
    selected: false,
  },
  {
    key: 'Tax Return (Individual)',
    value: 'Tax Return',
    selected: false,
  },
  {
    key: 'Company Financials',
    value: 'Company Financial',
    selected: false,
  },
  {
    key: 'Employment Confirmation',
    value: 'Employment Confirmation',
    selected: false,
  },
  {
    key: 'PAYG Summary',
    value: 'PAYG Summary',
    selected: false,
  },
  {
    key: 'Rental income statements',
    value: 'Rental income statements',
    selected: false,
  },
  {
    key: 'Spousal Income Confirmation',
    value: 'Spousal Income Confirmation',
    selected: false,
  },
  {
    key: 'Tenancy Agreement',
    value: 'Tenancy Agreement',
    selected: false,
  },
  {
    key: 'Proof of Address',
    value: 'Proof of Address',
    selected: false,
  },
  {
    key: 'Selfie ID',
    value: 'Selfie ID',
    selected: false,
  },
  {
    key: 'Business Use Confirmation',
    value: 'Business Use Confirmation',
    selected: false,
  },
  {
    key: 'Centrelink income statement',
    value: 'Centrelink income statement',
    selected: false,
  },
];
export const DEFAULT_SETTLEMENT_DOCUMENT_TYPES = [
  {
    key: 'Brokerage and Origination Invoice',
    value: 'Brokerage and Origination Invoice',
    selected: false,
  },
  {
    key: 'Business Use Confirmation',
    value: 'Business Use Confirmation',
    selected: false,
  },
  {
    key: 'Certified Copy of Deed of Variation',
    value: 'Certified Copy of Deed of Variation',
    selected: false,
  },
  {
    key: 'Certified Copy of Trust Deed',
    value: 'Certified Copy of Trust Deed',
    selected: false,
  },
  {
    key: 'Council Rates Notice',
    value: 'Council Rates Notice',
    selected: false,
  },
  {
    key: 'Dealer Deposit Slip',
    value: 'Dealer Deposit Slip',
    selected: false,
  },
  {
    key: 'Inspection Report (Verimoto/Redbook)',
    value: 'Inspection Report (Verimoto/Redbook)',
    selected: false,
  },
  {
    key: 'Insurance Certificate of Currency',
    value: 'Insurance Certificate of Currency',
    selected: false,
  },
  {
    key: 'Payout Letter',
    value: 'Payout Letter',
    selected: false,
  },
  {
    key: 'Private Sale Tax Invoice',
    value: 'Private Sale Tax Invoice',
    selected: false,
  },
  {
    key: 'Tax Return and Notice of Assessment',
    value: 'Tax Return and Notice of Assessment',
    selected: false,
  },
  {
    key: 'Warranty Policy',
    value: 'Warranty Policy',
    selected: false,
  },
  {
    key: 'Signed Finance Contract',
    value: 'Signed Finance Contract',
    selected: false,
  },
  {
    key: 'Dealer Invoice',
    value: 'Dealer Invoice',
    selected: false,
  },
  {
    key: 'Insurance Policies (Warranties etc)',
    value: 'Insurance Policies (Warranties etc)',
    selected: false,
  },
  {
    key: 'Remittance',
    value: 'Remittance',
    selected: false,
  },
];

export const DEFAULT_SETTLEMENT_APPLICANT_DOCUMENT_TYPES = [
  {
    key: 'PAYG Summary',
    value: 'PAYG Summary',
    selected: false,
  },
  {
    key: 'Proof of Address',
    value: 'Proof of Address',
    selected: false,
  },
  {
    key: 'Selfie ID',
    value: 'Selfie ID',
    selected: false,
  },
];

export const LABEL_SETTLEMENT_PRIMARY = 'settlementPrimary';
export const LABEL_SETTLEMENT_SECONDARY = 'secondaryApplicant';
export const LABEL_SETTLEMENT_OTHER = 'otherApplicant';

export const LABEL_SEND_LINK = 'Send link';
export const LABEL_RESEND_LINK = 'Resend link';

export const LABEL_SEND_APPLICATION_LINK = 'Send application link';
export const LABEL_SEND_APPLICATION_LINK_DESCRIPTION = 'Are you sure you want to send application link (SMS & Email) to primary applicant ';

export const LABEL_RESEND_APPLICATION_LINK = 'Resend application link';
export const LABEL_RESEND_APPLICATION_LINK_DESCRIPTION = 'Are you sure you want to resend application link (SMS & Email) to primary applicant ';

export const LABEL_APPLICATION_LINK_SENT = 'Application link sent';
export const LABEL_APPLICATION_LINK_SENT_DESCRIPTION = 'Application link has been sent to the primary applicant ';

export const LABEL_APPLICATION_STARTED = 'Application started';
export const LABEL_APPLICATION_STARTED_DESCRIPTION = 'Last updated by the primary applicant ';

export const LABEL_APPLICATION_COMPLETED = 'Application completed';
export const LABEL_APPLICATION_COMPLETED_DESCRIPTION = 'Application has been completed by the primary applicant ';

export const LABEL_MARK_AS_REVIEWED = 'Mark as reviewed';
export const LABEL_UNMARK_AS_REVIEWED = 'Unmark';
export const LABEL_APPLICATION_MARK_AS_REVIEWED = 'Application marked as reviewed';
export const LABEL_APPLICATION_MARK_AS_REVIEWED_DESCRIPTION = 'Application has been marked as reviewed by the agent ';

export const LABEL_APPLICATION_UPDATES_MARK_AS_REVIEWED = 'Applicant updates | Mark as reviewed';

export const LABEL_APPLICATION_UPDATES_MARK_AS_REVIEWED_DESCRIPTION_ONE = 'Are you sure you have reviewed all application sections updated by the applicant?';

export const LABEL_APPLICATION_UPDATES_MARK_AS_REVIEWED_DESCRIPTION_TWO = 'Please also note that using the option <b> Mark as reviewed </b> will lock the applicant out of the application link.';

export const LABEL_APPLICATION_UPDATES_MARK_AS_REVIEWED_DESCRIPTION_THREE = 'Do you want to continue?';

export const LABEL_COPY = 'Copy';

export const APPLICATION_LINK_STATUS_SENT = 'Application link sent';
export const APPLICATION_LINK_STATUS_IN_PROGRESS = 'Application in progress';
export const APPLICATION_LINK_STATUS_COMPLETED = 'Application completed';
export const APPLICATION_LINK_STATUS_MARK_AS_REVIEWED = 'Application marked as reviewed';

export const LABEL_DOCUMENT_REQUEST_BANK_STATEMENT = 'Request Bank statement';
export const LABEL_DOCUMENT_REQUEST_BANK_STATEMENT_DESCRIPTION = 'Are you sure you want to email a secure link for uploading the bank statement to the primary applicant';
export const LABEL_DOCUMENT_REQUEST_BANK_STATEMENT_DESCRIPTION_FOR_SECONDARY = 'Are you sure you want to email a secure link for uploading the bank statement to the secondary applicant';
export const LABEL_DOWNLOAD_ALL_DOCUMENTS = 'Download all documents';
export const LABEL_DOWNLOAD_ALL_DOCUMENTS_AS_ZIP = 'Download all documents as zip';
export const LABEL_UPLOADED_AT = 'Uploaded At';
export const LABEL_REQUESTED_AT = 'Requested At';
export const LABEL_ADDED_AT = 'Added at';
export const LABEL_REQUESTED_VIA_EMAIL = 'Requested via email at';
export const LABEL_BANK_STATEMENT_SECURE = 'Bank statement (secure)';
export const LABEL_EXPENSE_DECLARATION = 'Above expenses are declared as';
export const LABEL_HELP_TEXT_GOES_HERE = 'Help text goes here';
export const LABEL_PRIMARY_STRING = 'primary';
export const LABEL_SECONDARY_STRING = 'secondary';
export const LABEL_MORE_THAN_ZERO = 'moreThanZero';
export const LABEL_IS_ZERO = 'isZero';
export const LABEL_DOWNLOAD = 'Download';
export const LABEL_CREDIT_REFETCH = 'Re-fetch';
export const LABEL_EXPENSE_FREQUENCY_WEEKLY = 'Weekly';
export const LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY = 'Fortnightly';
export const LABEL_EXPENSE_FREQUENCY_MONTHLY = 'Monthly';
export const LABEL_EXPENSE_FREQUENCY_YEARLY = 'Yearly';
export const LABEL_IMPORT = 'Import';
export const EXPENSE_FREQUENCY_TYPES = [
  {
    key: LABEL_EXPENSE_FREQUENCY_WEEKLY,
    value: LABEL_EXPENSE_FREQUENCY_WEEKLY,
  },
  {
    key: LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY,
    value: LABEL_EXPENSE_FREQUENCY_FORTNIGHTLY,
  },
  {
    key: LABEL_EXPENSE_FREQUENCY_MONTHLY,
    value: LABEL_EXPENSE_FREQUENCY_MONTHLY,
  },
  {
    key: LABEL_EXPENSE_FREQUENCY_YEARLY,
    value: LABEL_EXPENSE_FREQUENCY_YEARLY,
  },
];

export const EXPENSE_FREQUENCY_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(EXPENSE_FREQUENCY_TYPES);

// Secondary Applicant Adding Labels
export const LABEL_FOR_FIRST_NAME = 'First name';
export const LABEL_FOR_LAST_NAME = 'Last name';
export const LABEL_FOR_MOBILE = 'Mobile';
export const LABEL_FOR_EMAIL = 'Email';
export const LABEL_FOR_RELATIONSHIP_WITH_PRIMARY_APPLICANT = 'Relationship with primary applicant';
export const LABEL_FOR_PREFERRD_CUSTOMER_COMMUNICATION_METHOD = 'Preferred customer communication method';
export const LABEL_FOR_BASIC_DETAILS = 'Basic details';
export const LABEL_FOR_ADD_BUTTON = 'Add';
export const LABEL_FOR_CANCEL_BUTTON = 'Cancel';
export const LABEL_FOR_ADD_APPLICANT = 'Add applicant';
export const LABEL_FOR_ADD_CONTACT = 'Add Contact';
export const LABEL_FOR_ADD_DIRECTOR = 'Add Director';
export const LABEL_FOR_ADD_PARTNER = 'Add Partner';
export const LABEL_FOR_ADD_TRUSTEE = 'Add Trustee';
export const LABEL_FOR_SECONDARY = 'Secondary';
export const LABEL_FOR_PHONE_NUMBER = 'Phone number';
export const LABEL_FOR_DEALERSHIP_NAME = 'Dealership name';
export const LABEL_FOR_DEALERSHIP_ABN = 'Dealership ABN';
// Requirements tab development
export const LABEL_FOR_OBJECTIVES_AND_REQUIREMENT = 'Objectives & requirements';
export const LABEL_FOR_WHAT_IS_THE_PURPOSE_OF_OBTAINING = 'What is the purpose of obtaining, increasing or refinancing the credit?';
export const LABEL_FOR_WHAT_IS_LOAN_TYPE = 'What is the loan type?';
export const LABEL_FOR_WHAT_IS_THE_CUSTOMER_BUDGETTED = 'What is the customer’s budgeted monthly repayment?';
export const LABEL_FOR_WHAT_LOAN_FEATURES_ARE_IMPORTANT = 'What loan features are important to the customer?';
export const BALLON_ON_THE_LOAN = 'Balloon on the loan';
export const VEHICLE_CONDITION_BALLOON = 'Balloon';
export const FLEXIBILITY_TO_MAKE_ADDITIONAL_REPLAYMENT = 'Flexibility to make additional repayments';
export const NO_EARLY_EXIT_FEES = 'No early exit fees';
export const LOWEST_RATE_POSSIBLE = 'Lowest rate possible';
export const REQUESTED_LOAN_DETAILS = 'Requested loan details';
export const DOCUMENT_EDIT_LINK = 'Edit';
export const LABEL_DOCUMENT_LOAN_TYPE = 'Loan type';
export const LABEL_DOCUMENT_CONSUMER_CAR_LOAN = 'Consumer Car Loan';
export const LABEL_DOCUMENT_COMMERCIAL_CAR_LOAN = 'Commercial Car Loan';
export const LABEL_DOCUMENT_PURCHASE_PRICE = 'Purchase price';
export const LABEL_TRADE_IN_EQUITY = 'Trade in Equity';
export const LABEL_DOCUMENT_LOAN_TERM = 'Loan term';
export const LABEL_FOR_LOAN_TERM = 'Loan Term';
export const LABEL_DOCUMENT_DEPOSIT = 'Deposit';
export const LABEL_DOCUMENT_BALLOON = 'Balloon %';
export const LABEL_DOCUMENT_BALLOON_ONLY = 'Balloon $';
export const LABEL_DOCUMENT_TRADE_IN = 'Trade-in';
export const LABEL_DOCUMENT_ESTIMETED_PRICE = 'Estimated Value:';
export const LABEL_DOCUMENT_BUDGETED_MONTHLY_REPAYMENT = 'Budgeted monthly repayment';
export const LABEL_DOCUMENT_LOAN_AMOUNT = 'Loan Amount';
export const LABEL_DOCUMENT_ASSET_SUMMARY = 'Asset summary';
export const LABEL_DOCUMENT_VEHICLE = 'Vehicle';
export const LABEL_DOCUMENT_MODEL_TYPE = 'Model type';
export const LABEL_DOCUMENT_CONDITION = 'Condition';
export const LABEL_DOCUMENT_SALE_TYPE = 'Sale type';
export const LABEL_GENERATE_MATCHES = 'Generate matches';
export const LABEL_DOCUMENT_ASSET_DETAILS = 'Assets details';
export const LABEL_DOCUMENT_TYPE = 'Type';
export const LABEL_DOCUMENT_MAKE = 'Make';
export const LABEL_DOCUMENT_MODEL = 'Model';
export const LABEL_DOCUMENT_YEAR = 'Year';
export const LABEL_DOCUMENT_MONTH = 'Month';
export const LABEL_APPROVAL_SETTLEMENT_ETA = 'What is the settlement ETA?';
export const LABEL_SETTLEMENT_ETA = 'Settlement ETA';
export const LABEL_APPROVED_AS_SUBMITTED = 'Approved as submitted';
export const LABEL_EXPIRY = 'Expiry';
export const EMPLOYMENT_TYPE_FULL_TIME = 'Full Time';
export const EMPLOYMENT_TYPE_PART_TIME = 'Part Time';
export const EMPLOYMENT_TYPE_CASUAL = 'Casual';
export const EMPLOYMENT_TYPE_CONTRACT_ROLE = 'Contract Role';
export const EMPLOYMENT_TYPE_SELF_EMPLOYED = 'Self Employed';
export const LABEL_QUOTE_DETAILS = 'Quote details';
export const EMPLOYMENT_TYPES = [
  {
    value: EMPLOYMENT_TYPE_FULL_TIME,
    label: EMPLOYMENT_TYPE_FULL_TIME,
  },
  {
    value: EMPLOYMENT_TYPE_PART_TIME,
    label: EMPLOYMENT_TYPE_PART_TIME,
  },
  {
    value: EMPLOYMENT_TYPE_CASUAL,
    label: EMPLOYMENT_TYPE_CASUAL,
  },
  {
    value: EMPLOYMENT_TYPE_CONTRACT_ROLE,
    label: EMPLOYMENT_TYPE_CONTRACT_ROLE,
  },
  {
    value: EMPLOYMENT_TYPE_SELF_EMPLOYED,
    label: EMPLOYMENT_TYPE_SELF_EMPLOYED,
  },
];

export const EMPLOYMENT_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(EMPLOYMENT_TYPES);

export const EMPLOYMENT_TYPES_FOR_QUICK_QUOTE = [
  {
    value: EMPLOYMENT_TYPE_FULL_TIME,
    label: EMPLOYMENT_TYPE_FULL_TIME,
  },
  {
    value: EMPLOYMENT_TYPE_PART_TIME,
    label: EMPLOYMENT_TYPE_PART_TIME,
  },
  {
    value: EMPLOYMENT_TYPE_CASUAL,
    label: EMPLOYMENT_TYPE_CASUAL,
  },
  {
    value: EMPLOYMENT_TYPE_CONTRACT_ROLE,
    label: EMPLOYMENT_TYPE_CONTRACT_ROLE,
  },
];

export const EMPLOYMENT_TYPES_FOR_QUICK_QUOTE_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(EMPLOYMENT_TYPES_FOR_QUICK_QUOTE);

export const CREDIT_HISTORY_TYPES_700 = '700+';
export const CREDIT_HISTORY_TYPES_600_699 = '600-699';
export const CREDIT_HISTORY_TYPES_500_599 = '500-599';
export const CREDIT_HISTORY_TYPES_0_499 = '0-499';

export const CREDIT_HISTORY_TYPES_VERY_GOOD = 'Very good';
export const CREDIT_HISTORY_TYPES_GOOD = 'Good';
export const CREDIT_HISTORY_TYPES_AVERAGE = 'Average';
export const CREDIT_HISTORY_TYPES_BELOW_AVERAGE = 'Below Average';

export const CREDIT_HISTORY_TYPES = [
  {
    value: CREDIT_HISTORY_TYPES_700,
    label: CREDIT_HISTORY_TYPES_VERY_GOOD + ' ' + CREDIT_HISTORY_TYPES_700,
  },
  {
    value: CREDIT_HISTORY_TYPES_600_699,
    label: CREDIT_HISTORY_TYPES_GOOD + ' ' + CREDIT_HISTORY_TYPES_600_699,
  },
  {
    value: CREDIT_HISTORY_TYPES_500_599,
    label: CREDIT_HISTORY_TYPES_AVERAGE + ' ' + CREDIT_HISTORY_TYPES_500_599,
  },
  {
    value: CREDIT_HISTORY_TYPES_0_499,
    label: CREDIT_HISTORY_TYPES_BELOW_AVERAGE + ' ' + CREDIT_HISTORY_TYPES_0_499,
  },
];

export const CREDIT_HISTORY_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(CREDIT_HISTORY_TYPES);

export const URGENCY_TYPES_ASAP = 'ASAP';
export const URGENCY_TYPES_WITHIN_1_MONTH = 'Within 1 Month';
export const URGENCY_TYPES_NO_HURRY = 'No Hurry';

export const URGENCY_TYPES = [
  {
    value: URGENCY_TYPES_ASAP,
    label: URGENCY_TYPES_ASAP,
  },
  {
    value: URGENCY_TYPES_WITHIN_1_MONTH,
    label: URGENCY_TYPES_WITHIN_1_MONTH,
  },
  {
    value: URGENCY_TYPES_NO_HURRY,
    label: URGENCY_TYPES_NO_HURRY,
  },
];

export const URGENCY_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(URGENCY_TYPES);

export const LOAN_TYPE_CAR_LOAN = 'Car loan';
export const LOAN_TYPE_PERSONAL_LOAN = 'Personal Loan';

export const LOAN_TYPES = [
  {
    value: LOAN_TYPE_CAR_LOAN,
    label: LOAN_TYPE_CAR_LOAN,
  },
  {
    value: LOAN_TYPE_PERSONAL_LOAN,
    label: LOAN_TYPE_PERSONAL_LOAN,
  },
];

export const LOAN_TYPE_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(LOAN_TYPES);

export const VEHICLE_DETAIL_TYPES_I_KNOW = 'I know the vehicle details';
export const VEHICLE_DETAIL_TYPES_I_DONT_KNOW = "I don't know the vehicle details";

export const VEHICLE_DETAIL_TYPES_I_KNOW_VALUE = 'Exact';
export const VEHICLE_DETAIL_TYPES_I_DONT_KNOW_VALUE = 'No Idea';

export const VEHICLE_DETAIL_TYPES = [
  {
    value: VEHICLE_DETAIL_TYPES_I_KNOW_VALUE,
    label: VEHICLE_DETAIL_TYPES_I_KNOW,
  },
  {
    value: VEHICLE_DETAIL_TYPES_I_DONT_KNOW_VALUE,
    label: VEHICLE_DETAIL_TYPES_I_DONT_KNOW,
  },
];

export const VEHICLE_DETAIL_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(VEHICLE_DETAIL_TYPES);

export const CAR_SOURCING_TYPE_CUSTOMER_FOUND_VEHICLE = 'The customer has found a vehicle';
export const CAR_SOURCING_TYPE_CARCLARITY_HELP = 'CarClarity to help the customer find their car';

export const CAR_SOURCING_TYPE_CUSTOMER_FOUND_VEHICLE_VALUE = 'Customer';
export const CAR_SOURCING_TYPE_CARCLARITY_HELP_VALUE = 'CarClarity';

export const CAR_SOURCING_TYPES = [
  {
    value: CAR_SOURCING_TYPE_CUSTOMER_FOUND_VEHICLE_VALUE,
    label: CAR_SOURCING_TYPE_CUSTOMER_FOUND_VEHICLE,
  },
  {
    value: CAR_SOURCING_TYPE_CARCLARITY_HELP_VALUE,
    label: CAR_SOURCING_TYPE_CARCLARITY_HELP,
  },
];

export const CAR_SOURCING_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(CAR_SOURCING_TYPES);
export const LOAN_OPTIONS = [
  {
    label: BALLON_ON_THE_LOAN,
    value: BALLON_ON_THE_LOAN,
  },
  {
    label: FLEXIBILITY_TO_MAKE_ADDITIONAL_REPLAYMENT,
    value: FLEXIBILITY_TO_MAKE_ADDITIONAL_REPLAYMENT,
  },
  {
    label: NO_EARLY_EXIT_FEES,
    value: NO_EARLY_EXIT_FEES,
  },
  {
    label: LOWEST_RATE_POSSIBLE,
    value: LOWEST_RATE_POSSIBLE,
  },
];

export const PURPOSE_OF_OBTAINING_OPTIONS_VEHICLE = 'Vehicle';
export const PURPOSE_OF_OBTAINING_OPTIONS_LEISURE = 'Leisure';
export const PURPOSE_OF_OBTAINING_OPTIONS_EQUIPMENT = 'Equipment';
export const PURPOSE_OF_OBTAINING_OPTIONS_DEBT_CONSOLIDATION = 'Debt consolidation';
export const PURPOSE_OF_OBTAINING_OPTIONS_MEDICAL = 'Medical';
export const PURPOSE_OF_OBTAINING_OPTIONS_HOME_IMROVEMENT = 'Home improvement';
export const PURPOSE_OF_OBTAINING_OPTIONS_TRAVEL = 'Travel';
export const PURPOSE_OF_OBTAINING_OPTIONS_OTHER = 'Other';

export const PURPOSE_OF_OBTAINING_OPTIONS_KEY_VALS = [
  {
    label: PURPOSE_OF_OBTAINING_OPTIONS_VEHICLE,
    value: PURPOSE_OF_OBTAINING_OPTIONS_VEHICLE,
  },
  {
    label: PURPOSE_OF_OBTAINING_OPTIONS_LEISURE,
    value: PURPOSE_OF_OBTAINING_OPTIONS_LEISURE,
  },
  {
    label: PURPOSE_OF_OBTAINING_OPTIONS_EQUIPMENT,
    value: PURPOSE_OF_OBTAINING_OPTIONS_EQUIPMENT,
  },
  {
    label: PURPOSE_OF_OBTAINING_OPTIONS_DEBT_CONSOLIDATION,
    value: PURPOSE_OF_OBTAINING_OPTIONS_DEBT_CONSOLIDATION,
  },
  {
    label: PURPOSE_OF_OBTAINING_OPTIONS_MEDICAL,
    value: PURPOSE_OF_OBTAINING_OPTIONS_MEDICAL,
  },
  {
    label: PURPOSE_OF_OBTAINING_OPTIONS_HOME_IMROVEMENT,
    value: PURPOSE_OF_OBTAINING_OPTIONS_HOME_IMROVEMENT,
  },
  {
    label: PURPOSE_OF_OBTAINING_OPTIONS_TRAVEL,
    value: PURPOSE_OF_OBTAINING_OPTIONS_TRAVEL,
  },
  {
    label: PURPOSE_OF_OBTAINING_OPTIONS_OTHER,
    value: PURPOSE_OF_OBTAINING_OPTIONS_OTHER,
  },
];

export const LOAN_FEATURE_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(LOAN_OPTIONS);

export const PURPOSE_OF_OBTAINING_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(PURPOSE_OF_OBTAINING_OPTIONS_KEY_VALS);

export const LABEL_WITHDRAWN = 'Withdrawn';

export const LABEL_SUBMITTED = 'Submitted';

export const LABEL_APPROVED = 'Approved';

export const LABEL_DECLINED = 'Declined';

export const OUTCOME_OPTIONS = [
  {
    label: LABEL_APPROVED,
    value: LABEL_APPROVED,
  },
  {
    label: LABEL_DECLINED,
    value: LABEL_DECLINED,
  },
  {
    label: LABEL_WITHDRAWN,
    value: LABEL_WITHDRAWN,
  },
];

export const LOAN_DECLINED_REASON_DOES_NOT_SERVICE = 'Does Not Service';
export const LOAN_DECLINED_REASON_ADVERSE_CREDIT_HISTORY = 'Adverse Credit History';
export const LOAN_DECLINED_REASON_POOR_BANK_ACCOUNT_CONDUCT = 'Poor Bank Account Conduct';
export const LOAN_DECLINED_REASON_POOR_REPAYMENT_HISTORY = 'Poor Repayment History';
export const LOAN_DECLINED_REASON_OUTSIDE_LENDER_GUIDELINES = 'Outside Lender Guidelines';
export const LOAN_DECLINED_REASON_OUTSIDE_VEHICLE_GUIDELINES = 'Outside Vehicle Guidelines';
export const LOAN_DECLINED_REASON_FINANCIALS_NOT_ACCEPTED = 'Financials Not Accepted';
export const LOAN_DECLINED_REASON_FRAUD = 'Fraud';
export const LOAN_DECLINED_REASON_OTHER = 'Other';
export const LOAN_DECLINED_REASON_NO_CAPACITY = 'No Capacity';

export const LOAN_DECLINED_REASONS_TYPES = [
  LOAN_DECLINED_REASON_DOES_NOT_SERVICE,
  LOAN_DECLINED_REASON_ADVERSE_CREDIT_HISTORY,
  LOAN_DECLINED_REASON_POOR_BANK_ACCOUNT_CONDUCT,
  LOAN_DECLINED_REASON_POOR_REPAYMENT_HISTORY,
  LOAN_DECLINED_REASON_OUTSIDE_LENDER_GUIDELINES,
  LOAN_DECLINED_REASON_OUTSIDE_VEHICLE_GUIDELINES,
  LOAN_DECLINED_REASON_FINANCIALS_NOT_ACCEPTED,
  LOAN_DECLINED_REASON_NO_CAPACITY,
  LOAN_DECLINED_REASON_FRAUD,
  LOAN_DECLINED_REASON_OTHER,
];
export const LABEL_DOES_NOT_SERVICE = 'Does Not Service';

export const LOAN_DECLINED_REASONS_OPTIONS = generateOptionsFromTypesWhereTheKeyIsSameAsValue(LOAN_DECLINED_REASONS_TYPES);

export const LOAN_WITHDRAWN_REASON_APPLICATION_TYPE_CHANGE = 'Application Type Change';
export const LOAN_WITHDRAWN_REASON_ADVERSE_CREDIT_INFORMATION = 'Adverse Credit Information';
export const LOAN_WITHDRAWN_REASON_BANK_ACCOUNT_CONDUCT = 'Bank Account Conduct';
export const LOAN_WITHDRAWN_REASON_BANKRUPTCY = 'Bankruptcy';
export const LOAN_WITHDRAWN_REASON_DOES_NOT_SERVICE = 'Does Not Service';
export const LOAN_WITHDRAWN_REASON_DOES_NOT_MEET_ANY_LENDER_CRITERIA = `Doesn't Meet Any Lender Criteria`;
export const LOAN_WITHDRAWN_REASON_DO_NOT_CONTACT_HARD_OPT_OUT = 'Do Not Contact (Hard Opt Out)';
export const LOAN_WITHDRAWN_REASON_DUPLICATE = 'Duplicate';
export const LOAN_WITHDRAWN_REASON_FRAUD = 'Fraud';
export const LOAN_WITHDRAWN_REASON_FUNDING_NOT_URGENT = 'Funding Not Urgent';
export const LOAN_WITHDRAWN_REASON_NOT_THE_RIGHT_PRODUCT = 'Not the Right Product';
export const LOAN_WITHDRAWN_REASON_WANTS_NOVATED_LEASE = 'Wants Novated Lease';
export const LOAN_WITHDRAWN_REASON_APPROVAL_EXPIRY = 'Approval Expiry';
export const LOAN_WITHDRAWN_REASON_VEHICLE_NOT_AVAILABLE = 'Vehicle Not Available';
export const LOAN_WITHDRAWN_REASON_INSUFFICIENT_APPROVAL_AMOUNT = 'Insufficient Approval Amount';
export const LOAN_WITHDRAWN_REASON_LOST_TO_BROKER = 'Lost To Broker';
export const LOAN_WITHDRAWN_REASON_LOST_TO_DEALER = 'Lost To Dealer';
export const LOAN_WITHDRAWN_REASON_LOST_TO_FAMILY_MEMBER = 'Lost To Family Member';
export const LOAN_WITHDRAWN_REASON_LOST_TO_LENDER = 'Lost To Lender';
export const LOAN_WITHDRAWN_REASON_NO_LONGER_INTERESTED = 'No Longer Interested';
export const LOAN_WITHDRAWN_REASON_PAID_CASH = 'Paid Cash';
export const LOAN_WITHDRAWN_REASON_RATE_PRICE_COST = 'Rate/Price/Cost';
export const LOAN_WITHDRAWN_REASON_SHORT_TERM_CASUAL_LESS_THAN_3_MONTHS = 'Short Term Casual < 3 Months';
export const LOAN_WITHDRAWN_REASON_SHORT_TERM_EMPLOYMENT_OR_ABN_LESS_THAN_6_MONTHS = 'Short Term Employment/Abn < 6 Months';
export const LOAN_WITHDRAWN_REASON_SPEED = 'Speed';
export const LOAN_WITHDRAWN_REASON_STOPPED_RESPONDING = 'Stopped Responding';
export const LOAN_WITHDRAWN_REASON_TERM = 'Term';
export const LOAN_WITHDRAWN_REASON_TEST = 'Test';
export const LOAN_WITHDRAWN_REASON_UNABLE_TO_COMPETE_REFINANCE = 'Unable to compete (Refinance)';
export const LOAN_WITHDRAWN_REASON_OTHER = 'Other';

export const LOAN_WITHDRAWN_REASONS_TYPES = [
  LOAN_WITHDRAWN_REASON_APPLICATION_TYPE_CHANGE,
  LOAN_WITHDRAWN_REASON_ADVERSE_CREDIT_INFORMATION,
  LOAN_WITHDRAWN_REASON_BANK_ACCOUNT_CONDUCT,
  LOAN_WITHDRAWN_REASON_BANKRUPTCY,
  LOAN_WITHDRAWN_REASON_DOES_NOT_SERVICE,
  LOAN_WITHDRAWN_REASON_DOES_NOT_MEET_ANY_LENDER_CRITERIA,
  LOAN_WITHDRAWN_REASON_DO_NOT_CONTACT_HARD_OPT_OUT,
  LOAN_WITHDRAWN_REASON_DUPLICATE,
  LOAN_WITHDRAWN_REASON_FRAUD,
  LOAN_WITHDRAWN_REASON_FUNDING_NOT_URGENT,
  LOAN_WITHDRAWN_REASON_NOT_THE_RIGHT_PRODUCT,
  LOAN_WITHDRAWN_REASON_WANTS_NOVATED_LEASE,
  LOAN_WITHDRAWN_REASON_APPROVAL_EXPIRY,
  LOAN_WITHDRAWN_REASON_VEHICLE_NOT_AVAILABLE,
  LOAN_WITHDRAWN_REASON_INSUFFICIENT_APPROVAL_AMOUNT,
  LOAN_WITHDRAWN_REASON_LOST_TO_BROKER,
  LOAN_WITHDRAWN_REASON_LOST_TO_DEALER,
  LOAN_WITHDRAWN_REASON_LOST_TO_FAMILY_MEMBER,
  LOAN_WITHDRAWN_REASON_LOST_TO_LENDER,
  LOAN_WITHDRAWN_REASON_NO_LONGER_INTERESTED,
  LOAN_WITHDRAWN_REASON_PAID_CASH,
  LOAN_WITHDRAWN_REASON_RATE_PRICE_COST,
  LOAN_WITHDRAWN_REASON_SHORT_TERM_CASUAL_LESS_THAN_3_MONTHS,
  LOAN_WITHDRAWN_REASON_SHORT_TERM_EMPLOYMENT_OR_ABN_LESS_THAN_6_MONTHS,
  LOAN_WITHDRAWN_REASON_SPEED,
  LOAN_WITHDRAWN_REASON_STOPPED_RESPONDING,
  LOAN_WITHDRAWN_REASON_TERM,
  LOAN_WITHDRAWN_REASON_TEST,
  LOAN_WITHDRAWN_REASON_UNABLE_TO_COMPETE_REFINANCE,
  LOAN_WITHDRAWN_REASON_OTHER,
];

export const LOAN_WITHDRAWN_REASONS_OPTIONS = generateOptionsFromTypesWhereTheKeyIsSameAsValue(LOAN_WITHDRAWN_REASONS_TYPES);

export const LABEL_WITHDRAW_REASON_1 = 'Withdraw-reason-1';
export const LABEL_WITHDRAW_REASON_2 = 'Withdraw-reason-2';

export const LABEL_OTHER_OPTION = 'Other';

export const WITHDRAW_OPTIONS = [
  {
    label: LABEL_WITHDRAW_REASON_1,
    value: LABEL_WITHDRAW_REASON_1,
  },
  {
    label: LABEL_WITHDRAW_REASON_2,
    value: LABEL_WITHDRAW_REASON_2,
  },
  {
    label: LABEL_OTHER_OPTION,
    value: LABEL_OTHER_OPTION,
  },
];

export const LABEL_LOAN_TERM_OPTION_1 = '1 Year';
export const LABEL_LOAN_TERM_OPTION_2 = '2 Year';
export const LABEL_LOAN_TERM_OPTION_3 = '3 Year';
export const LABEL_LOAN_TERM_OPTION_4 = '4 Year';
export const LABEL_LOAN_TERM_OPTION_5 = '5 Year';

export const LOAN_TERM_OPTION = [
  {
    label: LABEL_LOAN_TERM_OPTION_1,
    value: LABEL_LOAN_TERM_OPTION_1,
  },
  {
    label: LABEL_LOAN_TERM_OPTION_2,
    value: LABEL_LOAN_TERM_OPTION_2,
  },
  {
    label: LABEL_LOAN_TERM_OPTION_3,
    value: LABEL_LOAN_TERM_OPTION_3,
  },
  {
    label: LABEL_LOAN_TERM_OPTION_4,
    value: LABEL_LOAN_TERM_OPTION_4,
  },
  {
    label: LABEL_LOAN_TERM_OPTION_5,
    value: LABEL_LOAN_TERM_OPTION_5,
  },
];

export const LABEL_ASSET_TYPE_OPTION_VEHICLE = 'Vehicle';
export const LABEL_ASSET_TYPE_OPTION_OTHER = 'Other';
export const LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN = 'Asset unknown';
export const LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY = 'Unknown';
export const LABEL_ASSET_TYPE_STILL_DECIDING = 'Still deciding';

export const ASSET_TYPE_OPTIONS_VALUES = [
  {
    label: LABEL_ASSET_TYPE_OPTION_VEHICLE,
    value: LABEL_ASSET_TYPE_OPTION_VEHICLE,
  },
  {
    label: LABEL_ASSET_TYPE_OPTION_OTHER,
    value: LABEL_ASSET_TYPE_OPTION_OTHER,
  },
  {
    label: LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN,
    value: LABEL_ASSET_TYPE_OPTION_ASSET_UNKNOWN_KEY,
  },
];

export const ASSET_TYPE_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(ASSET_TYPE_OPTIONS_VALUES);

export const ASSET_TYPE_OPTIONS_VALUES_FOR_QUOTE_DETAILS = [
  {
    label: LABEL_ASSET_TYPE_OPTION_VEHICLE,
    value: LABEL_ASSET_TYPE_OPTION_VEHICLE,
  },
  {
    label: LABEL_ASSET_TYPE_OPTION_OTHER,
    value: LABEL_ASSET_TYPE_OPTION_OTHER,
  },
];

export const ASSET_TYPE_OPTIONS_FOR_QUOTE_DETAILS = generateOptionsFromTypesWhereKeyIsSameAsValue(ASSET_TYPE_OPTIONS_VALUES_FOR_QUOTE_DETAILS);

export const LABEL_REASON_FOR_ENTERING_ZERO = 'Reason for entering $0';
export const LABEL_PLEASE_SPECIFY_THE_REASON = 'Please specify the reason';
export const LABEL_FRIENDS_AND_FAMILY = 'Friends and family';
export const LABEL_DOF_ONlY = 'DOF only';
export const LABEL_PRICE_BEAT = 'Price beat';
export const LABEL_OTHER = 'Other';

export const ERROR_MESSAGES_FOR_ZERO_VALUE_REASON_TYPE = 'Zero Value Reason Type is required';
export const ERROR_MESSAGES_FOR_ZERO_VALUE_REASON = 'Zero Value Reason is required';

export const REASON_FOR_ENTERING_ZERO = [
  {
    label: LABEL_FRIENDS_AND_FAMILY,
    value: LABEL_FRIENDS_AND_FAMILY,
  },
  {
    label: LABEL_DOF_ONlY,
    value: LABEL_DOF_ONlY,
  },
  {
    label: LABEL_PRICE_BEAT,
    value: LABEL_PRICE_BEAT,
  },
  {
    label: LABEL_OTHER,
    value: LABEL_OTHER,
  },
];

export const LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_TITLE = '$0 input value(s) found';
export const LABEL_FOR_ZERO_INPUT_VALUE_DIALOG_DISCRIPTION = 'You have entered $0 input value in the following fields below. Are you sure you want to proceed?';

export const LABEL_CONDITION_OPTIONS_NEW = 'New';
export const LABEL_CONDITION_OPTIONS_DEMO = 'Demo';
export const LABEL_CONDITION_OPTIONS_USED = 'Used';
export const LABEL_DOCUMENT_DETAILS = 'Details';
export const LABEL_NOT_AVAILABLE = 'Not Available';

export const REFERRAL_LEAD_STATUS_NEW = 'New';
export const REFERRAL_LEAD_STATUS_IN_PROGRESS = 'In Progress';
export const REFERRAL_LEAD_STATUS_CALLBACK_REQUESTED = 'Callback Requested';
export const REFERRAL_LEAD_STATUS_LOST = 'Lost';
export const REFERRAL_LEAD_STATUS_UNQUALIFIED = 'Unqualified';
export const REFERRAL_LEAD_STATUS_QUALIFIED = 'Qualified';
export const REFERRAL_LEAD_STATUS_EXHAUSTED = 'Exhausted';
export const REFERRAL_LEAD_STATUS_APPLICATION_IN_PROGRESS = 'Application in Progress';
export const REFERRAL_LEAD_STATUS_SUBMITTED_TO_LENDER = 'Submitted to Lender';
export const REFERRAL_LEAD_STATUS_APPLICATION_WITHDRAWN = 'Application Withdrawn';
export const REFERRAL_LEAD_STATUS_APPROVED = 'Approved';
export const REFERRAL_LEAD_STATUS_DECLINED = 'Declined';
export const REFERRAL_LEAD_STATUS_PENDING_SETTLEMENT = 'Pending Settlement';
export const REFERRAL_LEAD_STATUS_WON = 'Won';

export const LABEL_STATUS_INFO_TEXT_RED = 'status-info-text-red';
export const LABEL_STATUS_INFO_TEXT_BLACK = 'status-info-text-black';
export const LABEL_BLUE_TAG = 'blue-tag';
export const LABEL_RED_TAG = 'red-tag';
export const LABEL_GREEN_TAG = 'green-tag';
export const REFERRAL_LEAD_STATUS = [
  REFERRAL_LEAD_STATUS_NEW,
  REFERRAL_LEAD_STATUS_IN_PROGRESS,
  REFERRAL_LEAD_STATUS_CALLBACK_REQUESTED,
  REFERRAL_LEAD_STATUS_UNQUALIFIED,
  REFERRAL_LEAD_STATUS_EXHAUSTED,
  REFERRAL_LEAD_STATUS_LOST,
  REFERRAL_LEAD_STATUS_APPLICATION_IN_PROGRESS,
  REFERRAL_LEAD_STATUS_SUBMITTED_TO_LENDER,
  REFERRAL_LEAD_STATUS_APPLICATION_WITHDRAWN,
  REFERRAL_LEAD_STATUS_APPROVED,
  REFERRAL_LEAD_STATUS_DECLINED,
  REFERRAL_LEAD_STATUS_PENDING_SETTLEMENT,
  REFERRAL_LEAD_STATUS_WON,
];
export const REFERRAL_SORT_BY = [
  {
    label: 'Created date (Latest first)',
    value: '-createdAt',
  },
  {
    label: 'Created date (Oldest first)',
    value: 'createdAt',
  },
  {
    label: 'Last updated (Latest first)',
    value: '-updatedAt',
  },
  {
    label: 'Last updated (Oldest first)',
    value: 'updatedAt',
  },
  {
    label: 'Subaccount (A to Z)',
    value: 'referrerSubaccount',
  },
  {
    label: 'Subaccount (Z to A)',
    value: '-referrerSubaccount',
  },
  {
    label: 'Referrer (A to Z)',
    value: 'referrerUser',
  },
  {
    label: 'Referrer (Z to A)',
    value: '-referrerUser',
  },
];

export const REFERRAL_STATUS = [
  { value: 'All', label: 'All' },
  { value: REFERRAL_LEAD_STATUS_NEW, label: REFERRAL_LEAD_STATUS_NEW },
  {
    value: REFERRAL_LEAD_STATUS_IN_PROGRESS,
    label: REFERRAL_LEAD_STATUS_IN_PROGRESS,
  },
  {
    value: REFERRAL_LEAD_STATUS_CALLBACK_REQUESTED,
    label: REFERRAL_LEAD_STATUS_CALLBACK_REQUESTED,
  },
  { value: REFERRAL_LEAD_STATUS_LOST, label: REFERRAL_LEAD_STATUS_LOST },
  {
    value: REFERRAL_LEAD_STATUS_UNQUALIFIED,
    label: REFERRAL_LEAD_STATUS_UNQUALIFIED,
  },
  {
    value: REFERRAL_LEAD_STATUS_EXHAUSTED,
    label: REFERRAL_LEAD_STATUS_EXHAUSTED,
  },
  {
    value: REFERRAL_LEAD_STATUS_APPLICATION_IN_PROGRESS,
    label: REFERRAL_LEAD_STATUS_APPLICATION_IN_PROGRESS,
  },
  {
    value: REFERRAL_LEAD_STATUS_SUBMITTED_TO_LENDER,
    label: REFERRAL_LEAD_STATUS_SUBMITTED_TO_LENDER,
  },
  {
    value: REFERRAL_LEAD_STATUS_APPLICATION_WITHDRAWN,
    label: REFERRAL_LEAD_STATUS_APPLICATION_WITHDRAWN,
  },
  {
    value: REFERRAL_LEAD_STATUS_APPROVED,
    label: REFERRAL_LEAD_STATUS_APPROVED,
  },
  {
    value: REFERRAL_LEAD_STATUS_DECLINED,
    label: REFERRAL_LEAD_STATUS_DECLINED,
  },
  {
    value: REFERRAL_LEAD_STATUS_PENDING_SETTLEMENT,
    label: REFERRAL_LEAD_STATUS_PENDING_SETTLEMENT,
  },
  { value: REFERRAL_LEAD_STATUS_WON, label: REFERRAL_LEAD_STATUS_WON },
];

export const CONDITION_OPTIONS_VALUES = [
  {
    label: LABEL_CONDITION_OPTIONS_NEW,
    value: LABEL_CONDITION_OPTIONS_NEW,
  },
  {
    label: LABEL_CONDITION_OPTIONS_DEMO,
    value: LABEL_CONDITION_OPTIONS_DEMO,
  },
  {
    label: LABEL_CONDITION_OPTIONS_USED,
    value: LABEL_CONDITION_OPTIONS_USED,
  },
];

export const SETTLEMENT_ADD_DOCUMENT_ACTION_NAME = [
  {
    label: LABEL_SHARED,
    value: 'updateSharedDocuments',
  },
  {
    label: LABEL_DOCUMENT_PRIMARY,
    value: 'updateDocuments',
  },
  {
    label: LABEL_DOCUMENT_SECONDARY,
    value: 'updateOtherDocuments',
  },
  {
    label: LABEL_DOCUMENT_SETTLEMENT,
    value: 'updateSharedDocuments',
  },
];
export const SETTLEMENT_REMOVE_DOCUMENT_ACTION_NAME = [
  {
    label: LABEL_SHARED,
    value: 'removeSharedDocument',
  },
  {
    label: LABEL_DOCUMENT_PRIMARY,
    value: 'removeDocument',
  },
  {
    label: LABEL_DOCUMENT_SECONDARY,
    value: 'removeOtherDocument',
  },
  {
    label: LABEL_DOCUMENT_SETTLEMENT,
    value: 'removeSharedDocument',
  },
];
export const CONDITION_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(CONDITION_OPTIONS_VALUES);

export const LABEL_REQUIREMENT_SAVE_BUTTON = 'Save';
export const LABEL_REQUIREMENT_SAVE_AND_NEXT_BUTTON = 'Save & Next';
export const LABEL_DOCUMENT_VIN = 'VIN';
export const LABEL_DOCUMENT_REGISTRATION_PLATE = 'Rego';
export const LABEL_DOCUMENT_MOBILE = 'Mobile';
export const LABEL_VIEW_AS = 'View as';

export const LABEL_LOAN_DETAILS = 'Loan details';
export const LABEL_RATE_AND_FEE = 'Rates & fees';
export const LABEL_LENDER_BASE_RATE = 'Lender base rate (%)';
export const LABEL_MONTHLY_REPAYMENT = 'Monthly repayment';
export const LABEL_CUSTOMER_RATE = 'Customer Rate';
export const LABEL_ACCOUNT_KEEPING_FEE = 'Account keeping fee';
export const LABEL_LENDER_ESTABLISHMENT_FEE = 'Lender establishment fee';
export const LABEL_PSPR_FEE = 'PPSR Fee';
export const LABEL_BROKER_FEE = 'Broker fee';
export const LABEL_RATE_DETAILS = 'rate details';
export const LABEL_CUSTOMIZED_INITIAL_VALUE = 'Customized initial value';
export const LABEL_PARTNER_FINANCE_REFERRAL_FEE = 'Partner finance referral fee';
export const LABEL_LENDING_COMMISSION = 'Lending Commission';
export const LABEL_LENDING_COMMISSION_FEE = 'Lending commision fee';
export const LABEL_QUESTIONS = 'Questions';
export const LABEL_IS_THE_CUSTOMER_AGREEABLE = 'Is the customer agreeable to the proposed loan details?';
export const LABEL_DOES_THE_PROPOSED_LOAN_SATISFY = 'Does the proposed loan satisfy the consumer’s objectives & requirements?';
export const LABEL_WILL_THE_COSTOMER_BE_ABLE_TO_COMPLY = 'Will the customer be able to comply with the financial obligations of this loan without incurring substantial hardship?';
export const LABEL_DO_YOU_AGREE_THRERE_ARE_NO_KNOWN_OR_LIKELY = 'Do you agree there are no known or likely changes to circumstances in the immediate future which will impact the suitability of this proposed loan?';
export const LABEL_DESCRIBE_HOW_THE_SELECTED_LENDER_AND_PRODUCT_MEET = 'Describe how the selected lender and product meets the customers Objectives and Requirements best';
export const LABEL_FOR_DOWNLOAD_APPLICATION = 'Download Application';
export const LABEL_SAVE_DETAILS_AND_GENERATE_ASSETMENT = 'Save details & generate assessment';

export const LABEL_SAVE_DETAILS = 'Save details';
export const LABEL_LENDER = 'Lender';

export const LABEL_SUBMIT_DECISION_DETAILS = 'Submit decision details';
export const LABEL_NAF = 'NAF';
export const LABEL_EXPIRY_APPROVAL = 'What is the expiry of the approval';
export const LABEL_OUTCOME_APPLICATION = 'What is the outcome of the submitted application';
export const LABEL_APPROVAL_CONDITIONS = 'What are the approval conditions?';
export const LABEL_APPLICATION_SUBMITTED = 'Was the application approved as submitted (loan details) ?';
export const LABEL_OTHER_ACTIONS = 'Other Actions';
export const LABEL_APPROVAL_DETAILS = 'Approval Details';
export const LABEL_CONDITION = 'Condition';
export const LABEL_DECLINE_DETAILS = 'Decline details';
export const LABEL_REASON = 'Reason';
export const LABEL_WITHDRAWN_DETAILS = 'Withdrawn details';
export const LABEL_DECLINE_REASON = 'What is the reason for the application being declined ?';
export const LABEL_WITHDRAW_REASON = 'What is the reason for the application being withdrawn ?';
export const LABEL_PRELIMINARY_ASSESSMENT = 'Preliminary assessment';
export const LABEL_LOAN_BALLOON = 'Balloon';
export const LABEL_LOAN_BALLOON_DOLLAR = 'Balloon $';
export const LABEL_PRELIMINARY_ASSESSMENT_DETAILS = 'Submission details confirmed and the document generated at';
export const LABEL_EDIT_SUBMISSION_DETAILS = 'Edit submission details';
export const LABEL_NO_OF_CONTACTS_MISMATCH = 'No. of contacts mismatch';
export const LABEL_VIEW_ASSESSMENT = 'View assessment';
export const LABEL_NEXT_ACTIONS = 'Next actions';
export const LABEL_CREDIT_PROPOSAL_DISCLOSURE = 'Credit proposal disclosure';
export const LABEL_CREDIT_PROPOSAL_DISCLOSURE_DETAILS = 'Use this option to generate and send proposal document to applicant(s)';
export const LABEL_SEND_PROPOSAL = 'Send proposal';
export const LABEL_VIEW_PROPOSAL = 'View proposal';
export const LABEL_REGENERATE_PROPOSAL = 'Regenerate proposal';
export const LABEL_APPLICATION_SUBMISSION = 'Application submission';
export const LABEL_SUBMIT_APPLICATION = 'Submit application';
export const LABEL_SUBMIT_DIGITALLY = 'Submit digitally';
export const LABEL_SUBMIT_MANUALLY = 'Submit manually';
export const LABEL_FOR_REGENERATE = 'Regenerate';
export const LABEL_APPLICATION_SUBMITTED_DIGITALLY = 'Application submitted digitally';
export const LABEL_APPLICATION_SUBMITTED_MANUALLY = 'Application submitted manually';
export const LABEL_APPLICATION_SUBMISSION_DETAILS = 'Use one of the options to submit the application';
export const LABEL_FOR_SUBMIT_APPLICATION_DETAILS = 'Use this option to submit the application.';
export const LABEL_FILE_TYPE = 'blob';
export const LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SENT = 'Privacy, credit guide and quote documents are not sent';
export const LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SIGNED = 'Privacy, credit guide and quote documents are not signed';

export const LABEL_FOR_DOCUMENTS_ARE_SIGNED_AT = 'Documents are signed at ';
export const LABEL_FOR_DOCUMENT_SIGNED_AT_COMMERCIAL = 'Document signed at ';

export const LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SENT_DISCRIPTION = 'In order to submit the application, please send and get signed the documents of privacy form, credit guide and quote by the applicant(s) ';
export const LABEL_FOR_PRIVACY_POLICY_CHECK_ON_SUBMISSION_DOCUMENTS_ARE_NOT_SIGNED_DISCRIPTION = 'In order to submit the application, please get signed the documents of privacy form, credit guide and quote by the applicant(s) ';

export const LABEL_FOR_PRIVACY_FROM_IS_NOT_SENT = 'Privacy form is not sent';
export const LABEL_FOR_PRIVACY_FROM_IS_NOT_SIGNED = 'Privacy form is not signed';

export const LABEL_FOR_PRIVACY_FROM_IS_NOT_SENT_DISCRIPTION = 'In order to submit the application, please send and get signed the privacy form document by the applicant(s) ';
export const LABEL_FOR_PRIVACY_FROM_IS_NOT_SIGNED_DISCRIPTION = 'In order to submit the application, please get signed the privacy form document by the applicant(s)';

export const LABEL_CONTACTS_MISMATCH_DISCRIPTION_FOR_DIRECTORS = 'Please note that no. of directors mentioned in business details is greater than no. of contacts added. You can still proceed and submit the application.';
export const LABEL_CONTACTS_MISMATCH_DISCRIPTION_FOR_PARTNERS = 'Please note that no. of partners mentioned in business details is greater than no. of contacts added. You can still proceed and submit the application.';
export const LABEL_SEND_PROPOSAL_DETAILS = 'Are you sure you want to send credit proposal disclosure document to the applicant(s)?';
export const LABEL_CONFIRM_EDIT_SUBMISSION_DETAILS = 'Credit proposal document has to be resent and application is required to be submitted again. Are you sure you want to continue?';
export const LABEL_CONFIRM_EDIT_SUBMISSION_DETAILS_COMMERCIAL = 'Are you sure you want to update the submission details?';

export const LABEL_EDIT_SUBMISSION_DETAILS_SUBTITLE = 'Credit proposal document has to be resent and application is required to be submitted again. Are you sure you want to continue?';
export const LABEL_SUBMIT_APPLICATION_DETAILS = 'Are you sure you want to submit the application?';

export const LABEL_TITLE_FOR_CPD_VIEW_AND_SEND = 'Save pre-settlement details &';
export const LABEL_TITLE_FOR_CPD_VIEW_AND_SEND_SETTLEMENT = 'Save settlement details &';
export const LABLE_TITLE_FOR_CPD_SEND = 'Send credit proposal disclosure document';

export const LABEL_DESCRIPTION_FOR_CPD_VIEW_AND_SEND = 'Loan details or rate and fees are updated. Are you sure you want to save the pre-settlement details and send a credit proposal disclosure document to the applicant(s)?';

export const LABEL_DESCRIPTION_FOR_CPD_VIEW_AND_SEND_SETTLEMENT = 'Loan details or rate and fees are updated. Are you sure you want to save the settlement details and send a credit proposal disclosure document to the applicant(s)?';

export const LABEL_FOR_ASSET_DETAILS = 'Asset details';

export const LABEL_FOR_SETTLEMENT_CHECKLIST = 'Checklist';
export const LABEL_FOR_SETTLEMENT_CHECKLIST_NOT_COMPLETED = 'Settlement checklist not completed';
export const LABEL_FOR_CHECKLIST_COMPLETED = 'checklist completed';

export const LABEL_FOR_SETTLEMENT_AGENT = 'Settlement agent';

export const LABEL_FOR_ASSIGNED_AT = 'Assigned at';
export const LABEL_FOR_SETTLEMENT_NOTES = 'Notes';
export const WARNING_MESSAGE_FOR_ASSET_DETAIL = 'Please fill in all the mandatory fields for asset details';
export const WARNING_MESSAGE_FOR_LENDER = 'Please fill in all the mandatory fields for Lender';
export const LABEL_FOR_EDIT_SETTLEMENT = 'Edit settlement';
export const LABEL_FOR_CANCEL_SETTLEMENT = 'Cancel editing settlement';
export const PLACEHOLDER_FOR_ASSET_DESCRIPTION = 'Write your text here ...';
export const LABEL_FOR_NAF = 'NAF';
export const LABEL_FOR_RATES_FEES = 'Rates & fees';
export const LABEL_FOR_WARRANTY = 'Warranty';
export const LABEL_FOR_ADD_WARRANTY = 'Add Warranty details';
export const LABEL_FOR_INSURANCE = 'Insurance';
export const LABEL_FOR_PROVIDER = 'Provider';
export const LABEL_FOR_PRODUCT = 'Product #';
export const LABEL_FOR_POLICY = 'Policy #';
export const LABEL_FOR_WHOLESALE = 'Wholesale $';
export const LABEL_FOR_RETAIL = 'Retail $';
export const LABEL_FOR_PREMIUM = 'Premium $';
export const LABEL_FOR_COMMISSION = 'Commission $';
export const LABEL_COMMISSION = 'Commission';
export const LABEL_FOR_ADD_INSURANCE = 'Add Insurance details';
export const LABEL_FOR_SAVE_PRE_SETTLEMENT = 'Save pre-settlement details';
export const SUCCESS_TITLE_FOR_PRE_SETTLEMENT = 'Pre-settlement';
export const SUCCESS_DESCRIPTION_FOR_PRE_SETTLEMENT = 'Application is pre-settled at';
export const SUCCESS_TITLE_FOR_CREDIT_PROPSAL = 'Credit proposal disclosure';
export const SUCCESS_DESCRIPTION_FOR_CREDIT_PROPSAL = 'Proposal document sent at';
export const INFO_TITLE_FOR_SETTLEMENT = 'Settlement';
export const INFO_DESCRIPTION_FOR_SETTLEMENT = 'Use this option to mark this application as settled. It would be disabled if any credit proposal disclosure document is pending to be sent';
export const INFO_DESCRIPTION_FOR_SETTLEMENT_COMMERCIAL = 'Use this option to mark this application as settled.';
export const LABEL_FOR_SUBMIT_SETTLEMENT = 'Submit settlement';
export const LABEL_MARK_APPLICATION_AS_SETTLED = 'Mark application as settled';
export const LABEL_FOR_VIEW_PROPOSAL = 'View proposal';
export const LABEL_FOR_NEXT_ACTIONS = 'Next actions';

export const LABEL_BUSINESS_STRUCTURE_SOLE_TRADER = 'Sole Trader';
export const LABEL_BUSINESS_STRUCTURE_TRUST = 'Trust';
export const LABEL_BUSINESS_STRUCTURE_COMPANY = 'Company';
export const LABEL_BUSINESS_STRUCTURE_PARTNERSHIP = 'Partnership';

export const LABEL_SEARCH_BY_BUSINESS_NAME_OR_ABN = 'Search by Business name or ABN';
export const LABEL_BUSINESS_DETAILS = 'Business details';
export const LABEL_ENTER_BUSINESS_DETAILS_MANUALLY = 'Enter the details of the business manually if not found using search';
export const LABEL_CAN_NOT_FIND_THE_BUSINESS = "I can't find the business";
export const LABEL_PLACE_HOLDER_BUSINESS_NAME = 'XXXXXXXXXXX';
export const LABEL_PLACE_HOLDER_ABN = 'XXXXXXXXXXX';
export const LABEL_BUSINESS_STRUCTURE = 'Business structure';
export const LABEL_BUSINESS_NAME = 'Business name';
export const LABEL_BUSINESS_ABN = 'ABN';
export const LABEL_COMMERCIAL_CAR_LOAN = 'Commercial Car Loan';
export const LABEL_CONSUMER_CAR_LOAN = 'Consumer Car Loan';

export const LABEL_FOR_ABN_ACTIVE_FORM = 'ABN active from';
export const LABEL_FOR_REGISTERED_GST = 'Registered for GST';
export const ERROR_MESSAGE_FOR_REGISTERED_FOR_GST_FROM = 'GST from Date is required';
export const ERROR_MESSAGE_FOR_REGISTERED_FOR_GST_EFFECTIVE_FROM = 'GST effective from date is required';

export const ERROR_MESSAGE_FOR_USER_EMAIL_ALREADY_EXISTS = 'User with this email address already exists. Please use another email address.';
export const ERROR_MESSAGE_FOR_USER_MOBILE_NUMBER_ALREADY_EXISTS = 'User with this mobile number already exists. Please use another mobile number';
export const ERROR_MESSAGE_FOR_USER_INFO_ALREADY_EXISTS = 'already belongs to another applicant. Please contact support for assistance.';
export const ERROR_MESSAGE_FOR_ENTERED_MOBILE_NO = 'Entered mobile no.';
export const ERROR_MESSAGE_FOR_ENTERED_EMAIL = 'Entered email';

export const LABEL_FOR_REGISTERED_FOR_GST_FORM = 'Registered for GST from';
export const LABEL_FOR_INDUSTRY = 'Industry';
export const LABEL_FOR_OCCUPATION = 'Occupation';
export const LABEL_FOR_NUMBER_OF_DIRECTORS = 'No. of directors';
export const LABEL_FOR_NUMBER_OF_PARTNERS = 'No. of partners';
export const LABEL_FOR_NO_OF_ATTEMPTS = 'No. of attempts:';
export const LABEL_FOR_CALLBACK_TIME = 'Callback time:';
export const LABEL_FOR_NAME = 'Name';
export const LABEL_FOR_STRUCTURE = 'Structure';
export const LABEL_FOR_TRUSTEE_NAME = 'Trustee';
export const LABEL_FOR_VIEW = 'View';
export const LABEL_FOR_DIRECTORS = 'Directors';
export const LABEL_FOR_LOAN_DETAILS = 'Loan details';
export const LABEL_FOR_AMOUNT = 'Amount';
export const LABEL_FOR_DEPOSIT = 'Deposit';
export const LABEL_FOR_TERM = 'Term';
export const LABEL_FOR_LENDER = 'Lender';
export const LABEL_FOR_BALLOON_PERCENT = 'Balloon %';
export const LABEL_FOR_COMPREHENSIVE = 'Comprehensive';
export const LABEL_ACCOUNTANT_DETAILS = 'Accountant Details';
export const LABEL_ACCOUNTANT = 'Accountant';
export const LABEL_CONTACT_NAME = 'Contact Name';
export const LABEL_PHONE = 'Phone';
export const LABEL_EMAIL = 'Email';
export const LABEL_LOAN_ID = 'Loan ID';
export const LABEL_FOR_VIEW_IN_LENDER_PORTAL = 'View in Lender portal';

export const LABEL_TITLE_FOR_MISSING_FIELDS_MODEL = 'Status update | Mandatory data is not entered';

export const LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST = 'Mandatory data is not entered for the status change to ';

export const LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND = '. Please review the following sections';

export const LABEL_GO_TO = 'Go to';
export const LABEL_PRIMARY_LOWERCASE = 'primary';
export const LABEL_SECONDARY_LOWERCASE = 'secondary';

export const REQUIRED_FIELDS_COMMERCIAL = [
  {
    key: 'finance.application.businessData.businessName',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.trusteeType',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.aBN',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.trusteeBusinessName',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.trusteeACN',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.trusteeFirstName',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.trusteeLastName',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.businessStructure',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.aBNEffectiveFrom',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.noOfDirectors',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.noOfPartners',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.noOfTrusteeDirectors',
    value: 'Business details > Details',
  },
  {
    key: 'finance.application.businessData.billingAddress.addressLine1',
    value: 'Business details > Address',
  },
  {
    key: 'finance.application.businessData.billingAddress.city',
    value: 'Business details > Address',
  },
  {
    key: 'finance.application.businessData.billingAddress.state',
    value: 'Business details > Address',
  },
  {
    key: 'finance.application.businessData.billingAddress.postcode',
    value: 'Business details > Address',
  },
  {
    key: 'finance.application.businessData.billingAddress.atYears',
    value: 'Business details > Address',
  },
  {
    key: 'finance.application.businessData.billingAddress.atAdditionalMonths',
    value: 'Business details > Address',
  },
  {
    key: 'finance.application.businessData.billingAddress.livingSituation',
    value: 'Business details > Address',
  },
  {
    key: 'finance.application.businessData.billingAddress',
    value: 'Business details > Address',
  },
  {
    key: 'finance.application.primaryApplicantData.documents',
    value: 'Documents',
  },
  {
    key: 'finance.application.shared.documents',
    value: 'Documents',
  },
  {
    key: 'finance.application.vehicle.makeCode',
    value: 'Asset details',
  },
  {
    key: 'finance.application.vehicle.familyCode',
    value: 'Asset details',
  },
  {
    key: 'finance.application.vehicle.year',
    value: 'Asset details',
  },

  {
    key: 'finance.application.vehicle.codeDescription',
    value: 'Asset details',
  },
  {
    key: 'finance.application.vehicle',
    value: 'Asset details',
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.salutation',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.firstName',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.lastName',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.gender',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.dateOfBirth',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.email',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.mobile',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.driverLicence',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.driverLicenceExpiry',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.driverLicenceState',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.driverLicenceType',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.passportNumber',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.residencyStatus',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.relationshipStatus',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.numberOfDependants',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.idTypes',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.employments',
    value: 'Contacts > APPLICANT_NAME > Employment',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.addresses',
    value: 'Contacts > APPLICANT_NAME > Address',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.asset',
    value: 'Contacts > APPLICANT_NAME > Assets & Liabilities',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.liability',
    value: 'Contacts > APPLICANT_NAME > Assets & Liabilities',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.loanDetails.submission.doesApplicantAgreeWithProposedLoanDetails',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.doesSatisfyConsumersObjectivesAndRequirements',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.doesApplicantComplyWithFinancialObligations',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.isNoKnownOrLikelyFutureChangeInCircumstancesAgreed',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.doesFutureChangesImpactPropsedLoan',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.howDoesSelectedLenderMeetCustomersNeed',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.purchasePrice',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.depositAmount',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.balloonPct',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.loanTermYears',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.balloonAmount',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.monthlyRepayment',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.baseRate',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.customerRate',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.accountKeepingFee',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.lenderEstablishmentFee',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.partnerFinanceReferralFee',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.ppsrFee',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.brokerFee',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.lendingCommision',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.approval.outcomeOfAnApplication',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.approvalExpiresAt',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.approvalConditions',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.doesApprovedAsSubmitted',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.purchasePrice',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.depositAmount',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.balloonPct',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.loanTermYears',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.balloonAmount',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.monthlyRepayment',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.baseRate',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.customerRate',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.accountKeepingFee',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.lenderEstablishmentFee',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.partnerFinanceReferralFee',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.ppsrFee',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.brokerFee',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.lendingCommision',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.settlement.purchasePrice',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.depositAmount',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.balloonPct',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.loanTermYears',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.balloonAmount',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.monthlyRepayment',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.baseRate',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.customerRate',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.accountKeepingFee',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.lenderEstablishmentFee',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.partnerFinanceReferralFee',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.ppsrFee',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.brokerFee',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.lendingCommision',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.settlementChecklist',
    value: 'Settlement > Checklist',
    label: 'Checklist',
  },
  {
    key: 'finance.application.loanDetails.settlement.sellerDetails',
    value: 'Settlement > Seller Details',
    label: 'Seller Details',
  },
  {
    key: 'finance.application.loanDetails.settlement.loanId',
    value: 'Settlement > Loan Details',
    label: 'Loan Details',
  },
  {
    key: 'addresses',
    value: 'Contacts > APPLICANT_NAME > Address',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'asset',
    value: 'Contacts > APPLICANT_NAME > Assets & Liabilities',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'liability',
    value: 'Contacts > APPLICANT_NAME > Assets & Liabilities',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'documents',
    value: 'Documents',
    userType: LABEL_SECONDARY_LOWERCASE,
  },

  {
    key: 'applicant.salutation',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },

  {
    key: 'applicant.firstName',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.lastName',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.gender',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.email',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.mobile',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.dateOfBirth',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.driverLicence',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.driverLicenceExpiry',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.driverLicenceState',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.driverLicenceType',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.passportNumber',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.residencyStatus',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.relationshipStatus',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.numberOfDependants',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.idTypes',
    value: 'Contacts > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'applicant.employments',
    value: 'Contacts > APPLICANT_NAME > Employment',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
];

export const REQUIRED_FIELDS = [
  {
    key: 'finance.application.purposeOfObtainingIncreasingOrRefinanceCredit',
    value: 'Requirements',
  },
  {
    key: 'finance.application.primaryApplicantData.employments.netIncome',
    value: 'Income',
  },
  {
    key: 'finance.application.loanType',
    value: 'Requirements',
  },
  {
    key: 'finance.application.purchasePrice',
    value: 'Requirements',
  },
  {
    key: 'finance.application.monthlyRepaymentBudget',
    value: 'Requirements',
  },
  {
    key: 'finance.application.customerExpectedLoanFeatures',
    value: 'Requirements',
  },
  {
    key: 'finance.application.isCreditRequiredForAdditionalCosts',
    value: 'Requirements',
  },
  {
    key: 'finance.application.purchasePrice',
    value: 'Requirements',
  },
  {
    key: 'finance.application.balloonPct',
    value: 'Requirements',
  },
  {
    key: 'finance.application.loanTermYears',
    value: 'Requirements',
  },
  {
    key: 'finance.application.primaryApplicantData.employments',
    value: 'Applicant > APPLICANT_NAME > Employment',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.addresses',
    value: 'Applicant > APPLICANT_NAME > Address',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.employments.netIncome',
    value: 'Income',
  },
  {
    key: 'finance.application.primaryApplicantData.partnerFirstName',
    value: 'Income',
  },
  {
    key: 'finance.application.primaryApplicantData.partnerLastName',
    value: 'Income',
  },
  {
    key: 'finance.application.primaryApplicantData.partnerNetIncomeFrequency',
    value: 'Income',
  },

  {
    key: 'finance.application.primaryApplicantData.expenses.expensesDisclaimer',
    value: 'Expenses',
  },
  {
    key: 'finance.application.primaryApplicantData.expenses',
    value: 'Expenses',
  },
  {
    key: 'finance.application.primaryApplicantData.documents',
    value: 'Documents',
  },
  {
    key: 'finance.application.vehicle.makeCode',
    value: 'Asset details',
  },
  {
    key: 'finance.application.vehicle.familyCode',
    value: 'Asset details',
  },
  {
    key: 'finance.application.vehicle.year',
    value: 'Asset details',
  },
  {
    key: 'finance.application.vehicle.codeDescription',
    value: 'Asset details',
  },
  {
    key: 'finance.application.vehicle',
    value: 'Asset details',
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.salutation',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.firstName',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.lastName',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.gender',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.dateOfBirth',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.email',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.mobile',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.driverLicence',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.driverLicenceExpiry',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.driverLicenceState',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.driverLicenceType',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.idTypes',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.residencyStatus',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.primaryApplicant.relationshipStatus',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.asset',
    value: 'Assets & Liabilities',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.liability',
    value: 'Assets & Liabilities',
    userType: LABEL_PRIMARY_LOWERCASE,
  },
  {
    key: 'finance.application.loanDetails.submission.doesApplicantAgreeWithProposedLoanDetails',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.doesSatisfyConsumersObjectivesAndRequirements',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.doesApplicantComplyWithFinancialObligations',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.isNoKnownOrLikelyFutureChangeInCircumstancesAgreed',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.doesFutureChangesImpactPropsedLoan',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.howDoesSelectedLenderMeetCustomersNeed',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.purchasePrice',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.depositAmount',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.balloonPct',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.loanTermYears',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.balloonAmount',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.monthlyRepayment',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.baseRate',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.customerRate',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.accountKeepingFee',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.lenderEstablishmentFee',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.partnerFinanceReferralFee',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.ppsrFee',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.brokerFee',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.submission.lendingCommision',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.howDoesSelectedLenderMeetCustomersNeed',
    value: 'Loan details > Submission',
  },
  {
    key: 'finance.application.loanDetails.approval.outcomeOfAnApplication',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.approvalExpiresAt',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.approvalConditions',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.doesApprovedAsSubmitted',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.purchasePrice',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.depositAmount',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.balloonPct',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.loanTermYears',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.balloonAmount',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.monthlyRepayment',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.baseRate',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.customerRate',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.accountKeepingFee',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.lenderEstablishmentFee',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.partnerFinanceReferralFee',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.ppsrFee',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.brokerFee',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.approval.lendingCommision',
    value: 'Loan details > Decision',
  },
  {
    key: 'finance.application.loanDetails.settlement.purchasePrice',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.depositAmount',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.balloonPct',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.loanTermYears',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.balloonAmount',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.monthlyRepayment',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.baseRate',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.customerRate',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.accountKeepingFee',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.lenderEstablishmentFee',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.partnerFinanceReferralFee',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.ppsrFee',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.brokerFee',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.loanDetails.settlement.lendingCommision',
    value: 'Loan details > Settlement',
  },
  {
    key: 'finance.application.settlementChecklist',
    value: 'Settlement > Checklist',
    label: 'Checklist',
  },
  {
    key: 'finance.application.loanDetails.settlement.sellerDetails',
    value: 'Settlement > Seller Details',
    label: 'Seller Details',
  },
  {
    key: 'finance.application.loanDetails.settlement.loanId',
    value: 'Settlement > Loan Details',
    label: 'Loan Details',
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.salutation',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.firstName',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.lastName',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.gender',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.relationshipStatus',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.residencyStatus',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.dateOfBirth',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.email',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.mobile',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.driverLicence',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.driverLicenceExpiry',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.driverLicenceState',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.driverLicenceType',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.applicant.idTypes',
    value: 'Applicant > APPLICANT_NAME > Personal',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.employments',
    value: 'Applicant > APPLICANT_NAME > Employment',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.addresses',
    value: 'Applicant > APPLICANT_NAME > Address',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.otherApplicant.0.documents',
    value: 'Documents',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.shared.documents',
    value: 'Documents',
  },
  {
    key: 'asset',
    value: 'Assets & Liabilities',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
  {
    key: 'finance.application.primaryApplicantData.areAllCreditCommitmentsUptoDate',
    value: 'Assets & Liabilities',
  },
  {
    key: 'liability',
    value: 'Assets & Liabilities',
    userType: LABEL_SECONDARY_LOWERCASE,
  },
];
export const LABEL_APPLICANT_PERSONAL = 'Applicant > APPLICANT_NAME > Personal';

export const LABEL_APPLICANT_ADDRESSES = 'Applicant > APPLICANT_NAME > Address';

export const LABEL_APPLICANT_EMPLOYMENT = 'Applicant > APPLICANT_NAME > Employment';

export const LABEL_APPLICANT_NAME = 'APPLICANT_NAME';

export const LABEL_LOAN_BUSINESS_DETAILS_DETAILS = 'Business details > Details';
export const LABEL_LOAN_BUSINESS_DETAILS_ADDRESS = 'Business details > Address';
export const LABEL_CONTACTS_PERSONAL = 'Contacts > APPLICANT_NAME > Personal';
export const LABEL_CONTACTS_ADDRESSES = 'Contacts > APPLICANT_NAME > Address';
export const LABEL_CONTACTS_ASSETS_AND_LIABILITIES = 'Contacts > APPLICANT_NAME > Assets & Liabilities';
export const LABEL_CONTACTS_EMPLOYMENT = 'Contacts > APPLICANT_NAME > Employment';
export const LABEL_LOAN_DETAILS_SUBMISSION = 'Loan details > Submission';
export const LABEL_LOAN_DETAILS_DECISION = 'Loan details > Decision';
export const LABEL_LOAN_DETAILS_SETTEMENT = 'Loan details > Settlement';
export const LABEL_SETTLEMENT_SELLER = 'Settlement > Seller Details';
export const LABEL_SETTLEMENT_LOAN_ID = 'Settlement > Loan Details';
export const LABEL_SETTLEMENT_CHECKLIST = 'Settlement > Checklist';
export const LABEL_ONE = 'one';
export const LABEL_TWO = 'two';
export const BUSINESS_STRUCTURE_VALUES = [
  {
    label: LABEL_BUSINESS_STRUCTURE_SOLE_TRADER,
    value: LABEL_BUSINESS_STRUCTURE_SOLE_TRADER,
  },
  {
    label: LABEL_BUSINESS_STRUCTURE_TRUST,
    value: LABEL_BUSINESS_STRUCTURE_TRUST,
  },
  {
    label: LABEL_BUSINESS_STRUCTURE_COMPANY,
    value: LABEL_BUSINESS_STRUCTURE_COMPANY,
  },
  {
    label: LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
    value: LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
  },
];

export const LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL = 'Individual';
export const LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY = 'Company';
export const BUSINESS_TRUSTEE_TYPE_OPTIONS = [
  {
    label: LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL,
    value: LABEL_BUSINESS_TRUSTEE_TYPE_INDIVIDUAL,
  },
  {
    label: LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY,
    value: LABEL_BUSINESS_TRUSTEE_TYPE_COMPANY,
  },
];
export const BUSINESS_STRUCTURE_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(BUSINESS_STRUCTURE_VALUES);

export const LABEL_FOR_ANNUAL_TURNOVER = 'Annual turnover $';

export const DEFAULT_VEHICLE_GROUP_OBJECT = {
  hasTradeIn: null,
  primaryUser: '',
  wantsFinance: '',
  account: '',
  externalReferrer: '',
  leadSource: '',
  urgency: '',
  primaryUserAgreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt: null,
  vehicleSourced: 'CarClarity',
  gCLID: '',
  fBCLID: '',
  partnerOwnSystemLeadId: '',
  lastClickUTMSource: '',
  lastClickUTMMedium: '',
  lastClickUTMCampaign: '',
  lastClickUTMTerm: '',
  lastClickUTMContent: '',
  lastClickUTMId: '',
  googleTransactionId: '',
  featureBranch: '',
  partnerId: '',
  startedAt: null,
  httpReferrer: '',
  isAppReady: true,
  finance: {
    application: {
      saleType: '',
      purchaseSource: '',
      purchasePrice: '',
      vehicle: '',
      isTradeInFinanced: null,
      certaintyStage: '',
      balloonPct: 0,
      customerReasonForBalloon: null,
      customerReasonForBalloonExplanation: null,
      loanTermYears: 5,
      completedBalloonPaymentAt: null,
      completedLeadApplicationAt: '',
      completedTradeInAt: null,
      productCategoryType: '',
      applicationType: '',
      loanType: '',
      loanAmount: '',
      primaryApplicantData: {
        primaryApplicant: {
          dateOfBirth: '',
          driverLicence: '',
          driverLicenceExpiry: null,
          driverLicenceState: '',
          driverLicenceType: '',
          driverLicenceCardNumber: '',
          email: '',
          firstName: '',
          lastName: '',
          mailingAddress: {
            country: '',
            isCurrent: false,
            addressLine1: '',
            state: '',
          },
          mobile: '',
          salutation: '',
          type: '',
        },
        employments: [
          {
            employerName: '',
            position: '',
            status: '',
            isCurrent: false,
            atYears: null,
            atAdditionalMonths: null,
            isAdditionalJob: false,
            netIncome: null,
            netIncomeFrequency: 'Monthly',
            hasExtraEarnings: null,
            commissionPretax: 0,
            commissionFrequency: '',
            bonusPretax: 0,
            bonusFrequency: '',
            overtimePretax: 0,
            overtimeFrequency: '',
            allowancesPretax: 0,
            allowancesFrequency: '',
          },
        ],
        assets: [
          {
            type: 'Property',
            amount: 0,
            hasLiability: true,
            liability: '',
          },
        ],
        expenses: [
          {
            type: 'Rent Or Board',
            amount: 0,
            frequency: 'Monthly',
            helpText: '',
          },
          {
            type: 'Food or Grocery',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'Household food and groceries, and pet care',
          },
          {
            type: 'Internet And Telephone',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'Mobile phone, home phone, wifi, Foxtel, and any other subscriptions',
          },
          {
            type: 'Utilities',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'Council rates, strata, gas, electricity, and water bills',
          },
          {
            type: 'Insurances',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'Car insurance, health insurance, life Insurance, home and contents, pet insurance, and any other insurances',
          },
          {
            type: 'Travel',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'Public transport, parking, tolls, and ride sharing services',
          },
          {
            type: 'Entertainment',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'Take away, social outings, holidays, gym, and any other entertainment',
          },
          {
            type: 'Education',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'Schooling fees, private school costs, tutoring, and adult education',
          },
          {
            type: 'Childcare',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'Child care and child support',
          },

          {
            type: 'Motor Vehicle Running Costs',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'Petrol, rego, servicing and maintenance costs for owned vehicles',
          },
          {
            type: 'Clothing and personal care',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'Clothing, beauty (e.g., nails), hair, and cosmetics',
          },
          {
            type: 'Medical and health care',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'Medical scripts and ongoing appointments (e.g., physiotherapy) ',
          },
          {
            type: 'Other',
            amount: 0,
            frequency: 'Monthly',
            helpText: 'All expenses not specified above',
          },
        ],
        liabilities: [
          {
            type: 'Mortgage',
            name: '',
            lenderName: '',
            outstandingAmount: null,
            installmentAmount: null,
            installmentFrequency: '',
            loanYearsRemaining: '',
            loanMonthsRemaining: '',
            creditLimit: '',
            balloonAmount: '',
            interestRatePct: '',
            monthlyRepayment: '',
            repaymentHistoryDepiction: '',
            hasPayoutLetter: null,
            vehicle: '',
          },
          {
            type: 'Short Term Finance',
            lenderName: '',
            outstandingAmount: null,
            installmentAmount: null,
            installmentFrequency: '',
          },
          {
            type: 'Lease Or Hire Purchase',
            lenderName: '',
            installmentAmount: null,
            installmentFrequency: '',
            loanYearsRemaining: 0,
            loanMonthsRemaining: 0,
          },
          {
            type: 'Tax Debt',
            outstandingAmount: null,
            installmentAmount: null,
            installmentFrequency: '',
          },
          {
            type: 'Personal Loan',
            outstandingAmount: null,
            lenderName: '',
            creditLimit: null,
            installmentAmount: null,
            installmentFrequency: '',
          },
          {
            type: 'Line Of Credit',
            outstandingAmount: null,
            lenderName: '',
            creditLimit: null,
            installmentAmount: null,
            installmentFrequency: '',
          },
          {
            type: 'Overdraft',
            outstandingAmount: null,
            lenderName: '',
            creditLimit: null,
            installmentAmount: null,
            installmentFrequency: '',
          },
          {
            type: 'Help Hecs',
            outstandingAmount: null,
          },
          {
            type: 'Other',
            lenderName: '',
            creditLimit: null,
            installmentAmount: null,
            installmentFrequency: '',
            loanYearsRemaining: 0,
            loanMonthsRemaining: 0,
          },
          {
            type: 'Credit Card',
            name: '',
            lenderName: '',
            outstandingAmount: '',
            installmentAmount: '',
            installmentFrequency: 'Monthly',
            loanYearsRemaining: '',
            loanMonthsRemaining: '',
            creditLimit: null,
            balloonAmount: '',
            interestRatePct: '',
            monthlyRepayment: '',
            repaymentHistoryDepiction: '',
            hasPayoutLetter: null,
            vehicle: '',
          },
        ],
        documents: [],
        nonEmploymentIncomes: [],
        hasAdditionalLiabilities: true,
        hasAdditionalJobs: null,
        hasCreditCardsOrStoreCards: true,
        hasPersonalLoans: null,
        hasNonEmploymentIncomes: null,
        hasPartnerIncome: null,
        hasAdditionalVehicles: null,
        livingSituation: '',
        currentWorkStatus: '',
        personalNetMonthlyIncomeGuesstimate: '',
        residencyStatus: '',
        addresses: [
          {
            address: '',
            atYears: '',
            atAdditionalMonths: '',
            isCurrent: true,
            country: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postcode: '',
          },
        ],
        relationshipStatus: '',
        numberOfDependants: 0,
        stage: 'LEAD_APPLICATION',
        step: 1,
        stageMax: '',
        stepMax: 0,
        completedMainApplicationAt: null,
        completedPersonalInfoAt: null,
        completedEmploymentAndIncomeAt: null,
        completedExpensesAt: null,
        completedAssetsAt: null,
        completedLiabilitiesAt: null,
        completedInitialDocumentsUploadAt: null,
        customerSuppliedCreditRatingDepiction: '',
        completedApplicationDetails: '',
        agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt: '',
        liabilitiesTotal: 0,
      },
      businessData: {
        isDirector: null,
        businessName: '',
        tradingName: '',
        businessStructure: '',
        hasABN: null,
        aBN: '',
        aBNRegisteredYearsRange: '',
        tradingYearsRange: '',
        isRegisteredForGST: null,
        gSTRegisteredYearsRange: '',
        assets: [],
        liabilities: [],
        completedBusinessInfoAt: null,
        aSIC: '',
        billingAddress: {
          address: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          postcode: '',
          country: '',
          atYears: null,
          atAdditionalMonths: null,
          isCurrent: false,
          livingSituation: '',
        },
        aBNEffectiveFrom: null,
        gSTEffectiveFrom: null,
        directors: [],
        trusteeBusinessName: '',
        trusteeABN: '',
        trusteeASIC: '',
        trusteeBusinessStructure: '',
        trusteeBillingAddress: {
          state: '',
          postcode: '',
        },
        trusteeABNEffectiveFrom: null,
        trusteeGSTEffectiveFrom: null,
        grossTradingIncomeMonthly: 0,
        isPropertyOwner: null,
      },
      step: 1,
      stage: '',
      mobile: '',
      primaryUser: '',
    },
  },
  links: [],
  tradeIn: {
    completedLeadFormAt: null,
    vehicle: '',
  },
  initialLeadFunnel: 'Finance',
  vehicle: {
    account: '',
    code: '',
    codeDescription: '',
    condition: '',
    familyCode: '',
    familyDescription: '',
    financeDepositAmount: '',
    makeCode: '',
    makeDescription: '',
    purchaseSource: '',
    type: '',
    use: '',
    year: '',
    yearDescription: '',
    customerNote: '',
  },
};
// export const LABEL_WITHDRAWN = 'Withdrawn'
export const LABEL_ADD_OTHER_DOCUMENT_TYPE = 'Add other document type';
export const LABEL_NEW_DOCUMENT_TYPE = 'New document type';
export const LABEL_DOCUMENT_TYPE_NAME = 'Document type name';

export const LABEL_WITHDRAW_APPLICATION = 'Withdraw Application';
export const LABEL_CHANGE_DOCUMENT_TYPE = 'Change document type';

export const LABEL_DECLINED_APPLICATION = 'Declined Application';
export const LABEL_WITHDRAWN_REASON = 'Withdrawn Reason';
export const LABEL_DECLINED_REASON = 'Declined Reason';

export const DESCRIPTION_FOR_CHANGE_DOCUMENT_TYPE = 'Are you sure you want to change ';

export const DESCRIPTION_FOR_WITHDRAW_APPLICATION = 'Are you sure you want to withdraw the application?';
export const DESCRIPTION_FOR_DECLINED_APPLICATION = 'Are you sure you want to declined the application?';

export const LABEL_APPROVED_SUBTITLE = 'Application is approved at 06/02/2022 11:59 am';

export const LABEL_DECLINED_OR_WITHDRAW_SUBTITLE_APPLICATION = 'Application is declined or withdraw subtitle';

export const LABEL_WITHDRAWN_SUBTITLE = 'Application is withdrawn at 11/01/2021 am';
export const LABEL_CLONE = 'Clone';

export const LABEL_CLONE_SUBTITLE = 'Use this option to copy the details of current application and create a new one';

export const LABEL_WITHDRAW_APPROVED_APPLICATION = 'Use this option to withdraw the currently approved application';

export const CLONE_APPLICATION = 'Clone Application';
export const CLONE_APPLICATION_CONFIRMATION = 'Are you sure you want to clone the current application?';

export const LABEL_NEGATIVE_NET_SURPLUS_VALUE = 'This customer has negative capacity (<netValue>). Are you sure you wish to proceed?';

export const REGEX_FOR_NET_SURPLUS = /<netValue>/g;

export const LABEL_YES = 'Yes';
export const LABEL_NO = 'No';
export const LABEL_ID = 'ID';

export const LABEL_IMPERSONATE = 'Impersonate';
export const LABEL_IMPERSONATE_ACCELERATE = 'Impersonate Accelerate';
export const LABEL_CHANGE_PASSWORD = 'Change Password';
export const LABEL_LOGOUT = 'Logout';
export const LABEL_SUBACCOUNT = 'Subaccount';
export const LABEL_REFERRER = 'Referrer';
export const LABEL_TOTAL_REFERRALS = 'Total referrals';
export const LABEL_TOTAL_SETTLEMENTS = 'Total settlements';

export const LABEL_LOAN_SEND_PROPSAL = 'sendProposal';
export const LABEL_LOAN_EDIT_SUBMISSION_DETAILS = 'editSubmissionDetails';
export const LABEL_LOAN_SUBMIT_APPLICATION = 'submitApplication';

export const LABEL_LOWER_MONTHLY_PAYMENT = 'Lower Monthly Payment';
export const LABEL_PLANNED_UPGRADE_OF_VEHICLE = 'Planned Upgrade Of Vehicle';
export const LABEL_BALLOON_REPAYMENT_OTHER = 'Other';

export const LABEL_LOWER_MONTHLY_PAYMENT_VAL = 'LowerMonthlyPayment';
export const LABEL_PLANNED_UPGRADE_OF_VEHICLE_VAL = 'PlannedUpgradeOfVehicle';
export const LABEL_BALLOON_REPAYMENT_OTHER_VAL = 'Other';
export const LABEL_SUBMIT_APPLICATION_DIGITALLY = 'Submit application digitally';
export const LABEL_SUBMIT_APPLICATION_MANUALLY = 'Submit application manually';
export const LABEL_DIGITAL = 'digital';
export const LABEL_MANUAL = 'manual';
export const LABEL_SUBMIT_APPLICATION_DIGITALLY_DESCRIPTION = 'Are you sure you want to submit the application details digitally to Lender via API?';
export const LABEL_SUBMIT_APPLICATION_MANUALLY_DESCRIPTION = 'Are you sure you only want to submit the application manually? Please note you will need to manually into the Lender’s system to submit the application.';

export const REASON_FOR_BALLOON_PAYMENT_OPTIONS = [
  {
    label: LABEL_LOWER_MONTHLY_PAYMENT,
    value: LABEL_LOWER_MONTHLY_PAYMENT_VAL,
  },
  {
    label: LABEL_PLANNED_UPGRADE_OF_VEHICLE,
    value: LABEL_PLANNED_UPGRADE_OF_VEHICLE_VAL,
  },
  {
    label: LABEL_BALLOON_REPAYMENT_OTHER,
    value: LABEL_BALLOON_REPAYMENT_OTHER_VAL,
  },
];
export const LABEL_REASON_FOR_BALLOON_PAYMENT = 'Reason for balloon payment';

export const LABEL_REVIEW_AND_SEND_PROPOSAL = 'Review & Send';

export const LABEL_I_HAVE_REVIEWED_COMPLETE_CPD = 'I have reviewed the complete credit proposal disclosure document';
export const LABEL_OTHER_REASON_FOR_BALLOON_PAYMENT_VIEW = 'Other Reason for balloon payment';
export const LABEL_OTHER_REASON_FOR_BALLOON_PAYMENT = 'Enter other reason for balloon payment';

export const LABEL_CREDIT_PROPOSAL_DISCLOSURE_SENT_AT = 'Proposal document sent at';
export const LABEL_APPLICATION_SUBMITTED_AT = 'Application submitted at';
export const LABEL_APPLICATION_SETTLED_AT = 'Application is settled at';
export const LABEL_UPLOAD_SETTLEMENT_DOCUMENTS = 'Upload settlement documents';
export const LABEL_FOR_STATUS_UPDATE = 'Status update';
export const LABEL_FOR_MISSING_DOCUMENTS = 'Missing documents';
export const LABEL_FOR_MISSING_DOCUMENTS_DESCRIPTION = 'Following document(s) required for <b> Settlement </b> are not uploaded. <br /> Please upload them and try again';
export const REGEX_ERROR_MESSAGE_FOR_DOCUMENTS_ARE_MISSING = /^Documents (\[.*\]) are missing?/;
export const LABEL_GO_TO_DOCUMENTS = 'Go to “Documents”';
export const LABEL_GO_TO_SETTLEMENT_DOCUMENT = 'Go to “Settlement Documents”';
export const LABEL_UPDATE = 'Update';
export const DOCUMENT_VIEW_LINK = 'View';
export const LABEL_REMOVE = 'Remove';
export const LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_PRE_SETTLEMENT = 'Are you sure you want to save the pre-settlement details?';
export const LABEL_GO_TO_CONTACT_LIST = 'Go to “Contact list”';

export const LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_PRE_SETTLEMENT_DIFFERENT = 'Are you sure you want to save the pre-settlement details and send a credit proposal disclosure document to the applicant(s)?';

export const LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_SETTLEMENT = 'Are you sure you want to submit the settlement details?';
export const LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_SETTLEMENT_WITH_CPD_GENERATION = 'Are you sure you want to save the settlement details and send a credit proposal disclosure document to the applicant(s)?';
export const LABEL_CONGRATULATIONS_DONT_FORGET_TO_ADD_DOCUMENTS = "Congratulations! Don't forget to upload the settlement documents";

export const INDUSTRY_AGRICULTURE = 'Agriculture';
export const INDUSTRY_FORESTRY_AND_FISHING = 'Forestry and Fishing';
export const INDUSTRY_MINING = 'Mining';
export const INDUSTRY_MANUFACTURING = 'Manufacturing';
export const INDUSTRY_ELECTRICITY = 'Electricity';
export const INDUSTRY_GAS = 'Gas';
export const INDUSTRY_WATER_AND_WASTE_SERVICES = 'Water and Waste Services';
export const INDUSTRY_CONSTRUCTION = 'Construction';
export const INDUSTRY_WHOLESALE_TRADE = 'Wholesale Trade';
export const INDUSTRY_RETAIL_TRADE = 'Retail Trade';
export const INDUSTRY_ACCOMMODATION_AND_FOOD_SERVICES = 'Accommodation and Food Services';
export const INDUSTRY_TRANSPORT = 'Transport';
export const INDUSTRY_POSTAL_AND_WAREHOUSING = 'Postal and Warehousing';
export const INDUSTRY_INFORMATION_MEDIA_AND_TELECOMMUNICATIONS = 'Information Media and Telecommunications';
export const INDUSTRY_FINANCIAL_AND_INSURANCE_SERVICES = 'Financial and Insurance Services';
export const INDUSTRY_RENTAL = 'Rental';
export const INDUSTRY_HIRING_AND_REAL_ESTATE_SERVICES = 'Hiring and Real Estate Services';
export const INDUSTRY_PROFESSIONAL = 'Professional';
export const INDUSTRY_SCIENTIFIC_AND_TECHNICAL_SERVICES = 'Scientific and Technical Services';
export const INDUSTRY_ADMINISTRATIVE_AND_SUPPORT_SERVICES = 'Administrative and Support Services';
export const INDUSTRY_PUBLIC_ADMINISTRATION_AND_SAFETY = 'Public Administration and Safety';
export const INDUSTRY_EDUCATION_AND_TRAINING = 'Education and Training';
export const INDUSTRY_HEALTH_CARE_AND_SOCIAL_ASSISTANCE = 'Health Care and Social Assistance';
export const INDUSTRY_ARTS_AND_RECREATION_SERVICES = 'Arts and Recreation Services';
export const INDUSTRY_OTHER_SERVICES = 'Other Services';

export const ALL_INDUSTRY_TYPES = [
  INDUSTRY_AGRICULTURE,
  INDUSTRY_FORESTRY_AND_FISHING,
  INDUSTRY_MINING,
  INDUSTRY_MANUFACTURING,
  INDUSTRY_ELECTRICITY,
  INDUSTRY_GAS,
  INDUSTRY_WATER_AND_WASTE_SERVICES,
  INDUSTRY_CONSTRUCTION,
  INDUSTRY_WHOLESALE_TRADE,
  INDUSTRY_RETAIL_TRADE,
  INDUSTRY_ACCOMMODATION_AND_FOOD_SERVICES,
  INDUSTRY_TRANSPORT,
  INDUSTRY_POSTAL_AND_WAREHOUSING,
  INDUSTRY_INFORMATION_MEDIA_AND_TELECOMMUNICATIONS,
  INDUSTRY_FINANCIAL_AND_INSURANCE_SERVICES,
  INDUSTRY_RENTAL,
  INDUSTRY_HIRING_AND_REAL_ESTATE_SERVICES,
  INDUSTRY_PROFESSIONAL,
  INDUSTRY_SCIENTIFIC_AND_TECHNICAL_SERVICES,
  INDUSTRY_ADMINISTRATIVE_AND_SUPPORT_SERVICES,
  INDUSTRY_PUBLIC_ADMINISTRATION_AND_SAFETY,
  INDUSTRY_EDUCATION_AND_TRAINING,
  INDUSTRY_HEALTH_CARE_AND_SOCIAL_ASSISTANCE,
  INDUSTRY_ARTS_AND_RECREATION_SERVICES,
  INDUSTRY_OTHER_SERVICES,
];

export const ALL_INDUSTRY_OPTIONS = generateOptionsFromTypesWhereTheKeyIsSameAsValue(ALL_INDUSTRY_TYPES);

export const LABEL_APPLICATION_IS = 'Application is ';
export const LABEL_APPLICATION_IS_APPROVED_AT = ' at ';

export const APPLICATION_STATUS_STARTED = 'Started';
export const APPLICATION_STATUS_AWAITING_DOCS = 'Awaiting Docs';
export const APPLICATION_STATUS_DOCUMENTS_RECEIVED = 'Documents Received';
export const APPLICATION_STATUS_READY_FOR_SUBMISSION = 'Ready For Submission';
export const APPLICATION_STATUS_SUBMITTED_TO_LENDER = 'Submitted To Lender';
export const APPLICATION_TRADE_IN_VEHICLE = 'Trade In';

export const LABEL_HAVE_YOU_MADE_THE_CUSTOMER_AWARE = 'Have you made the customer aware of any potential costs associated with repaying the credit contract early?';

export const APPLICATION_STATUS_DECLINED = 'Declined';
export const APPLICATION_STATUS_APPROVED = 'Approved';
export const APPLICATION_STATUS_WITHDRAWN = 'Withdrawn';
export const ACCELERATE = 'accelerate';
export const LABEL_ACCELERATE = 'ACCELERATE';
export const LABEL_REFER_TO_CARCLARITY = 'Refer to CarClarity';
export const FUEL = 'fuel';
export const FUEL_KEYWORD = 'Fuel';
export const CARCLARITY_KEYWORD = 'CarClarity';
export const LABEL_DO_YOU_REQUIRE_CREDIT_TO_PAY_FOR_ADDITIONAL = 'Do you require credit to pay for additional costs in relation to the vehicle such as insurance?';
export const LABEL_ARE_YOU_AWARE_OF_THE_COSTS = 'Are you aware of the costs involved in financing the insurance?';
export const LABEL_AUSTRALIA = 'Australia';
export const LABEL_YEARS = 'years';
export const BALLOON_PCT_MAX_PER_LOAN_TERMS = {
  1: 60,
  2: 55,
  3: 50,
  4: 40,
  5: 30,
  6: 0,
  7: 0,
};
export const LABEL_FOR_GO_TO_DOCUMENTS = 'Go to documents';
export const BALLOON_AMOUNT_CAN_NOT_BE_GREATER_THAN = 'Balloon amount cannot be greater than';
export const LABEL_FOR = 'for';
export const LABEL_FOR_COMMERCIAL = 'Commercial';
export const LABEL_SAVE_BUTTON = 'Save';
export const LABEL_SAVE_AND_NEXT = 'Save & Next';
export const LABEL_CURRENT_ADDRESS = 'Current address';
export const LABEL_ADD_SHARE_HOLDER = '+ Add Shareholder';
export const LABEL_SHARE_HOLDER_DETAILS = 'Shareholders Detail';
export const LABEL_IS_THE_BUSINESS_ADDRESS_SAME_AS_RESIDENCIAL = 'Is the business address same as the residential address?';
export const LABEL_RENTING_THROUGH_AGENT = 'Are you renting through agent?';
export const LABEL_DATE_OF_BIRTH = 'Date of birth';
export const LABEL_BENEFICIARIES_DETAILS = 'Beneficiaries details';
export const LABEL_ADD_BENEFICIARY = ' + Add Beneficiary';
export const LABEL_TRUSTEE_DETAILS = 'Trustee details';
export const LABEL_TRUSTEE_TYPE = 'Trustee type';
export const LABEL_TRUSTEE_NAME = 'Trustee name';
export const LABEL_TRUSTEE_ABN = 'Trustee ABN';
export const LABEL_TRUSTEE_ACN = 'Trustee ACN';
export const LABEL_NO_OF_TRUSTEE_DIRECTORS = 'No. of trustee directors';
export const LABEL_TRUSTEE_DIRECTORS = 'Trustee directors';
export const LABEL_TRUSTEE_COMPANY = 'Trust - Company';
export const LABEL_BUSINESS_TRUSTEE_FIRSTNAME = 'Trustee first name';
export const LABEL_BUSINESS_TRUSTEE_LASTNAME = 'Trustee last name';
export const LABEL_FOR_PARTNERS = 'Partners';
export const LABEL_FOR_CLOSE = 'Close';
export const LABEL_FOR_PARTNER = 'Partner';
export const LABEL_FOR_DIRECTOR = 'Director';
export const LABEL_FOR_IMPORT = 'Import';
export const LABEL_TITLE_FOR_IMPORT_APPLICATION = 'Import data from related application';
export const LABEL_DESCRIPTION_FOR_IMPORT_APPLICATION = `Please select an application from the list to import the data (related to the same primary applicant, no. of applicants and application type). Data from the sections of <b>Applicant, Income, Asset & Liabilities</b> and <b>Expenses</b>`;
export const LABEL_DESCRIPTION_FOR_IMPORT_APPLICATION_SECOND = ` will get imported.  Please note that it would overwrite any existing data in the current application for the above mentioned sections.`;
export const LABEL_DESCRIPTION_FOR_IMPORT_APPLICATION_FOR_JOINT_APPLICANTS =
  'Please select an application from the list to import the data (related to the same primary applicant, no. of applicants and application type). Both <b>primary and secondary applicants</b>’ data from the sections of <b>Applicant, Income, Asset & Liabilities</b> and <b>Expenses</b> ';
export const LABEL_DESCRIPTION_FOR_IMPORT_APPLICATION_FOR_COMMERCIAL =
  'Please select an application from the list to import the data (related to the same primary applicant, no. of contacts and application type). <b>All applicants</b>’ data from the section <b>Contacts</b> along with <b>Business details</b> will get imported.';
export const LABEL_DESCRIPTION_FOR_IMPORT_APPLICATION_FOR_COMMERCIAL_SECOND = ' Please note that it would overwrite any existing data in the current application for the above mentioned sections.';
export const LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS = 'An email will be sent to ';
export const LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_SECOND = 'requesting for the following pending documents to be sent back through the email as an attachment.<p class="mt-2">Are you sure you want to proceed?</p>';
export const LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_REQUEST_CUSTOMER_DASHBOARD = 'requesting for the following pending documents to be uploaded through the customer dashboard.<p class="mt-2">Are you sure you want to proceed?</p>';
export const LABEL_DESCRIPTION_FOR_RESEND_EMAIL_FOR_PENDING_DOCUMENTS_REQUEST_MANUALLY = 'requesting for the following pending documents to be sent back through the email as an attachment.<p class="mt-2">Are you sure you want to proceed?</p>';

export const BLOCKED_DOCUMENTS = ['ESign', 'Privacy Form', 'Credit Quote', 'Credit Guide', 'Preliminary Assessment', 'Credit Proposal Disclosure'];
export const CHECKLIST_ENUM_VALUE_IDENTIFICATION = 'Identification';
export const CHECKLIST_ENUM_VALUE_INCOME_VERIFICATION = 'Income Verification';
export const CHECKLIST_ENUM_VALUE_EXPENSES_VERIFICATION = 'Expenses Verification';
export const CHECKLIST_ENUM_VALUE_DOCUMENTS = 'Documents';
export const CHECKLIST_ENUM_VALUE_COMPLIANCE = 'Compliance';
export const CHECKLIST_ENUM_VALUE_REVENUE_DETAILS = 'Revenue Details';

export const LABEL_IDENTIFICATION = 'Identification';
export const LABEL_INCOME_VERIFICATION = 'Income verification';
export const LABEL_EXPENSES_VERIFICATION = 'Expenses verification';
export const LABEL_DOCUMENTS = 'Documents';
export const LABEL_COMPLIANCE = 'Compliance';
export const LABEL_REVENUE_DETAILS = 'Revenue details';

export const SETTLEMENT_CHECKLISTS = [
  {
    key: CHECKLIST_ENUM_VALUE_IDENTIFICATION,
    label: LABEL_IDENTIFICATION,
    details: ['Acceptable photo ID received', 'Drivers License is current', 'Passport is current', 'Medicare card is current'],
  },
  {
    key: CHECKLIST_ENUM_VALUE_INCOME_VERIFICATION,
    label: LABEL_INCOME_VERIFICATION,
    details: ['Payslips confirmed to be 2 x latest pay cycles', 'Bank statements received', 'Tax Return / NOA (if applicable)'],
  },
  {
    key: CHECKLIST_ENUM_VALUE_EXPENSES_VERIFICATION,
    label: LABEL_EXPENSES_VERIFICATION,
    details: ['Reasonable expectation check - expenses above minimum HEM'],
  },
  {
    key: CHECKLIST_ENUM_VALUE_DOCUMENTS,
    label: LABEL_DOCUMENTS,
    details: ['Signed loan agreement', 'Dealer/Private sales tax invoice', 'Insurance COC', 'Insurance policy docs forwarded to customer', 'Warranty docs complete check', 'Remittance/Settlement advice', 'Verimoto for private sales'],
  },
  {
    key: CHECKLIST_ENUM_VALUE_COMPLIANCE,
    label: LABEL_COMPLIANCE,
    details: ['Requirements & objectives complete', 'Privacy, credit quote, credit guide complete', 'Preliminary assessment saved', 'CPD confirmed accurate and sent'],
  },
  {
    key: CHECKLIST_ENUM_VALUE_REVENUE_DETAILS,
    label: LABEL_REVENUE_DETAILS,
    details: ['NAF validated', 'Broker Fee validated', 'Lending commission validated', 'Warranty commission validated', 'Insurance commission validated'],
  },
];
export const FINANCE_APPLICATION_TAB_REQUIREMENTS_KEY = 'requirements';
export const FINANCE_APPLICATION_TAB_REQUIREMENTS_LABEL = 'Requirements';

export const FINANCE_APPLICATION_TAB_APPLICANT_KEY = 'applicant';
export const FINANCE_APPLICATION_TAB_APPLICANT_LABEL = 'Applicant';

export const FINANCE_APPLICATION_TAB_INCOME_KEY = 'income';
export const FINANCE_APPLICATION_TAB_INCOME_LABEL = 'Income';

export const FINANCE_APPLICATION_TAB_EXPENSES_KEY = 'expenses';
export const FINANCE_APPLICATION_TAB_EXPENSES_LABEL = 'Expenses';

export const FINANCE_APPLICATION_TAB_ASSETS_AND_LIABILITIES_KEY = 'assetsAndLiabilities';
export const FINANCE_APPLICATION_TAB_ASSETS_AND_LIABILITIES_LABEL = 'Assets & Liabilities';

export const FINANCE_APPLICATION_TAB_DOCUMENTS_KEY = 'documents';
export const FINANCE_APPLICATION_TAB_DOCUMENTS_LABEL = 'Documents';

export const FINANCE_APPLICATION_TAB_COMPLIANCE_KEY = 'compliance';
export const FINANCE_APPLICATION_TAB_COMPLIANCE_LABEL = 'Compliance';

export const FINANCE_APPLICATION_TAB_CREDIT_REPORT_KEY = 'creditReport';
export const FINANCE_APPLICATION_TAB_CREDIT_REPORT_LABEL = 'Credit Report';

export const FINANCE_APPLICATION_TAB_ASSET_DETAILS_KEY = 'assetDetails';
export const FINANCE_APPLICATION_TAB_ASSET_DETAILS_LABEL = 'Asset Details';

export const FINANCE_APPLICATION_TAB_LOAN_DETAILS_KEY = 'loanDetails';
export const FINANCE_APPLICATION_TAB_LOAN_DETAILS_LABEL = 'Loan Details';

export const FINANCE_APPLICATION_TAB_NOTES_KEY = 'notes';
export const FINANCE_APPLICATION_TAB_NOTES_LABEL = 'Notes';

export const FINANCE_APPLICATION_TAB_SETTLEMENT_KEY = 'settlement';
export const FINANCE_APPLICATION_TAB_SETTLEMENT_LABEL = 'Settlement';

export const FINANCE_APPLICATION_TABS = [
  {
    key: FINANCE_APPLICATION_TAB_REQUIREMENTS_KEY,
    label: FINANCE_APPLICATION_TAB_REQUIREMENTS_LABEL,
  },
  {
    key: 'applicant',
    label: 'Applicant',
  },
  {
    key: 'income',
    label: 'Income',
  },
  {
    key: 'expenses',
    label: 'Expenses',
  },
  {
    key: 'assetsAndLiabilities',
    label: 'Assets & Liabilities',
  },
  {
    key: 'documents',
    label: 'Documents',
  },
  {
    key: 'compliance',
    label: 'Compliance',
  },
  {
    key: 'creditReport',
    label: 'Credit Report',
  },
  {
    key: 'assetDetails',
    label: 'Asset Details',
  },
  {
    key: 'loanDetails',
    label: 'LoanDetails',
  },
  {
    key: FINANCE_APPLICATION_TAB_SETTLEMENT_KEY,
    label: FINANCE_APPLICATION_TAB_SETTLEMENT_LABEL,
  },
  {
    key: 'notes',
    label: 'Notes',
  },
];

export const LABEL_FOR_SHAREHOLDING_PERCENTAGE = 'Shareholding %';
export const LABEL_FOR_DIRECTORSHIP_START_DATE = 'Directorship start date';
export const SAVE_LABEL = 'Save';
export const SAVE_AND_NEXT_LABEL = 'Save & Next';
export const LABEL_ADD_ACCOUNTANT = '+ Add Accountant';
export const SAVE_AND_SEND_LABEL = 'Save & Send';
export const LABEL_ESIGN = 'ESign';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_KEY = 'businessDetails';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_LABEL = 'Business details';

export const FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY = 'contacts';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_LABEL = 'Contacts';

export const FINANCE_APPLICATION_COMMERCIAL_TAB_GUARANTOR_KEY = 'guarantor';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_GUARANTOR_LABEL = 'Guarantor';

export const FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY = 'documents';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_LABEL = 'Documents';

export const FINANCE_APPLICATION_COMMERCIAL_TAB_COMPLIANCE_KEY = 'compliance';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_COMPLIANCE_LABEL = 'Compliance';

export const FINANCE_APPLICATION_COMMERCIAL_TAB_CREDIT_REPORT_KEY = 'creditReport';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_CREDIT_REPORT_LABEL = 'Credit Report';

export const FINANCE_APPLICATION_COMMERCIAL_TAB_ASSET_DETAILS_KEY = 'assetDetails';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_ASSET_DETAILS_LABEL = 'Asset Details';

export const FINANCE_APPLICATION_COMMERCIAL_TAB_LOAN_DETAILS_KEY = 'loanDetails';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_LOAN_DETAILS_LABEL = 'Loan Details';

export const FINANCE_APPLICATION_COMMERCIAL_TAB_SETTLEMENT_KEY = 'settlement';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_SETTLEMENT_LABEL = 'Settlement';

export const FINANCE_APPLICATION_COMMERCIAL_TAB_NOTES_KEY = 'notes';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_NOTES_LABEL = 'Notes';

export const FINANCE_APPLICATION_COMMERCIAL_TAB_MATCHES_LABEL = 'Matches';
export const FINANCE_APPLICATION_COMMERCIAL_TAB_MATCHES_KEY = 'matches';

export const FINANCE_APPLICATION_COMMERCIAL_TABS = [
  // {
  //   label: FINANCE_APPLICATION_COMMERCIAL_TAB_MATCHES_LABEL,
  //   key: FINANCE_APPLICATION_COMMERCIAL_TAB_MATCHES_KEY,
  // },
  {
    label: FINANCE_APPLICATION_COMMERCIAL_TAB_MATCHES_LABEL,
    key: FINANCE_APPLICATION_COMMERCIAL_TAB_MATCHES_KEY,
  },
  {
    key: FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_KEY,
    label: FINANCE_APPLICATION_COMMERCIAL_TAB_BUSINESS_DETAILS_LABEL,
  },
  {
    key: FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_KEY,
    label: FINANCE_APPLICATION_COMMERCIAL_TAB_CONTACTS_LABEL,
  },
  // {
  //   key: FINANCE_APPLICATION_COMMERCIAL_TAB_GUARANTOR_KEY,
  //   label: FINANCE_APPLICATION_COMMERCIAL_TAB_GUARANTOR_LABEL,
  // },
  {
    key: FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_KEY,
    label: FINANCE_APPLICATION_COMMERCIAL_TAB_DOCUMENTS_LABEL,
  },
  {
    key: FINANCE_APPLICATION_COMMERCIAL_TAB_COMPLIANCE_KEY,
    label: FINANCE_APPLICATION_COMMERCIAL_TAB_COMPLIANCE_LABEL,
  },
  {
    key: FINANCE_APPLICATION_COMMERCIAL_TAB_CREDIT_REPORT_KEY,
    label: FINANCE_APPLICATION_COMMERCIAL_TAB_CREDIT_REPORT_LABEL,
  },
  {
    key: FINANCE_APPLICATION_COMMERCIAL_TAB_ASSET_DETAILS_KEY,
    label: FINANCE_APPLICATION_COMMERCIAL_TAB_ASSET_DETAILS_LABEL,
  },
  {
    key: FINANCE_APPLICATION_COMMERCIAL_TAB_LOAN_DETAILS_KEY,
    label: FINANCE_APPLICATION_COMMERCIAL_TAB_LOAN_DETAILS_LABEL,
  },
  {
    key: FINANCE_APPLICATION_COMMERCIAL_TAB_SETTLEMENT_KEY,
    label: FINANCE_APPLICATION_COMMERCIAL_TAB_SETTLEMENT_LABEL,
  },
];

export const ACCOUNTANT_NAME_KEY = 'name';
export const ACCOUNTANT_CONTACTNAME_KEY = 'contactName';
export const ACCOUNTANT_EMAIL_KEY = 'email';
export const ACCOUNTANT_PHONE_KEY = 'phone';

export const LABEL_BUSINESS_DOCUMENTS = 'Business documents';
export const LABEL_PLACEHOLDER_LOAN_AMOUNT_REQUIREMENT = '(Vehicle Purchase Price - Deposit - Trade in Equity)';

export const LABEL_FOR_OWNER_IS_NOT_PRESENT_IN_PORTAL = 'Owner is not created in portal';
export const LABEL_PRIVACY_FORM_CREDIT_GUIDE_AND_QUOTE = 'Privacy form, credit guide and quote';
export const LABEL_PRIVACY_FORM_CREDIT_GUIDE_AND_QUOTE_DESCRIPTION = 'Use this option to send privacy form, credit guide and credit quote to the current applicant for review and signature';
export const LABEL_PRIVACY_FORM_CREDIT_GUIDE_AND_QUOTE_SENT = 'Privacy form, credit guide and quote sent';
export const LABEL_PRIVACY_FORM_CREDIT_GUIDE_AND_QUOTE_DESCRIPTION_COMMERCIAL = 'Use this option to send privacy form to the current applicant for review and signature';

export const LABEL_PRIVACY_FORM_CREDIT_GUIDE_AND_QUOTE_SIGNED = 'Privacy form, credit guide and quote sent';
export const DOCUMENTS_SENT_AT = 'Documents sent at';
export const DOCUMENTS_SIGNED_AT = 'Documents signed at';
export const LABEL_SEND = 'Send';
export const LABEL_TRADING_LIABILITIES = 'Trade-in Liability';

/** Confirmable Credit Props */

export const LABEL_FOR_CONFIRMABLE_CREDIT_TYPE = 'Type';
export const LABEL_FOR_CONFIRMABLE_CREDIT_LENDER = 'Lender';
export const LABEL_FOR_CONFIRMABLE_CREDIT_LOAN_AMOUNT = 'Loan Amount';
export const LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_AMOUNT = 'Repayment Amount';
export const LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_LOAN_STATUS = 'Loan Status';
export const LABEL_FOR_CONFIRMABLE_CREDIT_REPAYMENT_END_DATE = 'End Date';

export const CONFIRMABLE_CREDIT_TYPE_PERSONAL_KEY = 'Personal';
export const CONFIRMABLE_CREDIT_TYPE_PERSONAL_VALUE = 'Personal';
export const CONFIRMABLE_CREDIT_TYPE_BUSINESS_KEY = 'Business';
export const CONFIRMABLE_CREDIT_TYPE_BUSINESS_VALUE = 'Business';

export const CONFIRMABLE_CREDIT_TYPE_LOAN_STATUS_ACTIVE_KEY = 'Active';
export const CONFIRMABLE_CREDIT_TYPE_LOAN_STATUS_ACTIVE_VALUE = 'Active';
export const CONFIRMABLE_CREDIT_TYPE_PAID_OUT_KEY = 'Paid Out';
export const CONFIRMABLE_CREDIT_TYPE_PAID_OUT_VALUE = 'Paid Out';

export const CONFIRMABLE_CREDIT_TYPE_OPTIONS = [
  {
    key: CONFIRMABLE_CREDIT_TYPE_PERSONAL_KEY,
    value: CONFIRMABLE_CREDIT_TYPE_PERSONAL_VALUE,
  },
  {
    key: CONFIRMABLE_CREDIT_TYPE_BUSINESS_KEY,
    value: CONFIRMABLE_CREDIT_TYPE_BUSINESS_VALUE,
  },
];

export const CONFIRMABLE_CREDIT_LOAN_STATUS_OPTIONS = [
  {
    key: CONFIRMABLE_CREDIT_TYPE_LOAN_STATUS_ACTIVE_KEY,
    value: CONFIRMABLE_CREDIT_TYPE_LOAN_STATUS_ACTIVE_VALUE,
  },
  {
    key: CONFIRMABLE_CREDIT_TYPE_PAID_OUT_KEY,
    value: CONFIRMABLE_CREDIT_TYPE_PAID_OUT_VALUE,
  },
];

export const LIABILITY_TYPE_CONFIRMABLE_CREDIT = 'Confirmable credit';
export const LOAN_TYPE_BUSINESS = 'Business';

export const ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LOAN_TYPE = 'Loan Type is required';
export const ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LENDER = 'Lender is required';
export const ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LOAN_AMOUNT = 'Loan Amount is required';
export const ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_LOAN_STATUS = 'Loan Status is required';
export const ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT = 'confirmable credit is required';
export const ERROR_MESSAGES_FOR_CONFIRMABLE_CREDIT_END_DATE = 'End Date is required';

export const LABEL_FOR_ADD_SECONDARY_APPLICANT_CONTACT = 'Add Contact';
export const LABEL_FOR_ADD_SECONDARY_APPLICANT_PARTNER = 'Add Partner';
export const LABEL_FOR_ADD_SECONDARY_APPLICANT_TRUSTEE = 'Add Trustee';
export const ERROR_MESSAGE_FOR_HAS_LIABILITY = 'Owned outright is required';
export const APPLICATION_UNDER_CONSTRUCTION_MESSAGE = 'Commercial application is under construction.';

export const LABEL_SUBMISSION_DETAILS_SAVED = 'Submission details saved';

export const LABEL_SUBMISSION_DETAILS_SAVED_DESCRIPTION = 'Submission details are saved at';

export const CONSUMER_APPLICANT_TYPE_LABELS = {
  SINGLE: 'Single',
  JOINT: 'Joint',
};

export const LEAD_SOURCE_SYSTEMS = {
  WEB_APP: 'Web App',
};

export const LABEL_BUSINESS_ABN_LESS_THAN_3_YEAR = 'Business ABN active date is less than 3 years. Please enter prior work history in the Employment section under Contacts tab';
export const LABEL_ADDING_PRIOR_EMPLOYMENT = 'Adding prior employment is';
export const LABEL_EMPLOYMENT_OPTIONAL = 'Optional';
export const LABEL_ADD_EMPLOYMENT = 'Use the option ';
export const LABEL_ADD_EMPLOYMENT_SECOND = '+ Add Employment';
export const LABEL_ADD_EMPLOYMENT_THIRD = 'to add one or more employment details';

export const LABEL_CHANGE_SETTLEMENT_AGENT = 'Change settlement agent';
export const LABEL_CHANGE_SETTLEMENT_AGENT_DESCRIPTION_FIRST = 'Are you sure you want to change the current settlement agent?';
export const LABEL_CHANGE_SETTLEMENT_AGENT_DESCRIPTION_SECOND = 'is currently assigned to settlement agent ';
export const ERROR_MESSAGE_FOR_NOTES_MAXIMUM_LENGTH = 'Notes can contain maximum 600 characters';
export const LABEL_ALLOWED_DIGITAL_SUBMISSION_LENDERS = ['Plenti'];
export const LABEL_VEHICLE_VALUATION = 'Vehicle valuation';
export const LABEL_GST_REGISTRATION = 'GST registration';
export const LABEL_ABN_LENGTH = 'ABN length';
export const LABEL_ABN = 'ABN';
export const LABEL_STRUCTURE = 'Structure';
export const LABEL_LAST_ENQUIRY = 'Last enquiry';
export const LABEL_COLLAPSE_MENU_HIDE = 'Hide';
export const LABEL_COLLAPSE_MENU_SHOW = 'Show';
export const ERROR_MESSAGE_FOR_INVALID_BALLOON_VALUE = 'Not valid more than';
export const ERROR_MESSAGE_FOR_INVALID_BALLOON_VALUE_ZERO_TO_HUNDRED = 'Please enter a number from 0 to 100';
export const LABEL_LOAN_TYPE = 'Type';
export const MAX_BROKER_FEE = 1990;
export const LABEL_APPLICATION_CANNOT_BE_SUBMITTED = 'Application can’t be submitted';
export const LABEL_APPLICATION_CANNOT_BE_SUBMITTED_DESCRIPTION = 'You can’t proceed with the submission of the application because the net surplus is either $0 or negative';
export const WEB_APP_JOURNEY = {
  STANDARD_V4_VERIFICATION: 'Standard V4 (Verification)',
  STANDARD_V3_VERIFICATION: 'Standard V3 (Verification)',
  STANDARD_V3_NO_VERIFICATION: 'Standard V3 (No Verification)',
  EXPRESS_VERIFICATION: 'Express (Verification)',
  EXPRESS_NO_VERIFICATION: 'Express (No Verification)',
};
export const SETTLEMENT_ETA_EDIT_LINK = 'Edit';
export const LABEL_PAYSLIP_MISSING_DOCUMENT = 'Payslip 1 / Bank Statement';
export const LABEL_REMOVE_EMPLOYMENT = 'Remove employment';
export const LABEL_DELETE_DOCUMENT = 'Delete document';
export const LABEL_DELETE_DOCUMENT_TYPE = 'Delete document type';
export const LABEL_DELETE_DOCUMENT_DESCRIPTION = 'Are you sure you want to delete ';
export const LABEL_DELETE_DOCUMENT_CUSTOMER_DASHBOARD = ' It will also be deleted in the applicant’s dashboard.';
export const LABEL_DELETE_DOCUMENT_TYPE_DESCRIPTION = 'By proceeding, all documents added in the above type will also be deleted. Do you want to continue?';
export const LABEL_DELETE_DOCUMENT_TYPE_DESCRIPTION_ALSO_CUSTOMER_DASHBOARD = 'By proceeding, all documents added in the above type will also be deleted here and in the applicant’s dashboard.';
export const LABEL_CONTINUE = 'Do you want to continue?';
export const LABEL_PURCHASE_PRICE = 'purchasePrice';
export const LABEL_LOAN_AMOUNT = 'loanAmount';
export const LABEL_TRADE_IN_AMOUNT = 'tradeInAmount';
export const LABEL_BALLOON_PCT = 'balloonPct';
export const LABEL_PARTNER_FINANCE_REF_FEE = 'partnerFinanceReferralFee';
export const LABEL_NET_AMOUNT_FINANCED = 'netAmountFinanced';
export const LABEL_DOCUMENT_VEHICLE_PURCHASE_PRICE = 'Vehicle Purchase Price';
export const LABEL_LOAN_TREM = 'Loan Term';
export const LABEL_MONTHLY_REPAYMENT_AMOUNT = 'Monthly Repayment Amount';
export const LABEL_BASE_RATE = 'Base Rate';
export const LABEL_LENDER_FEE = 'Lender Fee';
export const LABEL_ORIGINATION_FEE = 'Origination Fee';
export const LABEL_LENDER_MONTHLY_FEE = 'Lender Monthly Fee';
export const LABEL_PARTNER_REFERRAL_FEE = 'Partner Referral Fee';

export const LABEL_VEHCILE_BUILD_DATE = 'Vehicle Build Date';
export const LABEL_APPLICATION_DETAILS = 'Applicant details';
export const LABEL_PERSONAL = 'Personal';
export const LABEL_RESIDENCY_STATUS = 'Residency status';
export const LABEL_RESIDENTIAL = 'Residential';
export const LABEL_LIVING_SITUATION = 'Living situation';
export const LABEL_TIME_AT_CURRENT_ADDRESS = 'Time at current address';
export const LABEL_EMPLOYMENT = 'Employment';
export const LABEL_WORK_SITUATION = 'Work situation';
export const LABEL_EMPLOYEE = 'Employee';
export const LABEL_UNEMPLOYED = 'Unemployed';
export const LABEL_CREDIT_HISTORY_SCORES = 'CREDIT HISTORY & SCORES';
export const LABEL_FOR_CREDIT_HISTORY = 'CREDIT HISTORY';
export const LABEL_EQUIFAX = 'EQUIFAX';
export const LABEL_ADVERSE_CREDIT = 'Adverse credit?';
export const LABEL_EQUIFAX_COMPREHENSIVE = 'Equifax Comprehensive';
export const LABEL_EQUIFAX_ONE_SCORE = 'Equifax One Score';
export const LABEL_EMPLOYMENT_STATUS = 'Employment status';
export const LABEL_TIME_AT_CURRENT_EMPLOYMENT = 'Time at current employment';
export const LABEL_ABN_EFFECTIVE_FROM = 'ABN effective from';
export const LABEL_GST_EFFECTIVE_FROM = 'GST effective from';
export const LABEL_EQUIFAX_VEDA = 'Equifax Veda 1.1';
export const LABEL_REPAYMENTS = 'Repayments';
export const LABEL_FINANCE_FEE = 'Finance fee?';
export const LABEL_REPAYMENT_TIMING = 'Repayment timing';
export const LABEL_ARREARS = 'Arrears';
export const LABEL_ADVANCE = 'Advance';
export const LOAN_TYPE_QUICK_QUOTE = {
  CONSUMER: 'Consumer Car Loan',
  COMMERCIAL: 'Commercial Car Loan',
};

export const REQUEST_QUICK_QUOTE_LOAN_TYPES = generateOptionsWithSameValueAndLabel(Object.values(LOAN_TYPE_QUICK_QUOTE));

export const QUICK_QUOTE_LOAN_DETAILS = generateOptionsFromTypesWhereKeyIsSameAsValue(REQUEST_QUICK_QUOTE_LOAN_TYPES);

export const QUOTE_RESIDENCY_STATUS_TYPES = [
  {
    value: RESIDENCY_STATUS_AUSTRALIA_CITIZEN_VALUE,
    label: RESIDENCY_STATUS_AUSTRALIA_CITIZEN_VALUE,
  },
  {
    value: RESIDENCY_STATUS_PERMANENT_RESIDENT_VALUE,
    label: RESIDENCY_STATUS_PERMANENT_RESIDENT_VALUE,
  },
  {
    value: RESIDENCY_STATUS_VISA_457_VALUE,
    label: RESIDENCY_STATUS_VISA_457_VALUE,
  },
  {
    value: RESIDENCY_STATUS_VISA_OTHER_VALUE,
    label: RESIDENCY_STATUS_VISA_OTHER_VALUE,
  },
  {
    value: RESIDENCY_STATUS_VISA_UNSPECIFIED_VALUE,
    label: RESIDENCY_STATUS_VISA_UNSPECIFIED_VALUE,
  },
  {
    value: RESIDENCY_STATUS_NON_RESIDENT_VALUE,
    label: RESIDENCY_STATUS_NON_RESIDENT_VALUE,
  },
];

export const QUOTE_RESIDENCY_STATUS_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(QUOTE_RESIDENCY_STATUS_TYPES);

export const QUOTE_WORK_SITUATION = [
  {
    value: LABEL_EMPLOYEE,
    label: LABEL_EMPLOYEE,
  },
  {
    value: LABEL_UNEMPLOYED,
    label: LABEL_UNEMPLOYED,
  },
];

export const QUOTE_WORK_SITUATION_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(QUOTE_WORK_SITUATION);

export const LABEL_PAID_DEFAULTS = 'Paid defaults';
export const LABEL_UNPAID_DEFAULTS = 'Unpaid defaults';
export const LABEL_EX_BANKRUPT = 'Ex-Bankrupt';

export const QUOTE_CREDIT_HISTORY = [
  {
    value: LABEL_PAID_DEFAULTS,
    label: LABEL_PAID_DEFAULTS,
  },
  {
    value: LABEL_UNPAID_DEFAULTS,
    label: LABEL_UNPAID_DEFAULTS,
  },
  {
    value: LABEL_EX_BANKRUPT,
    label: LABEL_EX_BANKRUPT,
  },
];
export const QUOTE_CREDIT_HISTORY_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(QUOTE_CREDIT_HISTORY);

export const QUOTE_BUSINESS_STRUCTURE_VALUES = [
  {
    label: LABEL_BUSINESS_STRUCTURE_COMPANY,
    value: LABEL_BUSINESS_STRUCTURE_COMPANY,
  },
  {
    label: LABEL_BUSINESS_STRUCTURE_SOLE_TRADER,
    value: LABEL_BUSINESS_STRUCTURE_SOLE_TRADER,
  },

  {
    label: LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
    value: LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
  },
  {
    label: LABEL_BUSINESS_STRUCTURE_TRUST,
    value: LABEL_BUSINESS_STRUCTURE_TRUST,
  },
];
export const QUOTE_BUSINESS_STRUCTURE_VALUES_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(QUOTE_BUSINESS_STRUCTURE_VALUES);

export const LABEL_JANUARY_MONTH = 'January';
export const QUOTE_MONTH = [
  {
    value: 1,
    label: 'January',
  },
  {
    value: 2,
    label: 'February',
  },
  {
    value: 3,
    label: 'March',
  },
  {
    value: 4,
    label: 'April',
  },
  {
    value: 5,
    label: 'May',
  },
  {
    value: 6,
    label: 'June',
  },
  {
    value: 7,
    label: 'July',
  },
  {
    value: 8,
    label: 'August',
  },
  {
    value: 9,
    label: 'September',
  },
  {
    value: 10,
    label: 'October',
  },
  {
    value: 11,
    label: 'November',
  },
  {
    value: 12,
    label: 'December',
  },
];
export const QUOTE_MONTH_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(QUOTE_MONTH);

export const QUOTE_REPAYMENT_TIMING = [
  {
    value: LABEL_ARREARS,
    label: LABEL_ARREARS,
  },
  {
    value: LABEL_ADVANCE,
    label: LABEL_ADVANCE,
  },
];
export const QUOTE_REPAYMENT_TIMINGY_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(QUOTE_REPAYMENT_TIMING);

export const QUOTE_SALE_TYPES = [
  {
    label: SALE_TYPE_PRIVATE,
    value: SALE_TYPE_PRIVATE,
  },
  {
    label: SALE_TYPE_DEALER,
    value: SALE_TYPE_DEALER,
  },
  {
    label: SALE_BUY_BACK,
    value: SALE_BUY_BACK,
  },
  {
    label: SALE_BALLOON_REFINANCE,
    value: SALE_BALLOON_REFINANCE,
  },
  {
    label: SALE_MIDSTREAM_REFINANCE,
    value: SALE_MIDSTREAM_REFINANCE,
  },
];

export const QUOTE_SALE_TYPE_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(QUOTE_SALE_TYPES);
export const ALLOWED_MAX_FILE_SIZE = '10485760';
export const ERROR_MESSAGE_FOR_FILE_SIZE = 'Maximum allowed file size is 10MB. Please add another document.';
export const LABEL_DELETE_SECONDARY_APPLICANT_TITLE = 'Delete secondary applicant';
export const LABEL_DELETE_SECONDARY_APPLICANT_DESCRIPTION = 'Unable to delete secondary applicant <b><applicantName></b>. Delete any associated secondary applicant documents or any shared documents and try again.';
export const LABEL_FOR_READY = 'ready';

export const LABEL_FOR_GRID = 'grid';
export const LABEL_FOR_KANBAN = 'kanban';
export const LABEL_FOR_TABLE = 'table';
