import { format, differenceInCalendarYears, differenceInYears } from 'date-fns';
import {
  ERROR_MESSAGE_FOR_INVALID_BALLOON,
  ERROR_MESSAGE_FOR_INVALID,
  ERROR_MESSAGE_FOR_NUMBER_LENGTH,
  ERROR_MESSAGE_FOR_NUMBER_ONLY,
  ERROR_MESSAGE_FOR_MAX_AMOUNT,
  ERROR_MESSAGE_AMOUNT_CANNOT_BE_MORE_THAN,
  ERROR_MESSAGE_AMOUNT_CANNOT_BE_EQUAL_TO,
  ERROR_MESSAGE_FOR_VALID_ABN,
  ERROR_MESSAGE_FOR_DEPOSIT,
  ERROR_MESSAGE_FOR_REQUIRED_FIELD,
  ERROR_FOR_MAX_NUMBER_REACHED,
  ERROR_MESSAGE_FOR_VALID_TRUSTEE_ACN,
  ERROR_MESSAGE_FOR_DISTINCT_MOBILE_NUM,
  REGEX_FOR_AUS_PHONE,
  ERROR_MESSAGE_FOR_INVALID_MOBILE,
  ERROR_MESSAGE_FOR_ZERO_AMOUNT,
  ERROR_MESSAGE_FOR_FIELD_VALUE_GREATER_THAN_ZERO,
  ERROR_MESSAGE_FOR_MAX_BROKER_FEE,
  ERROR_MESSAGE_FOR_WARRENTY_RETAIL_PRICE,
} from '../constants';
import { ERROR_MESSAGE_FOR_INVALID_BALLOON_VALUE, MAX_BROKER_FEE, ERROR_MESSAGE_FOR_INVALID_BALLOON_VALUE_ZERO_TO_HUNDRED } from '../constants/applications';
import { numbersOnly, mobileWithoutCountryCode } from './mobile';
import { postcodeToState } from './address';

export const validateBrokerFee = (value: string | number | null | undefined, callback: any) => {
  if (value === null || value === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD));
  } else if (Number.isNaN(Number(value))) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  } else if (Number(value) < 0) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID));
  } else if (Number.parseInt(value.toString(), 10) < 0 || Number.parseInt(value.toString(), 10) > Number.parseInt(MAX_BROKER_FEE.toString(), 10)) {
    callback(new Error(ERROR_MESSAGE_FOR_MAX_BROKER_FEE + MAX_BROKER_FEE));
  } else {
    callback();
  }
};

export const isMobile = (value: string | undefined) => {
  const mobileNumbersOnly = Number(numbersOnly(value));
  const mobileWithoutCountryCode_ = mobileWithoutCountryCode(mobileNumbersOnly.toString());
  const isValidMobile = mobileWithoutCountryCode_.startsWith('0') ? /^04\d{8}$/.test(mobileWithoutCountryCode_) : /^4\d{8}$/.test(mobileWithoutCountryCode_);
  if (isValidMobile) {
    return;
  }
  return 'Please input valid mobile number';
};

export const isPostcodeValid = (postcode: string | undefined) => Boolean(postcodeToState(postcode));

export const isValidPhoneNum = (phonenumber: string) => {
  const validPhone = /^(0\d{9})|(614\d{8}|04\d{8})$/;
  return validPhone.test(phonenumber);
};

export const isValidABNNum = (abnNumber: string) => {
  const validABN = /^[0-9]{11}$/;
  return validABN.test(abnNumber);
};

export const isDateOfBirthGTE18Years = (date: string | number | Date) => {
  const day = parseInt(format(new Date(date), 'dd'), 10);
  const month = parseInt(format(new Date(date), 'MM'), 10);
  const year = parseInt(format(new Date(date), 'yyyy'), 10);
  const birthDate = new Date(year, month - 1, day);
  return differenceInCalendarYears(new Date(), birthDate) >= 18;
};

export const isdateOfBirthGTE18YearsAndLessThan110Years = (date: string | number | Date) => {
  const day = parseInt(format(new Date(date), 'dd'), 10);
  const month = parseInt(format(new Date(date), 'MM'), 10);
  const year = parseInt(format(new Date(date), 'yyyy'), 10);
  const birthDate = new Date(year, month - 1, day);
  return differenceInYears(new Date(), birthDate) >= 18 && differenceInYears(new Date(), birthDate) <= 110;
};
export const isdateLessThan10Years = (date: string | number | Date) => {
  const day = parseInt(format(new Date(date), 'dd'), 10);
  const month = parseInt(format(new Date(date), 'MM'), 10);
  const year = parseInt(format(new Date(date), 'yyyy'), 10);
  const MainDate = new Date(year, month - 1, day);
  return differenceInCalendarYears(MainDate, new Date()) <= 10;
};

export const validateReferralBalloonPct = (_: any, value: any, callback: any) => {
  try {
    const amount = Number(value);
    if (amount >= 0 && amount <= 60) {
      callback();
    } else {
      callback(new Error(ERROR_MESSAGE_FOR_INVALID_BALLOON));
    }
  } catch {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID_BALLOON));
  }
};

export const checkExpiryOfApproval = (date: string | number | Date) => {
  const currentDate = new Date();
  const selectedDate = new Date(date);
  const currentTime = Date.now();
  const d = currentDate.getDate();
  currentDate.setMonth(currentDate.getMonth() + 3);
  if (currentDate.getDate() !== d) {
    currentDate.setDate(0);
  }
  return currentDate.getTime() <= selectedDate.getTime() || currentTime >= selectedDate.getTime();
};

export const validateNumber = (_rule: any, value: any, callback: any, comparisonAmount: any) => {
  if (Number.isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  }

  if (Number.parseInt(value, 10).toString().length > 10) {
    return callback(new Error(ERROR_MESSAGE_FOR_MAX_AMOUNT));
  }
  if (Number.parseInt(value, 10) > Number.MAX_SAFE_INTEGER) {
    return callback(new Error(ERROR_FOR_MAX_NUMBER_REACHED));
  }

  if (comparisonAmount && Number.parseInt(value, 10) > Number.parseInt(comparisonAmount, 10)) {
    return callback(new Error(`${ERROR_MESSAGE_AMOUNT_CANNOT_BE_MORE_THAN}${comparisonAmount}`));
  }

  if (Number.parseInt(value, 10) === Number.parseInt(comparisonAmount, 10)) {
    return callback(new Error(`${ERROR_MESSAGE_AMOUNT_CANNOT_BE_EQUAL_TO}${comparisonAmount}`));
  }

  if (value === null || value === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_DEPOSIT));
  }
  callback();
};

export const validatePositiveNumber = (value: string, callback: any) => {
  const checkVal = parseFloat(value).toFixed(2);

  if (Number.isNaN(checkVal)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  } else if (Number(checkVal) && Number(checkVal) < 0) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID));
  } else if (`${checkVal.split('.')[0]}`.length > 10) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_LENGTH));
  } else if (Number(checkVal) > Number.MAX_SAFE_INTEGER) {
    callback(new Error(ERROR_FOR_MAX_NUMBER_REACHED));
  } else if (checkVal === null || checkVal === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD));
  } else {
    callback();
  }
};

export const validateAmountShouldGreaterThanZero = (value: string, callback: any) => {
  const checkVal = value ? parseFloat(value).toFixed(2) : value;

  if (Number(checkVal) <= 0) {
    callback(new Error(ERROR_MESSAGE_FOR_FIELD_VALUE_GREATER_THAN_ZERO));
  } else if (Number.isNaN(checkVal)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  } else if (String(checkVal).length > 10) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_LENGTH));
  } else if (checkVal === null || checkVal === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD));
  } else {
    callback();
  }
};

export const validateNumericField = (value: string, callback: any) => {
  const checkVal = parseFloat(value).toFixed(2);

  if (Number.isNaN(checkVal)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  } else if (Number(checkVal) && Number(checkVal) <= 0) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID));
  } else if (String(Number.parseInt(checkVal, 10)).length > 10) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_LENGTH));
  } else if (!checkVal) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD));
  } else if (Number.parseInt(checkVal, 10) === 0) {
    callback(new Error(ERROR_MESSAGE_FOR_ZERO_AMOUNT));
  } else {
    callback();
  }
};

export const validatePercentageWithGreaterThanZero = (value: any, callback: any, defaultErrorMessage: any) => {
  if (value === null || value === undefined || value === '') {
    callback(defaultErrorMessage);
  } else if (Number.isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  } else if (Number.parseInt(value, 10) <= 0 || value > 100) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID));
  } else {
    callback();
  }
};

export const validatePercentage = (value: any, callback: any, defaultErrorMessage: any) => {
  if (value === null || value === undefined) {
    callback(defaultErrorMessage);
  } else if (Number.isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  } else if (Number.parseInt(value, 10) < 0 || value > 100) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID));
  } else {
    callback();
  }
};

export const validateBalloonPercentage = (value: any, callback: any, defaultErrorMessage: any, maxBalloonPct = 100) => {
  if (!value && value !== 0) {
    callback(defaultErrorMessage);
  } else if (Number.isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  } else if (Number.parseInt(value, 10) < 0 || value > maxBalloonPct) {
    callback(new Error(`${ERROR_MESSAGE_FOR_INVALID_BALLOON_VALUE} ${maxBalloonPct}%`));
  } else {
    callback();
    // TO FULFILLED THE PURPOSE OF THIS TICKET - https://carclarity.atlassian.net/browse/WEB-4451
    // BELOW CODE IS COMMENTED
    // checkLoanYearWithBalloon(callback, value, loanYears)
  }
};
export const validateBalloonPercentageWithGreaterThanZero = (value: any, callback: any, defaultErrorMessage: any, maxBalloonPct = 100) => {
  if (!value && value !== 0) {
    callback(defaultErrorMessage);
  } else if (Number.isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  } else if (Number.parseInt(value, 10) < 0 || value > maxBalloonPct) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID_BALLOON_VALUE_ZERO_TO_HUNDRED));
  } else {
    callback();
    // TO FULFILLED THE PURPOSE OF THIS TICKET - https://carclarity.atlassian.net/browse/WEB-4451
    // BELOW CODE IS COMMENTED
    // checkLoanYearWithBalloon(callback, value, loanYears)
  }
};

export const checkAmount = (_rule: any, value: any, callback: any, comparisonAmount: any) => {
  if (Number.isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  }

  if (Number.parseInt(value, 10).toString().length > 10) {
    return callback(new Error(ERROR_MESSAGE_FOR_MAX_AMOUNT));
  }
  if (Number.parseInt(value, 10) > Number.MAX_SAFE_INTEGER) {
    return callback(new Error(ERROR_FOR_MAX_NUMBER_REACHED));
  }

  if (comparisonAmount && Number.parseInt(value, 10) > Number.parseInt(comparisonAmount, 10)) {
    return callback(new Error(`${ERROR_MESSAGE_AMOUNT_CANNOT_BE_MORE_THAN}${comparisonAmount}`));
  }

  if (Number.parseInt(value, 10) === Number.parseInt(comparisonAmount, 10)) {
    return callback(new Error(`${ERROR_MESSAGE_AMOUNT_CANNOT_BE_EQUAL_TO}${comparisonAmount}`));
  }
  if (Number(value) < 0) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID));
  }
  if (value === null || value === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_DEPOSIT));
  }
  callback();
};

export const validateABN = (value: string | number | any[], callback: (arg0: Error) => void, defaultErrorMessage: any) => {
  if (!value) {
    callback(defaultErrorMessage);
  } else if (Number.isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  } else if (value.toString().length <= 0 || value.toString().length > 15) {
    callback(new Error(ERROR_MESSAGE_FOR_VALID_ABN));
  }
};

export const validateTrusteeACN = (value: string | number, callback: (arg0: Error) => void, defaultErrorMessage: any) => {
  if (!value) {
    callback(defaultErrorMessage);
  } else if (Number.isNaN(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_ONLY));
  } else if (value.toString().split('.')[0].length <= 0 || value.toString().split('.')[0].length > 9) {
    callback(new Error(ERROR_MESSAGE_FOR_VALID_TRUSTEE_ACN));
  }
};

export const validateDistinctMobileNumber = (value: string, callback: any, currentMobileNumber: string, existingMobileNumbers: string[]) => {
  if (value && value.length > 0 && !value.match(REGEX_FOR_AUS_PHONE)) {
    return callback(ERROR_MESSAGE_FOR_INVALID_MOBILE);
  }
  let mobile = `${value}`;

  mobile = stripMobileNumberFormat(mobile);
  currentMobileNumber = stripMobileNumberFormat(`${currentMobileNumber}`);
  existingMobileNumbers = existingMobileNumbers.map((mobileNumber) => stripMobileNumberFormat(mobileNumber)).filter((mobileNumber) => mobileNumber !== currentMobileNumber);
  const isMobileNumberDistinct = !existingMobileNumbers.filter((mobileNumber) => mobileNumber === mobile).length;
  if (!isMobileNumberDistinct) {
    return callback(new Error(ERROR_MESSAGE_FOR_DISTINCT_MOBILE_NUM));
  }
  return callback();
};

const stripMobileNumberFormat = (mobileNumber: string) => {
  if (mobileNumber && mobileNumber.indexOf('0') === 0) {
    return mobileNumber.substring(1);
  }
  if (mobileNumber && mobileNumber.indexOf('61') === 0) {
    return mobileNumber.substring(2);
  }
  return mobileNumber;
};
export const validateWarrentyRetailPrice = (_rule: any, value: string, callback: any, warrentyPrice: string) => {
  if (parseFloat(warrentyPrice) > parseFloat(value)) {
    callback(new Error(ERROR_MESSAGE_FOR_WARRENTY_RETAIL_PRICE));
  } else {
    return callback();
  }
};
export const validateAssestLiabilityType = (assets: any[], id: any) => {
  const data = assets.find((x) => x.liability === id);
  return data && data.hasLiability;
};
export const validatePositiveNumberWithZero = (value: string, callback: any) => {
  const checkVal = parseFloat(value).toFixed(2);
  if (Number(checkVal) && Number(checkVal) < 0) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID));
  } else if (`${checkVal.split('.')[0]}`.length > 10) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_LENGTH));
  } else if (Number(checkVal) > Number.MAX_SAFE_INTEGER) {
    callback(new Error(ERROR_FOR_MAX_NUMBER_REACHED));
  } else if (checkVal === null || checkVal === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD));
  } else {
    callback();
  }
};

export const validateForEquifaxScore = (value: any, callback: any) => {
  const minScore = -999;
  const maxScore = 1200;
  const checkVal = Number(value).toFixed(2);
  if (`${checkVal.split('.')[0]}`.length > 10) {
    callback(new Error(ERROR_MESSAGE_FOR_NUMBER_LENGTH));
  } else if (Number(checkVal) < minScore || Number(checkVal) > maxScore) {
    callback(new Error(ERROR_FOR_MAX_NUMBER_REACHED));
  } else if (checkVal === null || checkVal === undefined) {
    callback(new Error(ERROR_MESSAGE_FOR_REQUIRED_FIELD));
  } else if (Number.isNaN(checkVal)) {
    callback(new Error(ERROR_MESSAGE_FOR_INVALID));
  } else {
    callback();
  }
};
