import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Collapse, IconButton, Grid, Divider, FormControlLabel, Checkbox, TextField, Dialog, DialogTitle, DialogActions, DialogContent, Backdrop, CircularProgress, Autocomplete, FormLabel, FormControl } from '@mui/material';
import { ExpandLess, ExpandMore, Search } from '@mui/icons-material';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { datadogLogs } from '@datadog/browser-logs';
import axios from 'axios';
import axiosInstance from '../../axiosInstance';
import DynamicForm, { FormField } from '../../shared/Form';
import Button from '../../shared/Button';
import theme from '../../theme';
import { APPLICANT_STATE_OPTIONS, SALE_TYPE_OPTIONS } from '../../constants/applications';
import { isMobile } from '../../helpers/validators';
import {
  ERROR_MESSAGE_FOR_ABN,
  ERROR_MESSAGE_FOR_BUSINESS_NAME,
  ERROR_MESSAGE_FOR_BUSINESS_STRUCTURE,
  ERROR_MESSAGE_FOR_EMAIL,
  ERROR_MESSAGE_FOR_FIRST_NAME,
  ERROR_MESSAGE_FOR_INVALID_EMAIL,
  ERROR_MESSAGE_FOR_LAST_NAME,
  ERROR_MESSAGE_FOR_PHONE,
  ERROR_MESSAGE_FOR_PREFERRED_COMMUNICATION_METHOD,
  ERROR_MESSAGE_FOR_STATE,
  REGEX_FOR_EMAIL,
} from '../../constants/index';
import { DEFAULT_VEHICLE_GROUP_OBJECT, DEFAULT_VEHICLE_OBJECT, getConsentText, LABEL_TYPE_CONSENT_PRIVACY } from '../../constants/referrals';
import ThankYou from '../../components/ThankYou';
import SnackbarAlert, { SnackBarProps } from '../../shared/SnackbarAlert';

/* eslint-disable */
const QuickReferralConsumer: React.FC = () => {
  const methods = useForm();
  const { reset, control, watch, setValue } = methods;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const storedUser = JSON.parse(localStorage.getItem('user') || '{}');
  const referrerUser = storedUser._id;
  const referrerAccount = storedUser.accountRelations?.find((acc: any) => acc.isActive)?.account?._id || '';
  const isSuccessParam = queryParams.get('isSuccess') === 'true';
  const isCommercial = location.pathname.includes('commercial');
  const applicationType = isCommercial ? 'Commercial' : 'Consumer';

  const [isCustomerDetailsOpen, setIsCustomerDetailsOpen] = useState<boolean>(true);
  const [isAssetDetailsOpen, setIsAssetDetailsOpen] = useState<boolean>(true);
  const [isNoteDetailsOpen, setIsNoteDetailsOpen] = useState<boolean>(true);
  const [isBusinessDetailsOpen, setIsBusinessDetailsOpen] = useState<boolean>(true);
  const [makeOptions, setMakeOptions] = useState<{ value: string; label: string }[]>([]);
  const [modelOptions, setModelOptions] = useState<{ value: string; label: string }[]>([]);
  const [yearOptions, setYearOptions] = useState<{ value: string; label: string }[]>([]);
  const [modelTypeOptions, setModelTypeOptions] = useState<{ value: string; label: string; code: string }[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBusinessLoading, setIsBusinessLoading] = useState<boolean>(false);
  const [isManualEntry, setIsManualEntry] = useState<boolean>(false);
  const [isManualEntryBusiness, setIsManualEntryBusiness] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(isSuccessParam);
  const [snackbarAlert, setSnackbarAlert] = useState<SnackBarProps>({
    open: false,
    message: '',
    severity: 'error',
    onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
  });
  const [businessOptions, setBusinessOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const selectedMake = watch('make');
  const selectedModel = watch('model');
  const selectedYear = watch('year');
  const selectedModelType = watch('modelType');

  const toggleCustomerDetails = () => setIsCustomerDetailsOpen(!isCustomerDetailsOpen);
  const toggleAssetDetails = () => setIsAssetDetailsOpen(!isAssetDetailsOpen);
  const toggleNotesDetails = () => setIsNoteDetailsOpen(!isNoteDetailsOpen);
  const toggleBusinessDetails = () => setIsBusinessDetailsOpen(!isBusinessDetailsOpen);

  const stateOptions = APPLICANT_STATE_OPTIONS.map((option: { value: any; label: any }) => ({
    value: option.value,
    label: option.label,
  }));

  const fetchMakeOptions = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL + '/vehicles-meta-data/makes');
      return response.data.map((make: { makeCode: any; description: any }) => ({
        value: make.makeCode,
        label: make.description,
      }));
    } catch (error) {
      console.error('Failed to fetch make options:', error);
      return [];
    }
  };

  const fetchModelOptions = async (makeCode: string) => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL + `/vehicles-meta-data/models?makeCode=${makeCode}`);
      return response.data.map((model: { familyCode: any; description: any }) => ({
        value: model.familyCode,
        label: model.description,
      }));
    } catch (error) {
      console.error('Failed to fetch model options:', error);
      return [];
    }
  };

  const fetchYearOptions = async (makeCode: string, modelCode: string) => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL + `/vehicles-meta-data/years?makeCode=${makeCode}&familyCode=${modelCode}`);
      return response.data.map((year: any) => ({
        value: year.toString(),
        label: year.toString(),
      }));
    } catch (error) {
      console.error('Failed to fetch year options:', error);
      return [];
    }
  };

  const fetchModelTypeOptions = async (makeCode: string, modelCode: string, year: string) => {
    try {
      const response = await axios.get(process.env.REACT_APP_SERVER_URL + `/vehicles-meta-data/vehicles?makeCode=${makeCode}&familyCode=${modelCode}&year=${year}`);
      return response.data.map((modelType: { description: any; code: any }) => ({
        value: modelType.description,
        label: modelType.description,
        code: modelType.code,
      }));
    } catch (error) {
      console.error('Failed to fetch model type options:', error);
      return [];
    }
  };

  const fetchBusinessOptions = useCallback(
    debounce(async (query: string) => {
      try {
        setIsBusinessLoading(true);
        const response = await axiosInstance.get(process.env.REACT_APP_SERVER_URL + `/businesses/search?query=${query}&excludeCancelled=true`);
        const businessData = response?.data;
        if (businessData.length !== 0) {
          setBusinessOptions(businessData);
        } else {
          setBusinessOptions([]);
        }
        setIsBusinessLoading(false);
      } catch (error) {
        setBusinessOptions([]);
        setIsBusinessLoading(false);
      }
    }, 300),
    []
  );

  const fetchBusinessDetails = async (abn: string) => {
    try {
      setIsBusinessLoading(true);
      const response = await axiosInstance.get(process.env.REACT_APP_SERVER_URL + `/businesses/search-by-abn?abn=${abn}`);
      const businessDetail = response?.data[0];
      setValue('businessName', businessDetail?.mainName?.organisationName);
      setValue('abn', businessDetail?.ABN?.identifierValue);
      setValue('businessStructure', businessDetail?.businessStructure);
      setIsManualEntryBusiness(false);
      setInputValue('');
      setIsBusinessLoading(false);
    } catch (error) {
      console.error('Failed to fetch business details:', error);
      setIsBusinessLoading(false);
    }
  };

  useEffect(() => {
    setIsSuccess(isSuccessParam);
  }, [isSuccessParam]);

  useEffect(() => {
    const fetchMakes = async () => {
      const makes = await fetchMakeOptions();
      setMakeOptions(makes);
    };
    fetchMakes();
  }, []);

  useEffect(() => {
    const fetchModels = async () => {
      if (selectedMake) {
        const models = await fetchModelOptions(selectedMake);
        setModelOptions(models);
        setValue('model', '');
        setValue('year', '');
        setValue('modelType', '');
      }
    };
    fetchModels();
  }, [selectedMake]);

  useEffect(() => {
    const fetchYears = async () => {
      if (selectedMake && selectedModel) {
        const years = await fetchYearOptions(selectedMake, selectedModel);
        setYearOptions(years);
        setValue('year', '');
        setValue('modelType', '');
      }
    };
    fetchYears();
  }, [selectedModel]);

  useEffect(() => {
    const fetchModelTypes = async () => {
      if (selectedMake && selectedModel && selectedYear) {
        const modelTypes = await fetchModelTypeOptions(selectedMake, selectedModel, selectedYear);
        setModelTypeOptions(modelTypes);
        setValue('modelType', '');
      }
    };
    fetchModelTypes();
  }, [selectedYear]);

  useEffect(() => {
    const selectedOption = makeOptions.find((option) => option.value === selectedMake);
    if (selectedOption) {
      setValue('makeDescription', selectedOption?.label);
    }
  }, [selectedMake, makeOptions, setValue]);

  useEffect(() => {
    const selectedOption = modelOptions.find((option) => option.value === selectedModel);
    if (selectedOption) {
      setValue('modelDescription', selectedOption?.label);
    }
  }, [selectedModel, modelOptions, setValue]);

  useEffect(() => {
    const selectedOption = modelTypeOptions.find((option) => option.value === selectedModelType);
    if (selectedOption) {
      setValue('code', selectedOption?.code);
    }
  }, [selectedModelType, modelTypeOptions, setValue]);

  const validatePhone = (phoneNumber: string): string | undefined => {
    if (!phoneNumber) {
      return ERROR_MESSAGE_FOR_PHONE;
    }
    return isMobile(phoneNumber) ? isMobile(phoneNumber) : '';
  };

  const validateEmail = (email: string): string => {
    if (!email) {
      return ERROR_MESSAGE_FOR_EMAIL;
    }
    return REGEX_FOR_EMAIL.test(email) ? '' : ERROR_MESSAGE_FOR_INVALID_EMAIL;
  };

  const formFields: FormField[] = [
    { label: 'First name', name: 'firstName', type: 'text', placeHolder: 'Enter first name', errorMessage: ERROR_MESSAGE_FOR_FIRST_NAME, isRequired: true },
    { label: 'Last name', name: 'lastName', type: 'text', placeHolder: 'Enter last name', errorMessage: ERROR_MESSAGE_FOR_LAST_NAME, isRequired: true },
    { label: 'Mobile', name: 'mobile', type: 'phoneNumber', placeHolder: '04XXXXXXXX', validateField: validatePhone, isRequired: true, isNumber: true },
    { label: 'Email', name: 'email', type: 'email', placeHolder: 'name@domain.com', validateField: validateEmail, isRequired: true },
    { label: 'State', name: 'state', type: 'select', placeHolder: 'Select', errorMessage: ERROR_MESSAGE_FOR_STATE, isRequired: true, options: stateOptions },
    {
      label: 'Preferred customer communication method',
      name: 'communicationMethod',
      type: 'select',
      placeHolder: 'Select',
      isRequired: true,
      options: [
        { value: 'Email', label: 'Email' },
        { value: 'Phone', label: 'Phone' },
        { value: 'SMS', label: 'SMS' },
        { value: 'Any', label: 'Any' },
      ],
      errorMessage: ERROR_MESSAGE_FOR_PREFERRED_COMMUNICATION_METHOD,
      multiple: true,
    },
  ];
  const assetFields: FormField[] = [
    { label: 'Sale type', name: 'saleType', type: 'select', placeHolder: 'Select', options: SALE_TYPE_OPTIONS.map((option: { value: string }) => ({ value: option.value, label: option.value })) },
    { label: 'Make', name: 'make', type: 'select', placeHolder: 'Select', options: makeOptions, isDisabled: isManualEntry },
    { label: 'Model', name: 'model', type: 'select', placeHolder: 'Select', options: modelOptions, isDisabled: !selectedMake || isManualEntry },
    { label: 'Year', name: 'year', type: 'select', placeHolder: 'Select', options: yearOptions, isDisabled: !selectedModel || isManualEntry },
    { label: 'Model Type', name: 'modelType', type: 'select', placeHolder: 'Select', options: modelTypeOptions, isDisabled: !selectedYear || isManualEntry },
  ];

  const businessFields: FormField[] = [
    { label: 'Business name', name: 'businessName', type: 'text', placeHolder: 'Business name', errorMessage: ERROR_MESSAGE_FOR_BUSINESS_NAME, isRequired: true, isDisabled: !isManualEntryBusiness },
    { label: 'ABN', name: 'abn', type: 'text', placeHolder: '11 222 333 444', errorMessage: ERROR_MESSAGE_FOR_ABN, isRequired: true, isDisabled: !isManualEntryBusiness },
    { label: 'Business structure', name: 'businessStructure', type: 'text', placeHolder: 'Business structure', errorMessage: ERROR_MESSAGE_FOR_BUSINESS_STRUCTURE, isRequired: true, isDisabled: !isManualEntryBusiness },
  ];

  const handleManualEntryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsManualEntry(event.target.checked);
    if (event.target.checked) {
      setValue('make', '');
      setValue('model', '');
      setValue('year', '');
      setValue('modelType', '');
      setValue('code', '');
      setValue('modelDescription', '');
      setValue('makeDescription', '');
    }
  };

  const handleManualEntryBusinessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsManualEntryBusiness(event.target.checked);
    if (event.target.checked) {
      setValue('businessName', '');
      setValue('abn', '');
      setValue('businessStructure', '');
    }
  };

  const onSubmit = (formData: Record<string, any>) => {
    const validationErrors = formFields.reduce(
      (errors, field) => {
        if (field.validateField) {
          const errorMessage = field.validateField(formData[field.name]);
          if (errorMessage) {
            errors[field.name] = errorMessage;
          }
        }

        return errors;
      },
      {} as Record<string, string>
    );

    if (Object.keys(validationErrors).length > 0) {
      Object.entries(validationErrors).forEach(([name, error]) => {
        methods.setError(name, { type: 'manual', message: error });
      });
      return;
    }
    setIsLoading(true);
    try {
      setIsShowDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setIsShowDialog(false);
  };

  async function upsertUser() {
    let data = null;
    const upsertUserPayload = {
      background: watch('notes'),
      email: watch('email'),
      firstName: watch('firstName'),
      lastName: watch('lastName'),
      mailingAddress: { state: watch('state') },
      mobile: watch('mobile'),
      preferredCommunicationMethods: watch('communicationMethod'),
    };
    try {
      const createUserAPIUrl = process.env.REACT_APP_SERVER_URL + '/user/upsert';
      const response = await axiosInstance.post(createUserAPIUrl, upsertUserPayload);
      data = response.data;
      return data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
      datadogLogs.logger.error('Error upserting user', upsertUserPayload, errorMessage);
      setSnackbarAlert({
        open: true,
        message: errorMessage,
        severity: 'error',
        onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
      });
      return null;
    }
  }

  async function createVRG(userId: string) {
    let data = null;
    const createVehicleRequestGroupAPIUrl = process.env.REACT_APP_SERVER_URL + '/vehicle-request-groups';
    const vehicleGroupPayload = {
      ...DEFAULT_VEHICLE_GROUP_OBJECT({ applicationType }),
      primaryUser: userId,
      referrerUser,
      referrerAccount,
      hasTradeIn: null,
      wantsFinance: '',
      account: '',
      externalReferrer: '',
      fBCLID: '',
      featureBranch: '',
      gCLID: '',
      googleTransactionId: '',
      httpReferrer: '',
      initialLeadFunnel: 'Finance',
      isAppReady: true,
      lastClickUTMCampaign: '',
      lastClickUTMContent: '',
      lastClickUTMId: '',
      lastClickUTMMedium: '',
      lastClickUTMSource: '',
      lastClickUTMTerm: '',
      leadSource: '',
      leadSourceSystem: 'Referral Portal',
      partnerId: '',
      partnerOwnSystemLeadId: '',
      primaryUserAgreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt: null,
      referralMode: 'quick',
      startedAt: null,
      tradeIn: null,
      urgency: '',
      vehicleSourced: '',
      finance: {
        ...DEFAULT_VEHICLE_GROUP_OBJECT({ applicationType }).finance,
        application: {
          ...DEFAULT_VEHICLE_GROUP_OBJECT({ applicationType }).finance.application,
          loanType: isCommercial ? 'Commercial Car Loan' : 'Consumer Car Loan',
          businessData: {
            ...DEFAULT_VEHICLE_GROUP_OBJECT({ applicationType }).finance.application.businessData,
            aBN: watch('abn'),
            businessName: watch('businessName'),
            businessStructure: watch('businessStructure'),
          },
          vehicle: null,
          primaryUser: userId,
          primaryApplicantData: {
            ...DEFAULT_VEHICLE_GROUP_OBJECT({ applicationType }).finance.application.primaryApplicantData,
            primaryApplicant: userId,
          },
        },
      },
    };
    try {
      const response = await axiosInstance.post(createVehicleRequestGroupAPIUrl, vehicleGroupPayload);
      data = response.data;
      datadogLogs.logger.info('Successfully submitted referral', data);
      return data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
      datadogLogs.logger.error('Error creating vrg', vehicleGroupPayload, errorMessage);
      setSnackbarAlert({
        open: true,
        message: errorMessage,
        severity: 'error',
        onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
      });
      return;
    }
  }
  async function createVehicle(userId: string) {
    let data = null;
    const createVehicleAPIUrl = process.env.REACT_APP_SERVER_URL + '/vehicles';
    const selectedYear = parseInt(watch('year'), 10);
    const vehiclePayload = {
      ...DEFAULT_VEHICLE_OBJECT(),
      type: 'Asset',
      use: 'Personal',
      user: userId,
      code: watch('code'),
      codeDescription: watch('modelType'),
      familyCode: watch('model'),
      familyDescription: watch('modelDescription'),
      makeCode: watch('make'),
      makeDescription: watch('makeDescription'),
      purchaseSource: watch('saleType'),
      customerNote: watch('manualEntryDetails'),
      year: selectedYear || '',
    };
    try {
      const response = await axiosInstance.post(createVehicleAPIUrl, vehiclePayload);
      data = response.data;
      return data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
      datadogLogs.logger.error('Error creating vehcile', vehiclePayload, errorMessage);
      setSnackbarAlert({
        open: true,
        message: errorMessage,
        severity: 'error',
        onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
      });
      return;
    }
  }

  async function patchVRG(resCreateVehicleRequestGroup: any, vehicleRequestGroupId: string, vehicleId: string) {
    let data = null;
    const updateVehicleRequestGroupAPIUrl = `${process.env.REACT_APP_SERVER_URL}/vehicle-request-groups/${vehicleRequestGroupId}`;
    const updatedVehicleGroupPayload = {
      ...resCreateVehicleRequestGroup,
      finance: {
        ...resCreateVehicleRequestGroup.finance,
        application: {
          ...resCreateVehicleRequestGroup.finance.application,
          loanType: isCommercial ? 'Commercial Car Loan' : 'Consumer Car Loan',
          vehicle: vehicleId,
        },
      },
    };
    try {
      const response = await axiosInstance.patch(updateVehicleRequestGroupAPIUrl, updatedVehicleGroupPayload);
      data = response.data;
      return data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
      datadogLogs.logger.error('Error patching vrg data', updatedVehicleGroupPayload, errorMessage);
      setSnackbarAlert({
        open: true,
        message: errorMessage,
        severity: 'error',
        onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
      });
      return;
    }
  }
  const handleSubmitForm = async () => {
    setIsShowDialog(false);
    setIsLoading(true);
    const resCreateUser = await upsertUser();
    if (!resCreateUser) {
      setIsLoading(false);
      return;
    }
    const userId = resCreateUser.user._id;

    // Step 2: Create Vehicle Request Group
    const resCreateVehicleRequestGroup = await createVRG(userId);
    if (!resCreateVehicleRequestGroup) {
      setIsLoading(false);
      return;
    }

    const vehicleRequestGroupId = resCreateVehicleRequestGroup._id;

    // Step 3: Create Vehicle
    const resCreateVehicle = await createVehicle(userId);
    if (!resCreateVehicle) {
      setIsLoading(false);
      return;
    }
    const vehicleId = resCreateVehicle._id;

    // Step 4: Update Vehicle Request Group with Vehicle ID
    const patchResult = await patchVRG(resCreateVehicleRequestGroup, vehicleRequestGroupId, vehicleId);
    if (!patchResult) {
      setIsLoading(false);
      return;
    }
    setIsSuccess(true);
    setIsLoading(false);
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {isSuccess ? (
        <Box sx={{ padding: '20px', backgroundColor: '#f7f7f7', borderRadius: '8px' }}>
          <ThankYou reset={reset} setIsSuccess={setIsSuccess} />
        </Box>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <Box sx={{ padding: '20px', backgroundColor: '#f7f7f7', borderRadius: '8px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                <Button variantType="full" color={theme.palette.primary.main} type="submit" sx={{ padding: '.46rem .75rem', height: '31px' }}>
                  <Typography fontWeight="800" fontSize="14px">
                    Refer to CarClarity
                  </Typography>
                </Button>
              </Box>
              <Box sx={{ backgroundColor: '#ffffff', borderRadius: '8px', marginBottom: '20px', padding: '16px' }}>
                <div onClick={toggleCustomerDetails} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#f5f4fc', borderRadius: '4px', fontWeight: '800', paddingLeft: '16px', fontSize: '12px', marginBottom: '20px', height: '48px' }}>
                  <Typography fontSize="12px" fontWeight="800">
                    Customer details
                  </Typography>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Divider orientation="vertical" sx={{ marginRight: '25px', height: '16px' }} />
                    <Typography fontSize="12px" fontWeight="800" color={theme.palette.primary.main}>
                      {isCustomerDetailsOpen ? 'Hide' : 'Show'}
                    </Typography>
                    <IconButton sx={{ padding: '2px', paddingRight: '10px' }}>{isCustomerDetailsOpen ? <ExpandLess sx={{ color: theme.palette.primary.main }} /> : <ExpandMore sx={{ color: theme.palette.primary.main }} />}</IconButton>
                  </Box>
                </div>
                <Collapse in={isCustomerDetailsOpen}>
                  <Grid container spacing={2}>
                    {formFields.map((field, index) => (
                      <Grid item xs={12} md={6} key={index}>
                        <DynamicForm fields={[field]} fieldMargin="10px" />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Box>
              {isCommercial && (
                <Box sx={{ backgroundColor: '#ffffff', borderRadius: '8px', marginBottom: '20px', padding: '16px' }}>
                  <div
                    onClick={toggleBusinessDetails}
                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#f5f4fc', borderRadius: '4px', fontWeight: '800', paddingLeft: '16px', fontSize: '12px', marginBottom: '20px', height: '48px' }}
                  >
                    <Typography fontSize="12px" fontWeight="800">
                      Business details
                    </Typography>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Divider orientation="vertical" sx={{ marginRight: '25px', height: '16px' }} />
                      <Typography fontSize="12px" fontWeight="800" color={theme.palette.primary.main}>
                        {isBusinessDetailsOpen ? 'Hide' : 'Show'}
                      </Typography>
                      <IconButton sx={{ padding: '2px', paddingRight: '10px' }}>{isBusinessDetailsOpen ? <ExpandLess sx={{ color: theme.palette.primary.main }} /> : <ExpandMore sx={{ color: theme.palette.primary.main }} />}</IconButton>
                    </Box>
                  </div>
                  <Collapse in={isBusinessDetailsOpen}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          autoComplete={false}
                          options={businessOptions}
                          getOptionLabel={(option) => `${option.name}`}
                          loading={isBusinessLoading && businessOptions.length === 0}
                          sx={{ paddingRight: '0 !important' }}
                          inputValue={inputValue}
                          onInputChange={(_, newInputValue) => {
                            setInputValue(newInputValue);
                            fetchBusinessOptions(newInputValue);
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Box paddingLeft="20px">
                                <Box sx={{ fontWeight: 'bold', color: '#606266', fontSize: '14px', lineHeight: '34px' }}>{option.name}</Box>
                                <Box sx={{ fontSize: '0.875rem', color: '#606266', lineHeight: '34px' }}>{option.abn}</Box>
                                <Box sx={{ fontSize: '0.875rem', color: '#606266', lineHeight: '34px' }}>
                                  {option.location.postCode} {option.location.state}
                                </Box>
                              </Box>
                            </li>
                          )}
                          renderInput={(params) => (
                            <FormControl key="search" fullWidth margin="normal" sx={{ margin: '0px' }}>
                              <FormLabel
                                sx={{
                                  fontSize: '12px',
                                  color: 'black',
                                  marginBottom: '10px',
                                  '&.Mui-focused': {
                                    color: 'black',
                                  },
                                }}
                              >
                                <Box display="flex" alignItems="center">
                                  Search by Business name or ABN
                                </Box>
                              </FormLabel>
                              <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                placeholder="Search by Business name or ABN"
                                sx={{
                                  backgroundColor: 'white',
                                  '& .MuiOutlinedInput-root': {
                                    paddingRight: '6px !important',
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                      borderColor: 'rgba(0, 0, 0, 0.33)',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                      borderColor: theme.palette.primary.main,
                                      borderWidth: '1px',
                                    },
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: <Search color="disabled" />,
                                  endAdornment: null,
                                }}
                              />
                            </FormControl>
                          )}
                          onChange={(_, value) => {
                            if (value) {
                              fetchBusinessDetails(value.abn);
                              setInputValue('');
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={<Checkbox checked={isManualEntryBusiness} onChange={handleManualEntryBusinessChange} sx={{ color: theme.palette.primary.main }} />}
                          label="Enter the details of the business manually if it is not found above"
                          sx={{
                            '& .MuiTypography-root': {
                              fontSize: '14px',
                            },
                          }}
                        />
                      </Grid>
                      {businessFields.map((field, index) => (
                        <Grid item xs={12} md={6} key={index}>
                          <DynamicForm fields={[field]} fieldMargin="10px" />
                        </Grid>
                      ))}
                    </Grid>
                  </Collapse>
                </Box>
              )}
              <Box sx={{ backgroundColor: '#ffffff', borderRadius: '8px', marginBottom: '20px', padding: '16px' }}>
                <div onClick={toggleAssetDetails} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#f5f4fc', borderRadius: '4px', fontWeight: '800', paddingLeft: '16px', fontSize: '12px', marginBottom: '20px', height: '48px' }}>
                  <Typography fontSize="12px" fontWeight="800">
                    Asset details <span style={{ color: '#909090', fontWeight: 'lighter', fontSize: '12px' }}>Optional</span>
                  </Typography>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Divider orientation="vertical" sx={{ marginRight: '25px', height: '16px' }} />
                    <Typography fontSize="12px" fontWeight="800" color={theme.palette.primary.main}>
                      {isAssetDetailsOpen ? 'Hide' : 'Show'}
                    </Typography>
                    <IconButton sx={{ padding: '2px', paddingRight: '10px' }}>{isAssetDetailsOpen ? <ExpandLess sx={{ color: theme.palette.primary.main }} /> : <ExpandMore sx={{ color: theme.palette.primary.main }} />}</IconButton>
                  </Box>
                </div>
                <Collapse in={isAssetDetailsOpen}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DynamicForm fields={[assetFields[0]]} fieldMargin="10px" />
                    </Grid>
                    <Divider sx={{ marginLeft: '16px', width: '99%', mt: '16px' }} />
                    {assetFields.slice(1).map((field, index) => (
                      <Grid item xs={12} md={6} key={index}>
                        <DynamicForm fields={[field]} fieldMargin="10px" />
                      </Grid>
                    ))}
                    <Grid item xs={12} paddingTop="0 !important">
                      <FormControlLabel
                        control={<Checkbox checked={isManualEntry} onChange={handleManualEntryChange} sx={{ color: theme.palette.primary.main }} />}
                        label="Enter the details of the vehicle manually if the car is not found above"
                        sx={{
                          '& .MuiTypography-root': {
                            fontSize: '14px',
                          },
                        }}
                      />
                    </Grid>
                    {isManualEntry && (
                      <Grid item xs={12}>
                        <Controller
                          name="manualEntryDetails"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              multiline
                              rows={5}
                              placeholder="Enter vehicle details manually"
                              variant="outlined"
                              sx={{
                                width: '50%',
                                '& .MuiOutlinedInput-root': {
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme.palette.primary.main,
                                    borderWidth: '1px',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme.palette.primary.main,
                                    borderWidth: '1px',
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Collapse>
              </Box>
              <Box sx={{ backgroundColor: '#ffffff', borderRadius: '8px', marginBottom: '20px', padding: '16px' }}>
                <div onClick={toggleNotesDetails} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#f5f4fc', borderRadius: '4px', fontWeight: '800', paddingLeft: '16px', fontSize: '12px', marginBottom: '20px', height: '48px' }}>
                  <Typography fontSize="12px" fontWeight="800">
                    Notes <span style={{ color: '#909090', fontWeight: 'lighter', fontSize: '12px' }}>Optional</span>
                  </Typography>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Divider orientation="vertical" sx={{ marginRight: '25px', height: '16px' }} />
                    <Typography fontSize="12px" fontWeight="800" color={theme.palette.primary.main}>
                      {isNoteDetailsOpen ? 'Hide' : 'Show'}
                    </Typography>
                    <IconButton sx={{ padding: '2px', paddingRight: '10px' }}>{isNoteDetailsOpen ? <ExpandLess sx={{ color: theme.palette.primary.main }} /> : <ExpandMore sx={{ color: theme.palette.primary.main }} />}</IconButton>
                  </Box>
                </div>
                <Collapse in={isNoteDetailsOpen}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        name="notes"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            multiline
                            placeholder="Write any additional notes here ..."
                            variant="outlined"
                            draggable
                            rows={5}
                            sx={{
                              width: '50%',
                              '& .MuiOutlinedInput-root': {
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: theme.palette.primary.main,
                                  borderWidth: '1px',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                  borderColor: theme.palette.primary.main,
                                  borderWidth: '1px',
                                },
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
                <Button variantType="full" color={theme.palette.primary.main} type="submit" sx={{ padding: '.46rem .75rem', height: '31px' }}>
                  <Typography fontWeight="800" fontSize="14px">
                    Refer to CarClarity
                  </Typography>
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      )}
      <Dialog
        open={isShowDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth={true}
        PaperProps={{
          sx: {
            boxShadow: 'none',
            borderRadius: '0',
            maxWidth: { xs: '100%', sm: '80%', md: '22%' },
          },
        }}
      >
        <DialogTitle sx={{ display: 'flex', paddingLeft: '15px', paddingTop: '20px', justifyContent: 'center' }}>
          <Typography fontSize="14px" fontWeight="800">
            Consent
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', paddingX: '1rem', marginTop: '10px' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography fontSize="0.875rem" color="#606266" marginBottom="0.5rem" dangerouslySetInnerHTML={{ __html: getConsentText(watch('firstName') + ' ' + watch('lastName')) }} />
            <Typography fontSize="0.875rem" color="#606266" marginBottom="0.5rem" dangerouslySetInnerHTML={{ __html: getConsentText(`${watch('firstName')} ${watch('lastName')}`) }} />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: 'space-between', paddingRight: '20px', paddingBottom: '10px' }}>
          <Button variantType="filled" onClick={handleCloseDialog}>
            <Typography fontWeight="800" fontSize="14px">
              Cancel
            </Typography>
          </Button>
          <Button variantType="full" color={theme.palette.primary.main} type="submit" sx={{ height: '31px' }} onClick={handleSubmitForm}>
            <Typography fontWeight="800" fontSize="14px">
              {LABEL_TYPE_CONSENT_PRIVACY}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      {snackbarAlert.message && <SnackbarAlert autoHideDuration={3000} message={snackbarAlert.message} severity={snackbarAlert.severity} open={snackbarAlert.open} onClose={snackbarAlert.onClose} />}
    </>
  );
};
export default QuickReferralConsumer;
