import { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Box, Container, Link, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import Button from '../../../shared/Button';
import DynamicForm, { FormField } from '../../../shared/Form';
import theme from '../../../theme';
import SnackbarAlert, { SnackBarProps } from '../../../shared/SnackbarAlert';
import {
  USER_SESSION_DATA_KEY,
  ERROR_MESSAGE_FOR_INVALID_EMAIL,
  ERROR_MESSAGE_FOR_EMAIL,
  ERROR_MESSAGE_FOR_PASSWORD,
  ERROR_MESSAGE_FOR_PASSWORD_LENGTH,
  REGEX_FOR_PASSWORD,
  USER_TYPE_REFERRER,
  ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED,
  LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE,
  LABEL_FOR_EMAIL,
  REGEX_FOR_EMAIL,
  LABEL_FOR_PASSWORD,
  USER_TYPE_SUPER_ADMIN,
  USER_TYPE_ADMIN,
} from '../../../constants';
import { axiosErrorMessageExtractor } from '../../../utils/error';
import { AuthContext } from '../../../App';

const EmailLogin: React.FC = () => {
  const { setUser, setIsLoggedIn, setRedirectPath } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const methods = useForm();

  const [isRequestCodeLoading, setIsRequestCodeLoading] = useState<boolean>(false);
  const [snackbarAlert, setSnackbarAlert] = useState<SnackBarProps>({
    open: false,
    message: '',
    severity: 'error',
    onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
  });

  useEffect(() => {
    if (location.pathname === '/auth/email-login') {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('user');
      setIsLoggedIn(false);
      setUser(null);
    }
  }, [location, setIsLoggedIn]);

  const handleNavigation = () => {
    history.push('/email-password-reset-accelerate');
  };

  const handleMobileLogin = () => {
    history.push('/auth/login');
  };

  const validateEmail = (email: string): string => {
    if (!email) {
      return ERROR_MESSAGE_FOR_EMAIL;
    }
    return REGEX_FOR_EMAIL.test(email) ? '' : ERROR_MESSAGE_FOR_INVALID_EMAIL;
  };

  const validatePassword = (password: string): string => {
    if (!password) {
      return ERROR_MESSAGE_FOR_PASSWORD;
    }
    return REGEX_FOR_PASSWORD.test(password) ? '' : ERROR_MESSAGE_FOR_PASSWORD_LENGTH;
  };

  const formFields: FormField[] = [
    {
      label: LABEL_FOR_EMAIL,
      name: 'email',
      type: 'email',
      placeHolder: 'name@domain.com',
      validateField: validateEmail,
    },
    {
      label: LABEL_FOR_PASSWORD,
      name: 'password',
      type: 'password',
      placeHolder: 'Enter password',
      validateField: validatePassword,
    },
  ];

  const authenticationEmailPass = async (email: string, password: string) => {
    setIsRequestCodeLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + '/user/sign-in-email', {
        email,
        password,
      });

      if (response.data.tokens) {
        localStorage.setItem('token', response.data.tokens.token);
        localStorage.setItem('refreshToken', response.data.tokens.refreshToken);
      }

      const { user } = response.data;

      localStorage.setItem(USER_SESSION_DATA_KEY, JSON.stringify(user));

      if (![USER_TYPE_ADMIN, USER_TYPE_SUPER_ADMIN, USER_TYPE_REFERRER].includes(user.type)) {
        setSnackbarAlert({
          open: true,
          message: ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED,
          severity: 'error',
          onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
        });
        datadogLogs.logger.error(`User unable to login - ${ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED}`);
        localStorage.setItem('accelerate-login-email', 'true');
        localStorage.removeItem(USER_SESSION_DATA_KEY);

        return;
      }

      if (response?.data) {
        datadogLogs.logger.info('Auth0 is authenticated - Login using email successfully');
        datadogRum.startSessionReplayRecording();
        setIsLoggedIn(true);
        setRedirectPath('/referrals');
        history.push('/referrals');
      }
    } catch (err: any) {
      const errorMessage = axiosErrorMessageExtractor(err);
      datadogLogs.logger.error(`User not authenticated - ${errorMessage}`);
      setSnackbarAlert({
        open: true,
        message: errorMessage,
        severity: 'error',
        onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
      });
    } finally {
      setIsRequestCodeLoading(false);
    }
  };

  const onSubmit = async (formData: Record<string, any>) => {
    const validationErrors = formFields.reduce(
      (errors, field) => {
        if (field.validateField) {
          const errorMessage = field.validateField(formData[field.name]);
          if (errorMessage) {
            errors[field.name] = errorMessage;
          }
        }
        return errors;
      },
      {} as Record<string, string>
    );

    if (Object.keys(validationErrors).length > 0) {
      Object.entries(validationErrors).forEach(([name, error]) => {
        methods.setError(name, { type: 'manual', message: error });
      });
      return;
    }

    setIsRequestCodeLoading(true);
    try {
      await authenticationEmailPass(formData.email, formData.password);
    } finally {
      setIsRequestCodeLoading(false);
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 7rem)',
      }}
      maxWidth="sm"
    >
      <Box sx={{ width: '90%', maxWidth: { xs: '100%', sm: '90%', md: '600px' } }}>
        <Box sx={{ display: 'flex', marginBottom: '25px', justifyContent: 'space-between' }}>
          <Typography component="div" style={{ fontWeight: '600', fontSize: '24px' }}>
            Enter login details
          </Typography>
          <Button onPress={handleMobileLogin} disableRipple variantType="filled" color={theme.palette.primary.main} sx={{ paddingRight: '0px' }}>
            <Typography component="span" fontWeight="800" fontSize="14px">
              Login with mobile
            </Typography>
          </Button>
        </Box>

        <Box
          sx={{
            backgroundColor: '#fafafa',
            border: '1px solid #e8e8e8',
            padding: '24px',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <DynamicForm fields={formFields} />
              <Button type="submit" variantType="full" color={theme.palette.primary.main} sx={{ width: '100%' }}>
                <Typography component="span" fontSize="14px" fontWeight="800">
                  {isRequestCodeLoading ? 'Loading...' : 'Submit'}
                </Typography>
              </Button>
            </form>
          </FormProvider>
          <Box sx={{ mt: 3 }}>
            <Button onPress={handleNavigation} disableRipple variantType="filled" color={theme.palette.primary.main}>
              <Typography component="span" fontWeight="800">
                Forgot password?
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
          <Typography component="div" fontSize="0.875rem" color="#303030" marginBottom="0.5rem">
            For any technical assistance
          </Typography>
          <Link href={`mailto:tech-support@carclarity.com.au?subject=${LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE}`} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
            <Typography component="span" fontSize="14px" fontWeight="800" color={theme.palette.primary.main}>
              Contact Support
            </Typography>
          </Link>
        </Box>
      </Box>
      {snackbarAlert.message && <SnackbarAlert autoHideDuration={3000} message={snackbarAlert.message} severity="error" open={snackbarAlert.open} onClose={snackbarAlert.onClose} />}
    </Container>
  );
};

export default EmailLogin;
