import { Snackbar, Alert } from '@mui/material';

export interface SnackBarProps {
  message: string;
  severity?: 'success' | 'warning' | 'info' | 'error';
  open?: boolean;
  onClose?: () => void;
  autoHideDuration?: number;
}

const SnackbarAlert: React.FC<SnackBarProps> = ({ message, severity = 'success', open, onClose, autoHideDuration, ...props }) => (
  <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} {...props}>
    <Alert severity={severity} onClose={onClose}>
      {message}
    </Alert>
  </Snackbar>
);

export default SnackbarAlert;
