import { generateOptionsFromTypesWhereKeyIsSameAsValue } from '../utils/form';

export const LABEL_COMMERCIAL = 'Commercial';
export const LABEL_CONSUMER = 'Consumer';
export const LABEL_REFERRAL_TYPE = 'Referral type';
export const LABEL_QUICK_REFERRAL_TYPE = 'Quick referral type';
export const LABEL_QUICK_REFERRAL = 'Quick referral';
export const LABEL_FULL_REFERRAL = 'Full referral';
export const LABEL_NEW_REFERRAL = 'New referral';
export const LABEL_SELECT_REFERRAL_TYPE = 'Select the type of the referral';
export const LABEL_QUICK_SELECT_REFERRAL_TYPE = 'Select the type of the quick referral';
export const LABEL_TYPE_DIALOG_CANCEL_BUTTON = 'Cancel';
export const LABEL_TYPE_DIALOG_CONTINUE_BUTTON = 'Continue';
export const LABEL_TYPE_CONSENT_PRIVACY = 'Yes. Send privacy and submit referral';

export const LABEL_QUICK_REFERRAL_DETAILS = 'Only customer and asset details are required';

export const LABEL_FULL_REFERRAL_DETAILS = 'Customer, loan and asset details are required';

export const LABEL_CUSTOMER_AND_LOAN_DETAILS = 'Customer & Loan details';
export const LABEL_ASSET_DETAILS = 'Asset details';
export const LABEL_DOCUMENTS = 'Documents';
export const LABEL_ASSET_SALE_TYPE = 'Sale type';

export const LABEL_ASSET_TRADE_IN_DETAILS = 'Trade in details';
export const LABEL_ASSET_MAKE = 'Make';
export const LABEL_ASSET_MODEL = 'Model';
export const LABEL_ASSET_YEAR = 'Year';
export const LABEL_ASSET_MODEL_TYPE = 'Model Type';
export const LABEL_ASSET_ENTER_THE_DETAILS_MANUALLY = 'Enter the details of the vehicle manually if the car is not found above';
export const LABEL_ASSET_TRADE_IN_AMOUNT = 'Trade in amount';
export const LABEL_ASSET_FINANCE_OWNING = 'Finance owning';
export const LABEL_ASSET_BACK_BUTTON = 'Back';
export const LABEL_ASSET_NEXT_BUTTON = 'Next';

export const LABEL_UPLOAD_DOCUMENTS = 'Upload documents';
export const LABEL_DOCUMENT_DROP = 'Drop file here or';
export const LABEL_DOCUMENT_UPLOAD = 'click here to upload';
export const LABEL_DOCUMENT_BACK_BUTTON = 'Back';
export const LABEL_DOCUMENT_SUBMIT_BUTTON = 'Submit';
export const LABEL_ADDED_DOCUMENTS = 'Documents added';
export const LABEL_CONSENT_TITLE = 'Consent';
export const LABEL_AUTHOROTITY_MESSAGE = 'I have <username> authority to pass on their details to CarClarity ACL Pty Ltd (CarClarity) and express permission for CarClarity to contact them.';
export const LABEL_PLEASE_SELECT_REFERRAL_TYPE = 'Please select the referral type';
export const LABEL_PLEASE_SELECT_LOAN_TYPE = 'Please select the loan type';

export const LABEL_THANK_YOU_ONE_OF_OUR_TEAM = 'One of our team will be in touch with the customer';
export const LABEL_THANK_YOU_YOU_WILL_SEE_STATUS_UPDATES = "You'll see status updates in the portal and by email if you've got email alerts active";
export const LABEL_THANK_YOU_MESSAGE = 'Thank you!';
export const LABEL_THANK_YOU_REFERRAL_SUBMITTED = 'Referral to CarClarity has been submitted successfully';
export const LABEL_THANK_YOU_FULL_REFERRAL_SUBMITTED = 'Referral has been submitted successfully';
export const LABEL_THANK_YOU_WHATS_NEXT = "What's next!";
export const LABEL_THANK_YOU_ANOTHER_CUSTOMER = 'Refer another customer';
export const LABEL_THANK_YOU_HOME_LINK = 'Home';
export const LABEL_THANK_YOU_BACK_TO_HOME_LINK = 'Back to home';

export const LABEL_DOCUMENT_YES_SEND_PRIVACY = 'Yes. Send Privacy and submit referral';
export const LABEL_DOCUMENT_CANCEL_BUTTON = 'Cancel';

export const LABEL_BUSINESS_STRUCTURE_SOLE_TRADER = 'Sole Trader';
export const LABEL_BUSINESS_STRUCTURE_TRUST = 'Trust';
export const LABEL_BUSINESS_STRUCTURE_COMPANY = 'Company';
export const LABEL_BUSINESS_STRUCTURE_PARTNERSHIP = 'Partnership';

export const BUSINESS_STRUCTURE_VALUES = [
  {
    label: LABEL_BUSINESS_STRUCTURE_SOLE_TRADER,
    value: LABEL_BUSINESS_STRUCTURE_SOLE_TRADER,
  },
  {
    label: LABEL_BUSINESS_STRUCTURE_TRUST,
    value: LABEL_BUSINESS_STRUCTURE_TRUST,
  },
  {
    label: LABEL_BUSINESS_STRUCTURE_COMPANY,
    value: LABEL_BUSINESS_STRUCTURE_COMPANY,
  },
  {
    label: LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
    value: LABEL_BUSINESS_STRUCTURE_PARTNERSHIP,
  },
];
export const BUSINESS_STRUCTURE_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(BUSINESS_STRUCTURE_VALUES);

export const LABEL_SEARCH_BY_BUSINESS_NAME_OR_ABN = 'Search by Business name or ABN';
export const LABEL_BUSINESS_DETAILS = 'Business details';
export const LABEL_CUSTOMER_DETAILS = 'Customer details';
export const LABEL_PLACE_HOLDER_MOBILE = '04XXXXXXXX';
export const LABEL_PLACE_HOLDER_EMAIL = 'name@domain.com';
export const LABEL_ENTER_BUSINESS_DETAILS_MANUALLY = 'Enter the details of the business manually if not found using search';
export const LABEL_PLACE_HOLDER_BUSINESS_NAME = 'Business name';
export const LABEL_PLACE_HOLDER_ABN = '11 222 333 444';
export const LABEL_BUSINESS_STRUCTURE = 'Business structure';
export const LABEL_BUSINESS_NAME = 'Business name';
export const LABEL_BUSINESS_ABN = 'ABN';
export const LABEL_LOAN_DETAILS = 'Loan details';
export const LABEL_LOAN_TYPE = 'Loan Type';
export const LABEL_EXPECTED_PURCHASE_PRICE = 'Expected purchase price';
export const LABEL_LOAN_BALLOON = 'Balloon %';
export const LABEL_LOAN_RESIDENCY = 'Residency';
export const LABEL_LOAN_EMPLOYMENT = 'Employment';
export const LABEL_LOAN_CREDIT_HISTORY = 'Credit history';
export const LABEL_LOAN_URGENCY = 'Urgency';
export const LABEL_VEHICLE_DETAILS = 'Vehicle details';
export const LABEL_CAR_SOURCING = 'Car sourcing';
export const LABEL_BACKGROUND = 'Background';
export const LABEL_PLACE_HOLDER_BACKGROUND = 'Write your text here ...';
export const LABEL_CAR_LOAN = 'Car loan';
export const LABEL_COMMERCIAL_CAR_LOAN = 'Commercial Car Loan';
export const LABEL_CONSUMER_CAR_LOAN = 'Consumer Car Loan';
export const LABEL_CAR_LOAN_TAG = 'Car loan';
export const LABEL_PERSONAL_LOAN = 'Personal Loan';
export const LABEL_PERSONAL_LOAN_TAG = 'Personal loan';
export const LABEL_REFERRAL_DETAILS = 'Referral details';
export const LABEL_AGENT_NOTES = 'Agent notes';
export const LABEL_AVERAGE_NAF = 'Average NAF';
export const LABEL_TOTAL_COMMISSION = 'Total Commission';
export const LABEL_SALE_TYPE_OPTIONS_PRIVATE = 'Private';
export const LABEL_SALE_TYPE_OPTIONS_DEALER = 'Dealer';
export const LABEL_SALE_TYPE_OPTIONS_AUCTION = 'Auction';

export const SALE_TYPE_OPTIONS_VALUES = [
  {
    label: LABEL_SALE_TYPE_OPTIONS_PRIVATE,
    value: LABEL_SALE_TYPE_OPTIONS_PRIVATE,
  },
  {
    label: LABEL_SALE_TYPE_OPTIONS_DEALER,
    value: LABEL_SALE_TYPE_OPTIONS_DEALER,
  },
  {
    label: LABEL_SALE_TYPE_OPTIONS_AUCTION,
    value: LABEL_SALE_TYPE_OPTIONS_AUCTION,
  },
];

export const SALE_TYPE_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(SALE_TYPE_OPTIONS_VALUES);

export const CREDIT_HISTORY_TYPES_700 = '700+';
export const CREDIT_HISTORY_TYPES_600_699 = '600-699';
export const CREDIT_HISTORY_TYPES_500_599 = '500-599';
export const CREDIT_HISTORY_TYPES_0_499 = '0-499';

export const CREDIT_HISTORY_TYPES_VERY_GOOD = 'Very good';
export const CREDIT_HISTORY_TYPES_GOOD = 'Good';
export const CREDIT_HISTORY_TYPES_AVERAGE = 'Average';
export const CREDIT_HISTORY_TYPES_BELOW_AVERAGE = 'Below Average';

export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT = 'Excellent';
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE = 'Average';
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR = 'Fair';
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR = 'Poor';
export const CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_UNSURE = 'Unsure';

export const CREDIT_HISTORY_TYPES = [
  {
    value: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_EXCELLENT,
    label: CREDIT_HISTORY_TYPES_VERY_GOOD + ' ' + CREDIT_HISTORY_TYPES_700,
  },
  {
    value: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_AVERAGE,
    label: CREDIT_HISTORY_TYPES_GOOD + ' ' + CREDIT_HISTORY_TYPES_600_699,
  },
  {
    value: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_FAIR,
    label: CREDIT_HISTORY_TYPES_AVERAGE + ' ' + CREDIT_HISTORY_TYPES_500_599,
  },
  {
    value: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_POOR,
    label: CREDIT_HISTORY_TYPES_BELOW_AVERAGE + ' ' + CREDIT_HISTORY_TYPES_0_499,
  },
  {
    value: CUSTOMER_SUPPLIED_CREDIT_RATING_DEPICTION_UNSURE,
    label: 'Not sure',
  },
];

export const CREDIT_HISTORY_OPTIONS = generateOptionsFromTypesWhereKeyIsSameAsValue(CREDIT_HISTORY_TYPES);

export const LEAD_SOURCE_SYSTEM_ACCELERATE = 'Referral Portal';

export const TITLE_CONSENT = 'Consent';

export const REFERRAL_MODE_STANDARD = 'standard';
export const REFERRAL_MODE_QUICK = 'quick';
export const REFERRAL_MODES = [REFERRAL_MODE_STANDARD, REFERRAL_MODE_QUICK];

export const getConsentText = (customerName: string) => `I have <b>${customerName}'s</b> authority to pass on their details to CarClarity ACL Pty Ltd (CarClarity) and express permission for CarClarity to \n contact them.`;

// Functions because of JS' tendency to copy by ref, not value.
export const DEFAULT_VEHICLE_OBJECT = () => ({
  code: '',
  codeDescription: '',
  condition: '',
  familyCode: '',
  familyDescription: '',
  financeDepositAmount: 0,
  makeCode: '',
  makeDescription: '',
  purchaseSource: '',
  type: '',
  use: '',
  user: '',
  year: '',
  yearDescription: '',
  customerNote: '',
  tradeInAmount: 0,
});

export const DEFAULT_VEHICLE_GROUP_OBJECT = ({ applicationType = '' }) => ({
  hasTradeIn: null,
  primaryUser: '',
  wantsFinance: '',
  account: '',
  externalReferrer: '',
  leadSource: '',
  referralMode: '',
  leadSourceSystem: LEAD_SOURCE_SYSTEM_ACCELERATE,
  urgency: '',
  primaryUserAgreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt: null,
  vehicleSourced: '',
  gCLID: '',
  fBCLID: '',
  partnerOwnSystemLeadId: '',
  lastClickUTMSource: '',
  lastClickUTMMedium: '',
  lastClickUTMCampaign: '',
  lastClickUTMTerm: '',
  lastClickUTMContent: '',
  lastClickUTMId: '',
  googleTransactionId: '',
  featureBranch: '',
  partnerId: '',
  startedAt: null,
  httpReferrer: '',
  isAppReady: true,
  finance: {
    application: {
      saleType: '',
      purchaseSource: '',
      purchasePrice: 0,
      vehicle: DEFAULT_VEHICLE_OBJECT(),
      isTradeInFinanced: null,
      certaintyStage: '',
      balloonPct: 0,
      loanTermYears: 5,
      completedBalloonPaymentAt: null,
      completedLeadApplicationAt: '',
      completedTradeInAt: null,
      productCategoryType: '',
      applicationType,
      loanType: '',
      loanAmount: '',
      primaryApplicantData: {
        primaryApplicant: {
          dateOfBirth: '',
          driverLicence: '',
          driverLicenceExpiry: null,
          driverLicenceState: '',
          driverLicenceType: '',
          driverLicenceCardNumber: '',
          email: '',
          firstName: '',
          lastName: '',
          mailingAddress: {
            country: '',
            isCurrent: false,
            addressLine1: '',
            state: '',
          },
          mobile: '',
          salutation: '',
          type: '',
          background: '',
          leadSourceSystem: LEAD_SOURCE_SYSTEM_ACCELERATE,
          preferredCommunicationMethods: [],
        },
        employments: [
          {
            employerName: '',
            position: '',
            status: '',
            isCurrent: true,
            atYears: null,
            atAdditionalMonths: null,
            isAdditionalJob: false,
            netIncome: null,
            netIncomeFrequency: 'Monthly',
            hasExtraEarnings: null,
            commissionPretax: 0,
            commissionFrequency: '',
            bonusPretax: 0,
            bonusFrequency: '',
            overtimePretax: 0,
            overtimeFrequency: '',
            allowancesPretax: 0,
            allowancesFrequency: '',
          },
        ],
        assets: [],
        expenses: [
          {
            type: 'Rent Or Board',
            amount: '',
            frequency: 'Monthly',
          },
          {
            type: 'Motor Vehicle Running Costs',
            amount: '',
            frequency: 'Monthly',
          },
          {
            type: 'Travel',
            amount: '',
            frequency: 'Monthly',
          },
          {
            type: 'Utilities',
            amount: '',
            frequency: 'Monthly',
          },
          {
            type: 'Insurances',
            amount: '',
            frequency: 'Monthly',
          },
          {
            type: 'Internet And Telephone',
            amount: '',
            frequency: 'Monthly',
          },
          {
            type: 'Entertainment',
            amount: '',
            frequency: 'Monthly',
          },
          {
            type: 'Education',
            amount: '',
            frequency: 'Monthly',
          },
          {
            type: 'Childcare',
            amount: '',
            frequency: 'Monthly',
          },
        ],
        liabilities: [],
        documents: [],
        nonEmploymentIncomes: [],
        hasAdditionalLiabilities: true,
        hasAdditionalJobs: null,
        hasCreditCardsOrStoreCards: true,
        hasPersonalLoans: null,
        hasNonEmploymentIncomes: null,
        hasPartnerIncome: null,
        hasAdditionalVehicles: null,
        livingSituation: '',
        currentWorkStatus: '',
        personalNetMonthlyIncomeGuesstimate: '',
        residencyStatus: '',
        addresses: [
          {
            address: '',
            atYears: '',
            atAdditionalMonths: '',
            isCurrent: true,
            country: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postcode: '',
          },
        ],
        relationshipStatus: '',
        numberOfDependants: 0,
        stage: 'LEAD_APPLICATION',
        step: 1,
        stageMax: '',
        stepMax: 0,
        completedMainApplicationAt: null,
        completedPersonalInfoAt: null,
        completedEmploymentAndIncomeAt: null,
        completedExpensesAt: null,
        completedAssetsAt: null,
        completedLiabilitiesAt: null,
        completedInitialDocumentsUploadAt: null,
        customerSuppliedCreditRatingDepiction: '',
        completedApplicationDetails: '',
        agreedToPrivacyDisclaimerAndCreditGuideAndProvideDocumentsElectronicallyAt: '',
        liabilitiesTotal: 0,
      },
      businessData: {
        isDirector: null,
        businessName: '',
        tradingName: '',
        businessStructure: '',
        hasABN: null,
        aBN: '',
        aBNRegisteredYearsRange: '',
        tradingYearsRange: '',
        isRegisteredForGST: null,
        gSTRegisteredYearsRange: '',
        assets: [],
        liabilities: [],
        completedBusinessInfoAt: null,
        aSIC: '',
        billingAddress: {
          state: '',
          postcode: '',
        },
        aBNEffectiveFrom: null,
        gSTEffectiveFrom: null,
        directors: [],
        trusteeBusinessName: '',
        trusteeABN: '',
        trusteeASIC: '',
        trusteeBusinessStructure: '',
        trusteeBillingAddress: {
          state: '',
          postcode: '',
        },
        trusteeABNEffectiveFrom: null,
        trusteeGSTEffectiveFrom: null,
        grossTradingIncomeMonthly: 0,
        isPropertyOwner: null,
      },
      step: 1,
      stage: '',
      mobile: '',
      primaryUser: '',
    },
  },
  tradeIn: {
    completedLeadFormAt: null,
    vehicle: DEFAULT_VEHICLE_OBJECT(),
  },
  initialLeadFunnel: 'Finance',
});

export const LABEL_OPTIONAL = 'Optional';
export const INFINITE_SCROLL_HEIGHT_VALUE = 14.8;
