import React, { useEffect, useRef } from 'react';
import { Box, Grid, CircularProgress } from '@mui/material';
import { Referral, ReferralStatus } from 'pages/referrals/Referrals';
import ReferralsKanbanView from '../../components/ReferralsKanbanView';
import ReferralCard from '../../components/ReferralCard';
import ReferralsTableView from '../../components/ReferralsTableView';

interface ReferralsContentAreaProps {
  isViewLoading: boolean;
  activeGrid: string;
  referralList: Referral[];
  groupedReferrals: Record<string, Referral[]>;
  kanbanColumns: { title: string }[];
  form: ReferralStatus;
  setForm: React.Dispatch<React.SetStateAction<ReferralStatus>>;
  fetchReferrals: () => void;
  loadMoreReferrals: () => void;
  fetchMoreReferralsForColumn: (status: string, setColumnLoading: React.Dispatch<React.SetStateAction<boolean>>, setKanbanData: React.Dispatch<React.SetStateAction<Record<string, Referral[]>>>, setTotalDocs: React.Dispatch<React.SetStateAction<Record<string, number>>>) => void;
  setKanbanData: React.Dispatch<React.SetStateAction<Record<string, Referral[]>>>;
  totalDocs: Record<string, number>;
  setTotalDocs: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  setReferralList: React.Dispatch<React.SetStateAction<Referral[]>>;
  totalReferrals: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  searchTerm: string;
}

const ReferralsContentArea: React.FC<ReferralsContentAreaProps> = ({ isViewLoading, activeGrid, referralList, groupedReferrals, kanbanColumns, form, setForm, fetchReferrals, loadMoreReferrals, fetchMoreReferralsForColumn, setKanbanData, totalDocs, setTotalDocs, totalReferrals, page, setPage }) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isViewLoading) {
        loadMoreReferrals();
      }
    });

    if (lastElementRef.current) observer.current.observe(lastElementRef.current);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loadMoreReferrals, isViewLoading]);

  return (
    <Box paddingY="0px" paddingX="12px" height="78%">
      {activeGrid === 'grid' && (
        <Grid container spacing={2}>
          {referralList.map((referral, index) => (
            <Grid item xs={12} sm={referralList.length === 1 ? 6 : 6} md={referralList.length === 1 ? 6 : 6} key={index} ref={index === referralList.length - 1 ? lastElementRef : null}>
              <ReferralCard id={referral.id} name={referral.User} loanAmount={referral.loanAmount} updatedAt={referral.updatedAt} status={referral.status} reason={referral.details} details={referral} />
            </Grid>
          ))}
          {isViewLoading && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 5 }}>
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      )}
      {activeGrid === 'kanban' && (
        <Box sx={{ display: 'flex', overflowX: 'auto', width: '100%', overflowY: 'hidden', flexDirection: 'row', gap: '15px', height: '100%' }}>
          {kanbanColumns
            .filter((column) => form.status.includes('All') || form.status.includes(column.title))
            .map((column, index) => (
              <ReferralsKanbanView
                key={index}
                title={column.title}
                count={groupedReferrals[column.title] ? groupedReferrals[column.title].length : 0}
                referrals={groupedReferrals[column.title]}
                loadMoreReferrals={(status, setColumnLoading) => fetchMoreReferralsForColumn(status, setColumnLoading, setKanbanData, setTotalDocs)}
                totalDocs={totalDocs[column.title] || 0}
              />
            ))}
        </Box>
      )}
      {activeGrid !== 'grid' && activeGrid !== 'kanban' && (
        <Box sx={{ backgroundColor: 'white', borderRadius: '4px', boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.05)' }} padding="16px">
          <ReferralsTableView data={referralList} setForm={setForm} fetchReferrals={fetchReferrals} totalReferrals={totalReferrals} page={page} setPage={setPage} />
        </Box>
      )}
    </Box>
  );
};

export default ReferralsContentArea;
