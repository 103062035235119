import React from 'react';
import { Visibility, VisibilityOff, Cancel, Done, KeyboardArrowDown } from '@mui/icons-material';
import { TextField, Select, MenuItem, FormControl, FormLabel, InputAdornment, IconButton, Box, Typography, Chip, ListItemText, Checkbox } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import theme from 'theme';

export interface FormField {
  label: string;
  name: string;
  type: 'text' | 'email' | 'password' | 'select' | 'phoneNumber';
  options?: { value: string; label: string }[];
  placeHolder?: string;
  errorMessage?: string;
  validateField?: (value: any) => string | undefined;
  isRequired?: boolean;
  isNumber?: boolean;
  multiple?: boolean;
  isDisabled?: boolean;
}

interface DynamicFormProps {
  fields: FormField[];
  fieldMargin?: string;
}

const DynamicForm: React.FC<DynamicFormProps> = ({ fields, fieldMargin = '25px' }) => {
  const { control } = useFormContext();
  const [passwordVisibility, setPasswordVisibility] = React.useState<{ [key: string]: boolean }>({});

  /* eslint-disable */
  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key.length < 1 && (event.key === 'Backspace' || event.key === 'Arrow Left' || event.key === 'Arrow Right')) {
      return;
    } else if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  /* eslint-enable */
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = event.clipboardData.getData('Text');
    if (!/^\d*$/.test(pasteData)) {
      event.preventDefault();
    }
  };

  const handleClickShowPassword = (fieldName: string) => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  const handleDeleteChip = (value: string, onChange: (value: string[]) => void, selectedValues: string[]) => {
    const newValues = selectedValues.filter((item) => item !== value);
    onChange(newValues);
  };

  const handleChipDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const getInputType = (field: FormField) => {
    if (field.type === 'password') {
      return passwordVisibility[field.name] ? 'text' : 'password';
    }
    return field.type;
  };

  return (
    <Box>
      {fields.map((field) => (
        <FormControl key={field.name} fullWidth margin="normal" sx={{ margin: '0px' }}>
          <FormLabel
            sx={{
              fontSize: '12px',
              color: 'black',
              marginBottom: '10px',
              '&.Mui-focused': {
                color: 'black',
              },
            }}
          >
            <Box display="flex" alignItems="center">
              {field.isRequired && (
                <Typography color="red" marginRight="5px" fontSize="0.75rem">
                  *
                </Typography>
              )}
              {field.label}
            </Box>
          </FormLabel>
          {field.type !== 'select' ? (
            <Controller
              name={field.name}
              control={control}
              defaultValue=""
              rules={{
                required: field.isRequired ? field.errorMessage : false,
                validate: field.validateField ? (value) => field.validateField!(value) || true : undefined,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  FormHelperTextProps={{ sx: { marginLeft: '0px' } }}
                  sx={{
                    backgroundColor: 'white',
                    marginBottom: fieldMargin,
                    '& .MuiOutlinedInput-root': {
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.33)',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.primary.main,
                        borderWidth: '1px',
                      },
                    },
                  }}
                  error={!!error}
                  helperText={error?.message}
                  size="small"
                  type={getInputType(field)}
                  value={value}
                  onChange={onChange}
                  // onKeyDown={field.isNumber ? handleKeydown : undefined}
                  onPaste={field.isNumber ? handlePaste : undefined}
                  variant="outlined"
                  placeholder={field.placeHolder}
                  disabled={field.isDisabled}
                  InputProps={
                    field.type === 'password'
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton size="small" aria-label={`toggle ${field.name} visibility`} onClick={() => handleClickShowPassword(field.name)} edge="end">
                                {passwordVisibility[field.name] ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      : undefined
                  }
                />
              )}
            />
          ) : (
            <Controller
              name={field.name}
              control={control}
              defaultValue={field.multiple ? [] : ''}
              rules={{ required: field.isRequired ? field.errorMessage : false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <Select
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    displayEmpty
                    size="small"
                    multiple={field.multiple}
                    variant="outlined"
                    IconComponent={KeyboardArrowDown}
                    renderValue={(selected) => {
                      if (field.multiple) {
                        const selectedValues = selected as string[];
                        return (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            {selectedValues.map((value) => (
                              <Chip
                                key={value}
                                label={field.options?.find((option) => option.value === value)?.label || value}
                                onDelete={(event) => {
                                  event.stopPropagation();
                                  handleDeleteChip(value, onChange, selectedValues);
                                }}
                                deleteIcon={<Cancel fontSize="inherit" onMouseDown={handleChipDelete} />}
                                sx={{
                                  marginRight: '10px',
                                  backgroundColor: '#f5f4fc',
                                  color: theme.palette.primary.main,
                                  borderRadius: '4px',
                                  fontSize: '0.75rem',
                                  fontWeight: '500',
                                  height: '24px',
                                  '.MuiChip-deleteIcon': {
                                    fontSize: '16px',
                                    marginLeft: '0px',
                                  },
                                  '.MuiChip-label': {
                                    paddingLeft: '8px',
                                    paddingRight: '4px',
                                  },
                                }}
                              />
                            ))}
                          </Box>
                        );
                      }
                      const selectedOption = field.options?.find((option) => option.value === value);
                      return selectedOption ? selectedOption.label : <Typography sx={{ color: 'rgba(0, 0, 0, 0.33)' }}>{field.placeHolder}</Typography>;
                    }}
                    sx={{
                      marginBottom: fieldMargin,
                      backgroundColor: 'white',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.33)',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: field.isDisabled ? 'rgba(0, 0, 0, 0.33)' : theme.palette.secondary.main,
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: field.isDisabled ? 'rgba(0, 0, 0, 0.33)' : theme.palette.primary.main,
                        borderWidth: '1px',
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: '300px',
                          '& .MuiMenuItem-root': {
                            '&.Mui-selected': {
                              color: theme.palette.primary.main,
                            },
                            '&.Mui-selected:hover': {
                              color: '#fff',
                            },
                          },
                        },
                      },
                      transformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}
                    disabled={field.isDisabled}
                  >
                    {field.options?.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{
                          '&.Mui-selected': {
                            backgroundColor: 'transparent',
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                          },
                        }}
                      >
                        {field.multiple && <Checkbox checked={value.indexOf(option.value) > -1} size="small" sx={{ padding: 0 }} />}
                        <ListItemText
                          primary={option.label}
                          primaryTypographyProps={{
                            style: {
                              fontSize: '14px',
                              paddingLeft: '10px',
                              color: value.indexOf(option.value) > -1 ? theme.palette.primary.main : '#606266',
                              fontWeight: value.indexOf(option.value) > -1 && !field.multiple ? 'bold' : '500',
                            },
                          }}
                        />

                        {value.indexOf(option.value) > -1 && field.multiple && <Done fontSize="inherit" sx={{ fontSize: '14px' }} />}
                      </MenuItem>
                    ))}
                  </Select>
                  {error && (
                    <Typography color="red" variant="caption">
                      {error.message}
                    </Typography>
                  )}
                </>
              )}
            />
          )}
        </FormControl>
      ))}
    </Box>
  );
};

export default DynamicForm;
