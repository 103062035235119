import React from 'react';
import { Box, Grid, FormControl, Select, MenuItem, TextField, Chip, Typography, IconButton, ListItemText, SelectChangeEvent } from '@mui/material';
import { Search, Cancel, KeyboardArrowDown, Done } from '@mui/icons-material';
import { LABEL_FOR_TABLE, LABEL_TOTAL_REFERRALS, LABEL_VIEW_AS, REFERRAL_SORT_BY, REFERRAL_STATUS } from '../../constants/applications';
import GridIcon from '../../icons/grid.svg';
import KanbanIcon from '../../icons/kanban.svg';
import TableIcon from '../../icons/table.svg';
import theme from '../../theme';

interface ReferralsFilterPanelProps {
  activeGrid: string;
  form: any;
  handleStatusChange: (event: SelectChangeEvent<string[]>) => void;
  handleSortChange: (event: SelectChangeEvent<string>) => void;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchTerm: string;
  totalReferrals: number;
  handleViewChange: (view: string) => void;
  handleDeleteChip: (chipToDelete: string) => void;
}

const ReferralsFilterPanel: React.FC<ReferralsFilterPanelProps> = ({ activeGrid, form, handleStatusChange, handleSortChange, handleSearchChange, searchTerm, totalReferrals, handleViewChange, handleDeleteChip }) => {
  const handleChipDelete = (chipToDelete: string) => (event: React.MouseEvent) => {
    event.stopPropagation();
    handleDeleteChip(chipToDelete);
  };

  return (
    <Box padding="12px">
      <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Grid container spacing={1} item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <Box marginBottom="10px">
                <Typography color="#303030" fontSize="0.75rem" fontWeight="500">
                  Status
                </Typography>
              </Box>
              <Select
                sx={{
                  height: '40px',
                  '& .MuiSelect-select': {
                    padding: '8px 5px !important',
                    display: 'flex',
                    alignItems: 'center',
                  },
                  backgroundColor: 'white',
                  '&.MuiOutlinedInput-root': {
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.33)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderWidth: '1px',
                      borderColor: theme.palette.primary.main,
                    },
                  },
                }}
                multiple
                value={form.status}
                placeholder="Select"
                onChange={handleStatusChange}
                IconComponent={KeyboardArrowDown}
                renderValue={(selected) => {
                  const selectedValues = selected as string[];
                  if (selectedValues.length > 1) {
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip
                          label={selectedValues[0]}
                          onDelete={(event) => {
                            event.stopPropagation();
                            handleDeleteChip(selectedValues[0]);
                          }}
                          deleteIcon={<Cancel fontSize="inherit" onMouseDown={handleChipDelete(selectedValues[0])} />}
                          sx={{
                            zIndex: 10000000,
                            backgroundColor: '#f5f4fc',
                            color: theme.palette.primary.main,
                            borderRadius: '4px',
                            fontSize: '0.75rem',
                            fontWeight: '500',
                            height: '24px',
                            '.MuiChip-deleteIcon': {
                              fontSize: '16px',
                              marginLeft: '4px',
                            },
                            '.MuiChip-label': {
                              paddingLeft: '10px',
                            },
                          }}
                        />
                        <Chip
                          label={`+ ${selectedValues.length - 1}`}
                          sx={{
                            backgroundColor: '#f5f4fc',
                            color: theme.palette.primary.main,
                            borderRadius: '4px',
                            fontSize: '0.75rem',
                            fontWeight: '500',
                            height: '24px',
                          }}
                        />
                      </Box>
                    );
                  }
                  return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {selectedValues.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          onDelete={(event) => {
                            event.stopPropagation();
                            handleDeleteChip(value);
                          }}
                          deleteIcon={<Cancel fontSize="inherit" onMouseDown={handleChipDelete(value)} />}
                          sx={{
                            backgroundColor: '#f5f4fc',
                            color: theme.palette.primary.main,
                            borderRadius: '4px',
                            fontSize: '0.75rem',
                            fontWeight: '500',
                            height: '24px',
                            '.MuiChip-deleteIcon': {
                              fontSize: '16px',
                              marginLeft: '0px',
                            },
                            '.MuiChip-label': {
                              paddingLeft: '8px',
                              paddingRight: '4px',
                            },
                          }}
                        />
                      ))}
                    </Box>
                  );
                }}
              >
                {REFERRAL_STATUS.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: 'transparent',
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                      },
                    }}
                  >
                    <ListItemText
                      primary={item.label}
                      primaryTypographyProps={{
                        style: {
                          fontSize: '14px',
                          fontWeight: form.status.indexOf(item.value) > -1 ? 'bold' : 'normal',
                          color: form.status.indexOf(item.value) > -1 ? theme.palette.primary.main : '#606266',
                        },
                      }}
                    />
                    {form.status.indexOf(item.value) > -1 && <Done fontSize="inherit" sx={{ fontSize: '14px' }} />}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {activeGrid !== LABEL_FOR_TABLE && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined">
                <Box marginBottom="10px">
                  <Typography color="#303030" fontSize="0.75rem" fontWeight="500">
                    Sort by
                  </Typography>
                </Box>
                <Select
                  sx={{
                    height: '40px',
                    border: '1px',
                    '& .MuiSelect-select': {
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: '14px',
                    },
                    backgroundColor: 'white',
                    '& .MuiSelect-icon': {
                      backgroundColor: 'white',
                      paddingRight: '7px',
                      right: '0',
                    },
                    ':hover': {
                      backroundColor: 'black',
                    },
                    '&.MuiOutlinedInput-root': {
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.33)',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1px',
                        borderColor: theme.palette.primary.main,
                      },
                    },
                  }}
                  value={form.sort}
                  IconComponent={KeyboardArrowDown}
                  onChange={handleSortChange}
                >
                  {REFERRAL_SORT_BY.map((item) => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: 'transparent',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: '#7100da',
                        },
                        '&.MuiMenuItem-root': {
                          backgroundColor: 'transparent',
                          fontSize: '14px',
                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            sx={{
              marginTop: '1.5rem',
              backgroundColor: 'white',
              '& .MuiInputBase-input': {
                fontSize: '14px',
              },
              '& .MuiOutlinedInput-root': {
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.33)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
            size="small"
            variant="outlined"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: <Search fontSize="inherit" sx={{ fontSize: '18px', marginRight: '10px' }} />,
            }}
          />
        </Grid>
      </Grid>
      <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize="12px" color="#828282">
          {LABEL_TOTAL_REFERRALS}: {totalReferrals}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography fontSize="12px" color="#303030" component="div">
            {LABEL_VIEW_AS}
          </Typography>
          <IconButton
            onClick={() => handleViewChange('grid')}
            sx={{
              marginLeft: '12px',
              borderRadius: 0,
              padding: '4px',
              color: activeGrid === 'grid' ? theme.palette.primary.main : '#606266',
              backgroundColor: activeGrid === 'grid' ? '#f5f4fc' : 'transparent',
              '&:hover': {
                backgroundColor: activeGrid === 'grid' ? '#e0dff8' : 'transparent',
              },
              '& img': {
                filter: activeGrid === 'grid' ? 'none' : 'invert(53%) sepia(1%) saturate(11%) hue-rotate(319deg) brightness(90%) contrast(87%)',
              },
            }}
          >
            <img src={GridIcon} alt="grid-icon" height="20px" width="20px" />
          </IconButton>
          <IconButton
            onClick={() => handleViewChange('kanban')}
            sx={{
              marginX: '1rem',
              borderRadius: 0,
              padding: '4px',
              color: activeGrid === 'kanban' ? theme.palette.primary.main : '#606266',
              backgroundColor: activeGrid === 'kanban' ? '#f5f4fc' : 'transparent',
              '&:hover': {
                backgroundColor: activeGrid === 'kanban' ? '#e0dff8' : 'transparent',
              },
              '& img': {
                filter: activeGrid === 'kanban' ? 'none' : 'invert(53%) sepia(1%) saturate(11%) hue-rotate(319deg) brightness(90%) contrast(87%)',
              },
            }}
          >
            <img src={KanbanIcon} alt="kanban-icon" height="20px" width="20px" />
          </IconButton>
          <IconButton
            onClick={() => handleViewChange('table')}
            sx={{
              borderRadius: 0,
              padding: '4px',
              color: activeGrid === 'table' ? theme.palette.primary.main : '#606266',
              backgroundColor: activeGrid === 'table' ? '#f5f4fc' : 'transparent',
              '&:hover': {
                backgroundColor: activeGrid === 'table' ? '#e0dff8' : 'transparent',
              },
              '& img': {
                filter: activeGrid === 'table' ? 'none' : 'invert(53%) sepia(1%) saturate(11%) hue-rotate(319deg) brightness(90%) contrast(87%)',
              },
            }}
          >
            <img src={TableIcon} alt="table-icon" height="20px" width="20px" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ReferralsFilterPanel;
