import React from 'react';
import { Box, Grid, FormControl, Select, MenuItem, TextField, Typography, IconButton, SelectChangeEvent } from '@mui/material';
import { Search, KeyboardArrowDown } from '@mui/icons-material';
import { LABEL_FOR_TABLE, LABEL_TOTAL_SETTLEMENTS, LABEL_VIEW_AS, REFERRAL_SORT_BY } from '../../constants/applications';
import GridIcon from '../../icons/grid.svg';
import TableIcon from '../../icons/table.svg';
import theme from '../../theme';

interface SettlementsFilterPanelProps {
  activeGrid: string;
  form: any;
  handleSortChange: (event: SelectChangeEvent<string>) => void;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchTerm: string;
  totalSettlements: number;
  handleSettlementViewChange: (view: string) => void;
}

const SettlementsFilterPanel: React.FC<SettlementsFilterPanelProps> = ({ activeGrid, form, handleSortChange, handleSearchChange, searchTerm, totalSettlements, handleSettlementViewChange }) => (
  <Box padding="12px">
    <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Grid container spacing={1} item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {activeGrid !== LABEL_FOR_TABLE && (
          <Grid item xs={12} sm={8}>
            <FormControl fullWidth variant="outlined">
              <Box marginBottom="10px">
                <Typography color="#303030" fontSize="0.75rem" fontWeight="500">
                  Sort by
                </Typography>
              </Box>
              <Select
                sx={{
                  height: '40px',
                  border: '1px',
                  '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                  },
                  backgroundColor: 'white',
                  '& .MuiSelect-icon': {
                    backgroundColor: 'white',
                    paddingRight: '7px',
                    right: '0',
                  },
                  ':hover': {
                    backroundColor: 'black',
                  },
                  '&.MuiOutlinedInput-root': {
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0, 0, 0, 0.33)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderWidth: '1px',
                      borderColor: theme.palette.primary.main,
                    },
                  },
                }}
                value={form.sort}
                IconComponent={KeyboardArrowDown}
                onChange={handleSortChange}
              >
                {REFERRAL_SORT_BY.map((item) => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: 'transparent',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        color: '#7100da',
                      },
                      '&.MuiMenuItem-root': {
                        backgroundColor: 'transparent',
                        fontSize: '14px',
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          fullWidth
          sx={{
            marginTop: '1.5rem',
            backgroundColor: 'white',
            '& .MuiInputBase-input': {
              fontSize: '14px',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.33)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
              },
            },
          }}
          size="small"
          variant="outlined"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <Search fontSize="inherit" sx={{ fontSize: '18px', marginRight: '10px' }} />,
          }}
        />
      </Grid>
    </Grid>
    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
      <Typography fontSize="12px" color="#828282">
        {LABEL_TOTAL_SETTLEMENTS}: {totalSettlements}
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography fontSize="12px" color="#303030">
          {LABEL_VIEW_AS}
        </Typography>
        <IconButton
          onClick={() => handleSettlementViewChange('grid')}
          sx={{
            marginLeft: '12px',
            borderRadius: 0,
            padding: '4px',
            color: activeGrid === 'grid' ? theme.palette.primary.main : '#606266',
            backgroundColor: activeGrid === 'grid' ? '#f5f4fc' : 'transparent',
            '&:hover': {
              backgroundColor: activeGrid === 'grid' ? '#e0dff8' : 'transparent',
            },
            '& img': {
              filter: activeGrid === 'grid' ? 'none' : 'invert(53%) sepia(1%) saturate(11%) hue-rotate(319deg) brightness(90%) contrast(87%)',
            },
          }}
        >
          <img src={GridIcon} alt="grid-icon" height="20px" width="20px" />
        </IconButton>
        <IconButton
          onClick={() => handleSettlementViewChange('table')}
          sx={{
            borderRadius: 0,
            padding: '4px',
            color: activeGrid === 'table' ? theme.palette.primary.main : '#606266',
            backgroundColor: activeGrid === 'table' ? '#f5f4fc' : 'transparent',
            '&:hover': {
              backgroundColor: activeGrid === 'table' ? '#e0dff8' : 'transparent',
            },
            '& img': {
              filter: activeGrid === 'table' ? 'none' : 'invert(53%) sepia(1%) saturate(11%) hue-rotate(319deg) brightness(90%) contrast(87%)',
            },
          }}
        >
          <img src={TableIcon} alt="table-icon" height="20px" width="20px" />
        </IconButton>
      </Box>
    </Box>
  </Box>
);

export default SettlementsFilterPanel;
