import React, { useState } from 'react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import PageHeader from 'components/PageHeader';
import QuickReferralConsumer from 'components/QuickRefferalConsumer';
import Sidebar from '../../../shared/SideBar';
import theme from '../../../theme';
import MenuIcon from '../../../icons/menu.svg';

export interface Referral {
  id: string;
  User: string;
  status: string;
  updatedAt: string;
  loanAmount: number | string;
  agent?: string;
  subaccount?: string;
  referrer?: string;
  createdAt?: string;
  details?: string;
}

const ReferralForm: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpen, setIsOpen] = useState<boolean>(!isMobile);

  const toggleDrawer = () => setIsOpen(!isOpen);

  return (
    <Box height="100%" display="flex" bgcolor="#fafafa">
      {isOpen && (
        <IconButton sx={{ marginLeft: '10px', marginTop: '5px', position: 'absolute', zIndex: 10000 }} onClick={toggleDrawer} edge="start" color="inherit" aria-label="menu">
          <img src={MenuIcon} alt="menu" height="24px" />
        </IconButton>
      )}
      <Sidebar
        open={isOpen}
        menuItems={[
          { text: 'Referrals', path: '/referrals' },
          { text: 'Settlements', path: '/referrals/settlements' },
        ]}
        color={theme.palette.primary.main}
        drawerStyle={{
          position: 'relative',
          borderRight: '1px solid #e8e8e8',
          backgroundColor: '#fcf8fd',
          width: isOpen ? '220px' : '0px',
          height: '100%',
          paddingTop: '40px',
          paddingLeft: '5px',
          flexShrink: 0,
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100vh', overflow: 'hidden' }}>
        <PageHeader headerName="Quick Referral" open={isOpen} toggleDrawer={toggleDrawer} />
        <QuickReferralConsumer />
      </Box>
    </Box>
  );
};

export default ReferralForm;
