import { Box, Grid, CircularProgress } from '@mui/material';
import { useEffect, useRef } from 'react';
import { Settlement, SettlementStatus } from 'pages/referrals/Settlements';
import SettlementCard from 'components/SettlementCard';
import SettlementsTableView from 'components/SettlementsTableView';

interface SettlementsContentAreaProps {
  isViewLoading: boolean;
  activeGrid: string;
  settlementList: Settlement[];
  form: SettlementStatus;
  setForm: React.Dispatch<React.SetStateAction<SettlementStatus>>;
  fetchSettlements: () => void;
  loadMoreSettlements: () => void;
  totalSettlements: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const SettlementsContentArea: React.FC<SettlementsContentAreaProps> = ({ isViewLoading, activeGrid, settlementList, form, setForm, fetchSettlements, loadMoreSettlements, totalSettlements, page, setPage }) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isViewLoading) {
        loadMoreSettlements();
      }
    });

    if (lastElementRef.current) observer.current.observe(lastElementRef.current);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loadMoreSettlements, isViewLoading]);

  return (
    <Box paddingY="0px" paddingX="12px" height="78%">
      {activeGrid === 'grid' ? (
        <Grid container spacing={2}>
          {settlementList.map((settlement, index) => (
            <Grid item xs={12} sm={settlementList.length === 1 ? 6 : 6} md={settlementList.length === 1 ? 6 : 6} key={index} ref={index === settlementList.length - 1 ? lastElementRef : null}>
              <SettlementCard id={settlement.id} name={settlement.User} loanAmount={settlement.commission} updatedAt={settlement.updatedAt} status={settlement.status} reason={settlement.details} naf={settlement.naf} details={settlement} />
            </Grid>
          ))}
          {isViewLoading && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 5 }}>
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      ) : (
        <Box sx={{ backgroundColor: 'white', borderRadius: '4px', boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.05)' }} padding="16px">
          <SettlementsTableView data={settlementList} form={form} setForm={setForm} fetchSettlements={fetchSettlements} totalSettlements={totalSettlements} page={page} setPage={setPage} />
        </Box>
      )}
    </Box>
  );
};

export default SettlementsContentArea;
