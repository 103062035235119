import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          "x-access-token": `${token}`,
          Refreshtoken: `${refreshToken}`,
        },
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
