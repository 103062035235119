import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Container, Link, Typography } from '@mui/material';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { axiosErrorMessageExtractor } from '../../utils/error';
import { ERROR_MESSAGE_FOR_INVALID_EMAIL, ERROR_MESSAGE_FOR_EMAIL, LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE, LABEL_FOR_EMAIL, REGEX_FOR_EMAIL, SUCCESS_MESSAGE_EMAIL_PASSWORD_RESET } from '../../constants';
import theme from '../../theme';
import SnackbarAlert, { SnackBarProps } from '../../shared/SnackbarAlert';
import Button from '../../shared/Button';
import DynamicForm, { FormField } from '../../shared/Form';

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const methods = useForm();

  const [isRequestCodeLoading, setIsRequestCodeLoading] = useState<boolean>(false);
  const [snackbarAlert, setSnackbarAlert] = useState<SnackBarProps>({
    open: false,
    message: '',
    severity: 'error',
    onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
  });

  const handleBack = () => {
    history.push('/auth/email-login');
  };

  const validateEmail = (email: string): string => {
    if (email === '' || email === undefined) {
      return ERROR_MESSAGE_FOR_EMAIL;
    }
    return REGEX_FOR_EMAIL.test(email) ? '' : ERROR_MESSAGE_FOR_INVALID_EMAIL;
  };

  const formFields: FormField[] = [
    {
      label: LABEL_FOR_EMAIL,
      name: 'email',
      type: 'email',
      placeHolder: 'name@domain.com',
      validateField: validateEmail,
    },
  ];

  const handleForgotPassword = async (email: any) => {
    try {
      setIsRequestCodeLoading(true);
      if (!email) {
        return;
      }

      await axios.patch(process.env.REACT_APP_API_URL + '/auth/forgot-password', {
        email,
      });

      setSnackbarAlert({
        open: true,
        message: SUCCESS_MESSAGE_EMAIL_PASSWORD_RESET,
        severity: 'success',
        onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
      });
      setTimeout(() => {
        history.push('/auth/email-login');
      }, 5000);
    } catch (err: any) {
      const errorMessage = axiosErrorMessageExtractor(err);
      setSnackbarAlert({
        open: true,
        message: errorMessage,
        severity: 'error',
        onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
      });
    } finally {
      setIsRequestCodeLoading(false);
    }
  };

  const onSubmit = async (formData: Record<string, any>) => {
    const validationErrors = formFields.reduce(
      (errors, field) => {
        if (field.validateField) {
          const errorMessage = field.validateField(formData[field.name]);
          if (errorMessage) {
            errors[field.name] = errorMessage;
          }
        }
        return errors;
      },
      {} as Record<string, string>
    );

    if (Object.keys(validationErrors).length > 0) {
      Object.entries(validationErrors).forEach(([name, error]) => {
        methods.setError(name, { type: 'manual', message: error });
      });
      return;
    }

    setIsRequestCodeLoading(true);
    try {
      await handleForgotPassword(formData.email);
    } finally {
      setIsRequestCodeLoading(false);
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 7rem)',
      }}
      maxWidth="sm"
    >
      <Box sx={{ width: '90%', maxWidth: { xs: '100%', sm: '90%', md: '600px' } }}>
        <Box sx={{ display: 'flex', marginBottom: '25px', justifyContent: 'space-between' }}>
          <Typography component="div" style={{ fontWeight: '600', fontSize: '24px' }}>
            Forgot password?
          </Typography>
          <Button onPress={handleBack} disableRipple variantType="filled" color={theme.palette.primary.main} sx={{ paddingRight: '0px' }}>
            <Typography component="span" fontWeight="800" fontSize="16px">
              Back to Login
            </Typography>
          </Button>
        </Box>

        <Box
          sx={{
            backgroundColor: '#fafafa',
            border: '1px solid #e8e8e8',
            padding: '24px',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <DynamicForm fields={formFields} />
              <Button type="submit" variantType="full" color={theme.palette.primary.main} sx={{ width: '100%' }}>
                <Typography component="span" fontSize="14px" fontWeight="800">
                  {isRequestCodeLoading ? 'Loading...' : 'Submit'}
                </Typography>
              </Button>
            </form>
          </FormProvider>
        </Box>
        <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
          <Typography component="div" fontSize="0.875rem" color="#303030" marginBottom="0.5rem">
            For any technical assistance
          </Typography>
          <Link href={`mailto:tech-support@carclarity.com.au?subject=${LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE}`} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
            <Typography component="span" fontSize="14px" fontWeight="800" color={theme.palette.primary.main}>
              Contact Support
            </Typography>
          </Link>
        </Box>
      </Box>
      {snackbarAlert.message && <SnackbarAlert autoHideDuration={3000} message={snackbarAlert.message} severity={snackbarAlert.severity} open={snackbarAlert.open} onClose={snackbarAlert.onClose} />}
    </Container>
  );
};

export default ResetPassword;
