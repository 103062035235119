import get from 'lodash/get';

export const axiosErrorMessageExtractor = (
  e: { message: string; response: { data: { error: any } } },
  fallbackMsg = 'Your request could not be processed'
) => {
  try {
    if (e.message === 'Network Error') {
      return 'New updates are getting deployed right now. Please try to login again few minutes';
    }
    return e.response?.data.error || fallbackMsg;
  } catch (err) {
    return fallbackMsg;
  }
};

export const axiosStatusCode = (e: any) => get(e, 'response.status');
