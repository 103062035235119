import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Typography, Drawer, List, ListItem, ListItemButton } from '@mui/material';

interface SidebarProps {
  open: boolean;
  menuItems: {
    text: string;
    path: string;
  }[];
  color?: string;
  drawerStyle?: React.CSSProperties;
}

const Sidebar: React.FC<SidebarProps> = ({ open, menuItems, color, drawerStyle }) => {
  const location = useLocation();

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      PaperProps={{
        sx: {
          ...drawerStyle,
        },
      }}
      sx={{
        height: '100vh',
      }}
      transitionDuration={0}
    >
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.text} disablePadding sx={{ height: '45px' }}>
            <ListItemButton
              component={RouterLink}
              to={item.path}
              selected={location.pathname === item.path}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                  color,
                },
                '&.Mui-selected:hover': { backgroundColor: 'transparent' },
                '&:hover': {
                  color: location.pathname === item.path ? '' : color,
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Typography fontSize="12px" fontWeight={location.pathname === item.path ? '800' : '500'}>
                {item.text}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
