import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { LABEL_THANK_YOU_ONE_OF_OUR_TEAM, LABEL_THANK_YOU_YOU_WILL_SEE_STATUS_UPDATES } from 'constants/referrals';
import Button from 'shared/Button';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import { FieldValues, UseFormReset } from 'react-hook-form';
import ReferrerDialogBox from 'components/ReferrerDialogBox';
import ThankYou from '../../icons/thankYou.svg';

interface ThankYouComponentProps {
  reset: UseFormReset<FieldValues>;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThankYouComponent: React.FC<ThankYouComponentProps> = ({ reset, setIsSuccess }) => {
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          backgroundColor: '#f7f7f7',
        }}
      >
        <Box
          sx={{
            width: '100%',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            paddingTop: '40px',
            textAlign: 'center',
          }}
        >
          <img src={ThankYou} width="65px" height="65px" alt="Thank You" />
          <Typography fontSize="20px" fontWeight="800" sx={{ marginTop: '16px' }}>
            Thank you!
          </Typography>
          <Typography fontSize="14px" color="#303133" sx={{ marginTop: '8px' }}>
            Referral to CarClarity has been submitted successfully
          </Typography>
          <Typography fontSize="16px" fontWeight="700" sx={{ marginTop: '16px' }}>
            What's next!
          </Typography>
          <Box
            sx={{
              padding: '16px',
              textAlign: 'left',
              marginBottom: '16px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '306px',
                bgcolor: '#13B5E81A',
                padding: '8px 12px',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '16px',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  width: '22px',
                  height: '22px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#ffffff',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  lineHeight: '22px',
                  border: '1.5px solid #000',
                  borderRadius: '50%',
                  marginRight: '13px',
                  flex: '0 0 auto',
                }}
              >
                1
              </span>
              <Typography fontSize="12px" color="#303133">
                {LABEL_THANK_YOU_ONE_OF_OUR_TEAM}
              </Typography>
            </Box>
            <Box
              sx={{
                width: '306px',
                bgcolor: '#13B5E81A',
                padding: '8px 12px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  width: '22px',
                  height: '22px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#ffffff',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  lineHeight: '22px',
                  border: '1.5px solid #000',
                  borderRadius: '50%',
                  marginRight: '13px',
                  flex: '0 0 auto',
                }}
              >
                2
              </span>
              <Typography fontSize="12px" color="#303133">
                {LABEL_THANK_YOU_YOU_WILL_SEE_STATUS_UPDATES}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', marginBottom: '16px', justifyContent: 'center' }}>
            <Button
              variantType="outlined"
              color={theme.palette.primary.main}
              sx={{ textTransform: 'none', height: '32px' }}
              onClick={() => {
                reset();
                setIsDialogOpen(true);
              }}
            >
              <Typography fontSize=".875rem" fontWeight="800">
                + Refer another customer
              </Typography>
            </Button>
          </Box>
          <Box sx={{ display: 'flex', marginBottom: '16px', justifyContent: 'center' }}>
            <Button
              variantType="filled"
              color={theme.palette.primary.main}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                history.push('/referrals');
              }}
            >
              <Typography fontSize=".875rem" fontWeight="800">
                Back to home
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <ReferrerDialogBox open={isDialogOpen} handleClose={handleDialogClose} isFullReferral={false} setIsSuccess={setIsSuccess} />
    </>
  );
};

export default ThankYouComponent;
