import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Divider, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Consumer from '../../icons/ref-consumer.svg';
import Commercial from '../../icons/ref-commercial.svg';
import Button from '../../shared/Button';
import theme from '../../theme';

interface ReferrerDialogBoxProps {
  open: boolean;
  handleClose?: () => void;
  isFullReferral?: boolean;
  setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReferrerDialogBox: React.FC<ReferrerDialogBoxProps> = ({ open, handleClose, isFullReferral, setIsSuccess }) => {
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedLoanType, setSelectedLoanType] = useState<string | null>(null);
  const history = useHistory();

  const handleCloseDialog = () => {
    if (handleClose) {
      setSelectedType(null);
      setSelectedLoanType(null);
      handleClose();
    }
  };

  const handleSelectType = (type: string) => {
    setSelectedType(type);
    setSelectedLoanType(null);
  };

  const handleSelectLoanType = (loanType: string) => {
    setSelectedLoanType(loanType);
  };

  const handleContinue = () => {
    if (setIsSuccess) {
      setIsSuccess(false);
    }
    history.push(`/referrals/${selectedType}?quickReferral=true&isCollapse=false&isSuccess=false`);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth={true}
      PaperProps={{
        sx: {
          boxShadow: 'none',
          borderRadius: '0',
          width: '80%',
          maxWidth: { xs: '80%', sm: '70%', md: '25%' },
        },
      }}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', paddingLeft: '15px', paddingTop: '1rem', justifyContent: 'center', alignItems: 'center', marginTop: '25px' }}>
        <Typography fontSize="14px" fontWeight="800">
          Please select the referral type
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', paddingX: '15px', padding: '16px 80px' }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            bgcolor={selectedType === 'consumer' ? '#f7fcfc' : 'white'}
            width="50%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginRight="20px"
            padding="20px"
            borderRadius="12px"
            border={selectedType === 'consumer' ? '2px solid #59c4c2' : '1px solid #e8e8e8'}
            onClick={() => handleSelectType('consumer')}
            sx={{ cursor: 'pointer' }}
          >
            <img src={Consumer} alt="Consumer" height="72px" />
            <Typography color={selectedType === 'consumer' ? theme.palette.primary.main : '#606266'} fontWeight={selectedType === 'consumer' ? '500' : '400'} fontSize="14px" marginTop="10px">
              Consumer
            </Typography>
          </Box>
          <Box
            bgcolor={selectedType === 'commercial' ? '#f7fcfc' : 'white'}
            width="50%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="20px"
            borderRadius="12px"
            border={selectedType === 'commercial' ? '2px solid #59c4c2' : '1px solid #e8e8e8'}
            onClick={() => handleSelectType('commercial')}
            sx={{ cursor: 'pointer' }}
          >
            <img src={Commercial} alt="commercial" height="72px" />
            <Typography color={selectedType === 'commercial' ? theme.palette.primary.main : '#606266'} fontWeight={selectedType === 'commercial' ? '500' : '400'} fontSize="14px" marginTop="10px">
              Commercial
            </Typography>
          </Box>
        </Box>
        {isFullReferral && selectedType && (
          <>
            <Divider sx={{ marginY: '14px' }} />
            <Typography fontSize="14px" fontWeight="800" sx={{ marginBottom: '14px', textAlign: 'center' }}>
              Please select the loan type
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box
                bgcolor={selectedLoanType === 'Car loan' ? '#f7fcfc' : 'white'}
                width="50%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginRight="20px"
                padding="10px 8px 8px"
                borderRadius="4px"
                border={selectedLoanType === 'Car loan' ? '2px solid #59c4c2' : '1px solid #e8e8e8'}
                onClick={() => handleSelectLoanType('Car loan')}
                sx={{ cursor: 'pointer' }}
              >
                <Typography color={selectedLoanType === 'Car loan' ? theme.palette.primary.main : '#606266'} fontWeight={selectedLoanType === 'Car loan' ? '500' : '400'} fontSize="14px">
                  Car loan
                </Typography>
              </Box>
              <Box
                bgcolor={selectedLoanType === 'Personal loan' ? '#f7fcfc' : 'white'}
                width="50%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                padding="10px 8px 8px"
                borderRadius="4px"
                border={selectedLoanType === 'Personal loan' ? '2px solid #59c4c2' : '1px solid #e8e8e8'}
                onClick={() => handleSelectLoanType('Personal loan')}
                sx={{ cursor: 'pointer' }}
              >
                <Typography color={selectedLoanType === 'Personal loan' ? theme.palette.primary.main : '#606266'} fontWeight={selectedLoanType === 'Personal loan' ? '500' : '400'} fontSize="14px">
                  Personal loan
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between', padding: '0px 20px 15px 10px', paddingY: '0.725rem' }}>
        <Button variantType="filled" onClick={handleCloseDialog}>
          <Typography fontWeight="800" fontSize="14px">
            Cancel
          </Typography>
        </Button>
        <Button variantType="full" color={theme.palette.primary.main} onClick={handleContinue} sx={{ height: '33px', width: '100px' }} disabled={!selectedType || (!selectedLoanType && isFullReferral)}>
          <Typography fontWeight="800" fontSize="14px">
            Continue
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReferrerDialogBox;
