import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import App from './App';
import packageJson from '../package.json';

const appVersion = packageJson.version;

datadogLogs.init({
  clientToken: process.env.VITE_DD_CLIENT_TOKEN!,
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: 'accelerate',
  env: process.env.VITE_ENVIRONMENT,
  version: appVersion,
});

datadogRum.init({
  applicationId: process.env.VITE_DD_APPLICATION_ID!,
  clientToken: process.env.VITE_DD_CLIENT_TOKEN!,
  site: 'datadoghq.com',
  service: 'accelerate',
  env: process.env.VITE_ENVIRONMENT,
  version: appVersion,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'allow',
  allowedTracingUrls: [(url) => url.startsWith(process.env.VITE_DD_CARCLARITY_API_URL!)],
  traceSampleRate: 100,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
