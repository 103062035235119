export const DEFAULT_APP_TITLE = 'cc-portal';

export const VEHICLE_BUYING_REQUEST_STATUS_PENDING = 'Pending';
export const VEHICLE_BUYING_REQUEST_STATUS_DECIDE_LATER = 'Decide Later';
export const VEHICLE_BUYING_REQUEST_STATUS_OPEN = 'Open';
export const VEHICLE_BUYING_REQUEST_STATUS_AWAITING_DEPOSIT = 'Awaiting Deposit';
export const VEHICLE_BUYING_REQUEST_STATUS_DEPOSIT_RECEIVED = 'Deposit Received';
export const VEHICLE_BUYING_REQUEST_STATUS_AWAITING_PRICING = 'Awaiting Pricing';
export const VEHICLE_BUYING_REQUEST_STATUS_SENT_OFFER = 'Sent Offer';
export const VEHICLE_BUYING_REQUEST_STATUS_OFFER_ACCEPTED = 'Offer Accepted';
export const VEHICLE_BUYING_REQUEST_STATUS_OPEN_ORDER = 'Open Order';
export const VEHICLE_BUYING_REQUEST_STATUS_ORDERED = 'Ordered';
export const VEHICLE_BUYING_REQUEST_STATUS_WON = 'Won';
export const VEHICLE_BUYING_REQUEST_STATUS_LOST = 'Lost';

export const VEHICLE_BUYING_REQUEST_STATUSES = [
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_PENDING,
    value: VEHICLE_BUYING_REQUEST_STATUS_PENDING,
  },
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_DECIDE_LATER,
    value: VEHICLE_BUYING_REQUEST_STATUS_DECIDE_LATER,
  },
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_OPEN,
    value: VEHICLE_BUYING_REQUEST_STATUS_OPEN,
  },
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_AWAITING_DEPOSIT,
    value: VEHICLE_BUYING_REQUEST_STATUS_AWAITING_DEPOSIT,
  },
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_DEPOSIT_RECEIVED,
    value: VEHICLE_BUYING_REQUEST_STATUS_DEPOSIT_RECEIVED,
  },
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_AWAITING_PRICING,
    value: VEHICLE_BUYING_REQUEST_STATUS_AWAITING_PRICING,
  },
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_SENT_OFFER,
    value: VEHICLE_BUYING_REQUEST_STATUS_SENT_OFFER,
  },
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_OFFER_ACCEPTED,
    value: VEHICLE_BUYING_REQUEST_STATUS_OFFER_ACCEPTED,
  },
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_OPEN_ORDER,
    value: VEHICLE_BUYING_REQUEST_STATUS_OPEN_ORDER,
  },
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_ORDERED,
    value: VEHICLE_BUYING_REQUEST_STATUS_ORDERED,
  },
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_WON,
    value: VEHICLE_BUYING_REQUEST_STATUS_WON,
  },
  {
    label: VEHICLE_BUYING_REQUEST_STATUS_LOST,
    value: VEHICLE_BUYING_REQUEST_STATUS_LOST,
  },
];

export const APPLICATION_STATUS_STARTED = 'Started';
export const APPLICATION_STATUS_AWAITING_DOCS = 'Awaiting Docs';
export const APPLICATION_STATUS_DOCUMENTS_RECEIVED = 'Documents Received';
export const APPLICATION_STATUS_READY_FOR_SUBMISSION = 'Ready For Submission';
export const APPLICATION_STATUS_SUBMITTED_TO_LENDER = 'Submitted To Lender';
export const APPLICATION_STATUS_DECLINED = 'Declined';
export const APPLICATION_STATUS_ADDITIONAL_INFO = 'Additional Info';
export const APPLICATION_STATUS_APPROVED = 'Approved';
export const APPLICATION_STATUS_APPROVED_CREDIT_PROPOSAL_REVIEW = 'Approved Credit Proposal Review';
export const APPLICATION_STATUS_APPROVED_VEHICLE_SOURCING = 'Approved Vehicle Sourcing';
export const APPLICATION_STATUS_PRE_SETTLEMENT = 'Pre-Settlement';
export const APPLICATION_STATUS_SUBMITTED_FOR_SETTLEMENT = 'Submitted For Settlement';
export const APPLICATION_STATUS_SETTLED = 'Settled';
export const APPLICATION_STATUS_WITHDRAWN = 'Withdrawn';

export const APPLICATION_STATUSES = [APPLICATION_STATUS_STARTED, APPLICATION_STATUS_AWAITING_DOCS, APPLICATION_STATUS_DOCUMENTS_RECEIVED, APPLICATION_STATUS_READY_FOR_SUBMISSION, APPLICATION_STATUS_SUBMITTED_TO_LENDER, APPLICATION_STATUS_DECLINED, APPLICATION_STATUS_ADDITIONAL_INFO, APPLICATION_STATUS_APPROVED, APPLICATION_STATUS_APPROVED_CREDIT_PROPOSAL_REVIEW, APPLICATION_STATUS_APPROVED_VEHICLE_SOURCING, APPLICATION_STATUS_PRE_SETTLEMENT, APPLICATION_STATUS_SUBMITTED_FOR_SETTLEMENT, APPLICATION_STATUS_SETTLED, APPLICATION_STATUS_WITHDRAWN];
export const SETTLEMENT_APPLICATION_STATUSES = [APPLICATION_STATUS_PRE_SETTLEMENT, APPLICATION_STATUS_SUBMITTED_FOR_SETTLEMENT, APPLICATION_STATUS_SETTLED];

export const CUSTOM_APPLICATION_STATUSES = [
  {
    label: APPLICATION_STATUS_STARTED,
    value: APPLICATION_STATUS_STARTED,
    isDisabled: false,
  },
  {
    label: APPLICATION_STATUS_AWAITING_DOCS,
    value: APPLICATION_STATUS_AWAITING_DOCS,
    isDisabled: false,
  },
  {
    label: APPLICATION_STATUS_DOCUMENTS_RECEIVED,
    value: APPLICATION_STATUS_DOCUMENTS_RECEIVED,
    isDisabled: false,
  },
  {
    label: APPLICATION_STATUS_READY_FOR_SUBMISSION,
    value: APPLICATION_STATUS_READY_FOR_SUBMISSION,
    isDisabled: false,
  },
  {
    label: APPLICATION_STATUS_SUBMITTED_TO_LENDER,
    value: APPLICATION_STATUS_SUBMITTED_TO_LENDER,
    isDisabled: true,
  },
  {
    label: APPLICATION_STATUS_DECLINED,
    value: APPLICATION_STATUS_DECLINED,
    isDisabled: true,
  },
  {
    label: APPLICATION_STATUS_ADDITIONAL_INFO,
    value: APPLICATION_STATUS_ADDITIONAL_INFO,
    isDisabled: true,
  },
  {
    label: APPLICATION_STATUS_APPROVED,
    value: APPLICATION_STATUS_APPROVED,
    isDisabled: true,
  },
  {
    label: APPLICATION_STATUS_APPROVED_CREDIT_PROPOSAL_REVIEW,
    value: APPLICATION_STATUS_APPROVED_CREDIT_PROPOSAL_REVIEW,
    isDisabled: true,
  },
  {
    label: APPLICATION_STATUS_APPROVED_VEHICLE_SOURCING,
    value: APPLICATION_STATUS_APPROVED_VEHICLE_SOURCING,
    isDisabled: true,
  },
  {
    label: APPLICATION_STATUS_PRE_SETTLEMENT,
    value: APPLICATION_STATUS_PRE_SETTLEMENT,
    isDisabled: true,
  },
  {
    label: APPLICATION_STATUS_SUBMITTED_FOR_SETTLEMENT,
    value: APPLICATION_STATUS_SUBMITTED_FOR_SETTLEMENT,
    isDisabled: true,
  },
  {
    label: APPLICATION_STATUS_SETTLED,
    value: APPLICATION_STATUS_SETTLED,
    isDisabled: true,
  },
  {
    label: APPLICATION_STATUS_WITHDRAWN,
    value: APPLICATION_STATUS_WITHDRAWN,
    isDisabled: false,
  },
];
export const LABEL_PREFERRED_COMMUNICATION_METHOD = 'Preferred communication method';
export const SETTLEMENT_STATUS_INPROGRESS = 'In Progress';
export const SETTLEMENT_STATUS_RECEIVED = 'Settlement Received';
export const SETTLEMENT_STATUS_REWORK = 'Settlement Rework';
export const SETTLEMENT_STATUS_AWAITIND_DOCS = 'Awaiting Documents';
export const SETTLEMENT_STATUS_SUBMITTED_FOR_SETTLEMENT = 'Submitted for Settlement';

export const CUSTOM_SETTLEMENT_APPLICATION_STATUSES = [
  {
    label: SETTLEMENT_STATUS_INPROGRESS,
    value: SETTLEMENT_STATUS_INPROGRESS,
    isDisabled: false,
  },
  {
    label: SETTLEMENT_STATUS_AWAITIND_DOCS,
    value: SETTLEMENT_STATUS_AWAITIND_DOCS,
    isDisabled: false,
  },
  {
    label: SETTLEMENT_STATUS_SUBMITTED_FOR_SETTLEMENT,
    value: SETTLEMENT_STATUS_SUBMITTED_FOR_SETTLEMENT,
    isDisabled: false,
  },
  {
    label: SETTLEMENT_STATUS_RECEIVED,
    value: SETTLEMENT_STATUS_RECEIVED,
    isDisabled: false,
  },
  {
    label: SETTLEMENT_STATUS_REWORK,
    value: SETTLEMENT_STATUS_REWORK,
    isDisabled: false,
  },
  {
    label: APPLICATION_STATUS_SETTLED,
    value: APPLICATION_STATUS_SETTLED,
    isDisabled: true,
  },
];
export const SETTLEMENT_APPLICATION_FILTER_STATUSES = [SETTLEMENT_STATUS_INPROGRESS, SETTLEMENT_STATUS_AWAITIND_DOCS, SETTLEMENT_STATUS_SUBMITTED_FOR_SETTLEMENT, SETTLEMENT_STATUS_RECEIVED, SETTLEMENT_STATUS_REWORK, APPLICATION_STATUS_SETTLED];
export const APPLICATION_REQUEST_TYPE_ALL = 'All';
export const APPLICATION_REQUEST_TYPE_CONSUMER = 'Consumer';
export const APPLICATION_REQUEST_TYPE_COMMERCIAL = 'Commercial';

export const APPLICATION_REQUEST_TYPE = [
  {
    label: APPLICATION_REQUEST_TYPE_ALL,
    value: APPLICATION_REQUEST_TYPE_ALL,
  },
  {
    label: APPLICATION_REQUEST_TYPE_CONSUMER,
    value: APPLICATION_REQUEST_TYPE_CONSUMER,
  },
  {
    label: APPLICATION_REQUEST_TYPE_COMMERCIAL,
    value: APPLICATION_REQUEST_TYPE_COMMERCIAL,
  },
];

export const VEHICLE_BUYING_QUOTE_STATUS_OPEN = 'Open';
export const VEHICLE_BUYING_QUOTE_STATUS_ACCEPTED = 'Accepted';
export const VEHICLE__BUYING_QUOTE_STATUS_EXPIRED = 'Expired';
export const VEHICLE_BUYING_QUOTE_STATUS_CANCELLED = 'Cancelled';

export const VEHICLE_BUYING_QUOTE_STATUS_OPTIONS = [VEHICLE_BUYING_QUOTE_STATUS_OPEN, VEHICLE_BUYING_QUOTE_STATUS_ACCEPTED, VEHICLE__BUYING_QUOTE_STATUS_EXPIRED, VEHICLE_BUYING_QUOTE_STATUS_CANCELLED];

export const VEHICLE_BUYING_PRODUCT_NAME_LIST_PRICE = 'List Price';
export const VEHICLE_BUYING_PRODUCT_NAME_ACCESSORY = 'Accessory';
export const VEHICLE_BUYING_PRODUCT_NAME_OPTION_PACK = 'Option Pack';
export const VEHICLE_BUYING_PRODUCT_NAME_OPTION = 'Option';
export const VEHICLE_BUYING_PRODUCT_NAME_DISCOUNT = 'Discount';
export const VEHICLE_BUYING_PRODUCT_NAME_DEALER_DELIVERY = 'Dealer Delivery';

export const USER_TYPE_SUPER_ADMIN_VALUE = 'super admin';

export const USER_TYPE_SUPER_ADMIN = 'super_admin';
export const USER_TYPE_ADMIN = 'admin';
export const USER_TYPE_AGENT = 'agent';
export const USER_TYPE_REFERRER = 'referrer';
export const USER_TYPE_SETTLEMENT = 'settlement';
export const PERMITTED_AGENT_TYPES = [USER_TYPE_ADMIN, USER_TYPE_AGENT];

export const VEHICLE_BUYING_PRODUCT_NAMES = [VEHICLE_BUYING_PRODUCT_NAME_LIST_PRICE, VEHICLE_BUYING_PRODUCT_NAME_ACCESSORY, VEHICLE_BUYING_PRODUCT_NAME_OPTION_PACK, VEHICLE_BUYING_PRODUCT_NAME_OPTION, VEHICLE_BUYING_PRODUCT_NAME_DISCOUNT, VEHICLE_BUYING_PRODUCT_NAME_DEALER_DELIVERY];

export const ERROR_MESSAGE_FOR_UNAUTHORIZED_ACCESS = 'You are not authorised to visit that page';

export const ERROR_MESSAGE_FOR_EMAIL = 'Please enter email address';
export const ERROR_MESSAGE_FOR_PHONE = 'Please input mobile number';
export const ERROR_MESSAGE_FOR_INVALID_EMAIL = 'Please enter correct email address';
export const ERROR_MESSAGE_FOR_ABN_LENGTH = 'ABN must contain 11 characters';
export const ERROR_MESSAGE_FOR_PASSWORD = 'Please enter a Password';
export const ERROR_MESSAGE_FOR_PASSWORD_LENGTH = 'Password length must contain atleast 8 characters';
export const ERROR_MESSAGE_FOR_OLD_PASSWORD = 'Old Password is required';
export const ERROR_MESSAGE_FOR_NEW_PASSWORD = 'New Password is required';
export const ERROR_MESSAGE_FOR_FIRST_NAME = 'Please enter first name';
export const ERROR_MESSAGE_FOR_LAST_NAME = 'Please enter last name';
export const ERROR_MESSAGE_FOR_MOBILE = 'Please enter phone number';
export const ERROR_MESSAGE_FOR_INVALID_MOBILE = 'Please enter valid phone number';
export const ERROR_MESSAGE_FOR_USER = 'Please select user type';
export const ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED = 'Login is allowed only for referrer users';
export const ERROR_MESSAGE_FOR_USER_LIST_NOT_SUPPORTED = 'You are not authorised to visit that page';

export const ERROR_MESSAGE_FOR_PREFIX = 'Prefix required';
export const ERROR_MESSAGE_FOR_STATE = 'Please input your state.';
export const ERROR_MESSAGE_FOR_PREFERRED_COMMUNICATION_METHOD = 'Preferred communication method is required.';
export const ERROR_MESSAGE_FOR_STATUS = 'Please select an option';
export const ERROR_MESSAGE_FOR_DRIVER_LICENCE = 'Driver licence number is required';
export const ERROR_MESSAGE_FOR_DRIVER_LICENCE_STATE = 'Driver licence state is required';
export const ERROR_MESSAGE_FOR_DRIVER_LICENCE_TYPE = 'Please select your driver licence type';
export const ERROR_MESSAGE_FOR_ID_TYPE = 'Please select your identification type';
export const ERROR_MESSAGE_FOR_LICENCE_DATE_OF_EXPIRY = 'Licence date of expiry is required';
export const ERROR_MESSAGE_FOR_DATE_OF_BIRTH = 'Date of birth is required';
export const ERROR_MESSAGE_FOR_GENDER = 'Please select your gender';
export const ERROR_MESSAGE_FOR_VISA_OTHER = 'Please input your visa type.';
export const ERROR_MESSAGE_FOR_DEPENDANTS = 'Number of dependants is required';
export const ERROR_MESSAGE_FOR_PASSPORT_NUMBER = 'Passport number is required';
export const ERROR_MESSAGE_FOR_PASSPORT_DATE_OF_EXPIRY = 'Passport expiry Date is required';
export const ERROR_MESSAGE_FOR_REPAYMENTS = 'Please select repayments';

export const ERROR_MESSAGE_FOR_LOAN_ID = 'Please enter Loan ID';
export const ERROR_MESSAGE_FOR_UNIT_STREET = 'Please enter unit or street';
export const ERROR_MESSAGE_FOR_CITY = 'Please select your city';
export const ERROR_MESSAGE_FOR_SUBURB = 'Please enter suburb';
export const ERROR_MESSAGE_FOR_POSTCODE = 'Please enter valid postcode';
export const ERROR_MESSAGE_FOR_COUNTRY = 'Please select your country';
export const ERROR_MESSAGE_FOR_TIME_AT_YEARS = 'Please select number of the living years';
export const ERROR_MESSAGE_FOR_TIME_AT_MONTHS = 'Please select number of the living months';
export const ERROR_MESSAGE_FOR_LIVING_SITUATION = 'Please select the living situation';
export const ERROR_MESSAGE_FOR_UNIT_STREET_LENGTH = 'Unit or street address can contain maximum 60 characters';
export const ERROR_MESSAGE_FOR_CITY_LENGTH = 'City can contain maximum 30 characters';
export const ERROR_MESSAGE_FOR_OCCUPATION_NAME = 'Please input occupation name';
export const ERROR_MESSAGE_FOR_MAX_LENGTH_OCCUPATION = 'Maximum 30 characters are allowed';
export const ERROR_MESSAGE_FOR_START_DATE = 'Please select start date';
export const ERROR_MESSAGE_FOR_PHONE_NUMBER = 'Please enter phone number';
export const ERROR_MESSAGE_FOR_CONTACT_NAME = 'Please enter contact name';
export const ERROR_MESSAGE_FOR_EMPLOYER_NAME = 'Please enter employer name';
export const ERROR_MESSAGE_FOR_INDUSTRY = 'Please enter Industry';
export const ERROR_MESSAGE_FOR_EMPLOYER_ABN = 'Please enter employer abn';
export const ERROR_MESSAGE_FOR_MAX_ELEVEN_CHARACTERS = 'Maximum 11 characters are allowed';
export const ERROR_MESSAGE_FOR_VALID_ABN = 'Please enter valid abn';
export const ERROR_MESSAGE_FOR_VALID_TRUSTEE_ACN = 'Please enter valid Trustee ACN';
export const ERROR_MESSAGE_FOR_VALID_PHONE_NUMBER = 'Please enter valid phone number';
export const ERROR_MESSAGE_FOR_FREQUENCY_REQUIRED = 'Please select frequency';
export const ERROR_MESSAGE_FOR_AMOUNT_REQUIRED = 'Please enter amount';
export const ERROR_MESSAGE_FOR_NUMBER_ONLY = 'Please enter numbers only';
export const ERROR_MESSAGE_FOR_NUMBER_LENGTH = 'Maximum 10 characters are allowed';
export const ERROR_MESSAGE_FOR_EXPENSES_DECLARED = 'Expenses declaration is required';

export const ERROR_MESSAGE_FOR_INVALID_INPUTS = [
  ERROR_MESSAGE_FOR_EMAIL,
  ERROR_MESSAGE_FOR_PHONE,
  ERROR_MESSAGE_FOR_INVALID_EMAIL,
  ERROR_MESSAGE_FOR_PASSWORD,
  ERROR_MESSAGE_FOR_PASSWORD_LENGTH,
  ERROR_MESSAGE_FOR_OLD_PASSWORD,
  ERROR_MESSAGE_FOR_NEW_PASSWORD,
  ERROR_MESSAGE_FOR_FIRST_NAME,
  ERROR_MESSAGE_FOR_LAST_NAME,
  ERROR_MESSAGE_FOR_USER,
  ERROR_MESSAGE_FOR_PORTAL_LOGIN_NOT_SUPPORTED,
  ERROR_MESSAGE_FOR_PREFIX,
  ERROR_MESSAGE_FOR_STATE,
  ERROR_MESSAGE_FOR_VISA_OTHER,
  ERROR_MESSAGE_FOR_STATUS,
  ERROR_MESSAGE_FOR_DRIVER_LICENCE,
  ERROR_MESSAGE_FOR_DRIVER_LICENCE_STATE,
  ERROR_MESSAGE_FOR_DRIVER_LICENCE_TYPE,
  ERROR_MESSAGE_FOR_DEPENDANTS,
  ERROR_MESSAGE_FOR_ID_TYPE,
  ERROR_MESSAGE_FOR_GENDER,
  ERROR_MESSAGE_FOR_UNIT_STREET,
  ERROR_MESSAGE_FOR_SUBURB,
  ERROR_MESSAGE_FOR_POSTCODE,
  ERROR_MESSAGE_FOR_COUNTRY,
  ERROR_MESSAGE_FOR_TIME_AT_YEARS,
  ERROR_MESSAGE_FOR_TIME_AT_MONTHS,
  ERROR_MESSAGE_FOR_LIVING_SITUATION,
  ERROR_MESSAGE_FOR_UNIT_STREET_LENGTH,
  ERROR_MESSAGE_FOR_CITY,
  ERROR_MESSAGE_FOR_CITY_LENGTH,
  ERROR_MESSAGE_FOR_PASSPORT_NUMBER,
  ERROR_MESSAGE_FOR_PASSPORT_DATE_OF_EXPIRY,
  ERROR_MESSAGE_FOR_OCCUPATION_NAME,
  ERROR_MESSAGE_FOR_MAX_LENGTH_OCCUPATION,
  ERROR_MESSAGE_FOR_START_DATE,
  ERROR_MESSAGE_FOR_PHONE_NUMBER,
  ERROR_MESSAGE_FOR_CONTACT_NAME,
  ERROR_MESSAGE_FOR_EMPLOYER_NAME,
  ERROR_MESSAGE_FOR_EMPLOYER_ABN,
  ERROR_MESSAGE_FOR_MAX_ELEVEN_CHARACTERS,
  ERROR_MESSAGE_FOR_VALID_ABN,
  ERROR_MESSAGE_FOR_VALID_PHONE_NUMBER,
  ERROR_MESSAGE_FOR_FREQUENCY_REQUIRED,
  ERROR_MESSAGE_FOR_AMOUNT_REQUIRED,
];

export const ERROR_MESSAGE_FOR_NET_INCOME_INVALID = 'Please input net income value';
export const ERROR_MESSAGE_FOR_NET_INCOME_NUMBER_ONLY = 'Please input valid number';
export const ERROR_MESSAGE_FOR_NET_INCOME_NUMBER_MAX_VALUE = 'Net income can contain maximum 10 numbers';
export const ERROR_MESSAGE_FOR_NET_INCOME_GREATER_THAN_ZERO = 'Net income should be greater than 0';
export const ERROR_MESSAGE_FOR_OTHER_NOTES_MAX_VALUE = 'Notes can contain maximum 60 characters';
export const ERROR_MESSAGE_FOR_SPOUSAL_INCOME_NOTES_MAX_VALUE = 'Notes can contain maximum 400 characters';
export const ERROR_MESSAGE_FOR_FREQUENCY = 'Frequency is required';
export const ERROR_MESSAGE_FOR_SPOUSAL_INCOME_FIRST_NAME = 'First name is required';
export const ERROR_MESSAGE_FOR_SPOUSAL_INCOME_LAST_NAME = 'Last name is required';
export const ERROR_MESSAGE_FOR_SPOUSAL_INCOME_FIRST_NAME_30_CHARS = 'First name can contain maximum 30 characters';
export const ERROR_MESSAGE_FOR_SPOUSAL_INCOME_LAST_NAME_30_CHARS = 'Last name can contain maximum 30 characters';
export const ERROR_MESSAGE_FOR_FIELD_VALUE_GREATER_THAN_ZERO = 'This field value should be greater than 0';

export const ERROR_MESSAGE_FOR_INCOME_INVALID_INPUTS = [ERROR_MESSAGE_FOR_NET_INCOME_INVALID, ERROR_MESSAGE_FOR_NET_INCOME_NUMBER_ONLY, ERROR_MESSAGE_FOR_NET_INCOME_NUMBER_MAX_VALUE, ERROR_MESSAGE_FOR_OTHER_NOTES_MAX_VALUE, ERROR_MESSAGE_FOR_SPOUSAL_INCOME_NOTES_MAX_VALUE, ERROR_MESSAGE_FOR_FREQUENCY, ERROR_MESSAGE_FOR_SPOUSAL_INCOME_LAST_NAME, ERROR_MESSAGE_FOR_SPOUSAL_INCOME_FIRST_NAME];

export const SUCCESS_MESSAGE_EMAIL_PASSWORD_RESET = 'Please check your email address. If your email address is valid you will receive a password reset email shortly';
export const PASSWORD_RESET_SUCCESS = 'Password reset successfully';
export const PASSWORD_SET_SUCCESS = 'Password set successfully';
export const USER_UPDATED_SUCCESS = 'User updated successfully';
export const USER_CREATED_SUCCESS = 'User created successfully';
export const PASSWORD_CHANGE_SUCCESS = 'Password change successfully';
export const USER_VERIFICATIN_SUCCESS_MESSAGE = 'Please check your email address. If your email address is valid you will receive a verification link email shortly';
export const CREDIT_CHECK_SUCCESS_MESSAGE = 'Your request for credit check has been submitted. This may take few minutes to process.';
export const REFETCH_FILE_CHECK_SUCCESS_MESSAGE = 'File refetched successfully';
export const SUCCESS_MESSAGES_FOR_FORMS = [SUCCESS_MESSAGE_EMAIL_PASSWORD_RESET, PASSWORD_RESET_SUCCESS, PASSWORD_SET_SUCCESS, USER_CREATED_SUCCESS, USER_UPDATED_SUCCESS];

export const TITLE_FOR_INCOME_SUMMERY = 'Income summary (Net/Month)';
export const TITLE_FOR_PRIMARY_APPLCANT = 'Primary applicant';
export const TITLE_FOR_SECONDARY_APPLCANT = 'Secondary applicant';
export const TITLE_FOR_NO_EMPLOYMENT = 'No current employment';
export const TITLE_FOR_NO_OTHER_INCOME = 'No other income';
export const TITLE_FOR_OTHER = 'Other';
export const TITLE_FOR_TOTAL = 'Total';
export const TITLE_FOR_EMPLOYMENT = 'Employment';
export const TITLE_FOR_VIEW = 'View';
export const TITLE_FOR_SPOUSAL_INCOME = 'Spousal income';
export const LABEL_FOR_EMPLOYMENT_NET_INCOME = 'Net income';
export const LABEL_FOR_EMPLOYMENT_FREQUENCY = 'Frequency';
export const LABEL_FOR_NON_EMPLOYMENT_INCOME_TYPE = 'Income Type';
export const LABEL_FOR_EMPLOYMENT_ADD_SPOUSAL_INCOME_TEXT = 'Add spousal income for primary applicant for offset purposes';
export const LABEL_FOR_FIRST_NAME = 'First name';
export const LABEL_FOR_LAST_NAME = 'Last name';
export const LABEL_FOR_LAST_NOTES = 'Notes';
export const TITLE_FOR_INCOME = 'Income';

export const TITLE_FOR_ASSETS = 'Assets';
export const TITLE_FOR_ADD_ASSETS = 'Add Assets';
export const TITLE_FOR_LIABILITES = 'Liabilities';
export const TITLE_FOR__ADD_LIABILITES = 'Add Liabilities';
export const USER_SESSION_DATA_KEY = 'user';
export const LABEL_FOR_LAST_TYPE = 'Type';
export const LABEL_FOR_OWNER = 'Owner';
export const LABEL_FOR_OWNERSHIP = 'Ownership';
export const LABEL_FOR_EQUITY = 'Equity (%)';
export const LABEL_FOR_OWNERSHIP_EQUITY = 'Ownership (%)';
export const LABEL_FOR_VALUE = 'Value';
export const LABEL_FOR_OWNED_OUTRIGHT = 'Owned outright';
export const LABEL_FOR_FINANCIER = 'Financier';
export const LABEL_FOR_BALANCE = 'Balance';
export const LABEL_FOR_PAYMENT = 'Payment';
export const LABEL_FOR_FREQUENCY = 'Frequency';
export const LABEL_FOR_LIMIT = 'Limit';
export const LABEL_FOR_PAYOUT = 'Payout';
export const LABEL_FOR_YEARS = 'years';
export const LABEL_FOR_YEAR = 'year';
export const LABEL_FOR_MONTHS = 'months';
export const LABEL_FOR_MONTH = 'month';
export const LABEL_FOR_CHANGE = 'Change';
export const LABEL_FOR_MONTLY = 'Monthly';
export const LABEL_FOR_CONTACTS = 'Contact(s)';
export const LABEL_FOR_CONTACT = 'Contact';
export const LABEL_FOR_CREATED = 'Created';
export const LABEL_FOR_CLIENT = 'Client';
export const LABEL_FOR_CUSTOMER_CONSENT_AT = 'Customer consent at';
export const LABEL_FOR_CREATED_AT = 'Created at';
export const LABEL_FOR_CONSENT_AT = 'Consent at';
export const LABEL_FOR_SUMMARY = 'Summary';
export const LABEL_FOR_ALREADY_VEHICLE_SELECTED = 'Has the customer already selected the vehicle?';
export const TITLE_FOR_VEHICLE_DETAILS = 'Vehicle details';
export const TITLE_FOR_ASSET_DETAILS = ' Asset details';
export const LABEL_FOR_MAKE = 'Make';
export const LABEL_FOR_MODEL = 'Model';
export const LABEL_FOR_ASSET_DETAILS_YEAR = 'Year';
export const LABEL_FOR_MODEL_TYPE = 'Model type';
export const LABEL_FOR_VEHICLE_DESCRIPTION = 'Description';
export const LABEL_FOR_SELECT_VEHICLE = 'Select vehicle';
export const LABEL_FOR_ENTER_DETAILS_MANUALLY = 'Enter details manually';
export const LABEL_FOR_APPLICANT_PROFILE = 'Applicant profile';

export const LABEL_FOR_SELECT_ENTER_VEHICLE_MANUALLY = 'Enter vehicle details manually';
export const DESCRIPTION_FOR_SELECT_VEHICLE = 'Enter the vehicle details by selecting the Make, Model, Year and Model type';
export const DESCRIPTION_FOR_SELECT_VEHICLE_MANUALLY = 'Describe the details of the vehicle manually';
export const LABEL_FOR_ASSET_DETAIL_TYPE = 'What is the type of the asset selected by the customer?';

export const TITLE_FOR_UPDATE_BUSINESS_STRUCTURE = 'Update business structure';
export const DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_FIRST_PART = 'You are changing business structure from ';

export const DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_SECOND_PART_ONE = 'can only have ';

export const DESCRIPTION_FOR_UPDATE_BUSINESS_STRUCTURE_SECOND_PART_TWO = ' related contact. In order to change the structure view <b> Contact list </b> and remove any additional secondary contacts';

export const TITLE_FOR_OTHER_DETAILS = 'Other details';
export const LABEL_FOR_CONDITION = 'Condition';
export const LABEL_FOR_SALE_TYPE = 'Sale type';
export const LABEL_FOR_VIN = 'VIN';
export const LABEL_FOR_REGO = 'Rego';
export const LABEL_FOR_INCOME_OTHER_DESCRIPTION = "Use the option <b class='text-light-black'>+ Add Other Income</b> to add a new other income";
export const LABEL_FOR_APPLICATION_ID = 'Application ID';
export const LABEL_FOR_APPLICANTS = 'Applicants';
export const LABEL_FOR_OPPORTUNITY_CRM = 'Opportunity (CRM)';
export const LABEL_FOR_APPLICATION_CREATED = 'Application created';
export const LABEL_FOR_LEAD_SOURCE_PARTNER = 'Lead source/partner';
export const LABEL_FOR_LEAD_BUSINESS_DETAILS = 'Business details';
export const ERROR_MESSAGE_FOR_MAX_BROKER_FEE = 'Max. input amount allowed is $';
/** Replace <assetType> with type of asset and replace <liabilityType> with type of an liability */
export const DESCRIPTION_FOR_REMOVE_ASSETS = 'Are you sure you want to remove asset type of <assetType> and It will also remove its associated liability type of <liabilityType>';
/** Replace <assetType> with type of asset and replace <liabilityType> with type of an liability */
export const DESCRIPTION_FOR_REMOVE_LIABILITIES = 'Are you sure you want to remove liability type of <liabilityType>. It will also remove its associated asset type of <assetType>';
export const DESCRIPTION_IF_NO_ASSETS_FIRST_PART = 'No assets are added for this applicant. Use the option';
export const DESCRIPTION_IF_NO_ASSETS_SECOND_PART = 'to add a new asset.';
export const DESCRIPTION_IF_NO_LIABILITIES_FIRST_PART = 'No liabilities are added for this applicant. Use the option';
export const DESCRIPTION_IF_NO_LIABILITIES_SECOND_PART = 'to add a new liability.';

/** Replace <assetType> with type of an asset. */
export const TITLE_OF_ASSETS_REMOVE = 'Remove asset “<assetType>"';

/** Replace <liabilityType> with type of an liability. */
export const TITLE_OF_LABILITIES_REMOVE = 'Remove asset "<liabilityType>"';

export const ERROR_MESSAGE_FOR_OWNER = 'Owner is required';
export const ERROR_MESSAGE_FOR_SELECT_TYPE_ASSETS = 'Please select type of assets';
export const ERROR_MESSAGE_FOR_SELECT_TYPE_LIABILITIES = 'Please select type of liabilities';
export const ERROR_MESSAGE_FOR_INVALID = 'Please enter valid input';
export const ERROR_MESSAGE_FOR_MAX_TEN_NUMBER = 'Max 10 numbers';
export const ERROR_MESSAGE_FOR_MAX_TWENTY_CHARACTERS = 'Please input max 20 characters';
export const ERROR_MESSAGE_FOR_MAX_TWO_THOUSAND_CHARACTERS = 'Please input max 2000 characters';
export const CREDIT_REPORT_SELECT_APPLICANT_TITLE = 'Select applicant';
export const CREDIT_REPORT_PRIVACY_CREDIT_GUIDE = 'Privacy, credit guide and quote';
export const CREDIT_REPORT_PRIVACY_SENT_AT = 'Privacy sent';
export const CREDIT_REPORT_PRIVACY_SIGNED_AT = 'Privacy signed';
export const LAST_ERROR = 'Last error';
export const CREDIT_REPORT_CREDIT_CHECK_HISTORY_TITLE = 'Credit check history';
export const CREDIT_REPORT_APPLICANT_TITLE = 'Applicant';
export const CREDIT_REPORT_CREDIT_SCORE_TITLE = 'Credit score';
export const CREDIT_REPORT_ONE_SCORE_TITLE = 'One Score';
export const CREDIT_REPORT_COMPREHENSIVE_TITLE = 'Comprehensive';
export const CREDIT_REPORT_LAST_CREATED_AT_TITLE = 'Last created at';
export const CREDIT_REPORT_TITLE = 'Last report';
export const CREDIT_CHECK_TITLE = 'Credit check';
export const LABEL_CREDIT_HISTORY = 'History';
export const CREDIT_REPORT_PENDING = 'Pending';
export const RUN_CREDIT_REPORT_TITLE = 'Run Credit Report';
export const RUN_CREDIT_CHECK_TITLE = 'Run Credit check';
export const ERROR_MESSAGE_FOR_REQUIRED_FIELD = 'This field is required';
export const ERROR_MESSAGE_FOR_ZERO_AMOUNT = '0 is not a valid amount';
export const ERROR_MESSAGE_FOR_LENDER_NAME = 'Lender name is required';
export const ERROR_MESSAGE_FOR_EQUIFAX_SCORE = 'Please enter an amount from -999 to 1200';
/** Replace <applicantType> with type of an applicantType and <applicantName> with the firstName lastName */
export const CREDIT_RUN_REPORT_DESCRIPTION = 'Are you sure you want to run credit report for the <applicantType> applicant <b><applicantName></b>? ';

/* eslint-disable */
export const ABN_REGEX = /^[0-9]{11}$/;
export const REGEX_FOR_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const REGEX_FOR_PASSWORD = /^[\s\w@~`!@#$%^&*()_=+\\';:"\/?>.<,-]{8,}$/;
export const REGEX_FOR_AUS_MOBILES = /^(614\d{8}|04\d{8})$/;
export const REGEX_FOR_AUS_PHONE = /^(1800\d{6}|1300\d{6}|02\d{8}|03\d{8}|612\d{8}|613\d{8}|614\d{8}|617\d{8}|618\d{8}|08\d{8}|07\d{8}|04\d{8})$/;
export const REGEX_FOR_LIABILITIES = /\<liabilityType\>/g;
export const REGEX_FOR_ASSET = /\<assetType\>/g;
export const REGEX_FOR_APPLICATION_TYPE = /\<applicantType\>/g;
export const REGEX_FOR_APPLICATION_NAME = /\<applicantName\>/g;
/* eslint-enable */

export const ERROR_MESSAGE_FOR_18_YEARS_AGE = 'Age cannot be less than 18 years';
export const ERROR_MESSAGE_FOR_DATE_LESS_THAN_10_YEARS_CURR_YEAR = 'Date should be less than 10 years from the current year';
export const ERROR_MESSAGE_FOR_DATE_LESS_THAN_THREE_MONTH = 'Date should not be more than 3 months from the current month';

export const ERROR_MESSAGE_FOR_OTHER_DOCUMENT_NAME = 'Please enter other document name!';

export const ERROR_MESSAGE_FOR_PURCHASE_PRICE = 'Please enter purchase price';
export const ERROR_MESSAGE_FOR_DEPOSIT = 'Please enter deposit';
export const ERROR_MESSAGE_FOR_TRADE_IN = 'Please enter trade-in';
export const ERROR_MESSAGE_FOR_LOAN_TERM = 'Please select loan term';
export const ERROR_MESSAGE_FOR__INVALID_BALLOON = 'Please enter balloon between 0% and 30%';
export const ERROR_MESSAGE_FOR_BALLOON = 'Please enter balloon(%)';
export const ERROR_MESSAGE_FOR_BALLOON_ONLY = 'Please enter balloon';
export const ERROR_MESSAGE_FOR_INVALID_BALLOON = 'Please enter balloon between 0% and 60%';
export const ERROR_MESSAGE_FOR_BUDGET_MONTHLY_REPAYMENT = 'Please enter budgeted monthly repayment';
// This could be worded better.
export const ERROR_MESSAGE_FOR_INVALID_PRICE = 'Please enter a valid amount between 1 and 8 digits';

export const ERROR_MESSAGE_FOR_TYPE = 'Please select type';
export const ERROR_MESSAGE_FOR_MAKE = 'Please select make';
export const ERROR_MESSAGE_FOR_MODEL = 'Please select model';
export const ERROR_MESSAGE_FOR_MODEL_YEAR = 'Please select year';
export const ERROR_MESSAGE_FOR_MODEL_TYPE = 'Please select model type';
export const ERROR_MESSAGE_FOR_MODEL_DESCRIPTION = 'Please enter a description';
export const ERROR_MESSAGE_FOR_MAX_MODEL_DESCRIPTION = 'Description can contain maximum 300 characters';
export const ERROR_MESSAGE_FOR_MODEL_CONDITION = 'Please select condition';
export const ERROR_MESSAGE_FOR_SALE_TYPE = 'Please select sale type';
export const ERROR_MESSAGE_FOR_DETAILS = 'Please enter details';
export const ERROR_MESSAGE_FOR_PURPOSE_OF_OBTAINING = 'Please select purpose of obtaining, increasing or refinancing the credit';
export const ERROR_MESSAGE_FOR_LOAN_FEATURES = 'Please check at least one option';
export const ERROR_MESSAGE_FOR_BUDGETTED_MONTHLY_REPAYMENT = 'Please enter customer’s budgeted monthly repayment';
export const ERROR_MESSAGE_FOR_VIN = 'Please enter VIN';
export const ERROR_MESSAGE_FOR_MAX_20_CHARS = 'Maximum 20 character are allowed';
export const ERROR_MESSAGE_FOR_MAX_1000_CHARS = 'Maximum 1000 character are allowed';
export const ERROR_MESSAGE_FOR_MAX_600_CHARS = 'Maximum 600 character are allowed';
export const ERROR_MESSAGE_FOR_REGISTRATION_PLATE = 'Please enter rego';
export const ERROR_MESSAGE_FOR_TO_MANY_EQUIFAX_CREDIT_ENQUIRY = 'Applicant is already exceeded the limit of Equifax credit enquiry';
export const HEADER_MESSAGE_FOR_TOO_MANY_EQUIFAX_CREDIT_ENQUIRY = 'Maximum limit of 3 credit checks reached';
export const ERROR_MESSAGE_FOR_EXPECTED_PURCHASE_PRICE_REQUIRED = 'Please enter expected purchase price';
export const ERROR_MESSAGE_FOR_MINIMUM_5000_PURCHASE_PRICE_REQUIRED = 'The minimum loan we can help with is $5,000';
export const ERROR_MESSAGE_SELECT_LOAN_TYPE = 'Please select loan type';
export const ERROR_MESSAGE_FOR_RESIDENCY = 'Please select residency';
export const ERROR_MESSAGE_FOR_EMPLOYMENT = 'Please select employment';
export const ERROR_MESSAGE_FOR_CREDIT_HISTORY = 'Please select credit history';

export const LABEL_REFERRALS = 'referrals';

export const TITLE_FOR_COMPLIANCE = 'Compliance documents';
export const TITLE_FOR_COMPLIANCE_PRIVACY_GUIDE_QUOTE = 'Privacy, credit guide and quote';
export const TITLE_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL = 'Privacy form';
export const DESCRIPTION_FOR_COMPLIANCE_PRIVACY_GUIDE_QUOTE = 'Send and view privacy form, credit guide and credit quote documents for the applicant(s)';
export const DESCRIPTION_FOR_COMPLIANCE_PRIVACY_FORM_COMMERCIAL = 'Send and view privacy form document for the applicant(s)';
export const TITLE_FOR_COMPLIANCE_ASSESSMENT = 'Preliminary assessment';
export const DESCRIPTION_FOR_COMPLIANCE_ASSESSMENT = 'View the generated preliminary assessment document after the loan details are submitted';
export const TITLE_FOR_COMPLIANCE_PROPOSAL_DISCLOSURE = 'Credit proposal disclosure';
export const DESCRIPTION_FOR_COMPLIANCE_CLOSURE_PROPOSAL = 'Send and view the credit proposal disclosure document. It can only be sent once the loan details are submitted';
export const LABEL_FOR_PRIMARY_APPLICANT = 'Primary applicant';
export const LABEL_FOR_SECONDARY_APPLICANT = 'Secondary applicant';
export const LABEL_FOR_LAST_SENT_AT = 'Last sent at';
export const LABEL_FOR_LAST_SIGNED_AT = 'Last signed at';
export const LABEL_FOR_LAST_GENERATED_AT = 'Last generated at';
export const LABEL_FOR_LAST_VIEWED_AT = 'Last viewed at';
export const LABEL_FOR_PRIVACY_FORM = 'Privacy form';
export const LABEL_FOR_PRIVACY_GUIDE = 'Privacy Guide';
export const LABEL_FOR_CREDIT_QUOTE = 'Credit Quote';
export const LABEL_FOR_QUOTE = 'Quote';
export const LABEL_FOR_CREDIT_GUIDE = 'Credit Guide';
export const LABEL_FOR_DATE = 'Date';
export const LABEL_FOR_MOBILE = 'Mobile';
export const LABEL_FOR_EMAIL = 'Email';
export const LABEL_FOR_PASSWORD = 'Password';
export const LABEL_FOR_IP = 'IP';
export const TITLE_FOR_INFO_ADDRESS_DETAILS = 'Address details are not completed';
export const TITLE_FOR_INFO_LOAN_DETAILS = 'Loan details are not submitted';
export const LABEL_FOR_AGENT_NOTE = 'agent';
export const LABEL_FOR_REFERRER_NOTE = 'referrer';
export const LABEL_FOR_BROKER_NOTES = 'Broker notes';
export const LABEL_FOR_DESCRIPTION = 'Description';
export const PLACEHOLDER_FOR_WRITE_TEXT = 'Write your text here';
export const LABEL_FOR_LAST_UPDATED_AT = 'Last updated at';
export const LABEL_FOR_NOTE_BY_REFERRER = 'Notes by Referrer';
export const LABEL_APPLY = 'Apply';
export const LABEL_COMMERCIAL = 'Commercial';
export const LABEL_CONSUMER = 'Consumer';
export const ERROR_MESSAGE_FOR_URGENCY = 'Please select urgency';
export const ERROR_MESSAGE_FOR_BUSINESS_STRUCTURE = 'Please select business structure';
export const ERROR_MESSAGE_FOR_ABN_ACTIVE_FROM_DATE = 'ABN active from date is required';
export const ERROR_MESSAGE_FOR_BUSINESS_NAME = 'Please enter business name';
export const ERROR_MESSAGE_FOR_ABN = 'Please enter abn';
export const ERROR_MESSAGE_FOR_VEHICLE_DETAILS = 'Please select vehicle details';
export const ERROR_MESSAGE_FOR_CAR_SOURCING = 'Please select car sourcing';
export const ERROR_MESSAGE_FOR_3_NUMBER_LENGTH = 'Maximum 3 characters are allowed';
export const ERROR_OUTPUT_OPTION = 'Please select output option';
export const ERROR_EXPIRY = 'Please enter expiry of approval';

export const ERROR_SETTLEMENT_ETA = 'Please enter settlement ETA';

export const ERROR_APPROVAL_CONDITIONS = 'Please enter approval conditions';
export const ERROR_APPROVAL_CONDITIONS_OPTIONS = 'Please select approval conditions';
export const ERROR_DECLINED_OPTION = 'Please select declined option';
export const ERROR_PURCHASE_PRICE = 'Please  enter purchase price';
export const ERROR_TRADE_IN = 'Please enter trade in';
export const ERROR_DEPOSIT = 'Please enter deposit';
export const ERROR_NAF = 'Please enter NAF';
export const ERROR_BALLON = 'Please enter ballon %';
export const ERROR_BALLOON = 'Please enter balloon';

export const ERROR_LOAN_TERM = 'Please select loan term';
export const ERROR_LENDER_BASE_RATE = 'Please enter lender base rate';
export const ERROR_MONTHLY_REPAYMENT = 'Please enter monthly repayment';
export const ERROR_CUSTOMER_RATE = 'Please enter customer rate';
export const ERROR_ACCOUNT_KEEPING_FEE = 'Please enter account keeping fee';
export const ERROR_LENDER_ESTABLISHMENT_FEE = 'Please enter lender establishment fee';
export const ERROR_PPSR_FEE = 'Please enter lender PPSR fee';
export const ERROR_BROKER_FEE = 'Please enter broker fee';
export const ERROR_LENDING_COMMISSION = 'Please  enter lending commission';
export const ERROR_MESSAGE_BASE_RATE = 'Please enter base rate';
export const ERROR_MESSAGE_MONTHLY_REPAYMENT = 'Please enter monthly repayment';
export const ERROR_MESSAGE_CUSTOMER_RATE = 'Please enter customer rate';
export const ERROR_MESSAGE_ACCOUNT_KEEPING_FEE = 'Please enter account keeping fee';
export const ERROR_MESSAGE_LENDER_ESTABLISHMENT_FEE = 'Please enter lender establishment fee';
export const ERROR_MESSAGE_PSPR_FEE = 'Please enter ppsr fee';
export const ERROR_MESSAGE_BROKER_FEE = 'Please enter broker fee';
export const ERROR_MESSAGE_PARTNER_FINANCE_REFERRAL_FEE = 'Please enter partner finance referral fee';
export const ERROR_MESSAGE_LENDING_COMMISION_FEE = 'Please enter lending commision fee';
export const ERROR_MESSAGE_DOES_APPLICANT_AGRRE_WITH_PROPOSED_LOAN_DETAILS = 'Please select field';
export const ERROR_MESSAGE_DOES_SATISFY_CONSUMERS_OBJECTIVES_AND_REQUIREMENT = 'Please select field';
export const ERROR_MESSAGE_DOES_APPLICANT_COMPLY_WITH_FINANCIAL_OBLIGATIONS = 'Please select field';
export const ERROR_MESSAGE_DOES_FUTURE_CHANGES_IMPACT_PROPOSED_LOAN = 'Please select field';
export const ERROR_MESSAGE_HOW_DOES_SELECTED_LENDER_MEET_CUSTOMERS_NEED = 'Please enter description';
export const ERROR_MESSAGE_REASON_FOR_BALLOON_PAYMENT = 'Please select reason for balloon payment';
export const ERROR_MESSAGE_PLEASE_ENTER_PROVIDER = 'Please enter provider';
export const ERROR_MESSAGE_PLEASE_ENTER_PRODUCT = 'Please enter product';
export const ERROR_MESSAGE_PLEASE_ENTER_WHOLESALE = 'Please enter wholesale';
export const ERROR_MESSAGE_PLEASE_ENTER_RETAIL = 'Please enter retail';
export const ERROR_MESSAGE_PLEASE_ENTER_COMMISSION = 'Please enter commission';
export const ERROR_MESSAGE_PLEASE_ENTER_POLICY = 'Please enter policy';
export const ERROR_MESSAGE_PLEASE_ENTER_PREMIUM = 'Please enter premium';
export const ERROR_MESSAGE_PLEASE_SELECT_FIELD = 'Please select field';

export const ALL = 'All';
export const LABEL_NO_STATUS = 'No Status';
export const LABEL_ALL_WITH_NO_STATUS = 'All With No Status';
export const KEY_ALL_WITH_NO_STATUS = 'ALL_WITH_NO_STATUS';
export const BLANK = '';

export const ERROR_MESSAGE_INVALID_AMOUNT = 'invalid amount';
export const ERROR_MESSAGE_FOR_MAX_AMOUNT = 'Amount cannot be more than 10 digits';
export const ERROR_MESSAGE_AMOUNT_CANNOT_BE_MORE_THAN = 'Amount cannot be more than ';
export const ERROR_MESSAGE_AMOUNT_CANNOT_BE_EQUAL_TO = 'Amount cannot be equal to ';

export const ERROR_MESSAGE_NO_OF_DIRECTORS = 'Number of directors is required';
export const ERROR_MESSAGE_NO_OF_PARTNERS = 'Number of partners is required';
export const ERROR_MESSAGE_FOR_TRUSTEE_TYPE = 'Please select trustee type';
export const ERROR_MESSAGE_FOR_NO_OF_TRUSTEE_DIRECTORS = 'Please select no. of trustee directors';
export const ERROR_MESSAGE_TRUSTEE_FIRST_NAME = 'Please enter first name';
export const ERROR_MESSAGE_TRUSTEE_LAST_NAME = 'Please enter last name';
export const ERROR_MESSAGE_FOR_TRUSTEE_BUSINESS_NAME = 'Please enter trustee business name';

export const ERROR_MESSAGE_FOR_TRUSTEE_ACN = 'Please enter trustee ACN';
export const ERROR_MESSAGE_FOR_TRUSTEE_ACN_MAX_LENGTH = 'Maximum 9 characters allowed for trustee ACN';
export const TITLE_FOR_CREDIT_COMMITMENTS = 'Credit commitments';
export const CREDIT_COMMITMENT_UP_TO_DATE_LABEL = 'Are all current credit commitments up to date?';
export const ERROR_MESSAGE_FOR_CREDIT_COMMITMENT = 'Credit commitment is required';
export const LEAD_NOT_FOUND_PLEASE_CHECK_LATER = 'Lead not found. Please check again in 1 minute';
export const LEAD_VALUE_CANNOT_BE_EMPTY = 'Lead not found. Please check!';
export const LEAD_CREATED_SUCCESSFULLY = 'Application created successfully!';
export const ERROR_MESSAGE_FOR_SHAREHOLDING_PERCENTAGE = 'Shareholding percentage is required';
export const ERROR_MESSAGE_FOR_DIRECTORSHIP_START_DATE = 'Directorship start date is required';

export const EXPENSES_AND_COMFORTABLE_CREDIT_TITLE = 'Expenses and confirmable credit';
export const HOUSE_HOLD_EXPENSES_MONTHLY_LABEL = 'Household expenses (monthly)';
export const ERROR_MESSAGE_FOR_HOUSE_HOLD_EXPENSES = 'Household expnses is required';
export const DOES_APPLICANT_HAVE_CONFIRMABLE_CREDIT_LABEL = 'Does the applicant have confirmable credit?';
export const ALERT_MESSAGE_FOR_USER_IS_AGENT_AND_NOT_SALESFORCE_USER = "Looks like either you don't have a SalesForce account with same email id or you do not have any leads/applications assigned to you.";
export const ERROR_MESSAGE_LOGIN_REQUIRED = 'You need to login to access requested page';

export const ERROR_TYPE_CREDIT_FILE_NOT_FOUND = 'No Credit File Found';
export const ERROR_TYPE_NO_EXISTING_CREDIT_FILE_FOUND = 'No Existing Credit File Found';
export const ERROR_TYPE_OTHER = 'Other';
export const ERROR_TYPE_FILE_BAN = 'File ban';
export const ERROR_TYPE_NOT_FOUND = 'Not Found';
export const HEADER_MESSAGE_FOR_ERROR_TYPE_FILE_NOT_FOUND = 'File not found';
export const HEADER_MESSAGE_FOR_ERROR_TYPE_CREDIT_FILE_NOT_FOUND = 'Unable to fetch pdf';
export const HEADER_MESSAGE_FOR_ERROR_TYPE_UNEXPECTED_ERROR = 'Unexpected error';
export const HEADER_MESSAGE_FOR_ERROR_TYPE_FILE_BAN = 'Credit file ban';
export const BODY_MESSAGE_FOR_ERROR_TYPE_FILE_BAN = 'Credit inquiry failed due to a ban on accessing the file. Please contact customer for more details.';
export const BODY_MESSAGE_FOR_ERROR_TYPE_FILE_NOT_FOUND = `We can't find a credit file for this applicant. Please confirm their details and try again.`;
export const BODY_MESSAGE_FOR_ERROR_TYPE_CREDIT_FILE_NOT_FOUND = `Equifax cannot provide the PDF at this time. Please raise this with the support team`;
export const BODY_MESSAGE_FOR_UNEXPECTED_ERROR = `There was an issue retriving this customers credit file. Please try again and if you receive this error, raise this issue with the support team`;
export const BODY_MESSAGE_FOR_NO_OF_ATTEMPTS = `You have <b>[NO_OF_ATTEMPTS] attempts </b> remaining`;

export const EXPENSE_TYPE_HOUSEHOLD = 'Household';

export const ERROR_FOR_MAX_NUMBER_REACHED = 'You have reached the maximum. Please enter a valid number';

export const ERROR_MESSAGE_FOR_DISTINCT_MOBILE_NUM = 'Mobile number already exists in our records. Please enter a valid mobile number.';

export const ERROR_MESSAGE_FOR_TRADE_IN_SHOULD_NOT_GREATER_THAN_PURCHASE_PRICE = 'Please enter valid trade-in amount. Loan amount should not be nagative.';

export const ERROR_MESSAGE_FOR_DIRECTORS_DELETION = (directorsLabel: string) => `No. of ${directorsLabel.toLocaleLowerCase()} can't be less than the number of contacts added. Please delete any contact in order to reduce the no. of ${directorsLabel.toLocaleLowerCase()}`;

export const LABEL_FOR_MATCHES = 'Lender matches';

export const LABEL_FOR_REPAYMENTS = 'Repayment Amount';
export const LABEL_FOR_BASE = 'Base Rate';

export const LABEL_FOR_BASE_RATE_FOR_LENDER = 'Base Rate';
export const LABEL_FOR_CUSTOMER_RATE_FOR_LENDER = 'Customer Rate';
export const LABEL_FOR_COMPARISON_RATE_FOR_LENDER = 'Comparison Rate';
export const LABEL_FOR_CUSTOMER_RATE = 'Customer rate (%)';
export const LABEL_FOR_CUSTOMER = 'Customer';
export const LABEL_FOR_COMPARISON = 'Comparison';
export const LABEL_FOR_BROKER_LOADING = 'Broker Loading';
export const LABEL_FOR_COMPARISON_RATE = 'Comparison rate (%)';
export const LABEL_FOR_LENDING_COMMISSION = 'Lending commission';
export const LABEL_FOR_ORIGINATION_FEE = 'Origination fee';
export const LABEL_FOR_ORIGINATION_FEE_CAPITAL = 'Origination Fee';

export const LABEL_FOR_TOTAL_INCOME = 'Total income';
export const LABEL_FOR_TOTAL_FINANCE_INCOME = 'Total Finance Income';
export const LABEL_FOR_LENDER_MATCHES_FOUND = 'Lender matches found';
export const LABEL_FOR_PSPR = 'PPSR';
export const LABEL_FOR_FEATURES = 'Features';
export const LABEL_FOR_KNOCKOUTS = 'Knockouts';
export const LABEL_FOR_FEES = 'Fees';
export const LABEL_FOR_DOCUMENTS = 'Documents';
export const KEY_FOR_FEATURES = 'features';
export const KEY_FOR_FEES = 'fees';
export const KEY_FOR_KNOCKOUTS = 'Knockouts';
export const KEY_FOR_DOCUMENTS = 'documents';
export const LABEL_FOR_NO_MATCHES_FOUND = 'No matches are found';
export const LABEL_FOR_NO_MATCHES_GENERATED = 'No matches have been generated';
export const LABEL_FOR_NO_PRELIMINARY_LENDER_MATCHES_ARE_FOUND_FOR_THE_CUSTOMER = 'No lender has been selected by the customer';
export const LABEL_FOR_FORTNIGHT = 'Fortnightly';
export const LABEL_FOR_WEEK = 'Weekly';
export const FORESEEEBLE_CHANGES_TO_YOUR_FINANCIAL_SITUATION_LABEL = 'Do you see any foreseeable changes to your financial situation in the near future?';
export const LABEL_FOR_NOTES = 'Notes';
export const PLACEHOLDER_WRITE_ANY_NOTES = 'Write any notes here';
export const ERROR_MESSAGE_FOR_CREDIT_COMMITMENT_NOTES = 'Credit commitment is required';
export const ERROR_MESSAGE_FOR_FORESEEABLE_CHANGES_NOTES = 'Foreseeable changes notes is required';
export const ERROR_MESSAGE_FOR_FORESEEABLE_CHANGES = 'Foreseeable changes is required';
export const LABEL_FOR_SECONDARY_APPLICANT_DATA = 'Secondary applicant data';
export const LABEL_FOR_BUSINESS_INFORMATION = 'Business information';

export const ERROR_MESSAGE_FOR_USER_STATUS = 'Please select user status';
export const ERROR_MESSAGE_FOR_USER_ROUND_ROBIN = 'Please select Round robin status';
export const LABEL_SETTLEMENT = 'settlement';
export const LABEL_NEW_SETTLEMENT_AGENT = 'New settlement agent';
export const ERROR_MESSAGE_FOR_SETTLEMENT_AGENT = 'Please select settlement agent';
export const LABEL_SELECTED_BY_CUSTOMER = 'SELECTED BY CUSTOMER';
export const LABEL_FIXED_RATE = 'Fixed rate';
export const LABEL_VIEW_DETAILS = 'View details';
export const LABEL_CREDIT_SCORES = 'Credit scores';
export const LABEL_RHI_STATUS = 'RHI status';
export const LABEL_DEFAULTS = 'Defaults';
export const LABEL_ENQUIRIES = 'Enquiries';
export const LABEL_FILE_AGE = 'File age';
export const LABEL_CREDIT_ENQUIRIES = 'Credit Enquiries';
export const LABEL_CREDIT_FILE_AGE = 'Credit file age';
export const LABEL_VEDA = 'Veda 1.1';
export const LABEL_RRP = 'RRP';
export const LABEL_RRP_DESCRIPTION = '*Estimated recommended retail price from Redbook';
export const LABEL_UNKNOWN_PRICE = '$##,###.##';
export const LABEL_FUNNEL = 'Funnel';
export const LABEL_REFINANCE = 'Refinance';
export const LABEL_VEHICLE_BUYING = 'Vehicle Buying';
export const ERROR_MESSAGE_FOR_APP_LINK_NOT_AVAILABLE = '*App link is not available for';
export const LABEL_APPLICATION = 'application';
export const ERROR_MESSAGE_FOR_APP_LINK_NOT_AVAILABLE_FOR_LEADS = [LABEL_REFINANCE, LABEL_VEHICLE_BUYING];
export const LABEL_FROM_APPLICANT = '(from applicant)';
export const APPLICATION_TITLE = 'Applications';
export const APPLICATION_ROUTE_NAME = 'finance-applications';
export const ERROR_MESSAGE_FOR_DEALERSHIP_NAME = 'Please enter dealership name';
export const ERROR_MESSAGE_FOR_VALID_DEALERSHIP_ABN = 'Please enter valid dealership abn';
export const ERROR_MESSAGE_FOR_DEALERSHIP_ABN_LENGTH = 'Dealership abn must contain 11 characters';
export const ERROR_MESSAGE_PLEASE_ENTER_MONTHLY_PREMIUM = 'Please enter monthly premium';
export const LABEL_MONTHLY_OPTION_LABEL = 'Monthly';
export const LABEL_ANNUALLY_OPTION_LABEL = 'Annually';
export const LABEL_ANNUALLY_OPTION_VALUE = 'Yearly';
export const LABEL_MONTHLY_PREMIMUM = 'Monthly premium $';
export const LABEL_TOTAL_FREQUENCY_MONTH = 12;
export const INSURANCE_FREQUENCY = [
  {
    label: LABEL_MONTHLY_OPTION_LABEL,
    value: LABEL_MONTHLY_OPTION_LABEL,
  },
  {
    label: LABEL_ANNUALLY_OPTION_LABEL,
    value: LABEL_ANNUALLY_OPTION_VALUE,
  },
];
export const ERROR_MESSAGE_FOR_WARRENTY_RETAIL_PRICE = 'Please enter valid retail amount. Retail amount should be greater than or equal to Wholesale amount.';
export const LABEL_NULL_TYPE_STRING = 'null';
export const LABEL_APPLICATION_DETAILS_TABS = 'applicationDetails';
export const LABEL_LOAN_DETAILS_TABS = 'loanDetails';
export const LABEL_NO_LENDER_SELECTED = 'No lender has been selected';
export const LABEL_RATE_TYPE = 'Rate type';
export const LABEL_FIXED = 'Fixed';
export const LABEL_SOURCE = 'Source';
export const LABEL_UPDATED_AT = 'Updated at';
export const LOGIN_TITLE = 'Login';
export const ERROR_MESSAGE_FOR_YEAR_SHUOLD_BE_ZERO_OR_GREATER = 'Entered value should be equal or greater than 0';
export const LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE = 'Unable to login to Accelerate portal';
export const LABEL_FOR_CONTACT_SUPPORT_FOR_FUEL = 'Unable to login to Fuel portal';
export const LABEL_FOR_RESET_PASSWORD = 'Unable to reset password in';
