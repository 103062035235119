import React, { useState } from 'react';
import { FieldValues, FormProvider, useForm, UseFormReset } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress, Divider, Link, Typography } from '@mui/material';
import Button from '../../shared/Button';
import theme from '../../theme';
import { ERROR_MESSAGE_FOR_NEW_PASSWORD, ERROR_MESSAGE_FOR_OLD_PASSWORD, ERROR_MESSAGE_FOR_PASSWORD_LENGTH, LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE, REGEX_FOR_PASSWORD } from '../../constants';
import DynamicForm, { FormField } from '../../shared/Form';

interface DialogBoxProps {
  open: boolean;
  handleClose?: () => void;
  onUpdatePassword: (oldPassword: string, newPassword: string, reset: UseFormReset<FieldValues>) => Promise<void>;
}

const ChangePasswordDialogBox: React.FC<DialogBoxProps> = ({ open, handleClose, onUpdatePassword }) => {
  const methods = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const { reset } = methods;

  const validateOldPassword = (password: string): string => {
    if (password === '' || password === undefined) {
      return ERROR_MESSAGE_FOR_OLD_PASSWORD;
    }
    return REGEX_FOR_PASSWORD.test(password) ? '' : ERROR_MESSAGE_FOR_PASSWORD_LENGTH;
  };
  const validateNewPassword = (password: string): string => {
    if (password === '' || password === undefined) {
      return ERROR_MESSAGE_FOR_NEW_PASSWORD;
    }
    return REGEX_FOR_PASSWORD.test(password) ? '' : ERROR_MESSAGE_FOR_PASSWORD_LENGTH;
  };

  const formFields: FormField[] = [
    {
      label: 'Old Password',
      name: 'oldPassword',
      type: 'password',
      placeHolder: 'Enter old password',
      validateField: validateOldPassword,
      isRequired: true,
    },
    {
      label: 'New Password',
      name: 'newPassword',
      type: 'password',
      placeHolder: 'Enter new password',
      validateField: validateNewPassword,
      isRequired: true,
    },
  ];

  const onSubmit = async (formData: Record<string, any>) => {
    const validationErrors = formFields.reduce(
      (errors, field) => {
        if (field.validateField) {
          const errorMessage = field.validateField(formData[field.name]);
          if (errorMessage) {
            errors[field.name] = errorMessage;
          }
        }
        return errors;
      },
      {} as Record<string, string>
    );

    if (Object.keys(validationErrors).length > 0) {
      Object.entries(validationErrors).forEach(([name, error]) => {
        methods.setError(name, { type: 'manual', message: error });
      });
      return;
    }

    setIsLoading(true);
    try {
      await onUpdatePassword(formData.oldPassword, formData.newPassword, reset);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDialog = () => {
    reset();
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth={true}
      PaperProps={{
        sx: {
          boxShadow: 'none',
          borderRadius: '0',
          width: '90%',
          maxWidth: { xs: '100%', sm: '80%', md: '28%' },
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', paddingLeft: '15px', paddingTop: '20px' }}>
        <Typography fontSize="14px" fontWeight="800">
          Change Password
        </Typography>
      </DialogTitle>
      <form onSubmit={methods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', paddingX: '15px', marginTop: '10px' }}>
          <FormProvider {...methods}>
            <DynamicForm fields={formFields} />
          </FormProvider>
          <Box sx={{ textAlign: 'center' }}>
            <Typography fontSize="0.875rem" color="#303030" marginBottom="0.5rem">
              For any technical assistance
            </Typography>
            <Link href={`mailto:tech-support@carclarity.com.au?subject=${LABEL_FOR_CONTACT_SUPPORT_FOR_ACCELERATE}`} style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
              <Typography fontSize="14px" fontWeight="800" color={theme.palette.primary.main}>
                Contact Support
              </Typography>
            </Link>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: 'space-between', paddingRight: '20px', paddingBottom: '10px' }}>
          <Button
            variantType="filled"
            onClick={handleCloseDialog} // Use the modified handleClose function
          >
            <Typography fontWeight="800" fontSize="14px">
              Cancel
            </Typography>
          </Button>
          <Box sx={{ position: 'relative' }}>
            <Button variantType="full" color={theme.palette.primary.main} type="submit" sx={{ height: '31px', width: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {isLoading ? (
                <CircularProgress size={20} sx={{ color: '#fff', marginTop: '5px' }} />
              ) : (
                <Typography fontWeight="800" fontSize="14px">
                  Update
                </Typography>
              )}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePasswordDialogBox;
