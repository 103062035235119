import React from 'react';
import { AppBar, Box, Typography } from '@mui/material';
import theme from '../../theme';

interface HeaderProps {
  logoSrc: string;
}

const Header: React.FC<HeaderProps> = ({ logoSrc }) => (
  <AppBar
    position="static"
    sx={{
      backgroundColor: theme.palette.primary.main,
      height: '56px',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: '0 20px',
      boxShadow: 'none',
    }}
  >
    <Box display="flex" justifyContent="center" alignItems="center">
      <img src={logoSrc} alt="Logo" height="33px" />
      <Box sx={{ paddingLeft: '7px', paddingTop: '1px' }}>
        <Typography
          variant="h6"
          component="div"
          sx={{
            lineHeight: 1,
            fontSize: '10px',
            fontWeight: '900',
            background: '#a35ce8',
            padding: '6px 4px 4px 6px',
            borderRadius: '2px',
            letterSpacing: '2px',
            textTransform: 'uppercase',
          }}
        >
          Accelerate
        </Typography>
      </Box>
    </Box>
  </AppBar>
);

export default Header;
