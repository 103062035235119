import React, { useState, useRef } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Box, { BoxProps } from '@mui/material/Box';
import { outlinedInputClasses, styled, SxProps } from '@mui/material';

interface OTPInputProps {
  length: number;
  onChange?: (value: string, index: number) => void;
  onComplete?: (otp: string) => void;
  textFieldProps?: TextFieldProps;
  disabledInputField?: boolean;
  textFieldStyle?: SxProps;
  borderColor?: string;
  sx?: BoxProps;
}

const StyledTextField = styled(TextField)<{ custombordercolor?: string }>(({ custombordercolor }) => ({
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: custombordercolor,
  },
}));

const OTPInput: React.FC<OTPInputProps> = ({ length = 6, onChange, onComplete, textFieldProps, disabledInputField, textFieldStyle, borderColor, sx }) => {
  const [values, setValues] = useState<string[]>(Array(length).fill(''));
  const inputs = useRef<HTMLInputElement[]>([]);

  const focusInput = (index: number) => {
    if (inputs.current[index]) {
      inputs.current[index].focus();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = event.target;

    if (value.length > 1) return;

    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
    onChange?.(value, index);

    if (value.length === 1 && index < length - 1) {
      focusInput(index + 1);
    } else if (value.length === 0 && index > 0) {
      focusInput(index - 1);
    }

    if (index === length - 1 && value.length === 1) {
      const otp = newValues.join('');
      onComplete?.(otp);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    const inputElement = event.target as HTMLInputElement;
    if (event.key === 'Backspace') {
      if (inputElement.value === '') {
        if (index > 0) {
          setValues((values) => {
            const newValues = [...values];
            newValues[index - 1] = '';
            return newValues;
          });
          focusInput(index - 1);
        }
      } else {
        setValues((values) => {
          const newValues = [...values];
          newValues[index] = '';
          return newValues;
        });
      }
    } else if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Box sx={sx}>
      {Array.from({ length }).map((_, index) => (
        <StyledTextField
          key={index}
          type="tel"
          inputProps={{ maxLength: 1 }}
          sx={textFieldStyle}
          inputRef={(el) => {
            inputs.current[index] = el;
          }}
          value={values[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          disabled={disabledInputField}
          custombordercolor={borderColor}
          {...textFieldProps}
        />
      ))}
    </Box>
  );
};

export default OTPInput;
