import React, { useEffect, useState } from 'react';
import { Drawer, Box, Typography, IconButton, Divider, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// @ts-ignore
import CCApplicationStatus from '@carclarity/cc-application-status';
import theme from 'theme';
import RequestQuote from '../../icons/request_quote.svg';
import Person from '../../icons/person.svg';
import Referral from '../../icons/Referral.svg';
import ReferralNotes from '../../icons/referral_notes.svg';

interface SlideOutPaneProps {
  open: boolean;
  onClose: () => void;
  details: any;
  activeTab: number;
  isSettlement?: boolean;
}

const SlideOutPane: React.FC<SlideOutPaneProps> = ({ open, onClose, details, activeTab, isSettlement }) => {
  const [tabIndex, setTabIndex] = useState<number>(activeTab);

  useEffect(() => {
    setTabIndex(activeTab);
  }, [activeTab]);

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => setTabIndex(newValue);

  const formatMobileNumber = (mobile: string) => {
    if (mobile) {
      return mobile.length === 11 ? '+' + mobile.substring(0, 2) + ' ' + mobile.substring(2, 5) + ' ' + mobile.substring(5, 8) + ' ' + mobile.substring(8) : mobile.substring(0, 4) + ' ' + mobile.substring(4, 7) + ' ' + mobile.substring(7);
    }
    return mobile;
  };

  const renderAmountBox = (label: string, amount: string) => (
    <Box bgcolor="#E5F8ED" padding="12px 12px 8px" borderRadius="8px" mt="12px" width={isSettlement ? '50%' : '100%'}>
      <Typography fontSize="14px" fontWeight="500">
        {label}
      </Typography>
      <Typography fontSize="24px" color="rgb(0, 184, 73)" fontWeight="800">
        {amount === '--' ? '$0' : amount}
      </Typography>
    </Box>
  );

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: 350, padding: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" flexDirection="row">
            <Typography fontSize="0.75rem" color="#7A7A7A" marginRight="4px">
              ID
            </Typography>
            <Typography fontSize="12px" color="#303030">
              {details?.id}
            </Typography>
            <Typography fontSize="0.75rem" color="#7A7A7A" marginLeft="24px">
              Status
            </Typography>
            <Box marginLeft="0.5rem">
              <CCApplicationStatus status={details?.status || 'Unknown'} />
            </Box>
          </Box>
          <IconButton sx={{ padding: 0 }} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider sx={{ my: '10px' }} />

        <Box display="flex" alignItems="center" flexDirection="row">
          <Typography fontSize="16px" color="#303030" fontWeight="800">
            {details?.User}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" width="100%">
          {isSettlement && (
            <Box bgcolor="#E5F8ED" padding="12px 12px 8px" borderRadius="8px" mt="12px" width="50%" marginRight="16px">
              <Typography fontSize="14px" fontWeight="500">
                NAF
              </Typography>
              <Typography fontSize="24px" color="rgb(0, 184, 73)" fontWeight="800">
                {details?.naf === '--' ? '$0' : details?.naf}
              </Typography>
            </Box>
          )}

          <Box bgcolor="#E5F8ED" padding="12px 12px 8px" borderRadius="8px" mt="12px" width={isSettlement ? '50%' : '100%'}>
            <Typography fontSize="14px" fontWeight="500">
              {isSettlement ? 'Commission' : 'Loan Amount'}
            </Typography>
            <Typography fontSize="24px" color="rgb(0, 184, 73)" fontWeight="800">
              {isSettlement && renderAmountBox('NAF', details?.naf)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" flexDirection="row" mt="1rem">
          <Typography fontSize="0.75rem" color="#7A7A7A" marginRight="4px">
            Last updated at
          </Typography>
          <Typography fontSize="12px" color="#000000">
            {details?.updatedAt}
          </Typography>
        </Box>
        <Divider sx={{ my: '1rem' }} />
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ sx: { height: '4px' } }}
          sx={{
            minHeight: '32px',
            marginBottom: '8px',
            '& .MuiTab-root': {
              minHeight: '32px',
              padding: '0',
              minWidth: '20px',
              marginRight: '48px',
            },
            '& .Mui-selected img': {
              filter: 'none',
            },
            '& .MuiTab-root:not(.Mui-selected) img': {
              filter: 'invert(13%) sepia(181%) saturate(15%) hue-rotate(721deg) brightness(104%) contrast(88%)!important',
            },
          }}
        >
          <Tab icon={<img src={RequestQuote} alt="Loan details" style={{ width: '20px', height: '20px' }} />} />
          <Tab icon={<img src={Person} alt="Customer details" style={{ width: '20px', height: '20px' }} />} />
          <Tab icon={<img src={Referral} alt="Referrer details" style={{ width: '20px', height: '20px' }} />} />
          <Tab icon={<img src={ReferralNotes} alt="Referrer notes" style={{ width: '20px', height: '20px' }} />} />
        </Tabs>

        <TabPanel value={tabIndex} index={0}>
          <Typography fontSize="14px" fontWeight="800" marginBottom="16px" color="#303030">
            Loan details
          </Typography>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Term
              </Typography>
              <Typography fontSize="12px" color="#303030">
                {details?.term} years
              </Typography>
            </Box>
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Loan Type
              </Typography>
              <Typography fontSize="12px" color="#303030">
                {details?.loanType}
              </Typography>
            </Box>
          </Box>
          <Typography fontSize="14px" fontWeight="800" marginY="24px" color="#303030">
            Asset details
          </Typography>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box width="50%" paddingRight="20px">
              <Typography fontSize="12px" color="#7A7A7A">
                Asset
              </Typography>
              <Typography fontSize="12px" color="#303030" fontWeight="500">
                {details?.asset}
              </Typography>
            </Box>
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Trade In
              </Typography>
              <Typography fontSize="12px" color="#303030" fontWeight="500">
                {details?.tradeIn}
              </Typography>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <Typography fontSize="14px" fontWeight="800" marginBottom="16px" color="#303030">
            Customer details
          </Typography>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                First name
              </Typography>
              <Typography fontSize="12px" color="#303030">
                {details?.firstName}
              </Typography>
            </Box>
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Last name
              </Typography>
              <Typography fontSize="12px" color="#303030">
                {details?.lastName}
              </Typography>
            </Box>
          </Box>
          <Box marginTop="15px" display="flex" flexDirection="row" justifyContent="space-between">
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Mobile
              </Typography>
              <Typography component="a" href={`tel:${details?.mobile}`} fontSize="14px" color={theme.palette.primary.main} fontWeight="900" sx={{ textDecoration: 'none' }}>
                {formatMobileNumber(details?.mobile)}
              </Typography>
            </Box>
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Email
              </Typography>
              <Typography component="a" href={`mailto:${details?.email}`} fontSize="14px" color={theme.palette.primary.main} fontWeight="900" sx={{ textDecoration: 'none' }}>
                {details?.email}
              </Typography>
            </Box>
          </Box>
          <Box marginTop="15px" display="flex" flexDirection="row" justifyContent="space-between">
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                State
              </Typography>
              <Typography fontSize="12px" color="#303030">
                {details?.state}
              </Typography>
            </Box>
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Preferred communication method
              </Typography>
              <Typography fontSize="12px" color="#303030">
                {details?.preferredComm?.join(', ')}
              </Typography>
            </Box>
          </Box>
          {details?.type === 'Commercial' && (
            <>
              <Typography fontSize="14px" fontWeight="800" marginTop="24px" marginBottom="16px" color="#303030">
                Business details
              </Typography>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Box width="50%" paddingRight="20px">
                  <Typography fontSize="12px" color="#7A7A7A">
                    Business
                  </Typography>
                  <Typography fontSize="12px" color="#303030" fontWeight="500">
                    {details?.businessName}
                  </Typography>
                </Box>
                <Box width="50%">
                  <Typography fontSize="12px" color="#7A7A7A">
                    ABN
                  </Typography>
                  <Typography fontSize="12px" color="#303030" fontWeight="500">
                    {details?.abn}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          <Typography fontSize="14px" fontWeight="800" marginBottom="16px" color="#303030">
            Referral details
          </Typography>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Created at
              </Typography>
              <Typography fontSize="12px" color="#303030">
                {details?.createdAt}
              </Typography>
            </Box>
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Customer consent at
              </Typography>
              <Typography fontSize="12px" color="#303030">
                {details?.customerConsent}
              </Typography>
            </Box>
          </Box>
          <Box marginTop="15px" display="flex" flexDirection="row" justifyContent="space-between">
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Referrer
              </Typography>
              <Typography fontSize="12px" color="#303030">
                {details?.referrer}
              </Typography>
            </Box>
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Subaccount
              </Typography>
              <Typography fontSize="12px" color="#303030">
                {details?.subAccount}
              </Typography>
            </Box>
          </Box>
          <Box marginTop="15px" display="flex" flexDirection="row" justifyContent="space-between">
            <Box width="50%">
              <Typography fontSize="12px" color="#7A7A7A">
                Agent
              </Typography>
              <Typography fontSize="12px" color="#303030">
                {details?.agent}
              </Typography>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel value={tabIndex} index={3}>
          <Typography fontSize="14px" fontWeight="800" marginBottom="16px" color="#303030">
            Agent notes
          </Typography>
          <Box marginTop="15px" display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <Typography fontSize="14px" color="#7A7A7A">
                No notes are available from an agent
              </Typography>
            </Box>
          </Box>
        </TabPanel>
      </Box>
    </Drawer>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
    {value === index && <Box>{children}</Box>}
  </div>
);

export default SlideOutPane;
