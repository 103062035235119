import React from 'react';
import { Typography, Container } from '@mui/material';

const Copyright: React.FC = () => (
  <Container>
    <Typography variant="body2" color="textSecondary" align="center">
      Copyright © CarClarity {new Date().getFullYear()}
    </Typography>
  </Container>
);

export default Copyright;
