export const yearOption = (num: number) => ({
  value: num,
  label: `${num} ${num === 1 ? 'year' : 'years'}`,
})

export const yearViewOption = (num: string | number) => num === 1 ? `${num} year` : `${num} years`;

export const generateOptionsFromTypesWhereKeyIsSameAsValue = (data: any[]) =>
  data.map((row, index) => ({
    key: index,
    value: row.value,
    label: row.label,
  }));


export const generateOptionsWithSameValueAndLabel = (data: any[]) => 
  data.map((row, index) => ({
    key: index,
    value: row,
    label: row,
  }));

export const generateOptionsFromTypesWhereTheKeyIsSameAsValue = (data: any[]) => 
   data.map((val, index) => ({
    key: index,
    value: val,
    label: val,
  }));
