import React from 'react';
import { Button as MuiButton, ButtonProps, TypographyProps, Typography } from '@mui/material';

export interface CustomButtonProps extends Omit<ButtonProps, 'color'> {
  variantType?: 'filled' | 'full' | 'outlined';
  color?: string;
  onPress?: () => void;
  textProps?: TypographyProps;
  sx?: object;
  isImage?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({ variantType, color, onPress, textProps, children, sx, isImage, ...props }) => (
  <MuiButton
    onClick={onPress}
    sx={{
      height: '40px',
      backgroundColor: variantType === 'full' ? color : 'transparent',
      color: variantType === 'full' ? '#fff' : color,
      border: variantType === 'outlined' ? `1px solid ${color}` : 'none',
      '&:hover': {
        backgroundColor: variantType === 'full' ? color : 'transparent',
        opacity: 0.8,
      },
      textTransform: 'none',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...sx,
      '&.Mui-disabled': {
        backgroundColor: '#e8e8e8',
        color: '#fff',
      },
    }}
    {...props}
  >
    {isImage ? (
      children
    ) : (
      <Typography component="span" {...textProps}>
        {children}
      </Typography>
    )}
  </MuiButton>
);

export default CustomButton;
