import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, Typography, Menu, MenuItem, Box, useMediaQuery } from '@mui/material';
import { PersonOutline } from '@mui/icons-material';
import { FieldValues, UseFormReset } from 'react-hook-form';
import ccLogoFull from '../../icons/cc-logo.svg';
import ccLogo from '../../icons/cc-logo-full.svg';
import { URLS_FOR_HIDE_THE_PORTAL_TITLE, URLS_FOR_HIDE_NAVBAR_DROPDOWN_AND_SIDEBAR } from '../../constants/navbar';
import theme from '../../theme';
import Button from '../../shared/Button';
import ChangePasswordDialogBox from '../ChangePasswordDialogBox';
import { PASSWORD_CHANGE_SUCCESS, USER_SESSION_DATA_KEY } from '../../constants';
import SnackbarAlert, { SnackBarProps } from '../../shared/SnackbarAlert';
import axiosInstance from '../../axiosInstance';
import { axiosErrorMessageExtractor } from '../../utils/error';

interface NavbarProps {
  onLogout?: () => void;
}

const NavBar: React.FC<NavbarProps> = ({ onLogout }) => {
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [urlName, seturlName] = useState<string>('');
  const [isChangePasswordDialog, setIsChangePasswordDialog] = useState<boolean>(false);
  const [snackbarAlert, setSnackbarAlert] = useState<SnackBarProps>({
    open: false,
    message: '',
    severity: 'error',
    onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
  });

  const userObj = JSON.parse(localStorage.getItem('user') || '{}');
  const firstName = userObj?.firstName;

  useEffect(() => {
    seturlName(location.pathname);
  }, [location]);

  const isPortalTitleVisible = useMemo(() => URLS_FOR_HIDE_THE_PORTAL_TITLE.includes(urlName), [urlName]);

  const isDropDownVisible = useMemo(() => {
    const isLoggedIn = true;
    return isLoggedIn && !URLS_FOR_HIDE_NAVBAR_DROPDOWN_AND_SIDEBAR.includes(urlName);
  }, [urlName]);

  const handleMenu = (event: { currentTarget: any }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    setIsChangePasswordDialog(true);
  };

  const onCloseChangePassword = () => {
    setIsChangePasswordDialog(false);
  };

  const onUpdatePassword = async (oldPassword: string, newPassword: string, reset: UseFormReset<FieldValues>) => {
    if (!oldPassword || !newPassword) return;

    try {
      const userSessionData = localStorage.getItem(USER_SESSION_DATA_KEY);
      if (!userSessionData) {
        setSnackbarAlert({
          open: true,
          message: 'User session data not found',
          severity: 'error',
          onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
        });
        return;
      }

      const { _id } = JSON.parse(userSessionData);
      const params = {
        oldPassword,
        newPassword,
      };

      const response = await axiosInstance.patch(
        process.env.REACT_APP_API_URL + `/user/${_id}/update-email-password`,
        params
      );

      if (response.status === 204) {
        setSnackbarAlert({
          open: true,
          message: PASSWORD_CHANGE_SUCCESS,
          severity: 'success',
          onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
        });
        reset();
      }
    } catch (err: any) {
      setSnackbarAlert({
        open: true,
        message: axiosErrorMessageExtractor(err),
        severity: 'error',
        onClose: () => setSnackbarAlert({ ...snackbarAlert, open: false }),
      });
      reset();
    } finally {
      reset();
      setIsChangePasswordDialog(false);
    }
  };

  const handleLogout = () => {
    handleClose();
    onLogout?.();
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme.palette.primary.main,
        height: '56px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '0 20px',
        boxShadow: 'none',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={isMobile ? ccLogo : ccLogoFull} alt="Logo" height="33px" />
          {isPortalTitleVisible && (
            <Typography
              variant="h6"
              sx={{
                fontWeight: 800,
                fontSize: 14,
                paddingLeft: 1,
              }}
              component="div"
            >
              Portal
            </Typography>
          )}
        </Box>

        {isDropDownVisible && (
          <>
            <Button variantType="filled" onClick={handleMenu} color="white" sx={{ padding: 0 }}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <PersonOutline />
                <Typography marginLeft="3px" fontSize="12PX" fontWeight="500" component="div">
                  {firstName}
                </Typography>
              </Box>
            </Button>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  boxShadow: 'none',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 25,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem
                onClick={handleChangePassword}
                sx={{
                  '&:hover': {
                    backgroundColor: '#f1e6fb',
                  },
                  height: '36px',
                }}
              >
                <Typography fontSize="0.75rem" fontWeight="800" color="#606266" component="div">
                  Change password
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={handleLogout}
                sx={{
                  '&:hover': {
                    backgroundColor: '#f1e6fb',
                  },
                  height: '36px',
                }}
              >
                <Typography fontSize="0.75rem" fontWeight="800" color="#606266" component="div">
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
      <ChangePasswordDialogBox open={isChangePasswordDialog} handleClose={onCloseChangePassword} onUpdatePassword={onUpdatePassword} />
      {snackbarAlert.message && <SnackbarAlert autoHideDuration={3000} message={snackbarAlert.message} severity={snackbarAlert.severity} open={snackbarAlert.open} onClose={snackbarAlert.onClose} />}
    </AppBar>
  );
};

export default NavBar;
