import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import ReferralCard from 'components/ReferralCard';
// @ts-ignore
import CCApplicationStatus from '@carclarity/cc-application-status';
import { Referral } from 'pages/referrals/Referrals';

interface ReferralsKanbanViewProps {
  title: string;
  count: number;
  referrals: any;
  loadMoreReferrals: (status: string, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
  totalDocs: number;
}

const ReferralsKanbanView: React.FC<ReferralsKanbanViewProps> = ({ title, count, referrals, loadMoreReferrals, totalDocs }) => {
  const [isColumnLoading, setIsColumnLoading] = useState<boolean>(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useRef<HTMLDivElement | null>(null);

  const loadMore = useCallback(() => {
    if (count < totalDocs && !isColumnLoading) {
      setIsColumnLoading(true);
      loadMoreReferrals(title, setIsColumnLoading);
    }
  }, [count, totalDocs, isColumnLoading, loadMoreReferrals, title]);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    });

    if (lastElementRef.current) observer.current.observe(lastElementRef.current);

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [loadMore]);

  return (
    <Box display="flex" flexDirection="column" marginTop="5px" minWidth="318px" height="100%">
      <Box
        sx={{
          border: '1px solid #e8e8e8',
          borderRadius: '4px',
          height: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: 'white',
          padding: '16px',
          boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.05) !important',
          marginBottom: '0.5rem',
        }}
      >
        <CCApplicationStatus status={title} />
        <Typography fontSize="0.75rem" marginLeft="0.5rem" fontWeight="800">
          {count}
        </Typography>
      </Box>
      {referrals && referrals.length > 0 && (
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {referrals.map((referral: Referral | undefined, index: React.Key | null | undefined) => (
            <Box key={index} mb={2} ref={index === referrals.length - 1 ? lastElementRef : null}>
              {referral && <ReferralCard id={referral.id} loanAmount={referral.loanAmount} updatedAt={referral.updatedAt} name={referral.User} isKanbanView={true} details={referral} />}
            </Box>
          ))}
          {isColumnLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ReferralsKanbanView;
